<template>
	<AsiCardDetail :icon="icons.meta" :title="$t('ui.terms.metaData')"
	               :show-action-button="!readonly" :action-button-disabled="disabled"  @actionButtonClicked="updateDialog.open()">
		<AsiCardDetailEntry :icon="icons.metaKeywords" :label="$t('ui.terms.metaKeywords')" :value="category.metaKeywords">
			<template v-slot:default v-if="keywords.length > 0">
				<div class="d-flex flex-row flex-wrap ma-n1">
					<AsiChip :icon="icons.metaKeyword" v-for="keyword in keywords" :key="keyword"
					         class="ma-1" small label>
						{{ keyword }}
					</AsiChip>
				</div>
			</template>
		</AsiCardDetailEntry>
		<AsiCardDetailEntry :icon="icons.metaDescription" :label="$t('ui.terms.metaDescription')" :value="category.metaDescription"/>

		<CategoryUpdateDialogMetaData v-if="!readonly && updateDialog.isLoaded" :open="updateDialog.isOpen" :category="category"
		                          @cancel="updateDialog.close()" @save="updateDialog.close(); change();"/>
	</AsiCardDetail>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import {ICategoryAdmin} from "@/models/category/CategoryAdminModels";
	import Icon from "@/plugins/icons";
	import DialogHandler from "@/components/common/DialogHandler";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import AsiChip from "@/components/common/AsiChip.vue";
	import StringHelper from "@/helpers/StringHelper";
	import CategoryUpdateDialogMetaData from "@/components/category/admin/CategoryUpdateDialogMetaData.vue";

	@Component({
		components: {CategoryUpdateDialogMetaData, AsiChip, AsiCardDetail, AsiCardDetailEntry}
	})
	export default class CategoryCardMeta extends Vue {

		@Prop({type: Object, required: true})
		public category!: ICategoryAdmin;

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		private icons = Icon;
		private updateDialog: DialogHandler = new DialogHandler();

		private get keywords(): string[] {
			if (this.category.metaKeywords === null || StringHelper.isEmpty(this.category.metaKeywords)) {
				return [];
			}

			return this.category.metaKeywords
				.split(/\s*[,;]\s*/)
				.filter(w => !StringHelper.isEmpty(w))
				.map(w => w.trim());
		}

		@Emit('change')
		public change(): void {
			return;
		}

	}
</script>

<style lang="scss" scoped>

</style>
