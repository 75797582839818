<template>
	<AsiCardDetail :title="titleFinal" :icon="iconFinal">
		<AsiCardDetailEntry :label="$t('locale.de-CH')" :value="translatedValue.de" :icon="icons.language"/>
		<AsiCardDetailEntry :label="$t('locale.fr-CH')" :value="translatedValue.fr" :icon="icons.language"/>
		<AsiCardDetailEntry :label="$t('locale.en-CH')" :value="translatedValue.en" :icon="icons.language"/>
	</AsiCardDetail>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import {ITranslatedValue} from "@/models/translated-value/TranslatedValueModels";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import {TranslateResult} from "vue-i18n";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import Icon from "@/plugins/icons";
	@Component({
		components: {AsiCardDetailEntry, AsiCardDetail}
	})
	export default class DetailCardTranslatedValue extends Vue {

		@Prop({default: null})
		public title!: string | TranslateResult | null;

		@Prop({type: String, default: null})
		public icon!: string | null;

		@Prop({type: Object, required: true})
		public translatedValue!: ITranslatedValue | null;

		private icons = Icon;

		private get titleFinal(): string | TranslateResult {
			return this.title ?? this.$t('translatedValue.singular');
		}

		private get iconFinal(): string {
			return this.icon ?? Icon.language;
		}

	}
</script>

<style lang="scss" scoped>

</style>
