<template>
	<small v-if="small" class="d-flex flex-row align-center">
		<v-icon v-if="!hideIcon && icon" :size="15" class="mr-1">
			{{ icon }}
		</v-icon>

		<router-link :to="entry.route" v-if="linkEntry && entry.route">{{ label }}</router-link>
		<span v-else>{{ label }}</span>
	</small>
	<span v-else class="d-flex flex-row align-center">
		<v-icon v-if="!hideIcon && icon" :size="20" class="mr-1">
			{{ icon }}
		</v-icon>

		<router-link :to="entry.route" v-if="linkEntry && entry.route">{{ label }}</router-link>
		<span v-else>{{ label }}</span>
	</span>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import PathEntry from "../../models/PathEntry";

	@Component
	export default class AsiBreadcrumbsEntry extends Vue {

		@Prop({required: true})
		public entry!: string | PathEntry;

		@Prop({type: Boolean, default: false})
		public small!: boolean;

		@Prop({type: Boolean, default: false})
		public hideIcon!: boolean;

		@Prop({type: Boolean, default: false})
		public linkEntry!: boolean;

		private get isPathEntry(): boolean {
			return this.entry instanceof PathEntry;
		}

		private get label(): string {
			return this.entry instanceof PathEntry ? this.entry.label : this.entry;
		}

		private get icon(): string | null {
			return this.entry instanceof PathEntry ? this.entry.icon : null;
		}

	}
</script>

<style lang="scss" scoped>
	a {
		color: inherit;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
</style>
