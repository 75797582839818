<template>
	<AsiCardDetail :icon="icons.prices" :title="$t('itemPrice.plural')"
	               :show-action-button="!readonly" no-padding>
		<ItemPriceTable v-if="hasPrices" :prices="item.prices" :unit="item.salesUnitOfMeasure" hide-discount/>
		<div v-else class="pa-6 grey--text text-center">
			{{ $t('itemPrice.terms.noPrices') }}
		</div>
	</AsiCardDetail>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import AsiCardDetail from "../../common/AsiCardDetail.vue";
	import {IItemAdmin} from "@/models/item/ItemAdminModels";
	import Icon from "../../../plugins/icons";
	import DialogHandler from "../../common/DialogHandler";
	import ItemPriceTable from "../ItemPriceTable.vue";

	@Component({
		components: {ItemPriceTable, AsiCardDetail}
	})
	export default class ItemCardPrices extends Vue {
		@Prop({type: Object, required: true})
		public item!: IItemAdmin;

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		private icons = Icon;
		private updateDialog: DialogHandler = new DialogHandler();

		private get hasPrices(): boolean {
			return this.item.prices.length > 0;
		}

		@Emit('change')
		public change(): void {
			return;
		}
	}
</script>

<style lang="scss" scoped>

</style>
