<template>
	<svg id="logo-mk-dichtungen" :class="{clickable: hasClickListener}"
	     :viewBox="dimensions" :style="`enable-background:new ${dimensions};`"
	     :width="width" :height="height"
	     xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" xml:space="preserve"
	     @click="click">
		<g id="icon" :style="{fill: this.iconColorFinal}">
			<path class="st0" d="M0,4.1C0,6,1.5,7.5,3.4,7.5c1.9,0,3.4-1.5,3.4-3.4c0-1.9-1.5-3.4-3.4-3.4C1.5,0.7,0,2.2,0,4.1"/>
			<path class="st0" d="M7.7,4.1C7.7,6,9.2,7.5,11,7.5c1.9,0,3.4-1.5,3.4-3.4c0-1.9-1.5-3.4-3.4-3.4C9.2,0.7,7.7,2.2,7.7,4.1"/>
			<path class="st0" d="M15.3,4.1c0,1.9,1.5,3.4,3.4,3.4c1.9,0,3.4-1.5,3.4-3.4c0-1.9-1.5-3.4-3.4-3.4C16.8,0.7,15.3,2.2,15.3,4.1"/>		</g>
		<g id="typo" :style="{fill: this.typoColorFinal}">
		<g id="mk">
			<polygon class="st0" points="33.1,7.2 34.9,7.2 34.9,5.5 35.3,5.1 36.6,7.2 38.7,7.2 36.5,4 38.4,2 36.4,2 34.9,3.7 34.9,0
				33.1,0 		"/>
			<path class="st0" d="M24.2,2h1.7v0.7h0c0.3-0.5,0.9-0.9,1.5-0.9c0.7,0,1.2,0.2,1.5,0.8c0.4-0.6,0.9-0.8,1.6-0.8
				c1.7,0,1.8,1.3,1.8,2.3v3.1h-1.7V4.1c0-0.6-0.3-0.9-0.7-0.9c-0.7,0-0.8,0.6-0.8,1.4v2.6h-1.7v-3c0-0.6-0.2-1-0.6-1
				c-0.6,0-0.9,0.4-0.9,1.4v2.6h-1.7V2z"/>
		</g>
			<g id="dichtungen">
			<path class="st0" d="M81.8,5.5c0.1,1.5,1.3,1.9,2.6,1.9c1.2,0,2.5-0.5,2.5-1.9c0-0.4-0.3-1.2-1.3-1.4c-0.8-0.2-1.9-0.3-1.9-0.8
				c0-0.4,0.5-0.4,0.7-0.4c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.2,0.2,0.2,0.5h1.6c-0.1-1.4-1.3-1.7-2.4-1.7c-1,0-2.4,0.3-2.4,1.6
				c0,1.4,1.4,1.4,2.4,1.7c0.3,0.1,0.8,0.2,0.8,0.6c0,0.4-0.5,0.6-0.8,0.6c-0.2,0-0.5-0.1-0.6-0.2c-0.2-0.1-0.3-0.3-0.3-0.6H81.8z"/>
				<path class="st0" d="M79.8,2v0.7h0c-0.3-0.5-0.8-0.8-1.4-0.8c-1.5,0-2.3,1.2-2.3,2.6c0,1.5,0.7,2.6,2.3,2.6c0.6,0,1.1-0.3,1.4-0.8
				h0v0.5c0,0.6-0.1,1.2-0.9,1.2c-0.3,0-0.5,0-0.6-0.1C78.1,7.8,78,7.6,78,7.4h-1.7c0.2,1.4,1.4,1.7,2.6,1.7c2.5,0,2.6-1.6,2.6-2.5V2
				H79.8z M78.8,5.7c-0.7,0-1-0.6-1-1.2c0-0.6,0.2-1.3,1-1.3c0.8,0,1,0.7,1,1.3C79.8,5.1,79.5,5.7,78.8,5.7z"/>
				<path class="st0" d="M70.4,7.2h1.7V4.4c0-0.6,0.3-1.1,0.9-1.1c0.9,0,0.9,0.7,0.9,1.4v2.5h1.7V3.9c0-0.8-0.1-2-1.9-2
				c-0.6,0-1.3,0.3-1.6,0.9h0V2h-1.7V7.2z"/>
				<path class="st0" d="M69.7,2h-1.7v2.8C67.9,5.7,67.4,6,67,6c-0.5,0-0.8-0.2-0.8-0.9V2h-1.7v3.2c0,1.4,0.4,2.2,1.9,2.2
				C67,7.4,67.6,7,68,6.5h0v0.7h1.7V2z"/>
				<path class="st0" d="M64.1,2H63V0.4h-1.7V2h-0.9v1.1h0.9v2.3c0,1.4,0.4,1.9,1.8,1.9c0.3,0,0.6,0,0.9-0.1V6c-0.2,0-0.3,0-0.4,0
				C63.3,6,63,5.9,63,5.5V3.1h1.1V2z"/>
				<path class="st0" d="M54.9,7.2h1.7V4.4c0-0.6,0.3-1.1,0.9-1.1c0.9,0,0.9,0.7,0.9,1.4v2.5h1.7V3.9c0-0.8-0.1-2-1.9-2
				c-0.6,0-1.3,0.3-1.6,0.9h0V0h-1.7V7.2z"/>
				<path class="st0" d="M54.4,3.9c-0.1-1.4-1.3-2.1-2.6-2.1c-1.7,0-2.8,1.2-2.8,2.9c0,1.6,1.2,2.7,2.8,2.7c1.4,0,2.5-0.8,2.6-2.2
				h-1.7c-0.1,0.6-0.4,0.9-1,0.9c-0.7,0-1-0.7-1-1.4c0-0.7,0.1-1.5,1.1-1.5c0.4,0,0.8,0.3,0.8,0.8H54.4z"/>
				<path class="st0" d="M48.5,0h-1.7v1.3h1.7V0z M46.8,7.2h1.7V2h-1.7V7.2z"/>
				<path class="st0" d="M44.3,0v2.5h0c-0.4-0.5-0.8-0.7-1.5-0.7c-1.5,0-2.2,1.3-2.2,2.7c0,1.5,0.7,2.9,2.3,2.9c0.6,0,1.1-0.3,1.4-0.8
				h0v0.6H46V0H44.3z M43.3,6.1c-0.8,0-1.1-0.8-1.1-1.5c0-0.7,0.3-1.4,1.1-1.4c0.7,0,1,0.7,1,1.5C44.3,5.3,44.2,6.1,43.3,6.1z"/>
		</g>
			<g id="ag">
			<path class="st0" d="M98.4,2v0.7h0c-0.3-0.5-0.8-0.8-1.4-0.8c-1.5,0-2.3,1.2-2.3,2.6c0,1.5,0.7,2.6,2.3,2.6c0.6,0,1.1-0.3,1.4-0.8
				h0v0.5c0,0.6-0.1,1.2-0.9,1.2c-0.3,0-0.5,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.3-0.5h-1.7c0.2,1.4,1.4,1.7,2.6,1.7c2.5,0,2.6-1.6,2.6-2.5
				V2H98.4z M97.3,5.7c-0.7,0-1-0.6-1-1.2c0-0.6,0.2-1.3,1-1.3c0.8,0,1,0.7,1,1.3C98.4,5.1,98.1,5.7,97.3,5.7z"/>
				<path class="st0" d="M94.2,5.9V3.6c0-1.5-1.2-1.8-2.4-1.8c-0.6,0-1.2,0.1-1.7,0.4c-0.5,0.3-0.8,0.7-0.8,1.4h1.6
				c0.1-0.4,0.4-0.7,0.9-0.7c0.4,0,0.8,0.2,0.8,0.5c0,0.3-0.2,0.4-0.5,0.5c-1.2,0.2-3.1,0.1-3.1,1.8c0,1.1,0.8,1.6,1.8,1.6
				c0.6,0,1.3-0.2,1.7-0.6c0,0.2,0,0.3,0.1,0.5h1.7C94.2,6.8,94.2,6.3,94.2,5.9z M91.5,6.3c-0.4,0-0.7-0.2-0.7-0.6
				c0-0.4,0.3-0.6,0.7-0.7c0.4-0.1,0.8-0.1,1.1-0.3C92.6,5.8,92.3,6.3,91.5,6.3z"/>
		</g>
	</g>
</svg>

</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";

	@Component
	export default class LogoMkDichtungen extends Vue {

		@Prop({type: Boolean, default: false})
		public small!: boolean;

		@Prop({type: Boolean, default: false})
		public hideTypo!: boolean;

		@Prop({type: String, default: null})
		public iconColor!: string | null;

		@Prop({type: String, default: null})
		public typoColor!: string | null;

		@Prop({type: String, default: null})
		public width!: string | null;

		@Prop({type: String, default: null})
		public height!: string | null;

		private get dimensions(): string {
			return this.hideTypo ? '0 0  22.75 9.1' : '0 0 100 9.1';
		}

		private get iconColorFinal(): string {
			return this.iconColor ?? '#e2003f';
		}

		private get typoColorFinal(): string {
			return this.typoColor ?? '#e2003f';
		}

		private get hasClickListener(): boolean {
			return !!this.$listeners.click;
		}

		@Emit('click')
		public click(event: Event): Event {
			return event;
		}

	}
</script>

<style lang="scss" scoped>
	.clickable {
		cursor: pointer;
	}
</style>
