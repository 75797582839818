import { render, staticRenderFns } from "./UserCardPersonalData.vue?vue&type=template&id=4386e4f0&scoped=true"
import script from "./UserCardPersonalData.vue?vue&type=script&lang=ts"
export * from "./UserCardPersonalData.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4386e4f0",
  null
  
)

export default component.exports