<template>
	<v-app>
		<TopBar clipped-left class="white"/>
		<v-navigation-drawer app clipped permanent v-if="isLoggedIn" dark color="secondary">
			<PrimaryNavigation/>
		</v-navigation-drawer>
		<v-main>
			<slot v-if="isLoggedIn"/>
			<Login v-else/>
		</v-main>
	</v-app>
</template>

<script lang="ts">
	import {Component} from "vue-property-decorator";
	import Login from "@/views/admin/user/Login.vue";
	import PrimaryNavigation from "@/views/admin/layout/partials/PrimaryNavigation.vue";
	import {mixins} from "vue-class-component";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
	import {Routes} from "@/helpers/constants";
	import TopBar from "@/components/layout/TopBar.vue";

	@Component({
		components: {TopBar, PrimaryNavigation, Login}
	})
	export default class LayoutAdmin extends mixins(ResponsiveChecks) {

		private get isLoggedIn(): boolean {
			return this.$store.getters['user/isLoggedIn'];
		}

		private showHome(): void {
			if (this.$router.currentRoute.name === Routes.adminHome) {
				return;
			}
			this.$router.push({name: Routes.adminHome});
		}

	}
</script>

<style lang="scss" scoped>

</style>
