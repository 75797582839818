<template>
	<div class="d-flex flex-column custom-footer">
		<v-pagination class="py-4" v-model="tableOptions.page" :length="Math.ceil(totalItems/tableOptions.itemsPerPage)" :total-visible="5" />
		<v-divider />
		<div class="d-flex flex-row pa-3">
			<AsiSelectSimple v-model="tableOptions.itemsPerPage" :items="perPageOptions" color="primary" />

			<i18n path="$vuetify.dataFooter.pageText">
				<template v-slot:0>
					<span>{{ pageTextFirstItem }}</span>
				</template>
				<template v-slot:1>
					<span>{{ pageTextLastItem }}</span>
				</template>
				<template v-slot:2>
					<span>{{ totalItems }}</span>
				</template>
			</i18n>
		</div>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";
	import AsiSelectSimple from "@/components/common/AsiSelectSimple";

	@Component({
		components: {AsiSelectSimple}
	})
	export default class ItemListPagination extends Vue {
		@Prop({type: Number, required: true})
		public totalItems!: number;

		@Prop({type: Object, required: true})
		public tableOptions!: AsiListTableOptions;

		@Prop({type: Array, required: true})
		public perPageOptions!: number[];

		private get pageTextFirstItem(): string {
			let firstItem = 1;
			if (this.tableOptions.page === 1) {
				firstItem = 1;
			} else {
				firstItem = ((this.tableOptions.page-1) * this.tableOptions.itemsPerPage) + 1;
			}
			return firstItem.toString();
		}

		private get pageTextLastItem(): string {
			return (this.tableOptions.itemsPerPage * this.tableOptions.page > this.totalItems
				? this.totalItems
				: this.tableOptions.itemsPerPage * this.tableOptions.page)
				.toString();
		}
	}
</script>

<style lang="scss" scoped>
	.custom-footer {
		> div {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			background-color: var(--v-default-lighten3);

			> div {
				max-width: 30%;
			}
		}
	}
</style>
