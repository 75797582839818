<template>
	<AsiListTableLayout :filter-text="filterModel.fulltext" :has-filter="hasFilter" :hide-toolbar="hideToolbar"
	                    @clearFilterText="filterTextChanged(null)" @clearSearch="clearSearch()"
	                    @update:filterText="filterTextChanged">
		<AsiContentContainer no-bottom-margin>
			<v-data-table
				:footer-props="{itemsPerPageOptions: perPageOptions}"
				:headers="createHeaders()"
				:items="items"
				:loading="loadingInternal || loadingDelete"
				:options.sync="tableOptions"
				:server-items-length="total"
				item-key="id"
				multi-sort>

				<template v-slot:item.avatar="{item}">
					<AsiAvatar :size="32" :icon="icons.promotion"/>
				</template>
				<template v-slot:item.item="{item}">
					{{ itemName(item.item) }}
				</template>
				<template v-slot:item.minimumOrderPrice="{item}">
					{{
						$n(item.minimumOrderPrice.amount, {
							key: 'currencyDisplay',
							currency: item.minimumOrderPrice.currency.currencyCode,
						})
					}}
				</template>
				<template v-slot:item.validFrom="{item}">
					{{ $d(new Date(item.validFrom), 'short') }}
				</template>
				<template v-slot:item.validTo="{item}">
					{{ $d(new Date(item.validTo), 'short') }}
				</template>
				<template v-if="showActions" v-slot:item.actions="{item}">
					<div class="d-flex flex-row flex-wrap">
						<AsiBtn :disabled="loadingDelete" :icon="icons.edit" small @click.native.stop="updatePromotionId = item.id; updateDialog.open();"/>
						<AsiBtn :disabled="loadingDelete" :icon="icons.delete" small @click="performDelete(item)"/>
					</div>
				</template>

			</v-data-table>
		</AsiContentContainer>

		<template v-slot:advanced-filters>
			<AsiDatePickerCombined v-model="validAtDateRange" :label="$t('promotion.terms.validDateRange')" clearable range simple/>
		</template>

		<AsiConfirmDialog v-if="showActions" ref="confirm"/>
		<PromotionUpdateDialog v-if="showActions && updateDialog.isLoaded && updatePromotionId !== null"
		                       :open="updateDialog.isOpen" :id="updatePromotionId" @cancel="updateDialog.close()"
		                       @save="changed(updatePromotionId); updateDialog.close(); reload(false, false)"/>
	</AsiListTableLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiListTableLayout from "../../common/AsiListTableLayout.vue";
	import AsiContentContainer from "../../common/AsiContentContainer.vue";
	import Icon from "@/plugins/icons";
	import AsiListTableHeader from "@/components/common/AsiListTableHeader";
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";
	import IPaginatedResponse from "@/models/IPaginatedResponse";
	import AsiListTable from "@/components/common/AsiListTable.vue";
	import IAsiListTableImplementation from "@/components/common/IAsiListTableImplementation";
	import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import Snackbar from "@/helpers/Snackbar";
	import {IPromotionAdminListEntry} from "@/models/promotion/PromotionAdminModels";
	import PromotionListFilterAdmin from "@/models/promotion/PromotionListFilterAdmin";
	import {IItemAdminSimple} from "@/models/item/ItemAdminModels";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import PromotionUpdateDialog from "@/components/promotion/admin/PromotionUpdateDialog.vue";
	import DateTimeHelper from "@/helpers/DateTimeHelper";
	import AsiDatePickerCombined from "@/components/common/AsiDatePickerCombined.vue";

	@Component({
		components: {
			AsiDatePickerCombined,
			PromotionUpdateDialog,
			AsiAvatar,
			AsiConfirmDialog, AsiContentContainer, AsiListTableLayout
		}
	})
	export default class PromotionList extends AsiListTable<IPromotionAdminListEntry, PromotionListFilterAdmin> implements IAsiListTableImplementation<IPromotionAdminListEntry, PromotionListFilterAdmin> {

		@Prop({type: Boolean, default: false})
		public hideToolbar!: boolean;

		@Prop({type: Boolean, default: false})
		public showActions!: boolean;

		private icons = Icon;
		private initialized: boolean = false;
		private loadingDelete: boolean = false;
		private updatePromotionId: string | null = null;
		private updateDialog: DialogHandler = new DialogHandler(() => this.updatePromotionId = null);

		public created(): void {
			this.reload(false, false);
			this.$nextTick(() => this.initialized = true);
		}

		private get validAtDateRange(): string[] | null {
			if (this.filterModel === null) return [];
			return [this.filterModel.validAtFrom, this.filterModel.validAtTo].filter(d => d !== null) as string[];
		}

		private set validAtDateRange(range: string[] | null) {
			if (this.filterModel === null) return;

			const dateFrom = range !== null && range.length > 0 ? new Date(range[0]) : null;
			if (dateFrom !== null) dateFrom.setHours(0, 0, 0);
			const dateTo = range !== null && range.length > 1 ? new Date(range[1]) : null;
			if (dateTo !== null) dateTo.setHours(23, 59, 59);

			this.filterModel.validAtFrom = dateFrom === null ? null : DateTimeHelper.toISODateTimeString(dateFrom);
			this.filterModel.validAtTo = dateTo === null ? null : DateTimeHelper.toISODateTimeString(dateTo);
		}

		public createFilterModel(): PromotionListFilterAdmin {
			return new PromotionListFilterAdmin();
		}

		public openModelDetailPage(): void {
			//do nothing...
		}

		public createHeaders(): AsiListTableHeader[] {
			const ret: AsiListTableHeader[] = [
				new AsiListTableHeader('', 'avatar', false, false, 'center', '4rem'),
				new AsiListTableHeader(this.$t('item.singular'), 'item', true, true, 'start'),
				new AsiListTableHeader(this.$t('promotion.minimumOrderPrice'), 'minimumOrderPrice', true, true, 'right', '15rem'),
				new AsiListTableHeader(this.$t('promotion.validFrom'), 'validFrom', true, true, 'center', '12rem'),
				new AsiListTableHeader(this.$t('promotion.validTo'), 'validTo', true, true, 'center', '12rem'),
			];

			if (this.showActions) {
				ret.push(
					new AsiListTableHeader('', 'actions', false, false, 'end', '6rem'),
				);
			}

			return ret;
		}

		public fetchData(filterModel: PromotionListFilterAdmin | null, tableOptions: AsiListTableOptions | null): Promise<IPaginatedResponse<IPromotionAdminListEntry>> {
			return this.$promotionServiceAdmin.promotions(filterModel, tableOptions);
		}

		public filterTextChanged(filterText: string): void {
			if (this.filterModel === null) return;
			this.filterModel.fulltext = filterText;
		}

		public reload(goToFirstPage: boolean = false, debounce: boolean = true): void {
			this.reloadInternal(this, goToFirstPage, debounce);
		}

		protected prepareOptions(options: AsiListTableOptions): AsiListTableOptions {
			if (options.sortBy.length === 0 && this.defaultSortBy.length === 0) {
				options.sortBy = ['validFrom', 'validTo'];
				options.sortDesc = [false, false];
			}
			return options;
		}

		private performDelete(entry: IPromotionAdminListEntry): void {
			const confirm = this.$refs.confirm as unknown as AsiConfirmDialog;
			confirm.openDialog().then((res: boolean) => {
				if (!res) return;

				const id = entry.id;
				this.loadingDelete = true;
				this.$promotionServiceAdmin.delete(id)
					.then(() => {
						this.reload(false, false);
						this.deleted(id);
					})
					.catch(() => Snackbar.deleteError())
					.finally(() => this.loadingDelete = false);
			});
		}

		@Watch('tableOptions', {deep: true})
		private onTableOptionsChanged(): void {
			if (!this.initialized) return;
			this.reload();
		}

		@Watch('filterModel', {deep: true})
		private onFilterModelChanged(): void {
			if (!this.initialized) return;
			this.reload(true);
		}

		@Emit('changed')
		public changed(id: string): string {
			return id;
		}

		@Emit('deleted')
		public deleted(id: string): string {
			return id;
		}

		private itemName(item: IItemAdminSimple): string | null {
			return TranslatedValueHelper.get(item.name, this.$i18n.locale, true);
		}

	}
</script>

<style lang="scss" scoped>

</style>
