<template>
	<AsiDialogFormLayout :icon="icons.notes" :title="$t('customer.notes')"
	                     :open="open" :loading="loading" :valid="valid" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped scrollable>
		<v-card-text class="pa-6">
			<v-form v-model="valid">
				<v-row>
					<v-col>
						<AsiTextarea v-model="notes" :label="$t('customer.notes')" clearable/>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
	import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
	import {ICustomerAdmin} from "@/models/customer/CustomerAdminModels";
	import AsiTextarea from "@/components/common/AsiTextarea";

	@Component({
		components: {AsiTextarea, AsiDialogFormLayout}
	})
	export default class CustomerUpdateDialogNotes extends AsiDialogForm {

		@Prop({type: Object, required: true})
		public customer!: ICustomerAdmin;

		private notes: string | null = null;

		@Watch('customer.notes', {immediate: true})
		private onCustomerNotesChanged(value: string): void {
			this.notes = value;
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			this.notes = this.customer.notes;
			const form = this.$refs.form as HTMLFormElement;
			this.reset([], form);
			return event;
		}

		private requestSave(): void {
			this.loading = true;
			this.$customerServiceAdmin.updateNotes(this.customer.id, this.notes)
				.then(this.save)
				.catch(this.handleErrors)
				.finally(() => this.loading = false);
		}

	}
</script>

<style lang="scss" scoped>

</style>
