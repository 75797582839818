<template>
	<AsiDialogFormLayout v-if="model !== null" :icon="icons.user" :title="$t('user.terms.personalData')"
	                     :open="open" :loading="loading" :valid="valid" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped scrollable>
		<v-card-text class="pa-6">
			<v-form v-model="valid">
				<v-row>
					<v-col>
						<AsiLocaleAutocompleteInput v-model="model.locale" :rules="model.rules('locale')"
						                            :label="$t('user.locale')" :placeholder="$t('user.locale')"/>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<AsiTextField v-model="model.timeZone" :rules="model.rules('timeZone')"
						              :label="$t('user.timeZone')" :placeholder="$t('user.timeZone')"
						              disabled/>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import {IUserShop} from "@/models/user/UserShopModels";
	import {IUserAdmin} from "@/models/user/UserAdminModels";
	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
	import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
	import AsiTextField from "@/components/common/AsiTextField";
	import UserUpdatePersonalData from "@/models/user/UserUpdatePersonalData";
	import AsiLocaleAutocompleteInput from "@/components/common/AsiLocaleAutocompleteInput.vue";

	@Component({
		components: {AsiLocaleAutocompleteInput, AsiTextField, AsiDialogFormLayout, AsiDialogForm}
	})
	export default class UserUpdateDialogPersonalData extends AsiDialogForm {

		@Prop({type: Object, required: true})
		public user!: IUserShop | IUserAdmin;

		private model: UserUpdatePersonalData | null = null;

		public created(): void {
			this.setUserInternal();
		}

		@Watch('user', {deep: true})
		private onUserChanged(): void {
			this.setUserInternal();
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			this.setUserInternal();
			const form = this.$refs.form as HTMLFormElement;
			this.reset([], form);
			return event;
		}

		private setUserInternal(): void {
			if (this.model === null) {
				this.model = new UserUpdatePersonalData(this.user);
				this.valid = true;
			} else {
				this.model.setDetailModel(this.user);
			}
		}

		private requestSave(): void {
			if (this.model === null) return;

			this.loading = true;
			this.$userServiceShop.updatePersonalData(this.user.id, this.model)
				.then(this.save)
				.catch(this.handleErrors)
				.finally(() => this.loading = false);
		}

	}
</script>

<style lang="scss" scoped>

</style>
