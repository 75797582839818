<template>
	<v-menu v-model="open" auto top :nudge-bottom="-50" max-width="400px" :disabled="shoppingLists.length < 2">
		<template v-slot:activator="{on, attrs}">
			<AsiBtn v-on="on" v-bind="attrs"
			        :class="{'icon-button': simple}"
			        outlined
			        color="primary"
			        :icon="!simple ? icons.shoppingList : null"
			        large
			        @click="shoppingLists.length < 2 ? addToList(null) : null"
			>
				<span v-if="!simple">{{ $t('shoppingList.terms.addPosition') }}</span>
				<v-icon v-else>{{ icons.shoppingList }}</v-icon>
			</AsiBtn>
		</template>
		<AsiCard no-bottom-margin>
			<v-list>
				<v-list-item v-for="(shoppingList, i) in shoppingLists" :key="i" dense link>
					<v-list-item-icon class="mr-1 d-flex align-center">
						<v-icon color="primary" small>{{ icons.shoppingList }}</v-icon>
					</v-list-item-icon>
					<v-list-item-title @click="addToList(shoppingList.id)">{{ shoppingList.name }}</v-list-item-title>
				</v-list-item>
			</v-list>
		</AsiCard>
	</v-menu>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import AsiCard from "@/components/common/AsiCard.vue";
	import {IShoppingListShopListEntry} from "@/models/shopping-list/ShoppingListShopModels";
	import Snackbar from "@/helpers/Snackbar";

	@Component({
		components: {AsiCard, AsiBtn}
	})
	export default class ShoppingListButton extends Vue {
		private icons = Icon;
		private open: boolean = false;

		@Prop({type: Boolean, default: false})
		public simple!: boolean;

		@Prop({type: Boolean, default: false})
		public large!: boolean;

		@Prop({type: String, required: true})
		public itemId!: string;

		@Prop({type: Number, default: 1})
		public quantity!: number;

		private get shoppingLists(): IShoppingListShopListEntry[] {
			return this.$store.state.shoppingList.shoppingLists;
		}

		private addToList(listId: string | null): void {
			this.$store.dispatch('shoppingList/addPosition', {
				itemId: this.itemId,
				quantity: this.quantity,
				shoppingListId: listId ?? null,
			})
				.then(() => Snackbar.show(this.$t('shoppingList.terms.added'), 'success', 1500));
		}
	}
</script>

<style lang="scss" scoped>
	.icon-button {
		min-width: 0 !important;
	}
</style>
