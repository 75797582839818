<template>
	<AsiDialogFormLayout :icon="icons.password" :title="$t('ui.terms.changePassword')"
	                     :open="open" :loading="loading" :valid="valid" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped scrollable>
		<v-card-text class="pa-6">
			<v-form v-model="valid">
				<v-row>
					<v-col>
						<AsiTextField v-model="password"
						              :append-icon="showPassword ? icons.hidePassword : icons.showPassword"
						              :label="$t('ui.terms.password')"
						              :placeholder="$t('ui.terms.password')"
						              :rules="passwordRules"
						              :type="showPassword ? 'text' : 'password'"
						              autocomplete="new-password"
						              required
						              @click:append="showPassword = !showPassword"/>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop} from "vue-property-decorator";
	import {IUserShop} from "@/models/user/UserShopModels";
	import {IUserAdmin} from "@/models/user/UserAdminModels";
	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
	import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
	import AsiTextField from "@/components/common/AsiTextField";
	import {ValidationRule} from "@/declarations/ValidationRule";
	import UserRules from "@/models/user/UserRules";
	import Snackbar from "@/helpers/Snackbar";

	@Component({
		components: {AsiTextField, AsiDialogFormLayout, AsiDialogForm}
	})
	export default class UserUpdateDialogPassword extends AsiDialogForm {

		@Prop({type: Object, required: true})
		public user!: IUserShop | IUserAdmin;

		private password: string | null = null;
		private showPassword: boolean = false;

		// noinspection JSMethodCanBeStatic
		private get passwordRules(): ValidationRule[] {
			return UserRules.passwordRules;
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			const form = this.$refs.form as HTMLFormElement;
			this.reset([], form);
			this.password = null;
			return event;
		}

		private requestSave(): void {
			if (this.password === null) return;

			this.loading = true;
			this.$userServiceShop.updatePassword(this.user.id, this.password)
				.then(this.save)
				.catch(this.handleErrors)
				.finally(() => {
					this.loading = false;
					Snackbar.show(this.$t('snackbar.passwordUpdated'), 'success', 500);
					this.save();
				});
		}

	}
</script>

<style lang="scss" scoped>

</style>
