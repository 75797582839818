<template>
	<v-menu v-model="open" bottom left offset-y :nudge-top="-10">
		<template v-slot:activator="{on, attrs}">
			<span v-on="on" v-bind="attrs">
				<AsiAvatar v-if="rLoggedIn" :icon="icons.user" :image-url="avatarUrl" :size="32"/>
				<v-icon v-else>{{ icons.user }}</v-icon>
			</span>
		</template>

		<AsiCard highlighted unwrapped no-bottom-margin>
			<v-list v-if="rLoggedIn" class="pa-0" min-width="15rem">
				<v-list-item class="secondary">
					<v-list-item-avatar>
						<AsiAvatar :icon="icons.user" :image-url="avatarUrl" :size="40"
						           bg-color="primary" icon-color="white"/>
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title class="white--text font-weight-medium">
							{{ userFullName }}
						</v-list-item-title>
						<v-list-item-subtitle class="white--text">
							{{ user === null ? null : user.customer.name }}
						</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>

				<AsiMenuItem :icon="icons.vat" :label="$t(amountsInclVat ? 'ui.terms.showExclVat' : 'ui.terms.showInclVat')"
				             @click="amountsInclVat = !amountsInclVat"/>
				<v-divider/>
				<AsiMenuItem :icon="icons.user" :label="$t('user.terms.profile')" @click="showProfile"/>
				<AsiMenuItem :icon="icons.customer" :label="$t('customer.terms.myCustomer')" @click="showCustomer"/>
				<AsiMenuItem :icon="icons.orders" :label="$t('order.plural')" @click="showOrders"/>
				<AsiMenuItem :icon="$store.state.cart.carts.length > 1 ? icons.carts : icons.cart"
				             :label="$t($store.state.cart.carts.length > 1 ? 'cart.plural' : 'cart.singular')"
				             @click="showCarts"/>

				<template v-if="rBackendUser">
					<v-divider/>
					<AsiMenuItem v-if="this.$route.meta.adminLayout !== true"
					             :icon="icons.backend" :label="$t('ui.terms.openShopBackend')" class="grey lighten-4" @click="showBackend"/>
					<AsiMenuItem v-else
					             :icon="icons.frontend" :label="$t('ui.terms.openShopFrontend')" class="grey lighten-4" @click="showFrontend"/>
				</template>
			</v-list>
			<v-list v-else class="pa-0" min-width="15rem">
				<AsiMenuItem :label="$t('ui.terms.register')" :icon="icons.user" @click="register"/>
				<v-divider/>
				<AsiMenuItem :label="$t('ui.login')" :icon="icons.login" @click="login"/>
				<v-divider/>
				<AsiMenuItem :icon="icons.vat" :label="$t(amountsInclVat ? 'ui.terms.showExclVat' : 'ui.terms.showInclVat')"
				             @click="amountsInclVat = !amountsInclVat" class="grey lighten-4"/>
			</v-list>

			<v-divider v-if="rLoggedIn"/>
			<template v-slot:actions v-if="rLoggedIn">
				<AsiBtn :icon="icons.logout" color="error" @click="logout">
					{{ $t('ui.logout') }}
				</AsiBtn>
			</template>
		</AsiCard>
	</v-menu>
</template>

<script lang="ts">
	import {Component} from "vue-property-decorator";
	import Icon from "@/plugins/icons";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";
	import {IUserShop} from "@/models/user/UserShopModels";
	import UserHelper from "@/models/user/UserHelper";
	import {mixins} from "vue-class-component";
	import RightChecks from "@/mixins/RightChecks.vue";
	import {AttachmentMediaSize, Routes} from "@/helpers/constants";
	import AsiLoginForm from "@/components/common/AsiLoginForm.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import AsiCard from "@/components/common/AsiCard.vue";
	import RoutesHelper from "@/helpers/RoutesHelper";
	import CustomerHelper from "@/models/customer/CustomerHelper";
	import AsiMenuItem from "@/components/common/AsiMenuItem.vue";

	@Component({
		components: {AsiMenuItem, AsiCard, AsiBtn, AsiLoginForm, AsiAvatar}
	})
	export default class UserMenu extends mixins(RightChecks) {

		private icons = Icon;
		private open: boolean = false;

		private get menuIcon(): string {
			return this.rLoggedIn ? Icon.avatar : Icon.user;
		}

		private get user(): IUserShop | null {
			return this.$store.getters['user/model'];
		}

		private get userFullName(): string | null {
			return this.user === null ? null : UserHelper.fullName(this.user);
		}

		private get avatarUrl(): string | null {
			return this.user === null ? null : UserHelper.avatarUrl(this.user, AttachmentMediaSize.l);
		}

		private get amountsInclVat(): boolean {
			return this.$store.state.ui.amountsInclVat;
		}

		private set amountsInclVat(value: boolean) {
			this.$store.commit('ui/setAmountsInclVat', value);
		}

		private showProfile(): void {
			if (!this.rLoggedIn || this.$router.currentRoute.name === Routes.user) return;
			this.$router.push(UserHelper.detailRoute(this.$store.getters['user/userId']));
		}

		private showCustomer(): void {
			if (!this.rLoggedIn || this.$router.currentRoute.name === Routes.customer) return;
			this.$router.push(CustomerHelper.detailRoute(this.$store.getters['user/modelCustomer'].id));
		}

		private showOrders(): void {
			if (!this.rLoggedIn || this.$router.currentRoute.name === Routes.orders) return;
			this.$router.push({name: Routes.orders});
		}

		private showCarts(): void {
			if (!this.rLoggedIn || this.$router.currentRoute.name === Routes.carts) return;
			this.$router.push({name: Routes.carts});
		}

		private showBackend(): void {
			if (!this.rBackendUser || this.$router.currentRoute.name === Routes.adminHome) return;
			this.$router.push(RoutesHelper.homeAdminRoute());
		}

		private showFrontend(): void {
			if (this.$router.currentRoute.name === Routes.home) return;
			this.$router.push(RoutesHelper.homeShopRoute());
		}

		private async logout(): Promise<void> {
			if (!this.rLoggedIn) return;
			await this.$store.dispatch('user/logout');
			this.$router.go(0);
		}

		private login(): void {
			if (this.rLoggedIn || this.$router.currentRoute.name === Routes.login) return;
			this.$router.push({name: Routes.login});
		}

		private register(): void {
			if (this.rLoggedIn || this.$router.currentRoute.name === Routes.registration) return;
			this.$router.push({name: Routes.registration});
		}

	}
</script>

<style lang="scss" scoped>

</style>
