<template>
	<div>
		<AsiCard class="card d-flex align-start" @click="showCategory" highlighted>
			<div :class="['d-flex', 'justify-center', {'py-6': !showMore, 'py-3': showMore}]">
				<AsiCard class="avatar-wrapper" :style="avatarStyle" unwrapped no-bottom-margin>
					<v-img :src="avatarUrl" :alt="nameTranslated" :aspect-ratio="1" class="rounded-t" :style="imageStyles"/>
				</AsiCard>
			</div>
			<div :class="['d-flex', 'flex-column', 'align-center', {'py-6': !showMore, 'pt-0': showMore}]">
				<router-link v-if="detailRoute !== null" :to="detailRoute" :title="nameTranslated"
				             @click.native.stop
				             class="title font-weight-medium text-center"
				             style="text-decoration: inherit; color: inherit;">
					{{ nameTranslated }}
				</router-link>
				<span v-else class="title font-weight-medium text-center">
					{{ nameTranslated }}
				</span>
			</div>
			<v-expand-x-transition>
				<v-col v-show="showMore" class="mx-auto pt-0 px-0">
					<v-divider :style="'border-color: ' + categoryColor" class="pa-0"/>
				</v-col>
			</v-expand-x-transition>
			<v-expand-transition>
				<div v-show="showMore" class="description-wrapper">
					<p></p>
				</div>
			</v-expand-transition>
		</AsiCard>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import AsiCard from "@/components/common/AsiCard.vue";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import {ICategoryShopListEntry} from "@/models/category/CategoryShopModels";
	import CategoryHelper from "@/models/category/CategoryHelper";
	import {AttachmentMediaSize} from "@/helpers/constants";
	import {RawLocation} from "vue-router";

	@Component({
		components: {AsiCard}
	})
	export default class CategoryFeaturedCard extends Vue {

		@Prop({type: String, required: true})
		public id!: string;

		private showMore: boolean = false;

		private get category(): ICategoryShopListEntry | null {
			return this.$store.getters['category/categoryById'](this.id);
		}

		private get imageStyles(): object {
			return {
				'box-sizing': 'border-box',
				'border-bottom-color': this.categoryColor,
				'background-image': 'linear-gradient(45deg, #00000044, transparent)',
				'background-color': this.categoryColor,
			};
		}

		private get categoryColor(): string {
			return this.category === null ? 'grey' : CategoryHelper.colorHierarchical(this.category) ?? 'grey';
		}

		private get avatarStyle(): object {
			return {
				'width': this.showMore ? '15%' : '45%',
			};
		}

		private get nameTranslated(): string | null {
			return this.category === null
				? null
				: TranslatedValueHelper.get(this.category.name, this.$i18n.locale);
		}

		private get descriptionTranslated(): string | null {
			return this.category === null || this.category.description === null || this.category.description === undefined
				? null
				: TranslatedValueHelper.get(this.category.description, this.$i18n.locale);
		}

		private get avatarUrl(): string | null {
			return this.category === null ? null : CategoryHelper.avatarUrl(this.category, AttachmentMediaSize.m);
		}

		private get detailRoute(): RawLocation | null {
			return this.category === null ? null : CategoryHelper.detailRoute(CategoryHelper.currentCanonical(this.category.canonical));
		}

		private showCategory(): void {
			if (this.category === null || this.$router.currentRoute.params.canonical === CategoryHelper.currentCanonical(this.category.canonical) || this.detailRoute === null) {
				return;
			}
			this.$router.push(this.detailRoute);
		}
	}
</script>

<style lang="scss" scoped>
	.card {
		width: 17rem;

		.avatar-wrapper {
			transition: 0.25s ease-out;
		}

		.description-wrapper {
			width: 100%;
		}
	}
</style>
