<template>
	<v-list-item v-if="value !== null || !hideIfEmpty" v-on="hasClickListener ? {click: click} : {}" bg
	             @mouseenter="hover = true" @mouseleave="hover = false">
		<v-list-item-avatar>
			<AsiAvatar v-if="!hasAvatar" :bg-color="iconBgColor" :icon="finalIcon" :icon-color="iconColor"
			           :image-url="imageUrl" :size="45"/>
			<slot v-else name="avatar"/>
		</v-list-item-avatar>

		<v-list-item-content v-if="label">
			<v-list-item-title class="d-flex flex-row flex-wrap align-center">
				{{ label }}
				<v-tooltip v-if="labelHint" top>
					<template v-slot:activator="{on, attrs}">
						<v-icon v-bind="attrs" v-on="on" class="ml-1" small>
							{{ icons.info }}
						</v-icon>
					</template>
					{{ labelHint }}
				</v-tooltip>
			</v-list-item-title>

			<v-list-item-subtitle v-if="hasContent">
				<slot/>
			</v-list-item-subtitle>
			<v-list-item-subtitle v-if="!hasContent && !finalValueIsArray" :class="{multiline: multiline}">
				{{ finalValue }}
			</v-list-item-subtitle>
			<v-list-item-subtitle v-if="!hasContent && finalValueIsArray">
				<div v-for="line in finalValue" :key="line" :class="{multiline: multiline}">
					{{ line }}
				</div>
			</v-list-item-subtitle>
		</v-list-item-content>

		<v-list-item-content v-else>
			<v-list-item-title v-if="hasContent">
				<slot/>
			</v-list-item-title>
			<v-list-item-title v-if="!hasContent && !finalValueIsArray" :class="{multiline: multiline}">
				{{ finalValue }}
			</v-list-item-title>
			<v-list-item-title v-if="!hasContent && finalValueIsArray">
				<div v-for="line in finalValue" :key="line" :class="{multiline: multiline}">
					{{ line }}
				</div>
			</v-list-item-title>
		</v-list-item-content>

		<v-list-item-action v-if="hasActionButton">
			<div class="action-button d-flex flex-row flex-wrap justify-end"
			     :class="{shown: !actionButtonOnHover || hover }">
				<slot name="actionButton"/>
			</div>
		</v-list-item-action>
	</v-list-item>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import Icon from "@/plugins/icons";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";
	import AsiFadeTransition from "@/components/common/AsiFadeTransition.vue";

	@Component({
		components: {AsiFadeTransition, AsiAvatar}
	})
	export default class AsiCardDetailEntry extends Vue {

		@Prop({type: String, default: null})
		public icon!: string | null;

		@Prop({type: String, default: 'grey'})
		public iconColor!: string | null;

		@Prop({type: String, default: 'transparent'})
		public iconBgColor!: string | null;

		@Prop({type: String, default: null})
		public imageUrl!: string | null;

		@Prop({type: String, default: null})
		public label!: string | null;

		@Prop({type: String, default: null})
		public labelHint!: string | null;

		@Prop({default: null})
		public value!: any;

		@Prop({type: Boolean, default: false})
		public hideIfEmpty!: boolean;

		@Prop({type: Boolean, default: false})
		public multiline!: boolean;

		@Prop({type: Boolean, default: false})
		public actionButtonOnHover!: boolean;

		private icons = Icon;
		private hover: boolean = false;

		private get finalIcon(): string {
			return this.icon === null ? Icon.list : this.icon;
		}

		private get finalValue(): string | string[] | null {
			if (this.value === null || this.value === undefined) {
				return '-';
			} else if (Array.isArray(this.value) && this.value.length === 0) {
				return '-';
			} else if (typeof this.value === 'boolean') {
				return this.$t(this.value ? 'ui.yes' : 'ui.no').toString();
			} else if (typeof this.value === 'string' && this.multiline) {
				return this.value.split(/\r\n|\n|\r/);
			}

			return this.value;
		}

		private get finalValueIsArray(): boolean {
			return Array.isArray(this.finalValue);
		}

		private get hasContent(): boolean {
			return !!this.$slots.default;
		}

		private get hasAvatar(): boolean {
			return !!this.$slots.avatar || !!this.$scopedSlots['avatar'];
		}

		private get hasActionButton(): boolean {
			return !!this.$slots.actionButton || !!this.$scopedSlots['actionButton'];
		}

		@Emit('click')
		public click(event: Event): Event {
			return event;
		}

		private get hasClickListener(): boolean {
			return !!this.$listeners.click;
		}

	}
</script>

<style lang="scss" scoped>
	.multiline {
		overflow: initial;
		text-overflow: initial;
		white-space: initial;
	}

	.action-button {
		opacity: 0;
		transition: opacity .3s ease-out;

		&.shown {
			opacity: 1;
		}
	}
</style>
