import { render, staticRenderFns } from "./OrderPaymentReinitializeDialog.vue?vue&type=template&id=60a05464&scoped=true"
import script from "./OrderPaymentReinitializeDialog.vue?vue&type=script&lang=ts"
export * from "./OrderPaymentReinitializeDialog.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60a05464",
  null
  
)

export default component.exports