import { render, staticRenderFns } from "./GalleryPreview.vue?vue&type=template&id=ada1490e&scoped=true"
import script from "./GalleryPreview.vue?vue&type=script&lang=ts"
export * from "./GalleryPreview.vue?vue&type=script&lang=ts"
import style0 from "./GalleryPreview.vue?vue&type=style&index=0&id=ada1490e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ada1490e",
  null
  
)

export default component.exports