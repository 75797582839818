<template>
	<AsiCardDetail :icon="icons.item" :title="$t('ui.terms.basicData')"
	               :show-action-button="!readonly" :action-button-disabled="disabled" @actionButtonClicked="updateDialog.open()">
		<AsiCardDetailEntryTranslatedValue :icon="icons.name" :label="$t('item.name')" :value="item.name"/>
		<AsiCardDetailEntry v-if="item.itemType === itemType.linkItem" :icon="icons.link" :label="$t('item.remoteUrl')" :value="item.remoteUrl">
			<a :href="'//' + item.remoteUrl" target="_blank">{{ item.remoteUrl }}</a>
		</AsiCardDetailEntry>
		<AsiCardDetailEntry :icon="icons.id" :label="$t('item.itemNumber')" :value="item.itemNumber"/>
		<AsiCardDetailEntry :icon="icons.id" :label="$t('item.alternativeItemNumber')" :value="item.alternativeItemNumber"/>
		<AsiCardDetailEntry :icon="icons.id" :label="$t('item.itemNumberShort')" :value="item.itemNumberShort"/>
		<AsiCardDetailEntry :icon="icons.id" :label="$t('item.externalId')" :value="item.externalId"/>

		<ItemUpdateDialogBasicData v-if="!readonly && updateDialog.isLoaded" :open="updateDialog.isOpen" :item="item"
		                               @cancel="updateDialog.close()" @save="updateDialog.close(); change();"/>
	</AsiCardDetail>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import Icon from "../../../plugins/icons";
	import DialogHandler from "../../common/DialogHandler";
	import {IItemAdmin} from "@/models/item/ItemAdminModels";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import AsiCardDetailEntryTranslatedValue from "@/components/common/AsiCardDetailEntryTranslatedValue.vue";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import ItemUpdateDialogBasicData from "@/components/item/admin/ItemUpdateDialogBasicData.vue";
	import {ItemType} from "@/helpers/constants";
	@Component({
		components: {ItemUpdateDialogBasicData, AsiCardDetailEntry, AsiCardDetailEntryTranslatedValue, AsiCardDetail}
	})
	export default class ItemCardBasicData extends Vue {

		private itemType = ItemType;

		@Prop({type: Object, required: true})
		public item!: IItemAdmin;

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		private icons = Icon;
		private updateDialog: DialogHandler = new DialogHandler();

		@Emit('change')
		public change(): void {
			return;
		}

	}
</script>

<style lang="scss" scoped>

</style>
