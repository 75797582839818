<template>
	<section>
		<AsiPageTitle :icon="icons.settings" :title="$t('pageTitles.admin.settings')"/>
		<AsiTabs v-model="activeTab">
			<v-tab key="globalConfig">
				{{ $t('globalConfig.plural') }}
				<v-icon>{{ icons.settings }}</v-icon>
			</v-tab>
			<v-tab-item key="globalConfig">
				<SettingsTabGlobalConfig :readonly="!rAdmin"/>
			</v-tab-item>

			<v-tab key="paymentTypes">
				{{ $t('paymentType.plural') }}
				<v-icon>{{ icons.paymentTypes }}</v-icon>
			</v-tab>
			<v-tab-item key="paymentTypes">
				<SettingsTabPaymentTypes :readonly="!rAdmin"/>
			</v-tab-item>

			<v-tab key="shipmentTypes">
				{{ $t('shipmentType.plural') }}
				<v-icon>{{ icons.shipmentTypes }}</v-icon>
			</v-tab>
			<v-tab-item key="shipmentTypes">
				<SettingsTabShipmentTypes :readonly="!rAdmin"/>
			</v-tab-item>

			<v-tab key="promotions">
				{{ $t('promotion.plural') }}
				<v-icon>{{ icons.promotions }}</v-icon>
			</v-tab>
			<v-tab-item key="promotions">
				<SettingsTabPromotions :readonly="!rMasterData"/>
			</v-tab-item>
			<v-tab key="mail-templates">
				{{ $t('mailTemplate.plural') }}
				<v-icon>{{ icons.mailTemplates }}</v-icon>
			</v-tab>
			<v-tab-item key="mail-templates">
				<SettingsTabMailTemplates :readonly="!rMasterData"/>
			</v-tab-item>
		</AsiTabs>
	</section>
</template>

<script lang="ts">
	import {Component} from "vue-property-decorator";
	import AsiPageTitle from "@/components/common/AsiPageTitle.vue";
	import Icon from "@/plugins/icons";
	import AsiTabs from "@/components/common/AsiTabs";
	import SettingsTabPaymentTypes from "@/components/settings/admin/SettingsTabPaymentTypes.vue";
	import SettingsTabShipmentTypes from "@/components/settings/admin/SettingsTabShipmentTypes.vue";
	import {mixins} from "vue-class-component";
	import RightChecks from "@/mixins/RightChecks.vue";
	import SettingsTabPromotions from "@/components/settings/admin/SettingsTabPromotions.vue";
	import SettingsTabGlobalConfig from "@/components/settings/admin/SettingsTabGlobalConfig.vue";
	import SettingsTabMailTemplates from "@/components/settings/admin/SettingsTabMailTemplates.vue";

	@Component({
		components: {
			SettingsTabMailTemplates,
			SettingsTabGlobalConfig,
			SettingsTabPromotions, SettingsTabShipmentTypes, SettingsTabPaymentTypes, AsiTabs, AsiPageTitle
		}
	})
	export default class Settings extends mixins(RightChecks) {

		private icons = Icon;

		private get activeTab(): number {
			return this.$store.getters['ui/activeTabAdmin']('settings');
		}

		private set activeTab(index: number) {
			this.$store.commit('ui/activeTabAdmin', {key: 'settings', activeTab: index});
		}
	}
</script>

<style lang="scss" scoped>

</style>
