import { render, staticRenderFns } from "./CartDrawer.vue?vue&type=template&id=25254ce0&scoped=true"
import script from "./CartDrawer.vue?vue&type=script&lang=ts"
export * from "./CartDrawer.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25254ce0",
  null
  
)

export default component.exports