<template>
	<v-dialog v-if="attachment" :title="attachment.filename" :value="open"
	          fullscreen transition="dialog-bottom-transition" unwrapped @cancel="cancel" @keydown.esc="cancel" @click:outside="cancel">

		<AsiCard :icon="icons.download" :subtitle="attachment.filename" :title="$t('attachment.terms.preview')"
		         class="d-flex flex-column align-stretch" highlighted no-bottom-margin unwrapped>
			<template v-slot:headerActions>
				<AsiBtn :icon="icons.close" @click="cancel" icon-color="white"/>
			</template>
			<template v-slot:actions>
				<AsiBtn :icon="icons.close" @click="cancel">{{ $t('ui.close') }}</AsiBtn>
			</template>

			<div class="flex-grow-1 d-flex flex-column align-stretch justify-center">
				<div v-if="isImage" class="pa-6">
					<v-img :alt="attachment.filename" :src="objectUrl" contain style="height: calc(100vh - 200px)"/>
				</div>
				<object v-else-if="isPdf || isText" :data="objectUrl" :type="attachment.mimeType" class="flex-grow-1 flex-shrink-1">
					<embed :src="objectUrl" :type="attachment.mimeType"/>
				</object>
			</div>
		</AsiCard>
	</v-dialog>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiDialog from "../common/AsiDialog.vue";
	import AsiCard from "@/components/common/AsiCard.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import {AttachmentMediaSize} from "@/helpers/constants";
	import Icon from "@/plugins/icons";
	import {IAttachment} from "@/models/attachment/AttachmentModels";

	@Component({
		components: {AsiBtn, AsiCard, AsiDialog}
	})
	export default class AttachmentPreviewDialog extends Vue {

		@Prop({type: Object, required: true})
		public attachment!: IAttachment;

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		private icons = Icon;

		private get isImage(): boolean {
			return this.attachment.mimeType.startsWith('image/');
		}

		private get isPdf(): boolean {
			return this.attachment.mimeType === 'application/pdf';
		}

		private get isText(): boolean {
			return [
				'text/plain',
			].includes(this.attachment.mimeType);
		}

		private get objectUrl(): string | null {
			return this.attachment.files.find(f => f.mediaSize === AttachmentMediaSize.orig)?.url ?? null;
		}

		@Emit('cancel')
		public cancel(): void {
			return;
		}

		@Watch('open', {immediate: true})
		private onOpenChanged(value: boolean): void {
			this.$store.commit('ui/setFullscreenDialogOpenState', value);
		}

	}
</script>

<style lang="scss" scoped>
	.preview {
		background-color: salmon;
	}
</style>
