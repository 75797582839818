<template>
	<v-col>
		<AsiRadioGroup class="ma-0" :column="sMobile" v-model="valueInternal">
			<v-radio v-for="(salutation, index) in allSalutations" :key="index" class="mr-6" :label="salutation.text" :value="salutation.value" />
		</AsiRadioGroup>
	</v-col>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import {mixins} from "vue-class-component";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
	import AsiRadioGroup from "@/components/common/AsiRadioGroup";
	import {Salutation} from "@/helpers/constants";
	import EnumHelper from "@/helpers/EnumHelper";

	@Component({
		components: {AsiRadioGroup}
	})
	export default class SalutationInput extends mixins(ResponsiveChecks) {
        @Prop({type: Number, required: true})
        public value!: Salutation;

		private valueInternal: number = Salutation.mr;

		private get allSalutations(): { text: string; value: string | number; }[]  {
			return EnumHelper.toSelectItems(Salutation, true);
		}

		@Watch('value', {immediate: true})
		private onValueChanged(): void {
		    this.valueInternal = this.value;
		}

		@Watch('valueInternal')
		private onValueInternalChanged(): void {
		    this.input();
		}

		@Emit('input')
		public input(): Salutation {
		    return this.valueInternal;
		}
	}
</script>

<style lang="scss" scoped>

</style>
