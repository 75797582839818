<template>
	<AsiListTableLayout :filter-text="filterModel.fulltext"
	                    :has-filter="hasFilter"
	                    :hide-toolbar="hideToolbar"
	                    @clearFilterText="filterTextChanged(null)"
	                    @clearSearch="clearSearch"
	                    @update:filterText="filterTextChanged">

		<template v-slot:action-buttons>
			<v-tooltip top>
				<template v-slot:activator="{on, attrs}">
					<span v-bind="attrs" v-on="on">
						<AsiBtn :icon="icons.export" @click="performExport"/>
					</span>
				</template>
				{{ $t('ui.terms.exportView') }}
			</v-tooltip>
		</template>

		<AsiContentContainer no-bottom-margin>
			<v-data-table
				:footer-props="{itemsPerPageOptions: perPageOptions}"
				:headers="createHeaders()"
				:items="items"
				:loading="loadingInternal"
				:options.sync="tableOptions"
				:server-items-length="total"
				item-key="id"
				multi-sort
				@click:row="openModelDetailPage">

				<template v-slot:item.avatar="{item}">
					<AsiAvatar :size="32" :icon="icons.customer" :image-url="avatarUrl(item)"/>
				</template>
				<template v-slot:item.createdAt="{item}">
					<span>
						{{ $d(new Date(item.createdAt), 'short') }} {{ $d(new Date(item.createdAt), 'timeShort') }}
					</span>
				</template>
				<template v-slot:item.shippingAddress="{item}">
					<span v-if="item.defaultShippingAddress">
						{{ item.defaultShippingAddress.street }}
						{{ item.defaultShippingAddress.houseNumber }}
						{{ item.defaultShippingAddress.zip }}
						{{ item.defaultShippingAddress.city }}
						{{ item.defaultShippingAddress.countryCode }}
					</span>
					<span v-else>
						-
					</span>
				</template>
				<template v-slot:item.type="{item}">
					<CustomerTypeChip :customer="item"/>
				</template>
				<template v-slot:item.state="{item}">
					<CustomerStateChip :customer="item"/>
				</template>
			</v-data-table>
		</AsiContentContainer>

		<template v-slot:advanced-filters>
			<AsiTextFieldSimple v-model="filterModel.name" :placeholder="$t('customer.name')" clearable/>
			<AsiSelectSimple v-model="filterModel.types" :items="typeOptions" :placeholder="$t('customer.type')" multiple clearable/>
			<AsiSelectSimple v-model="filterModel.states" :items="stateOptions" :placeholder="$t('customer.state')" multiple clearable/>
			<AsiTextFieldSimple v-model="filterModel.notes" :placeholder="$t('customer.notes')" clearable/>
		</template>
	</AsiListTableLayout>
</template>

<script lang="ts">
	import {Component, Prop, Watch} from "vue-property-decorator";
	import AsiListTable from "@/components/common/AsiListTable.vue";
	import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
	import AsiListTableHeader from "@/components/common/AsiListTableHeader";
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";
	import IAsiListTableImplementation from "@/components/common/IAsiListTableImplementation";
	import AsiListTableLayout from "@/components/common/AsiListTableLayout.vue";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";
	import IPaginatedResponse from "@/models/IPaginatedResponse";
	import {ICustomerAdminListEntry} from "@/models/customer/CustomerAdminModels";
	import CustomerListFilter from "@/models/customer/CustomerListFilter";
	import CustomerHelper from "@/models/customer/CustomerHelper";
	import {AttachmentMediaSize, CustomerState, CustomerType} from "@/helpers/constants";
	import {ICustomerShopListEntry} from "@/models/customer/CustomerShopModels";
	import Icon from "@/plugins/icons";
	import EnumHelper from "@/helpers/EnumHelper";
	import AsiSelectSimple from "@/components/common/AsiSelectSimple";
	import CustomerStateChip from "@/components/customer/CustomerStateChip.vue";
	import CustomerTypeChip from "@/components/customer/CustomerTypeChip.vue";

	@Component({
		components: {
			CustomerTypeChip,
			CustomerStateChip,
			AsiSelectSimple,
			AsiAvatar,
			AsiContentContainer,
			AsiListTableLayout, AsiTextFieldSimple, AsiListTable
		}
	})
	export default class CustomerList extends AsiListTable<ICustomerAdminListEntry, CustomerListFilter> implements IAsiListTableImplementation<ICustomerAdminListEntry, CustomerListFilter> {

		@Prop({type: Boolean, default: false})
		public hideToolbar!: boolean;

		private icons = Icon;
		private initialized: boolean = false;
		private d: Date = new Date();
		private dateString: string = `${this.d.getFullYear().toString().substr(2, 2)}${(this.d.getMonth() + 1).toString().padStart(2, '0')}${this.d.getDate().toString().padStart(2, '0')}`;
		private filename: string = `${this.dateString}_customer_export.xlsx`;

		public created(): void {
			this.reload(false, false);
			this.$nextTick(() => this.initialized = true);
		}

		// noinspection JSMethodCanBeStatic
		private get typeOptions(): { text: string, value: string | number }[] {
			return EnumHelper.toSelectItems(CustomerType, true);
		}

		// noinspection JSMethodCanBeStatic
		private get stateOptions(): { text: string, value: string | number }[] {
			return EnumHelper.toSelectItems(CustomerState, true);
		}

		public createFilterModel(): CustomerListFilter {
			return new CustomerListFilter();
		}

		public createHeaders(): AsiListTableHeader[] {
			return [
				new AsiListTableHeader('', 'avatar', false, false, 'center', '4rem'),
				new AsiListTableHeader(this.$t('customer.externalId'), 'externalId', true, true, 'start', '7rem'),
				new AsiListTableHeader(this.$t('customer.name'), 'name', true, true, 'start'),
				new AsiListTableHeader(this.$t('address.terms.defaultShippingAddress'), 'shippingAddress', false, true, 'start', 'content-width'),
				new AsiListTableHeader(this.$t('customer.createdAt'), 'createdAt', true, true, 'start', '15rem'),
				new AsiListTableHeader(this.$t('customer.type'), 'type', true, true, 'center', '10rem'),
				new AsiListTableHeader(this.$t('customer.state'), 'state', true, true, 'center', '10rem'),
			];
		}

		public fetchData(filterModel: CustomerListFilter | null, tableOptions: AsiListTableOptions | null): Promise<IPaginatedResponse<ICustomerAdminListEntry>> {
			return this.$customerServiceAdmin.customers(filterModel, tableOptions);
		}

		public filterTextChanged(filterText: string): void {
			if (this.filterModel === null) return;
			this.filterModel.fulltext = filterText;
		}

		public openModelDetailPage(model: ICustomerAdminListEntry): void {
			this.$router.push(CustomerHelper.detailRouteAdmin(model.id));
		}

		public reload(goToFirstPage: boolean = false, debounce: boolean = true): void {
			this.reloadInternal(this, goToFirstPage, debounce);
		}

		protected prepareOptions(options: AsiListTableOptions): AsiListTableOptions {
			if (options.sortBy.length === 0 && this.defaultSortBy.length === 0) {
				options.sortBy = ['name'];
				options.sortDesc = [false];
			}
			return options;
		}

		@Watch('tableOptions', {deep: true})
		private onTableOptionsChanged(): void {
			if (!this.initialized) return;
			this.reload();
		}

		@Watch('filterModel', {deep: true})
		private onFilterModelChanged(): void {
			if (!this.initialized) return;
			this.reload(true);
		}

		// noinspection JSMethodCanBeStatic
		private avatarUrl(customer: ICustomerShopListEntry): string | null {
			return CustomerHelper.avatarUrl(customer, AttachmentMediaSize.s);
		}

		private performExport(): void {
			this.loading = true;

			this.$customerServiceAdmin.exportExcel(this.filterModel, this.tableOptions)
				.then(data => {
					const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
					const link = document.createElement('a');
					link.href = URL.createObjectURL(blob);
					link.download = this.filename;
					link.click();
					URL.revokeObjectURL(link.href);
				})
				.finally(() => this.loading = false);
		}

	}
</script>

<style lang="scss" scoped>

</style>
