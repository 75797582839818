<template>
	<v-dialog :fullscreen="fullscreen" :hide-overlay="hideOverlay" :max-width="maxWidth" :no-click-animation="noClickAnimation"
	          :persistent="persistent" :retain-focus="retainFocus" :scrollable="scrollable" :value="open"
	          transition="dialog-bottom-transition" @keydown.esc="cancel" @click:outside="requestCancel">
		<AsiCard :icon="icon" :loading="loading" :subtitle="subtitle" :title="title" :title-bg-color="color"
		         :unwrapped="unwrapped" highlighted no-bottom-margin>
			<v-card-text v-if="!unwrapped">
				<slot/>
			</v-card-text>
			<slot v-else/>

			<template v-if="!persistent || fullscreen" v-slot:headerActions>
				<AsiBtn :icon="icons.close" :icon-color="closeIconColor" @click="cancel"/>
			</template>
			<template v-slot:actions>
				<slot name="dialog-actions"/>
			</template>
		</AsiCard>
	</v-dialog>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import Icon from "@/plugins/icons";
	import AsiCard from "@/components/common/AsiCard.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";

	@Component({
		components: {AsiBtn, AsiCard}
	})
	export default class AsiDialog extends Vue {

		@Prop({type: Boolean, required: true})
		public open!: boolean;

		@Prop({type: Boolean, default: false})
		public persistent!: boolean;

		@Prop({type: Boolean, default: false})
		public fullscreen!: boolean;

		@Prop({type: Boolean, default: false})
		public scrollable!: boolean;

		@Prop({type: String, default: '50rem'})
		public maxWidth!: string;

		@Prop({type: String, default: null})
		public title!: string | null;

		@Prop({type: String, default: null})
		public subtitle!: string | null;

		@Prop({type: String, default: Icon.warning})
		public icon!: string;

		@Prop({type: String, default: null})
		public color!: string | null;

		@Prop({type: String, default: 'white'})
		public closeIconColor!: string | null;

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		@Prop({type: Boolean, default: false})
		public unwrapped!: boolean;

		@Prop({type: Boolean, default: false})
		public retainFocus!: boolean;

		@Prop({type: Boolean, default: false})
		public noClickAnimation!: boolean;

		@Prop({type: Boolean, default: false})
		public hideOverlay!: boolean;

		protected icons = Icon;

		@Emit('cancel')
		public cancel(event: Event): Event {
			return event;
		}

		protected requestCancel(event: Event): void {
			if (!this.persistent) {
				this.cancel(event);
			}
		}

	}
</script>

<style lang="scss" scoped>

</style>
