<template>
	<ItemAttributeFilter :attribute="attribute" :value-formatted="valueFormatted" :disabled="disabled"
	                     close-on-content-click :filter-active="filterActive" @clear="clear">
		<div class="d-flex flex-row justify-center ma-3">
			<v-btn-toggle :value="value" @change="input">
				<AsiBtn :icon="icons.yes" :value="true"
				        :color="value === true ? 'primary' : undefined" :icon-color="value === true ? 'white' : undefined"
				        :outlined="value !== true" :class="{'white--text': value === true}">
					{{ $t('ui.yes') }}
					<span>({{ data.trueCount }})</span>
				</AsiBtn>
				<AsiBtn :icon="icons.no" :value="false"
				        :color="value === false ? 'primary' : undefined" :icon-color="value === false ? 'white' : undefined"
				        :outlined="value !== false" :class="{'white--text': value === false}">
					{{ $t('ui.no') }}
					<span>({{ data.falseCount }})</span>
				</AsiBtn>
			</v-btn-toggle>
		</div>
	</ItemAttributeFilter>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import ItemAttributeFilter from "@/components/item/shop/attribute-filters/ItemAttributeFilter.vue";
	import VueI18n from "vue-i18n";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import {FilterEntryBoolean} from "@/models/item/IItemShopPaginatedResponse";
	import TranslateResult = VueI18n.TranslateResult;

	@Component({
		components: {AsiBtn, ItemAttributeFilter}
	})
	export default class ItemAttributeFilterBoolean extends Vue {

		@Prop({type: String, required: true})
		public attribute!: string;

		@Prop({required: true})
		public value!: boolean | null;

		@Prop({type: Object, required: true})
		public data!: FilterEntryBoolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		private icons = Icon;

		private get filterActive(): boolean {
			return this.value !== null;
		}

		private get valueFormatted(): string | TranslateResult | null {
			return this.$t(this.value ? 'ui.yes' : 'ui.no');
		}

		private clear(): void {
			this.input(null);
		}

		@Emit('input')
		public input(value: boolean | undefined | null): boolean | null {
			return value ?? null;
		}

	}
</script>

<style lang="scss" scoped>
	.v-btn:before {
		opacity: 0;
	}
</style>
