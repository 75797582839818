<template>
	<AsiCard :icon="icon" :subtitle="subtitle" :title="title" unwrapped :wrap-title="wrapTitle" :wrap-subtitle="wrapSubtitle"
	         :flat="flat" :no-bottom-margin="noBottomMargin">
		<div v-if="hasPrepend" :class="{'pa-6': !prependUnwrapped}">
			<slot name="prepend"/>
		</div>
		<v-divider v-if="hasPrepend"/>

		<v-list :style="computedListStyle" :class="{'pr-3': !noPadding, 'pa-0': noPadding}" dense>
			<slot/>
		</v-list>

		<v-divider v-if="hasAppend"/>
		<div v-if="hasAppend" :class="{'pa-6': !appendUnwrapped}">
			<slot name="append"/>
		</div>

		<template v-if="hasAdditionalHeaderActions || (showActionButton && hasActionButtonListener)" v-slot:headerActions>
			<div class="d-flex flex-row flex-wrap">
				<slot v-if="hasAdditionalHeaderActions" name="additionalHeaderActions"/>
				<AsiBtn v-if="showActionButton && hasActionButtonListener"
				        :icon="icons.edit" :disabled="actionButtonDisabled" @click="actionButtonClicked"/>
			</div>
		</template>
	</AsiCard>
</template>

<script lang="ts">
	import {Component, Emit, Prop} from "vue-property-decorator";
	import AsiCard from "./AsiCard.vue";
	import Icon from "@/plugins/icons";
	import AsiBtn from "@/components/common/AsiBtn.vue";

	@Component({
		components: {AsiBtn, AsiCard}
	})
	export default class AsiCardDetail extends AsiCard {

		@Prop({type: String, default: Icon.edit})
		public actionButtonIcon!: string;

		@Prop({type: Boolean, default: false})
		public prependUnwrapped!: boolean;

		@Prop({type: Boolean, default: false})
		public appendUnwrapped!: boolean;

		@Prop({type: Boolean, default: true})
		public showActionButton!: boolean;

		@Prop({type: Boolean, default: false})
		public actionButtonDisabled!: boolean;

		@Prop({type: Boolean, default: false})
		public multiColumn!: boolean;

		@Prop({type: Number, default: -1})
		public columns!: number;

		@Prop({type: String, default: '300px'})
		public minColumnWidth!: string;

		@Prop({type: Boolean, default: false})
		public noPadding!: boolean;

		protected icons = Icon;

		private get hasActionButtonListener(): boolean {
			return !!this.$listeners.actionButtonClicked;
		}

		private get hasPrepend(): boolean {
			return !!this.$slots['prepend'];
		}

		private get hasAppend(): boolean {
			return !!this.$slots['append'];
		}

		private get hasAdditionalHeaderActions(): boolean {
			return !!this.$slots['additionalHeaderActions'];
		}

		private get computedListStyle(): object {
			if (!this.multiColumn) return {};

			return {
				'display': 'grid',
				'grid-template-columns': `repeat(${this.columns > 0 ? this.columns : 'auto-fit'}, minmax(${this.minColumnWidth}, 1fr))`,
			};
		}

		@Emit('actionButtonClicked')
		public actionButtonClicked(event: Event): Event {
			return event;
		}

	}
</script>

<style lang="scss" scoped>
	.multiline {
		overflow: initial;
		text-overflow: initial;
		white-space: initial;
	}

	.list {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	}
</style>
