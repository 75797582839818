<template>
	<div>
		<span v-if="readonly" class="font-italic">
			{{
				position.customerReference === null ? $t('cartPosition.terms.noCustomerReference') : position.customerReference
			}}
		</span>
		<v-menu v-else v-model="menuOpen" :disabled="readonly" :close-on-content-click="false"
		        max-width="30rem" bottom offset-y left>
			<template v-slot:activator="{on, attrs}">
				<div v-on="on" v-bind="attrs" class="d-flex flex-row align-center text-wrap">
					<span class="font-italic">
						{{
							position.customerReference === null ? $t('cartPosition.terms.noCustomerReference') : position.customerReference
						}}
					</span>
					<AsiBtn v-if="!readonly" :icon="icons.edit" :loading="loading" :disabled="disabled" x-small class="ml-2"/>
				</div>
			</template>

			<div class="grey lighten-3 pa-2 d-flex flex-row align-center">
				<AsiTextFieldSimple v-model="inputValue" :disabled="disabled" style="width: 25rem"
				                    :label="$t('cartPosition.customerReference')" :palceholder="$t('cartPosition.customerReference')"
				                    @keydown.enter.prevent="keydownEnter"
				                    clearable/>
				<AsiBtn :icon="icons.save" :disabled="!hasChange" @click="performUpdate" class="ml-2"/>
			</div>
		</v-menu>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
	import StringHelper from "@/helpers/StringHelper";
	import {ICartPositionShopSimple} from "@/models/cart-position/CartPositionShopModels";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import Snackbar from "@/helpers/Snackbar";

	@Component({
		components: {AsiBtn, AsiTextFieldSimple}
	})
	export default class CartPositionCustomerReferenceInput extends Vue {

		@Prop({type: Object, required: true})
		public position!: ICartPositionShopSimple;

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		private icons = Icon;
		private menuOpen: boolean = false;
		private valueInternal: string | null = null;
		private loading: boolean = false;

		private get hasChange(): boolean {
			return this.position.customerReference !== this.valueInternal;
		}

		private get inputValue(): string | null {
			return this.valueInternal;
		}

		private set inputValue(value: string | null) {
			this.valueInternal = value === null || StringHelper.isEmpty(value) ? null : value.trim();
		}

		@Watch('position.customerReference', {immediate: true})
		private onCartPositionCustomerReferenceChanged(value: string): void {
			this.valueInternal = value;
		}

		@Watch('menuOpen')
		private onMenuOpenChanged(value: boolean): void {
			if (!value) {
				this.valueInternal = this.position.customerReference;
			}
		}

		@Emit('change')
		public change(customerReference: string | null): string | null {
			return customerReference;
		}

		private keydownEnter(): void {
			if (this.hasChange) this.performUpdate();
		}

		private performUpdate(): void {
			if (!this.hasChange) return;

			this.loading = true;
			this.$store.dispatch('cart/updatePositionCustomerReference', {
				positionId: this.position.id,
				customerReference: this.valueInternal,
			})
				.then(() => {
					this.change(this.valueInternal);
					this.menuOpen = false;
				})
				.catch(() => Snackbar.updateError())
				.finally(() => this.loading = false);
		}

	}
</script>

<style lang="scss" scoped>

</style>
