<template>
	<AsiCardDetail :title="$t('category.state')" :icon="icons.state"
	               :show-action-button="!readonly" :action-button-disabled="disabled" @actionButtonClicked="updateDialog.open()">
		<AsiCardDetailEntry :icon="icons.state" :label="$t('category.state')">
			<CategoryStateChip :category="category"/>
		</AsiCardDetailEntry>

		<CategoryUpdateDialogState v-if="!readonly && updateDialog.isLoaded" :open="updateDialog.isOpen" :category="category"
		                           @cancel="updateDialog.close()" @save="updateDialog.close(); change();"/>
	</AsiCardDetail>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import AsiCardDetail from "../../common/AsiCardDetail.vue";
	import AsiCardDetailEntry from "../../common/AsiCardDetailEntry.vue";
	import CategoryStateChip from "../CategoryStateChip.vue";
	import Icon from "../../../plugins/icons";
	import DialogHandler from "../../common/DialogHandler";
	import {ICategoryShop} from "@/models/category/CategoryShopModels";
	import {ICategoryAdmin} from "@/models/category/CategoryAdminModels";
	import CategoryUpdateDialogState from "@/components/category/admin/CategoryUpdateDialogState.vue";

	@Component({
		components: {CategoryUpdateDialogState, CategoryStateChip, AsiCardDetailEntry, AsiCardDetail}
	})
	export default class CategoryCardState extends Vue {
		@Prop({type: Object, required: true})
		public category!: ICategoryShop | ICategoryAdmin;

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		private icons = Icon;
		private updateDialog: DialogHandler = new DialogHandler();

		@Emit('change')
		public change(): void {
			return;
		}
	}
</script>

<style lang="scss" scoped>

</style>
