<template>
	<AsiChip :small="small" :x-small="xSmall" label @click="copy">
		<span class="mr-2 font-weight-bold">{{ $t('item.terms.itemNumberDense') }}</span>
		{{ item.itemNumber }}
	</AsiChip>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import {IItemShopListEntry} from "@/models/item/ItemShopModels";
	import AsiChip from "@/components/common/AsiChip.vue";
	import ClipboardHelper from "@/helpers/ClipboardHelper";
	@Component({
		components: {AsiChip}
	})
	export default class ItemChipNumber extends Vue {

		@Prop({type: Object, required: true})
		public item!: IItemShopListEntry;

		@Prop({type: Boolean, default: false})
		public small!: boolean;

		@Prop({type: Boolean, default: false})
		public xSmall!: boolean;

		private copy(): void {
			ClipboardHelper.copyText(this.item.itemNumber);
		}

	}
</script>

<style lang="scss" scoped>

</style>
