<template>
	<AsiDialogFormLayout v-if="model !== null" :icon="icons.paymentTypes" :title="$t('globalConfig.terms.surcharge')"
	                     :open="open" :loading="loading" :valid="valid" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped scrollable>
		<v-card-text class="pa-0">
			<v-form v-model="valid">
				<div class="pa-6">
					<v-row>
						<v-col>
							<AsiTextField v-model="model.amount" :rules="model.rules('amount')"
							              :label="$t('globalConfig.terms.surcharge')" :placeholder="$t('globalConfig.terms.surcharge')"
							              :suffix="currencyCode" clearable
							              @keydown.enter.prevent="requestSave"/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<AsiTextField v-model="model.untilAmount" :rules="model.rules('untilAmount')"
							              :label="$t('globalConfig.terms.surchargeUntil')" :placeholder="$t('globalConfig.terms.surchargeUntil')"
							              :suffix="currencyCode" clearable
							              @keydown.enter.prevent="requestSave"/>
						</v-col>
					</v-row>
				</div>
			</v-form>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop} from "vue-property-decorator";
	import AsiDialogFormLayout from "../../common/AsiDialogFormLayout.vue";
	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
	import AsiTextField from "@/components/common/AsiTextField";
	import AsiDatePickerCombined from "@/components/common/AsiDatePickerCombined.vue";
	import ItemAutocompleteInput from "@/components/item/admin/ItemAutocompleteInput.vue";
	import GlobalConfigUpdateMinimumOrderSurcharge
		from "@/models/global-config/GlobalConfigUpdateMinimumOrderSurcharge";
	import {IGlobalConfigAdmin} from "@/models/global-config/GlobalConfigAdminModels";

	@Component({
		components: {
			ItemAutocompleteInput,
			AsiDatePickerCombined, AsiTextField, AsiDialogFormLayout
		}
	})
	export default class GlobalConfigUpdateDialogSurcharge extends AsiDialogForm {

		@Prop({type: Object, required: true})
		public globalConfig!: IGlobalConfigAdmin;

		private model: GlobalConfigUpdateMinimumOrderSurcharge | null = null;

		public created(): void {
		    this.setGlobalConfigInternal();
		}

		// noinspection JSMethodCanBeStatic
		private get currencyCode(): string {
			//TODO: maybe later replace with configurable currency
			return 'CHF';
		}

		private setGlobalConfigInternal(): void {
			if (this.globalConfig === null) {
				this.model = null;
				return;
			}

			if (this.model === null) {
				this.model = new GlobalConfigUpdateMinimumOrderSurcharge(this.globalConfig);
				this.valid = true;
			} else {
				this.model.setDetailModel(this.globalConfig);
			}
		}

		private requestSave(): void {
			if (this.model === null) return;

			this.loading = true;
			this.$globalConfigServiceAdmin.updateMinimumOrderSurcharge(this.model)
				.then(this.save)
				.catch(this.handleErrors)
				.finally(() => this.loading = false);
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			this.setGlobalConfigInternal();
			const form = this.$refs.form as HTMLFormElement;
			this.reset([], form);
			return event;
		}

	}
</script>

<style lang="scss" scoped>

</style>
