<template>
	<v-list-item :disabled="disabled" @click="click">
		<v-list-item-icon>
			<v-icon :size="20">{{ icon }}</v-icon>
		</v-list-item-icon>
		<v-list-item-content>
			<v-list-item-title>
				{{ label }}
			</v-list-item-title>
			<v-list-item-subtitle v-if="hintText">
				{{ hintText }}
			</v-list-item-subtitle>
		</v-list-item-content>
	</v-list-item>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";

	@Component
	export default class AsiMenuItem extends Vue {

		@Prop({type: String, required: true})
		public label!: string;

		@Prop({type: String, required: true})
		public icon!: string;

		@Prop({type: String, default: null})
		public hintText!: string | null;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		@Emit('click')
		public click(event: Event): Event {
			return event;
		}

	}
</script>

<style lang="scss" scoped>
	.v-icon {
		width: 25px;
	}
</style>
