<template>
	<AsiDialogFormLayout v-if="model !== null" :icon="icons.category" :title="$t('ui.terms.description')"
	                     :open="open" :loading="loading" :valid="valid" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped scrollable>
		<v-card-text class="pa-0">
			<v-form v-model="valid">
				<div class="pa-6">
					<TranslatedValueFormPart :label="$t('category.description')" :model="model.description" required text-area/>
				</div>
			</v-form>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
	import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
	import AsiTextField from "@/components/common/AsiTextField";
	import AsiCheckbox from "@/components/common/AsiCheckbox";
	import {ICategoryAdmin} from "@/models/category/CategoryAdminModels";
	import TranslatedValueFormPart from "@/components/translated-value/admin/TranslatedValueFormPart.vue";
	import AsiSelectSimple from "@/components/common/AsiSelectSimple";
	import AsiSelect from "@/components/common/AsiSelect";
	import AsiAutocomplete from "@/components/common/AsiAutocomplete";
	import CategoryUpdateDescription from "@/models/category/CategoryUpdateDescription";

	@Component({
		components: {
			AsiAutocomplete,
			AsiSelect,
			AsiSelectSimple, TranslatedValueFormPart, AsiTextField, AsiDialogFormLayout, AsiDialogForm, AsiCheckbox
		}
	})
	export default class CategoryUpdateDialogDescription extends AsiDialogForm {

		@Prop({type: Object, required: true})
		public category!: ICategoryAdmin;

		private model: CategoryUpdateDescription | null = null;


		@Watch('category', {deep: true, immediate: true})
		private onCategoryChanged(): void {
			this.setCategoryInternal();
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			this.setCategoryInternal();
			const form = this.$refs.form as HTMLFormElement;
			this.reset([], form);
			return event;
		}

		private setCategoryInternal(): void {
			if (this.model === null) {
				this.model = new CategoryUpdateDescription(this.category);
				this.valid = true;
			} else {
				this.model.setDetailModel(this.category);
			}
		}

		private requestSave(): void {
			if (this.model === null) return;

			this.loading = true;
			this.$categoryServiceAdmin.updateDescription(this.category.id, this.model)
				.then(this.save)
				.catch(this.handleErrors)
				.finally(() => this.loading = false);
		}

	}
</script>

<style lang="scss" scoped>

</style>
