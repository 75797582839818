<template>
	<AsiCard unwrapped>
		<v-expansion-panels flat>
			<v-expansion-panel>
				<v-expansion-panel-header color="secondary lighten-2" class="card-expansion-header">
					<div class="white--text font-weight-bold d-flex align-center">
						<v-icon color="white" class="mr-3">{{ icons.frameAssembly }}</v-icon>
						<span class="text-uppercase">{{ $t('frameAssembly.singular') }}</span>
					</div>
					<div v-if="!sMobile" class="d-flex justify-end mr-9">
						<AsiChip color="primary" text-color="white" small label>
							{{ $t('frameAssembly.terms.assemblyPossible') }}
						</AsiChip>
					</div>
					<template v-slot:actions>
						<v-icon color="white">{{ icons.dropdown }}</v-icon>
					</template>
				</v-expansion-panel-header>
				<v-expansion-panel-content color="grey lighten-5">
					<FrameAssemblyConfigurator @created="frameAssemblyCreate = $event" />

					<div class="d-flex" :class="{'flex-column': sMobile}">
						<div class="footer-price-table grey lighten-4 pa-3" :class="{'is-mobile': sMobile}">
							<template v-if="item.frameAssemblyPriceTable !== null">
								<AsiBtn small block :text="true" @click="showPriceTable = !showPriceTable">
									<span>{{ $t('itemPrice.terms.showPriceTable') }}</span>
									<v-icon :class="{'flipped': showPriceTable}">{{ icons.dropdown }}</v-icon>
								</AsiBtn>
								<v-expand-transition>
									<ItemPriceTable v-if="showPriceTable" v-model="quantity" :unit="item.salesUnitOfMeasure" :prices="item.prices"/>
								</v-expand-transition>
							</template>
						</div>
						<div class="footer-actions grey lighten-4 pa-3" :class="{'is-mobile': sMobile}">
							<div class="d-flex flex-column">
								<span class="headline font-weight-bold mt-2">
									{{
										price !== null
											? $n(priceTotal, currencyCode !== null ? {
												key: 'currencyDisplay',
												currency: currencyCode
											} : {})
											: '-'
									}}
								</span>
								<small class="grey--text mt-n1">
									{{ $t(amountsInclVat ? 'ui.terms.inclVat' : 'ui.terms.exclVat') }}
								</small>
							</div>
							<div class="d-flex flex-row align-center" :class="{'mt-3': sMobile}">
								<ItemQuantitySelection v-model="quantity" :unit="item.salesUnitOfMeasure" class="mr-3"/>
								<AsiBtn :icon="icons.cart" color="primary" large @click="addToCart">
									{{ $t('cart.terms.addToCartDense') }}
								</AsiBtn>
							</div>
						</div>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</AsiCard>
</template>

<script lang="ts">
	import {Component, Prop} from "vue-property-decorator";
	import AsiCard from "@/components/common/AsiCard.vue";
	import Icon from "@/plugins/icons";
	import AsiChip from "@/components/common/AsiChip.vue";
	import ItemCardFrameAssemblyFrame from "@/components/item/shop/ItemCardFrameAssemblyFrame.vue";
	import AsiTextField from "@/components/common/AsiTextField";
	import AsiTextarea from "@/components/common/AsiTextarea";
	import AsiRadioGroup from "@/components/common/AsiRadioGroup";
	import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
	import AsiTextareaSimple from "@/components/common/AsiTextareaSimple";
	import ItemAddToCartButton from "@/components/item/shop/ItemAddToCartButton.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import {mixins} from "vue-class-component";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
	import ItemCardFrameAssemblyTypeSelection from "@/components/item/shop/ItemCardFrameAssemblyTypeSelection.vue";
	import {IItemShopListEntry} from "@/models/item/ItemShopModels";
	import ItemQuantitySelection from "@/components/item/shop/ItemQuantitySelection.vue";
	import ItemPriceTable from "@/components/item/ItemPriceTable.vue";
	import FrameAssemblyConfigurator from "@/components/frame-assembly/FrameAssemblyConfigurator.vue";
	import {IPrice} from "@/models/price/PriceModels";
	import TotalHelper from "@/helpers/TotalHelper";
	import {IItemPriceShopSimple} from "@/models/item-price/ItemPriceModelsShop";
	import Snackbar from "@/helpers/Snackbar";
	import FrameAssemblyCreate from "@/models/frame-assembly/FrameAssemblyCreate";

	@Component({
		components: {
			FrameAssemblyConfigurator,
			ItemPriceTable,
			ItemQuantitySelection,
			ItemCardFrameAssemblyTypeSelection,
			AsiBtn,
			ItemAddToCartButton,
			AsiTextareaSimple,
			AsiTextFieldSimple,
			AsiRadioGroup, AsiTextarea, AsiTextField, ItemCardFrameAssemblyFrame, AsiChip, AsiCard
		}
	})
	export default class ItemCardFrameAssembly extends mixins(ResponsiveChecks) {

		@Prop({type: Object, required: true})
		public item!: IItemShopListEntry;

		private icons = Icon;

		private quantity: number = 1;
		private showPriceTable: boolean = false;
		private selectedCartId: string | null = null;
		private frameAssemblyCreate!: FrameAssemblyCreate;

		private get sortedPrices(): IItemPriceShopSimple[] {
			return this.item.prices.map(p => p).sort((a: IItemPriceShopSimple, b: IItemPriceShopSimple) => b.fromQuantity - a.fromQuantity);
		}

		private get price(): IPrice | null {
			if (this.quantity === 0) return null;

			return this.sortedPrices
				.map(p => p)
				.find(p => p.fromQuantity <= (this.quantity as number))?.price ?? null;
		}

		private get priceTotal(): number | null {
			if (this.price === null) return null;

			const amount = this.price.amount + (this.amountsInclVat ? TotalHelper.vatAmount(this.price) : 0);
			return this.quantity * amount;
		}

		private get amountsInclVat(): boolean {
			return this.$store.state.ui.amountsInclVat;
		}

		private get currencyCode(): string | null {
			return this.item.price?.price.currency.currencyCode ?? null;
		}

		public created(): void {
			this.selectedCartId = this.$store.state.cart.activeCartId;
		}

		private addToCart(): void {
			if (this.item !== null) {
				this.$store.dispatch('cart/addPosition', {
					item: this.item,
					frameAssembly: this.frameAssemblyCreate,
					quantity: this.quantity,
					cartId: this.selectedCartId,
				})
					.then(() => this.$store.commit('cart/setExpanded', true))
					.catch(() => Snackbar.createError());
			}
		}
	}
</script>

<style lang="scss" scoped>
	.card-expansion-header {
		height: 68px;
	}

	.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
		padding: 0 !important;
	}

	.footer-price-table {

		&:not(.is-mobile) {
			width: 50%;
		}

		display: flex;
		flex-direction: column;

		.flipped {
			transform: rotate(180deg);
		}
	}

	.footer-actions {

		&:not(.is-mobile) {
			width: 50%;
		}

		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-end;
	}
</style>
