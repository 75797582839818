<template>
	<AsiCardDetail :title="$t('mailTemplate.general')" no-bottom-margin unwrapped>
		<MailTemplateDetailEntry v-for="template in generalTemplates" :key="template.id" :mail-template="template" @save="save" @delete="emitDelete">
			<template v-slot:specificInfos>
				<MailTemplateInfoCard :title="$t('mailTemplate.terms.generalInformations')" :infos="generalInfos(template.mailType)" :icon="icons.password" no-bottom-margin/>
			</template>
		</MailTemplateDetailEntry>
	</AsiCardDetail>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import Icon from "@/plugins/icons";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import MailTemplateDetailEntry from "@/components/mail-template/MailTemplateDetailEntry.vue";
	import MailTemplateInfoCard from "@/components/mail-template/MailTemplateInfoCard.vue";
	import MailTemplateInfo from "@/models/mail-template/MailTemplateInfo";
	import {IMailTemplateAdminListEntry} from "@/models/mail-template/MailTemplateAdminModels";
	import {MailType} from "@/helpers/constants";

	@Component({
		components: {MailTemplateInfoCard, MailTemplateDetailEntry, AsiBtn, AsiCardDetailEntry, AsiCardDetail}
	})
	export default class SettingsCardMailTemplatesGeneral extends Vue {

		@Prop({type: Array, required: true})
		public generalTemplates!: IMailTemplateAdminListEntry[];

		private icons = Icon;

		private generalInfos(type: MailType): MailTemplateInfo[] {
			const placeHolders = [];
			if (type === MailType.mailTypePasswordReset) {
				placeHolders.push(new MailTemplateInfo('{username}', 'mailTemplate.placeholders.pwReset.username'));
				placeHolders.push(new MailTemplateInfo('{password}', 'mailTemplate.placeholders.pwReset.password'));
				placeHolders.push(new MailTemplateInfo('{link}', 'mailTemplate.placeholders.pwReset.link'));
			}

			if (type === MailType.mailTypeConfirmation) {
				placeHolders.push(new MailTemplateInfo('{link}', 'mailTemplate.placeholders.userConfirmation.link'));
			}
			return placeHolders;
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('delete')
		public emitDelete(id: string): string {
			return id;
		}
	}
</script>

<style lang="scss" scoped>

</style>
