<template>
	<section>
		<div class="pa-3 d-flex flex-row align-center text-filter" :class="{'grey lighten-4': !hasTextFilter, 'primary rounded-t': hasTextFilter}">
			<AsiTextFieldSimple v-model="filter.fulltext" :placeholder="$t('ui.search')" clearable class="mr-6"
			                    @keydown.esc.prevent="filter.fulltext = null" @focus="$event.target.select()"/>
			<div>
				<v-btn-toggle :value="tableView" @change="$emit('update:tableView', $event)" mandatory>
					<AsiBtn :value="false" color="primary" :icon="icons.card" small/>
					<AsiBtn :value="true" color="primary" :icon="icons.table" small/>
				</v-btn-toggle>
			</div>
		</div>
		<v-expand-transition>
			<div v-show="showFilters">
				<v-divider/>
				<div v-if="filterConfigurations.length > 0" class="filters pa-3">
					<component v-for="cfg in filterConfigurationsExpanded" :key="cfg.attrs.attribute"
					           :is="cfg.component" v-on="cfg.on" v-bind="cfg.attrs"/>
					<template v-if="filterConfigurationsCollapsed.length > 0">
						<AsiBtn v-if="!filtersExpanded" @click="filtersExpanded = true" color="primary">
							{{ $t('ui.terms.showMore') }}
						</AsiBtn>
						<component v-else v-for="cfg in filterConfigurationsCollapsed" :key="cfg.attrs.attribute"
						           :is="cfg.component" v-on="cfg.on" v-bind="cfg.attrs"/>
					</template>
				</div>
			</div>
		</v-expand-transition>
	</section>
</template>

<script lang="ts">
	import {Component, Prop} from "vue-property-decorator";
	import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
	import ItemListFilterShop from "@/models/item/ItemListFilterShop";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import {ICategoryShopListEntry} from "@/models/category/CategoryShopModels";
	import ItemAttributeFilterSelection
		from "@/components/item/shop/attribute-filters/ItemAttributeFilterSelection.vue";
	import {
		FilterEntryBoolean,
		FilterEntryNumeric, FilterEntryNumericSelection,
		FilterEntrySelection,
		FilterEntryStockStatus,
		IItemShopPaginatedResponse
	} from "@/models/item/IItemShopPaginatedResponse";
	import ItemAttributeFilterBoolean from "@/components/item/shop/attribute-filters/ItemAttributeFilterBoolean.vue";
	import ItemAttributeFilterNumeric from "@/components/item/shop/attribute-filters/ItemAttributeFilterNumeric.vue";
	import ItemAttributeFilterStockStatus
		from "@/components/item/shop/attribute-filters/ItemAttributeFilterStockStatus.vue";
	import StringHelper from "@/helpers/StringHelper";
	import {ItemAttribute, ItemAttributeFilterVisibility} from "@/helpers/constants";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
	import {mixins} from "vue-class-component";
	import ItemAttributeFilterNumericSelection
		from "@/components/item/shop/attribute-filters/ItemAttributeFilterNumericSelection.vue";

	class FilterConfig {
		component!: string;
		attrs!: {
			attribute: string,
			value: any,
			data: FilterEntrySelection | FilterEntryBoolean | FilterEntryNumeric | FilterEntryStockStatus,
			disabled: boolean,
		};
		on!: object;
		visibility!: ItemAttributeFilterVisibility;
	}

	@Component({
		components: {
			ItemAttributeFilterNumericSelection,
			ItemAttributeFilterStockStatus,
			ItemAttributeFilterNumeric,
			ItemAttributeFilterBoolean, ItemAttributeFilterSelection, AsiBtn, AsiTextFieldSimple
		}
	})
	export default class ItemFilter extends mixins(ResponsiveChecks) {

		@Prop({type: Object, required: true})
		public filter!: ItemListFilterShop;

		@Prop({type: String, required: false})
		public categoryId!: string | null;

		@Prop({type: Boolean, required: true})
		public tableView!: boolean;

		@Prop({type: Object, default: null})
		public data!: IItemShopPaginatedResponse | null;

		private icons = Icon;
		private filtersExpanded: boolean = false;

		private get showFilters(): boolean {
			return this.data !== null;
		}

		private get hasTextFilter(): boolean {
			return !StringHelper.isEmpty(this.filter.fulltext);
		}

		private get category(): ICategoryShopListEntry | null {
			return this.categoryId === null ? null : this.$store.getters['category/categoryById'](this.categoryId);
		}

		private get filterConfigurationsExpanded(): FilterConfig[] {
			return this.filterConfigurations.filter(f => this.isExpanded(f.visibility));
		}

		private get filterConfigurationsCollapsed(): FilterConfig[] {
			return this.filterConfigurations.filter(f => !this.isExpanded(f.visibility));
		}

		private get filterConfigurations(): FilterConfig[] {
			if (this.data === null || this.category === null) return [];

			return [
				ItemFilter.selectionConfig(ItemAttribute.material, this.filter.materials, this.data.filters.materials, this.category.materialFilterVisibility, (val: string[]) => this.filter.materials = val),
				ItemFilter.selectionConfig(ItemAttribute.color, this.filter.colors, this.data.filters.colors, this.category.colorFilterVisibility, (val: string[]) => this.filter.colors = val),
				ItemFilter.selectionConfig(ItemAttribute.type, this.filter.types, this.data.filters.types, this.category.typeFilterVisibility, (val: string[]) => this.filter.types = val),
				ItemFilter.numericSelectionConfig(ItemAttribute.rebate, this.filter.rebateFrom, this.filter.rebateTo, this.data.filters.rebate, this.category.rebateFilterVisibility, (val: number[]) => {
					this.filter.rebateFrom = val[0];
					this.filter.rebateTo = val[1];
				}),
				ItemFilter.numericSelectionConfig(ItemAttribute.rebateSpace, this.filter.rebateSpaceFrom, this.filter.rebateSpaceTo, this.data.filters.rebateSpace, this.category.rebateSpaceFilterVisibility, (val: number[]) => {
					this.filter.rebateSpaceFrom = val[0];
					this.filter.rebateSpaceTo = val[1];
				}),
				ItemFilter.numericSelectionConfig(ItemAttribute.grooveWidth, this.filter.grooveWidthFrom, this.filter.grooveWidthTo, this.data.filters.grooveWidth, this.category.grooveWidthFilterVisibility, (val: number[]) => {
					this.filter.grooveWidthFrom = val[0];
					this.filter.grooveWidthTo = val[1];
				}),
				ItemFilter.numericSelectionConfig(ItemAttribute.grooveDepth, this.filter.grooveDepthFrom, this.filter.grooveDepthTo, this.data.filters.grooveDepth, this.category.grooveDepthFilterVisibility, (val: number[]) => {
					this.filter.grooveDepthFrom = val[0];
					this.filter.grooveDepthTo = val[1];
				}),
				ItemFilter.selectionConfig(ItemAttribute.hardnessType, this.filter.hardnessTypes, this.data.filters.hardnessTypes, this.category.hardnessTypeFilterVisibility, (val: string[]) => this.filter.hardnessTypes = val),
				ItemFilter.numericSelectionConfig(ItemAttribute.hardness, this.filter.hardnessFrom, this.filter.hardnessTo, this.data.filters.hardness, this.category.hardnessFilterVisibility, (val: number[]) => {
					this.filter.hardnessFrom = val[0];
					this.filter.hardnessTo = val[1];
				}),
				ItemFilter.selectionConfig(ItemAttribute.properties, this.filter.properties, this.data.filters.properties, this.category.propertiesFilterVisibility, (val: string[]) => this.filter.properties = val),
				ItemFilter.numericConfig(ItemAttribute.density, this.filter.densityFrom, this.filter.densityTo, this.data.filters.density, this.category.densityFilterVisibility, (val: number[]) => {
					this.filter.densityFrom = val[0];
					this.filter.densityTo = val[1];
				}),
				ItemFilter.selectionConfig(ItemAttribute.connectionType, this.filter.connectionTypes, this.data.filters.connectionTypes, this.category.connectionTypeFilterVisibility, (val: string[]) => this.filter.connectionTypes = val),
				ItemFilter.selectionConfig(ItemAttribute.surfaceTreatment, this.filter.surfaceTreatments, this.data.filters.surfaceTreatments, this.category.surfaceTreatmentFilterVisibility, (val: string[]) => this.filter.surfaceTreatments = val),
				ItemFilter.selectionConfig(ItemAttribute.tolerance, this.filter.tolerances, this.data.filters.tolerances, this.category.toleranceFilterVisibility, (val: string[]) => this.filter.tolerances = val),
				ItemFilter.selectionConfig(ItemAttribute.fireProtectionStandard, this.filter.fireProtectionStandards, this.data.filters.fireProtectionStandards, this.category.fireProtectionStandardFilterVisibility, (val: string[]) => this.filter.fireProtectionStandards = val),
				ItemFilter.selectionConfig(ItemAttribute.foodStandard, this.filter.foodStandards, this.data.filters.foodStandards, this.category.foodStandardFilterVisibility, (val: string[]) => this.filter.foodStandards = val),
				ItemFilter.selectionConfig(ItemAttribute.otherStandard, this.filter.otherStandards, this.data.filters.otherStandards, this.category.otherStandardFilterVisibility, (val: string[]) => this.filter.otherStandards = val),
				ItemFilter.selectionConfig(ItemAttribute.fixture, this.filter.fixtures, this.data.filters.fixtures, this.category.fixtureFilterVisibility, (val: string[]) => this.filter.fixtures = val),
				ItemFilter.numericSelectionConfig(ItemAttribute.wrenchSize, this.filter.wrenchSizeFrom, this.filter.wrenchSizeTo, this.data.filters.wrenchSize, this.category.wrenchSizeFilterVisibility, (val: number[]) => {
					this.filter.wrenchSizeFrom = val[0];
					this.filter.wrenchSizeTo = val[1];
				}),
				ItemFilter.numericConfig(ItemAttribute.threadLength, this.filter.threadLengthFrom, this.filter.threadLengthTo, this.data.filters.threadLength, this.category.threadLengthFilterVisibility, (val: number[]) => {
					this.filter.threadLengthFrom = val[0];
					this.filter.threadLengthTo = val[1];
				}),
				ItemFilter.selectionConfig(ItemAttribute.thread, this.filter.threads, this.data.filters.threads, this.category.threadFilterVisibility, (val: string[]) => this.filter.threads = val),
				ItemFilter.numericSelectionConfig(ItemAttribute.diameterMm, this.filter.diameterMmFrom, this.filter.diameterMmTo, this.data.filters.diameterMm, this.category.diameterMmFilterVisibility, (val: number[]) => {
					this.filter.diameterMmFrom = val[0];
					this.filter.diameterMmTo = val[1];
				}),
				ItemFilter.selectionConfig(ItemAttribute.applicationRange, this.filter.applicationRanges, this.data.filters.applicationRanges, this.category.applicationRangeFilterVisibility, (val: string[]) => this.filter.applicationRanges = val),
				ItemFilter.selectionConfig(ItemAttribute.resistance, this.filter.resistances, this.data.filters.resistances, this.category.resistanceFilterVisibility, (val: string[]) => this.filter.resistances = val),
				ItemFilter.selectionConfig(ItemAttribute.system, this.filter.systems, this.data.filters.systems, this.category.systemFilterVisibility, (val: string[]) => this.filter.systems = val),
				ItemFilter.numericSelectionConfig(ItemAttribute.glassThickness, this.filter.glassThicknessFrom, this.filter.glassThicknessTo, this.data.filters.glassThickness, this.category.glassThicknessFilterVisibility, (val: number[]) => {
					this.filter.glassThicknessFrom = val[0];
					this.filter.glassThicknessTo = val[1];
				}),
				ItemFilter.numericSelectionConfig(ItemAttribute.innerDiameter, this.filter.innerDiameterFrom, this.filter.innerDiameterTo, this.data.filters.innerDiameter, this.category.innerDiameterFilterVisibility, (val: number[]) => {
					this.filter.innerDiameterFrom = val[0];
					this.filter.innerDiameterTo = val[1];
				}),
				ItemFilter.numericSelectionConfig(ItemAttribute.outerDiameter, this.filter.outerDiameterFrom, this.filter.outerDiameterTo, this.data.filters.outerDiameter, this.category.outerDiameterFilterVisibility, (val: number[]) => {
					this.filter.outerDiameterFrom = val[0];
					this.filter.outerDiameterTo = val[1];
				}),
				ItemFilter.numericSelectionConfig(ItemAttribute.ringDiameter, this.filter.ringDiameterFrom, this.filter.ringDiameterTo, this.data.filters.ringDiameter, this.category.ringDiameterFilterVisibility, (val: number[]) => {
					this.filter.ringDiameterFrom = val[0];
					this.filter.ringDiameterTo = val[1];
				}),
				ItemFilter.numericSelectionConfig(ItemAttribute.crossSection, this.filter.crossSectionFrom, this.filter.crossSectionTo, this.data.filters.crossSection, this.category.crossSectionFilterVisibility, (val: number[]) => {
					this.filter.crossSectionFrom = val[0];
					this.filter.crossSectionTo = val[1];
				}),
				ItemFilter.selectionConfig(ItemAttribute.assemblyGroup, this.filter.assemblyGroups, this.data.filters.assemblyGroups, this.category.assemblyGroupFilterVisibility, (val: string[]) => this.filter.assemblyGroups = val),

				ItemFilter.booleanConfig('isTopSeller', this.filter.isTopSeller, this.data.filters.isTopSeller, this.category.isTopSellerFilterVisibility, (val: boolean | null) => this.filter.isTopSeller = val),
				ItemFilter.booleanConfig('canOrderSample', this.filter.canOrderSample, this.data.filters.canOrderSample, this.category.canOrderSampleFilterVisibility, (val: boolean | null) => this.filter.canOrderSample = val),
				ItemFilter.numericConfig('price', this.filter.priceFrom, this.filter.priceTo, this.data.filters.price, this.category.priceFilterVisibility, (val: number[]) => {
					this.filter.priceFrom = val[0];
					this.filter.priceTo = val[1];
				}),
				ItemFilter.numericConfig(ItemAttribute.grossWeight, this.filter.grossWeightFrom, this.filter.grossWeightTo, this.data.filters.grossWeight, this.category.grossWeightFilterVisibility, (val: number[]) => {
					this.filter.grossWeightFrom = val[0];
					this.filter.grossWeightTo = val[1];
				}),
				ItemFilter.numericConfig(ItemAttribute.length, this.filter.lengthFrom, this.filter.lengthTo, this.data.filters.length, this.category.lengthFilterVisibility, (val: number[]) => {
					this.filter.lengthFrom = val[0];
					this.filter.lengthTo = val[1];
				}),
				ItemFilter.numericConfig(ItemAttribute.width, this.filter.widthFrom, this.filter.widthTo, this.data.filters.width,this.category.widthFilterVisibility, (val: number[]) => {
					this.filter.widthFrom = val[0];
					this.filter.widthTo = val[1];
				}),
				ItemFilter.numericConfig(ItemAttribute.height, this.filter.heightFrom, this.filter.heightTo, this.data.filters.height, this.category.heightFilterVisibility, (val: number[]) => {
					this.filter.heightFrom = val[0];
					this.filter.heightTo = val[1];
				}),
			].filter(cfg => cfg !== null && cfg.visibility !== ItemAttributeFilterVisibility.never) as FilterConfig[];
		}

		private isExpanded(visibility: ItemAttributeFilterVisibility): boolean {
			if (visibility === ItemAttributeFilterVisibility.alwaysCollapsed) return false;

			if (this.sMobile) {
				return visibility === ItemAttributeFilterVisibility.expandedSmall;
			}
			if (this.sSemiMobile) {
				return visibility === ItemAttributeFilterVisibility.expandedMedium
					|| visibility === ItemAttributeFilterVisibility.expandedSmall;
			}

			return true;
		}

		private static selectionConfig(attr: string, filter: string[], data: FilterEntrySelection, visibility: ItemAttributeFilterVisibility, input: (val: string[]) => void): FilterConfig | null {
			return {
				component: ItemAttributeFilterSelection.name,
				attrs: {
					value: filter,
					attribute: attr,
					data: data,
					disabled: data.options.length === 0,
				},
				on: {input: input},
				visibility: visibility,
			};
		}

		private static numericConfig(attr: string, filterFrom: number | null, filterTo: number | null, data: FilterEntryNumeric, visibility: ItemAttributeFilterVisibility, input: (val: number[]) => void): FilterConfig | null {
			return {
				component: ItemAttributeFilterNumeric.name,
				attrs: {
					value: [filterFrom, filterTo],
					attribute: attr,
					data: data,
					disabled: data.min === null || data.max === null,
				},
				on: {input: input},
				visibility: visibility,
			};
		}

		private static numericSelectionConfig(attr: string, filterFrom: number | null, filterTo: number | null, data: FilterEntryNumericSelection, visibility: ItemAttributeFilterVisibility, input: (val: number[]) => void): FilterConfig | null {
			return {
				component: ItemAttributeFilterNumericSelection.name,
				attrs: {
					value: [filterFrom, filterTo],
					attribute: attr,
					data: data,
					disabled: data.min === null || data.max === null,
				},
				on: {input: input},
				visibility: visibility,
			};
		}

		private static booleanConfig(attrs: string, filter: boolean | null, data: FilterEntryBoolean, visibility: ItemAttributeFilterVisibility, input: (val: boolean | null) => void): FilterConfig | null {
			return {
				component: ItemAttributeFilterBoolean.name,
				attrs: {
					value: filter,
					attribute: attrs,
					data: data,
					disabled: data.trueCount === 0 && data.falseCount === 0,
				},
				on: {input: input},
				visibility: visibility,
			};
		}

	}
</script>

<style lang="scss" scoped>
	.text-filter {
		transition: background-color .25s ease-out;
	}

	.filters {
		display: grid;
		grid-auto-rows: auto;
		grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
		grid-gap: 0.75rem;
	}
</style>
