<template>
	<v-hover v-slot:default="{hover}">
		<AsiBtn :small="small" :disabled="disabled" v-ripple="false" class="btn" color="primary"
		        @click.native.stop="click">
			<v-icon v-if="price === null">
				{{ icons.cart }}
			</v-icon>
			<span v-else-if="currencyCode !== null">
				{{ $n(priceTotal, {key: 'currencyDisplay', currency: currencyCode}) }}
			</span>
			<span v-else>
				{{ $n(priceTotal) }}
			</span>

			<v-fade-transition>
				<v-overlay class="" v-if="!disabled && hover" absolute color="primary" opacity="1" @mouseleave="hover = false">
					<v-icon>{{ icons.cart }}</v-icon>
				</v-overlay>
			</v-fade-transition>
		</AsiBtn>
	</v-hover>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import {IItemShopListEntry} from "@/models/item/ItemShopModels";
	import {IItemPriceShopSimple} from "@/models/item-price/ItemPriceModelsShop";

	@Component({
		components: {AsiBtn}
	})
	export default class ItemAddToCartButton extends Vue {

		@Prop({type: Number, required: true})
		public quantity!: number;

		@Prop({type: Object, required: true})
		public item!: IItemShopListEntry;

		@Prop({type: Boolean, default: false})
		public small!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		private icons = Icon;

		private get sortedPrices(): IItemPriceShopSimple[] {
			return this.item.prices.map(p => p).sort((a: IItemPriceShopSimple, b: IItemPriceShopSimple) => b.fromQuantity - a.fromQuantity);
		}

		private get currencyCode(): string | null {
			return this.item.price?.price.currency.currencyCode ?? null;
		}

		private get price(): number | null {
			return this.quantity === null
				? null
				: this.sortedPrices.map(p => p).find(p => p.fromQuantity <= (this.quantity as number))?.price.amount ?? null;
		}

		private get priceTotal(): number | null {
			return this.price !== null ? this.price * this.quantity : null;
		}

		@Emit('click')
		public click(event: Event): Event {
			return event;
		}

	}
</script>

<style lang="scss" scoped>
	.v-btn {
		min-width: 7.5rem !important;

		&::before {
			display: none;
		}
	}
</style>
