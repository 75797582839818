import { render, staticRenderFns } from "./ShoppingListUpdateDialogName.vue?vue&type=template&id=6fabf266&scoped=true"
import script from "./ShoppingListUpdateDialogName.vue?vue&type=script&lang=ts"
export * from "./ShoppingListUpdateDialogName.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fabf266",
  null
  
)

export default component.exports