<template>
	<AsiDialogFormLayout :icon="icons.merge" :title="$t('customer.terms.merge')"
	                     :open="open" :loading="loading" :valid="valid" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped scrollable>
		<v-card-text class="pa-0">
			<v-form v-model="valid">
				<div class="pa-6">
					<CustomerAutocompleteInput v-model="targetCustomerId" :rules="rules" :customers="customerOptionsFinal"
					                           class="input-required"/>
				</div>
			</v-form>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop} from 'vue-property-decorator';
	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
	import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
	import Snackbar from "@/helpers/Snackbar";
	import {ICustomerAdminListEntry, ICustomerAdminSimple} from "@/models/customer/CustomerAdminModels";
	import {ValidationRule} from "@/declarations/ValidationRule";
	import RequiredValidator from "@/validators/RequiredValidator";
	import CustomerAutocompleteInput from "@/components/customer/admin/CustomerAutocompleteInput.vue";
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";
	import CustomerListFilter from "@/models/customer/CustomerListFilter";
	import {CustomerType} from "@/helpers/constants";

	@Component({
		components: {CustomerAutocompleteInput, AsiDialogFormLayout}
	})
	export default class CustomerMergeDialog extends AsiDialogForm {

		@Prop({type: Object, required: true})
		public customer!: ICustomerAdminSimple;

		private targetCustomerId: string | null = null;
		private customerOptions: ICustomerAdminListEntry[] = [];

		public created(): void {
		    this.loadCustomerOptions();
		}

		private get customerOptionsFinal(): ICustomerAdminListEntry[] {
			return this.customerOptions.filter(c => c.id !== this.customer.id);
		}

		// noinspection JSMethodCanBeStatic
		private get rules(): ValidationRule[] {
			return [
				new RequiredValidator('customer.singular').validationRule(),
			];
		}

		@Emit('save')
		public save(targetId: string): string {
			return targetId;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			const form = this.$refs.form as HTMLFormElement;
			this.reset([], form);
			return event;
		}

		private loadCustomerOptions(): void {
			const filter = new CustomerListFilter([]);
			filter.types = [CustomerType.business];
			const options = new AsiListTableOptions();
			options.itemsPerPage = 0;

			this.loading = true;
			this.$customerServiceAdmin.customers(filter, options)
				.then(data => this.customerOptions = data.data)
				.catch(() => Snackbar.loadingError())
				.finally(() => this.loading = false);
		}

		private requestSave(): void {
			if (this.targetCustomerId === null) return;

			this.loading = true;
			this.$customerServiceAdmin.mergeInto(this.customer.id, this.targetCustomerId)
				.then(() => this.save(this.targetCustomerId as string))
				.catch(() => Snackbar.updateError())
				.finally(() => this.loading = false);
		}

	}
</script>

<style lang="scss" scoped>

</style>
