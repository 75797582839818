<template>
	<AsiCardDetail :icon="icons.bulkyGoodsSurcharge" :title="$t('globalConfig.terms.bulkyGoodsSurcharge')"
	               :show-action-button="!readonly" @actionButtonClicked="updateDialog.open()">
		<AsiCardDetailEntry :label="$t('globalConfig.terms.bulkyGoodsSurcharge')"
		                    :icon="icons.bulkyGoodsSurcharge" :value="globalConfig.bulkyGoodsSurchargeAmount">
			<template v-slot:default v-if="globalConfig.bulkyGoodsSurchargeAmount !== null">
				{{
					$n(globalConfig.bulkyGoodsSurchargeAmount.amount, {
						key: 'currencyDisplay',
						currency: globalConfig.bulkyGoodsSurchargeAmount.currency.currencyCode,
					})
				}}
			</template>
		</AsiCardDetailEntry>

		<GlobalConfigUpdateDialogBulkyGoodsSurcharge v-if="!readonly && updateDialog.isLoaded" :open="updateDialog.isOpen"
		                                             :global-config="globalConfig"
		                                             @cancel="updateDialog.close()" @save="updateDialog.close(); change();"/>
	</AsiCardDetail>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import {IGlobalConfigAdmin} from "@/models/global-config/GlobalConfigAdminModels";
	import Icon from "@/plugins/icons";
	import DialogHandler from "@/components/common/DialogHandler";
	import GlobalConfigUpdateDialogBulkyGoodsSurcharge from "@/components/global-config/admin/GlobalConfigUpdateDialogBulkyGoodsSurcharge.vue";

	@Component({
		components: {
			GlobalConfigUpdateDialogBulkyGoodsSurcharge,
			AsiCardDetailEntry, AsiCardDetail
		}
	})
	export default class GlobalConfigCardBulkyGoodsSurcharge extends Vue {
		@Prop({type: Object, required: true})
		public globalConfig!: IGlobalConfigAdmin;

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		private icons = Icon;
		private updateDialog: DialogHandler = new DialogHandler();

		@Emit('change')
		public change(): void {
			return;
		}
	}
</script>

<style scoped lang="scss">

</style>