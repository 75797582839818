<template>
	<AsiDialog :title="$t('order.terms.assertingPayment')" :icon="icons.paymentType"
	           :open="open" unwrapped scrollable @cancel="cancel" persistent>
		<div class="text-center pa-6 grey lighten-4">
			{{ $t('order.terms.assertingPaymentHint') }}
		</div>
		<v-divider/>
		<div class="pa-6 text-center">
			<v-progress-circular indeterminate :size="64" :width="5" color="secondary"/>
		</div>
	</AsiDialog>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from 'vue-property-decorator';
	import AsiDialog from "@/components/common/AsiDialog.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";

	@Component({
		components: {AsiBtn, AsiDialog}
	})
	export default class OrderPaymentAssertDialog extends Vue {

		@Prop({type: String, required: true})
		public id!: string;

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		private icons = Icon;

		@Emit('cancel')
		public cancel(): void {
			return;
		}

	}
</script>

<style lang="scss" scoped>

</style>
