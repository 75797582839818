<template>
	<AsiCard unwrapped :no-bottom-margin="noBottomMargin" @click="clicked">
		<div class="card-content-wrapper d-flex flex-column">
			<div class="img-wrapper flex-grow-0">
				<v-img :src="avatarUrl" :alt="nameTranslated" :aspect-ratio="1" class="rounded-t" :style="imageStyles" :contain="$isMkShop"/>
				<div>
					<div class="img-overlay pa-3">
						<ItemAlertStockStatus :item="item" small/>
					</div>
				</div>
			</div>

			<div class="flex-grow-1 pa-3 grey lighten-5 d-flex flex-column align-start">
				<span class="caption">{{ item.itemNumber }}</span>
				<span class="font-weight-medium">
					<router-link v-if="detailRoute !== null" :to="detailRoute" :title="nameTranslated"
					             @click.native.stop
					             style="text-decoration: inherit; color: inherit;">
						{{ nameTranslated }}
					</router-link>
					<router-link v-else-if="remoteDetailUrl !== null" :to="remoteDetailUrl" :title="nameTranslated"
					             @click.native.stop
					             style="text-decoration: inherit; color: inherit;">
						{{ nameTranslated }}
					</router-link>
					<span v-else>
						{{ nameTranslated }}
					</span>
				</span>
			</div>
			<div class="flex-grow-0 d-flex flex-row align-center justify-center py-3">
				<ItemAddToCart :item="item"/>
			</div>
		</div>
	</AsiCard>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import AsiCard from "@/components/common/AsiCard.vue";
	import {IItemShopListEntry} from "@/models/item/ItemShopModels";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import AsiChip from "@/components/common/AsiChip.vue";
	import {AttachmentMediaSize, ItemType} from "@/helpers/constants";
	import ItemHelper from "@/models/item/ItemHelper";
	import ItemAddToCartButton from "@/components/item/shop/ItemAddToCartButton.vue";
	import ItemAddToCart from "@/components/item/shop/ItemAddToCart.vue";
	import ItemAlertStockStatus from "@/components/item/ItemAlertStockStatus.vue";
	import {Tenant} from "@/models/ui/Tenant";
	import HyperlinkHelper from "@/helpers/HyperlinkHelper";
	import {RawLocation} from "vue-router";

	@Component({
		components: {ItemAlertStockStatus, ItemAddToCart, ItemAddToCartButton, AsiChip, AsiBtn, AsiCard}
	})
	export default class ItemCard extends Vue {

		@Prop({type: Object, required: true})
		public item!: IItemShopListEntry;

		@Prop({type: Boolean, default: false})
		public noBottomMargin!: boolean;

		private icons = Icon;

		private get nameTranslated(): string | null {
			return TranslatedValueHelper.get(this.item.name, this.$i18n.locale);
		}

		private get avatarUrl(): string | null {
			return ItemHelper.avatarUrl(this.item, AttachmentMediaSize.m);
		}

		private get hasClickListener(): boolean {
			return !!this.$listeners.click;
		}

		// noinspection JSUnusedLocalSymbols
		private get imageStyles(): object {
			switch (this.$isTenant) {
				case Tenant.herzog:
					return {
						'background-image': 'linear-gradient(45deg, #00000044, transparent)',
						'background-color': ItemHelper.colorHierarchical(this.item) ?? undefined,
					};
				case Tenant.mk:
					return {
						'background-color': '#FFFFFF',
					};
				default:
					return {};
			}
		}

		private clicked(): void {
			if (!this.hasClickListener) {
				this.openItemDetail();
			} else {
				this.click();
			}
		}

		private get detailRoute(): RawLocation | null {
			if (this.item.itemType !== ItemType.shopItem) return null;
			return ItemHelper.detailRoute(this.item.itemNumber);
		}

		private get remoteDetailUrl(): string | null {
			if (this.item.itemType !== ItemType.linkItem) return null;
			return this.item.remoteUrl;
		}

		public openItemDetail(): void {
			const route = this.detailRoute;
			const remoteUrl = this.remoteDetailUrl;

			switch (this.item.itemType) {
				case ItemType.linkItem:
					if (remoteUrl !== null) {
						HyperlinkHelper.openUrl(remoteUrl);
					}
					break;
				case ItemType.shopItem:
					if (this.$router.currentRoute.params.id === this.item.id) return;
					if (route !== null) {
						this.$router.push(route);
					}
					break;
			}
		}

		@Emit('click')
		public click(): IItemShopListEntry {
			return this.item;
		}

		@Emit('showAddToCart')
		public showAddToCart(): void {
			return;
		}

	}
</script>

<style lang="scss" scoped>
	.card-content-wrapper {
		height: 100%;
	}

	.img-wrapper {
		position: relative;
	}

	.img-overlay {
		position: absolute;
		top: 0;
		height: 100%;
		width: 100%;
		z-index: 1;
	}
</style>
