<template>
	<ShopView full-width>
		<div class="text-center">
			<v-icon size="100" class="mt-16">{{ icons.search }}</v-icon>
			<p class="font-weight-bold mt-5 mb-0" style="font-size: 32pt;">Error 404</p>
			<p class="mb-16">{{ $t('pageTitles.notFound') }}</p>
		</div>
	</ShopView>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator';
	import ShopView from "@/components/layout/ShopView.vue";
	import Icon from "@/plugins/icons";
	import AsiCard from "@/components/common/AsiCard.vue";

	@Component({
		components: {AsiCard, ShopView}
	})
	export default class NotFound extends Vue {
		private icons = Icon;
	}
</script>

<style scoped lang="scss">

</style>