<template>
	<ShopView>
		<h1 class="mb-6">{{ $t('order.plural') }}</h1>
		<AsiCard unwrapped>
			<v-card-text class="pa-0">
				<OrderList memory-key="shop-orders"/>
			</v-card-text>
		</AsiCard>
	</ShopView>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component} from "vue-property-decorator";
	import ShopView from "@/components/layout/ShopView.vue";
	import AsiCard from "@/components/common/AsiCard.vue";
	import OrderList from "@/components/order/shop/OrderList.vue";

	@Component({
		components: {OrderList, AsiCard, ShopView}
	})
	export default class Orders extends Vue {

	}
</script>

<style lang="scss" scoped>

</style>
