<template>
	<section>
		<AsiPageTitle :title="$t('shoppingList.plural')" :icon="icons.shoppingList"/>
		<ShoppingListList />
	</section>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component} from "vue-property-decorator";
	import AsiPageTitle from "@/components/common/AsiPageTitle.vue";
	import Icon from "@/plugins/icons";
	import ShoppingListList from "@/components/shopping-list/admin/ShoppingListList.vue";
	@Component({
		components: {ShoppingListList, AsiPageTitle}
	})
	export default class ShoppingLists extends Vue {
		private icons = Icon;
	}
</script>

<style lang="scss" scoped>

</style>
