<template>
	<ul v-if="!collapse || entries.length < 3">
		<li v-for="(entry, i) in entries" :key="i">
			<AsiBreadcrumbsEntry v-if="!$slots.entry" :entry="entry" :hide-icon="hideIcons" :link-entry="linkEntries" :small="small"/>
			<slot v-else name="entry" v-bind:entry="entry"/>

			<template v-if="i < entries.length - 1">
				<v-icon v-if="!$slots.saparator" x-small>
					{{ icons.next }}
				</v-icon>
				<slot v-else name="separator"/>
			</template>
		</li>
	</ul>

	<v-tooltip v-else :open-delay="300" color="secondary" top transition="slide-y-reverse-transition">
		<template v-slot:activator="{on, attrs}">
			<ul v-bind="attrs" v-on="on">
				<li v-for="(entry, i) in collapsedEntries" :key="i">
					<AsiBreadcrumbsEntry v-if="!$slots.entry" :entry="entry" :hide-icon="hideIcons" :small="small"/>
					<slot v-else name="entry" v-bind:entry="entry"/>

					<template v-if="i < collapsedEntries.length - 1">
						<v-icon v-if="!$slots.saparator" x-small>
							{{ icons.next }}
						</v-icon>
						<slot v-else name="separator"/>
					</template>
				</li>
			</ul>
		</template>

		<ul>
			<li v-for="(entry, i) in entries" :key="i">
				<AsiBreadcrumbsEntry v-if="!$slots.entry" :entry="entry" :hide-icon="hideIcons" :small="small"/>
				<slot v-else name="entry" v-bind:entry="entry"/>

				<template v-if="i < entries.length - 1">
					<v-icon v-if="!$slots.saparator" color="white" x-small>
						{{ icons.next }}
					</v-icon>
					<slot v-else name="separator"/>
				</template>
			</li>
		</ul>
	</v-tooltip>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import Icon from "../../plugins/icons";
	import AsiBreadcrumbsEntry from "@/components/common/AsiBreadcrumbsEntry.vue";

	@Component({
		components: {AsiBreadcrumbsEntry, AsiBreadCrumbsEntry: AsiBreadcrumbsEntry}
	})
	export default class AsiBreadcrumbs extends Vue {

		@Prop({type: Array, default: () => []})
		public entries!: any[];

		@Prop({type: Boolean, default: false})
		public collapse!: boolean;

		@Prop({type: Boolean, default: false})
		public hideIcons!: boolean;

		@Prop({type: Boolean, default: false})
		public small!: boolean;

		@Prop({type: Boolean, default: false})
		public linkEntries!: boolean;

		private icons = Icon;

		private get collapsedEntries(): any[] {
			const ret: any[] = [];
			ret.push(this.entries[0]);
			ret.push('...');
			ret.push(this.entries[this.entries.length - 1]);
			return ret;
		}

	}
</script>

<style lang="scss" scoped>
	ul {
		list-style-type: none;
		padding: 0 !important;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;

		> li {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: center;

			.v-icon {
				margin: 0 5px;
			}
		}
	}
</style>
