<template>
	<AsiDialogFormLayout max-width="84rem" :icon="icons.frameAssembly" :title="$t('frameAssembly.singular')"
	                     :open="open" :loading="loading" :valid="true" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped scrollable>
		<v-card-text class="pa-6">
			<FrameAssemblyConfigurator :item="position.item" :frame-assembly="position.frameAssembly" @changed="model = $event" />
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop} from "vue-property-decorator";
	import AsiLocaleAutocompleteInput from "@/components/common/AsiLocaleAutocompleteInput.vue";
	import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
	import AsiTextField from "@/components/common/AsiTextField";
	import FrameAssemblyConfigurator from "@/components/frame-assembly/FrameAssemblyConfigurator.vue";
	import FrameAssemblyUpdate from "@/models/frame-assembly/FrameAssemblyUpdate";
	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
	import {ICartPositionShopSimple} from "@/models/cart-position/CartPositionShopModels";

	@Component({
		components: {FrameAssemblyConfigurator, AsiTextField, AsiDialogFormLayout, AsiLocaleAutocompleteInput}
	})
	export default class FrameAssemblyUpdateDialog extends AsiDialogForm {

		@Prop({type: Object, required: true})
		public position!: ICartPositionShopSimple;

		@Prop({type: String, required: true})
		public cartId!: string;

		private model: FrameAssemblyUpdate | null = null;

		private requestSave(): void {
			if (this.model === null) return;

			this.loading = true;
			this.$cartServiceShop.updateFrameAssembly(this.cartId, this.position.id, this.model)
				.then(this.save)
				.catch(this.handleErrors)
				.finally(() => this.loading = false);
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			const form = this.$refs.form as HTMLFormElement;
			this.reset([], form);
			return event;
		}
	}
</script>

<style lang="scss" scoped>

</style>
