<template>
	<AsiDialogFormLayout :icon="icons.phone" :title="$t('customer.phone')"
	                     :open="open" :loading="loading" :valid="valid" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped scrollable>
		<v-card-text class="pa-6">
			<v-form v-model="valid">
				<v-row>
					<v-col>
						<AsiTextField v-model="phone" :label="$t('customer.phone')" :placeholder="$t('customer.phone')"
						              :rules="rules" class="input-required" @keydown.enter.prevent="requestSave"/>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
	import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
	import {ValidationRule} from "@/declarations/ValidationRule";
	import {ICustomerAdmin} from "@/models/customer/CustomerAdminModels";
	import AsiTextField from "@/components/common/AsiTextField";
	import CustomerRules from "@/models/customer/CustomerRules";

	@Component({
		components: {AsiTextField, AsiDialogFormLayout}
	})
	export default class CustomerUpdateDialogPhone extends AsiDialogForm {

		@Prop({type: Object, required: true})
		public customer!: ICustomerAdmin;

		private phone: string = '';

		// noinspection JSMethodCanBeStatic
		private get rules(): ValidationRule[] {
			return CustomerRules.phoneRules();
		}

		@Watch('customer.phone', {immediate: true})
		private onCustomerNameChanged(value: string): void {
			this.phone = value;
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			this.phone = this.customer.phone ?? '';
			const form = this.$refs.form as HTMLFormElement;
			this.reset([], form);
			return event;
		}

		private requestSave(): void {

			this.loading = true;
			this.$customerServiceAdmin.updatePhone(this.customer.id, this.phone)
				.then(this.save)
				.catch(this.handleErrors)
				.finally(() => this.loading = false);
		}

	}
</script>

<style lang="scss" scoped>

</style>
