<template>
	<AsiCard :title="labelFinal" :icon="iconFinal" :no-bottom-margin="noBottomMargin" unwrapped
	         class="overflow-hidden">

		<template v-if="hasPrepend">
			<slot name="prepend"/>
			<v-divider/>
		</template>

		<v-list v-if="address !== null" dense class="pa-0">
			<v-list-item dense class="px-6">
				<v-list-item-content>
					<v-list-item-content>
						<v-list-item-title>
							<address class="body-2">
								<template v-for="(line, i) in lines">
									<span :key="`${i}-content`" :class="{'font-weight-bold': i === 0}">
										{{ line }}
									</span>
									<br v-if="i < lines.length - 1" :key="`${i}-linebreak`"/>
								</template>
							</address>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item-content>
				<v-list-item-action>
					<v-tooltip top>
						<template v-slot:activator="{on, attrs}">
							<span v-on="on" v-bind="attrs">
								<AsiBtn :icon="icons.map" @click="openMap"/>
							</span>
						</template>
						{{ $t('ui.terms.showOnMap') }}
					</v-tooltip>
				</v-list-item-action>
			</v-list-item>
		</v-list>
		<div v-else class="pa-6 text-center grey--text">
			{{ $t('$vuetify.noDataText') }}
		</div>

		<template v-if="hasAppend">
			<slot name="append"/>
			<v-divider/>
		</template>

	</AsiCard>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {IAddressFields} from "@/models/address/AddressModels";
	import VueI18n from "vue-i18n";
	import Icon from "@/plugins/icons";
	import AsiCard from "@/components/common/AsiCard.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import AddressHelper from "@/helpers/AddressHelper";
	import TranslateResult = VueI18n.TranslateResult;

	@Component({
		components: {AsiBtn, AsiCard}
	})
	export default class AddressCardSimple extends Vue {

		@Prop({type: Object, required: true})
		public address!: IAddressFields | null;

		@Prop({type: String, default: null})
		public label!: string | null;

		@Prop({type: String, default: null})
		public icon!: string | null;

		@Prop({type: Boolean, default: false})
		public noBottomMargin!: boolean;

		private icons = Icon;

		private get labelFinal(): string | TranslateResult {
			return this.label ?? this.address?.label ?? this.$t('address.terms.noLabel');
		}

		private get iconFinal(): string {
			return this.icon ?? Icon.address;
		}

		private get lines(): string[] {
			return this.address === null ? [] : AddressHelper.addressLines(this.address);
		}

		private get hasPrepend(): boolean {
			return !!this.$slots['prepend'];
		}

		private get hasAppend(): boolean {
			return !!this.$slots['append'];
		}

		private openMap(): void {
			if (this.address === null) return;
			const w = window.open(AddressHelper.googleMapsLink(this.address), '_blank');
			if (w !== null) w.focus();
		}

	}
</script>

<style lang="scss" scoped>
	address {
		font-style: normal;
	}
</style>
