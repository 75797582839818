<template>
	<AsiAutocomplete :clearable="clearable" :dense="simple" :disabled="loadingActual || disabled"
	                 :flat="simple" :item-text="itemText" :items="paymentTypesInternal" :label="finalLabel"
	                 :loading="loadingActual" :multiple="multiple" :rules="rules" :search-input.sync="searchInput"
	                 :solo="simple" :value="value" autocomplete="off" item-value="id" @change="change"
	                 @input="input" :filter="itemSearch">

		<template v-slot:selection="data">
			<v-list-item-content>
				<v-list-item-title>
					{{ translatedValue(data.item.name) }}
				</v-list-item-title>
				<v-list-item-subtitle v-if="data.item.description !== null">
					{{ translatedValue(data.item.description) }}
				</v-list-item-subtitle>
			</v-list-item-content>
		</template>
		<template v-slot:item="data">
			<template v-if="typeof data.item !== 'object'">
				<v-list-item-content v-text="data.item"></v-list-item-content>
			</template>
			<template v-else>
				<v-list-item-content>
					<v-list-item-title>
						{{ translatedValue(data.item.name) }}
					</v-list-item-title>
					<v-list-item-subtitle v-if="data.item.description !== null">
						{{ translatedValue(data.item.description) }}
					</v-list-item-subtitle>
				</v-list-item-content>
			</template>
		</template>
	</AsiAutocomplete>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";
	import Icon from "@/plugins/icons";
	import Snackbar from "@/helpers/Snackbar";
	import StringHelper from "@/helpers/StringHelper";
	import CustomerBusinessCard from "@/components/customer/admin/CustomerBusinessCard.vue";
	import AsiAutocomplete from "@/components/common/AsiAutocomplete";
	import {IPaymentTypeAdminListEntry} from "@/models/payment-type/PaymentTypeAdminModels";
	import {IPaymentTypeShopListEntry} from "@/models/payment-type/PaymentTypeShopModels";
	import PaymentTypeListFilter from "@/models/payment-type/PaymentTypeListFilter";
	import VueI18n from "vue-i18n";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import {ITranslatedValue} from "@/models/translated-value/TranslatedValueModels";
	import TranslateResult = VueI18n.TranslateResult;

	@Component({
		components: {AsiAutocomplete, CustomerBusinessCard, AsiAvatar}
	})
	export default class PaymentTypeAutocompleteInput extends Vue {

		@Prop({default: null})
		public value!: string | string[] | null;

		@Prop({default: null})
		public paymentTypes!: (IPaymentTypeAdminListEntry | IPaymentTypeShopListEntry)[] | null;

		@Prop({type: Object, default: null})
		public paymentTypeListFilter!: PaymentTypeListFilter | null;

		@Prop({type: Array, default: () => []})
		public rules!: (string | null | number | object | unknown | boolean)[];

		@Prop({type: Boolean, default: false})
		public clearable!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		@Prop({type: Boolean, default: false})
		public multiple!: boolean;

		@Prop({type: String, default: null})
		public label!: string | null;

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		@Prop({type: Boolean, default: false})
		public simple!: boolean;

		@Prop({type: Boolean, default: false})
		public keepSearch!: boolean;

		private loadingInternal: boolean = false;
		private paymentTypesInternal: (IPaymentTypeAdminListEntry | IPaymentTypeShopListEntry)[] = [];
		private icons = Icon;
		private paymentTypeListFilterInternal: PaymentTypeListFilter | null = null;
		private searchInput: string | null = null;

		private get finalLabel(): string {
			return this.label !== null ? this.label : this.$t(this.multiple ? 'paymentType.plural' : 'paymentType.singular').toString();
		}

		private get loadingActual(): boolean {
			return this.loading || this.loadingInternal;
		}

		public mounted(): void {
			if (this.paymentTypes !== null) {
				this.paymentTypesInternal = this.paymentTypes;
				this.paymentTypeOptionsChanged();
			} else {
				this.setPaymentTypeListFilterInternal();
				this.loadPaymentTypes();
			}
		}

		@Emit('input')
		public input(selection: string | string[] | null): string | string[] | null {
			if (!this.keepSearch) this.clearSearchInput();
			return selection;
		}

		@Emit('change')
		public change(selection: string | string[] | null): string | string[] | null {
			return selection;
		}

		@Emit('paymentTypeOptionsChanged')
		public paymentTypeOptionsChanged(): (IPaymentTypeAdminListEntry | IPaymentTypeShopListEntry)[] {
			return this.paymentTypesInternal;
		}

		private clearSearchInput(): void {
			this.searchInput = null;
		}

		// noinspection JSMethodCanBeStatic
		private removeFromSelection(paymentType: IPaymentTypeAdminListEntry | IPaymentTypeShopListEntry): void {
			if (!this.multiple || !Array.isArray(this.value)) return;

			const newSelection = this.value.filter(id => id !== paymentType.id);
			this.input(newSelection);
		}

		@Watch('paymentTypes', {deep: true})
		private onPaymentTypesChanged(value: (IPaymentTypeAdminListEntry | IPaymentTypeShopListEntry)[]): void {
			this.paymentTypesInternal = value;
		}

		@Watch('paymentTypeListFilter', {deep: true})
		private pnPaymentTypeListFilterChanged(): void {
			this.setPaymentTypeListFilterInternal();
			this.loadPaymentTypes();
		}

		private setPaymentTypeListFilterInternal(): void {
			this.paymentTypeListFilterInternal = this.paymentTypeListFilter ?? new PaymentTypeListFilter();
		}

		private loadPaymentTypes(): void {
			this.loadingInternal = true;
			this.$paymentTypeServiceShop.paymentTypes(this.paymentTypeListFilterInternal, null)
				.then((data) => {
					this.paymentTypesInternal = data.data;
					this.paymentTypeOptionsChanged();
				})
				.catch(() => Snackbar.loadingError())
				.finally(() => this.loadingInternal = false);
		}

		private itemText(paymentType: IPaymentTypeAdminListEntry | IPaymentTypeShopListEntry | []): string {
			if (Array.isArray(paymentType)) return '';
			// noinspection JSIncompatibleTypesComparison
			if (paymentType.id === undefined) return '';

			return `${paymentType.name.de} ${paymentType.name.fr} ${paymentType.name.en}`;
		}

		private itemSearch(item: any, queryText: string, itemText: string): boolean {
			const parts = queryText
				.toLowerCase()
				.split(/\s+/)
				.filter(p => !StringHelper.isEmpty(p));
			return !parts.some(p => itemText.toLowerCase().indexOf(p) < 0);
		}

		private translatedValue(value: ITranslatedValue): string | TranslateResult | null {
			return TranslatedValueHelper.get(value, this.$i18n.locale, true);
		}

	}
</script>

<style lang="scss" scoped>

</style>
