<template>
	<AsiCardDetail :icon="icons.phone" :title="$t('user.terms.contact')">
		<AsiCardDetailEntry :label="$t('cart.oneTimeEmail')" :icon="icons.email">
			{{ order.oneTimeEmail }}
		</AsiCardDetailEntry>
		<AsiCardDetailEntry v-if="order.oneTimePhone" :label="$t('cart.oneTimePhone')" :icon="icons.phone">
			{{ order.oneTimePhone }}
		</AsiCardDetailEntry>
	</AsiCardDetail>
</template>

<script lang="ts">
	import {Vue, Component, Prop} from 'vue-property-decorator';
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import Icon from "@/plugins/icons";
	import {IOrderAdmin} from "@/models/order/OrderAdminModels";
	import OrderShipmentStateChip from "@/components/order/OrderShipmentStateChip.vue";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";

	@Component({
		components: {AsiCardDetailEntry, OrderShipmentStateChip, AsiCardDetail}
	})
	export default class OrderCardContactInformation extends Vue {

		@Prop({required: true})
		public order!: IOrderAdmin;

		private icons = Icon;

	}
</script>

<style scoped lang="scss">

</style>
