<template>
	<AsiDialogFormLayout v-if="model !== null" :icon="icons.shipmentType" :title="$t('shipmentType.singular')"
	                     :open="open" :loading="loading" :valid="valid" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped scrollable>

		<v-card-text class="pa-0">
			<v-form v-model="valid">
				<div class="pa-6">
					<TranslatedValueFormPart :label="$t('shipmentType.name')" :model="model.name" required/>
				</div>
			</v-form>
		</v-card-text>

	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AttachmentUpdate from "../../models/attachment/AttachmentUpdate";
	import {IAttachment} from "@/models/attachment/AttachmentModels";
	import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
	import TranslatedValueFormPart from "@/components/translated-value/admin/TranslatedValueFormPart.vue";
	import IAdditionalAttachmentService from "@/services/IAdditionalAttachmentService";
	@Component({
		components: {TranslatedValueFormPart, AsiDialogFormLayout}
	})
	export default class AttachmentUpdateDialog extends AsiDialogForm {

		@Prop({type: Object, required: true})
		public attachmentModel!: IAttachment;

		@Prop({type: String, required: true})
		public subjectId!: string;

		@Prop({required: true})
		public service!: IAdditionalAttachmentService;

		private model: AttachmentUpdate | null = null;
		/*private detailModel: IAttachment | null = null;*/

		private get detailModel(): IAttachment | null {
			return this.attachmentModel;
		}


		private setAttachmentInternal(): void {
			if (this.detailModel === null) {
				this.model = null;
				return;
			}

			if (this.model === null) {
				this.model = new AttachmentUpdate(this.detailModel);
				this.valid = true;
			} else {
				this.model.setDetailModel(this.detailModel);
			}
		}

		private requestSave(): void {
			if (this.model === null || this.detailModel === null) return;

			this.loading = true;
			this.service.updateAdditionalAttachment(this.subjectId, this.detailModel.id, this.model)
				.then(this.save)
				.catch(this.handleErrors)
				.finally(() => this.loading = false);
		}

		@Watch('attachmentModel', {immediate: true})
		private onAttachmentChanged(): void {
			this.setAttachmentInternal();
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			this.setAttachmentInternal();
			const form = this.$refs.form as HTMLFormElement;
			this.reset([], form);
			return event;
		}
	}
</script>

<style lang="scss" scoped>

</style>
