<template>
	<v-row>
		<v-col :cols="textArea ? 12 : null">
			<AsiTextField v-if="!textArea" v-model="model.de" :rules="deRules"
			              :label="createLabel('de')" :placeholder="createLabel('de')"
			              :clearable="!required" :class="{'input-required': required}"/>
			<AsiTextarea v-else v-model="model.de" :rules="deRules"
			             :label="createLabel('de')" :placeholder="createLabel('de')"
			             :clearable="!required" :class="{'input-required': required}"/>
		</v-col>
		<v-col :cols="textArea ? 12 :  null">
			<AsiTextField v-if="!textArea" v-model="model.fr"
			              :label="createLabel('fr')" :placeholder="createLabel('fr')"
			              clearable/>
			<AsiTextarea v-else v-model="model.fr"
			             :label="createLabel('fr')" :placeholder="createLabel('fr')"
			             clearable/>
		</v-col>
		<v-col :cols="textArea ? 12 :  null">
			<AsiTextField v-if="!textArea" v-model="model.en"
			              :label="createLabel('en')" :placeholder="createLabel('en')"
			              clearable/>
			<AsiTextarea v-else v-model="model.en"
			             :label="createLabel('en')" :placeholder="createLabel('en')"
			             clearable/>
		</v-col>
	</v-row>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import TranslatedValueCreate from "@/models/translated-value/TranslatedValueCreate";
	import TranslatedValueUpdate from "@/models/translated-value/TranslatedValueUpdate";
	import AsiTextField from "@/components/common/AsiTextField";
	import {ValidationRule} from "@/declarations/ValidationRule";
	import TranslatedValueRules from "@/models/translated-value/TranslatedValueRules";
	import VueI18n from "vue-i18n";
	import LocaleHelper from "@/helpers/LocaleHelper";
	import AsiTextarea from "@/components/common/AsiTextarea";
	import TranslateResult = VueI18n.TranslateResult;

	@Component({
		components: {AsiTextarea, AsiTextField}
	})
	export default class TranslatedValueForm extends Vue {

		@Prop({type: Object, required: true})
		public model!: TranslatedValueCreate | TranslatedValueUpdate;

		@Prop({type: Boolean, default: false})
		public required!: boolean;

		@Prop({type: [String, Object], default: null})
		public label!: string | TranslateResult | null;

		@Prop({type: Boolean, default: false})
		public textArea!: boolean;

		private get deRules(): ValidationRule[] {
			return this.required ? TranslatedValueRules.deRules : [];
		}

		private createLabel(lang: string): string | TranslateResult {
			const locale = LocaleHelper.localeFromLanguage(lang);
			const label = this.label?.toString() ?? null;

			if (label !== null) {
				return `${label} (${locale === null ? lang.toUpperCase() : this.$t(`locale.${locale}`)})`;
			} else {
				return locale === null ? lang.toUpperCase() : this.$t(`locale.${locale}`);
			}
		}

	}
</script>

<style lang="scss" scoped>

</style>
