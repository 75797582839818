<template>
	<AsiAutocomplete v-model="valueInternal" :clearable="clearable" :dense="simple"
	                 :disabled="disabled" :flat="simple" :item-text="itemText" :item-value="itemValue"
	                 :items="currenciesInternal" :label="labelFinal" :loading="loadingActual"
	                 :multiple="multiple" :persistant-hint="persistentHint"
	                 :rules="rules" :solo="simple" autocomplete="off"
	                 @change="change" @input="input">

		<template v-slot:selection="data">
			<div class="d-flex flex-row align-center mt-1">
				<AsiChip class="px-2 mr-2" label small monospace color="secondary">
					{{ data.item.currencyCode }}
				</AsiChip>
				{{ currencyLabel(data.item.currencyCode) }}
			</div>
		</template>
		<template v-slot:item="data">
			<template v-if="typeof data.item !== 'object'">
				<v-list-item-content v-text="data.item"></v-list-item-content>
			</template>
			<template v-else>
				<v-list-item-content>
					<v-list-item-title>
						<div class="d-flex flex-row align-center">
							<AsiChip class="px-2 mr-2" label small monospace>
								{{ data.item.currencyCode }}
							</AsiChip>
							{{ currencyLabel(data.item.currencyCode) }}
						</div>
					</v-list-item-title>
				</v-list-item-content>
			</template>
		</template>
	</AsiAutocomplete>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiAutocomplete from "@/components/common/AsiAutocomplete";
	import AsiChip from "@/components/common/AsiChip.vue";
	import VueI18n from "vue-i18n";
	import Snackbar from "@/helpers/Snackbar";
	import {ICurrencyListEntry} from "@/models/currency/CurrencyModels";
	import TranslateResult = VueI18n.TranslateResult;

	@Component({
		components: {AsiChip, AsiAutocomplete}
	})
	export default class AsiCurrencyAutocomplete extends Vue {

		@Prop({default: null})
		public value!: string | string[] | null;

		@Prop({default: null})
		public currencies!: ICurrencyListEntry[] | null;

		@Prop({type: Array, default: () => []})
		public rules!: (string | null | number | object | unknown | boolean)[];

		@Prop({type: Boolean, default: false})
		public clearable!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		@Prop({type: Boolean, default: false})
		public multiple!: boolean;

		@Prop({type: Boolean, default: false})
		public simple!: boolean;

		@Prop({type: Boolean, default: false})
		public persistentHint!: boolean;

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		@Prop({type: String, default: null})
		public label!: string | null;

		private loadingInternal: boolean = false;
		private currenciesInternal: ICurrencyListEntry[] = [];
		private valueInternal: string | string[] | null = null;

		private get labelFinal(): string | TranslateResult {
			return this.label ?? this.$t(`currency.${this.multiple ? 'plural' : 'singular'}`);
		}

		private get loadingActual(): boolean {
			return this.loading || this.loadingInternal;
		}

		public mounted(): void {
			if (this.currencies !== null) {
				this.currenciesInternal = this.currencies;
				this.currencyOptionsChanged();
			} else {
				this.loadCurrencies();
			}
		}

		@Emit('input')
		public input(): string | string[] | null {
			return this.valueInternal;
		}

		@Emit('change')
		public change(): string | string[] | null {
			return this.valueInternal;
		}

		@Emit('currencyOptionsChanged')
		public currencyOptionsChanged(): ICurrencyListEntry[] {
			return this.currenciesInternal;
		}

		private removeCurrency(id: string): void {
			if (!this.multiple) return;
			const val = this.valueInternal as string[];
			this.valueInternal = val.filter((c: string) => c !== id);
			this.input();
			this.change();
		}

		// noinspection JSMethodCanBeStatic
		private itemValue(item: ICurrencyListEntry | string): string | null {
			return typeof item === 'object' ? item.currencyCode : item;
		}

		// noinspection JSMethodCanBeStatic
		private itemText(item: ICurrencyListEntry | string): string | null {
			return typeof item === 'object' ? `${item.symbol} ${item.currencyCode}` : item;
		}

		private currencyLabel(code: string): string | TranslateResult | null {
			return this.$t(`currency.codes.${code.toLowerCase()}`);
		}

		@Watch('value', {immediate: true})
		private onValueChanged(): void {
			this.valueInternal = this.value;
		}

		private loadCurrencies(): void {
			this.loadingInternal = true;
			this.$currencyService.currencies()
				.then((data) => {
					this.currenciesInternal = data;
					this.currencyOptionsChanged();
				})
				.catch(() => Snackbar.loadingError())
				.finally(() => this.loadingInternal = false);
		}

	}
</script>

<style lang="scss" scoped>

</style>
