<template>
	<AsiListTableLayout :filter-text="filterModel.fulltext" :has-filter="hasFilter" :hide-toolbar="hideToolbar"
	                    @clearFilterText="filterTextChanged(null)" @clearSearch="clearSearch" @update:filterText="filterTextChanged">

		<AsiContentContainer no-bottom-margin>
			<v-data-table
				:footer-props="{itemsPerPageOptions: perPageOptions}"
				:headers="createHeaders()"
				:items="items"
				:loading="loadingInternal"
				:options.sync="tableOptions"
				:server-items-length="total"
				item-key="id"
				multi-sort
				@click:row="openModelDetailPage">

				<template v-slot:item.avatar="{item}">
					<AsiAvatar :size="32" :icon="icons.cart" :image-url="avatarUrl(item)"/>
				</template>
				<template v-slot:item.customer="{item}">
					<span v-if="item.customer !== null">
						{{ item.customer.name }}
					</span>
					<span v-else>
						-
					</span>
				</template>
				<template v-slot:item.name="{item}">
					{{ cartLabel(item) }}
				</template>
			</v-data-table>
		</AsiContentContainer>

		<template v-slot:advanced-filters>
			<AsiTextFieldSimple v-model="filterModel.name" :placeholder="$t('cart.name')" clearable/>
		</template>
	</AsiListTableLayout>
</template>

<script lang="ts">
	import {Component, Prop, Watch} from "vue-property-decorator";
	import AsiListTable from "@/components/common/AsiListTable.vue";
	import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
	import AsiListTableHeader from "@/components/common/AsiListTableHeader";
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";
	import IAsiListTableImplementation from "@/components/common/IAsiListTableImplementation";
	import AsiListTableLayout from "@/components/common/AsiListTableLayout.vue";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";
	import IPaginatedResponse from "@/models/IPaginatedResponse";
	import Icon from "@/plugins/icons";
	import AsiSelectSimple from "@/components/common/AsiSelectSimple";
	import {ICartAdminListEntry} from "@/models/cart/CartAdminModels";
	import CartListFilter from "@/models/cart/CartListFilter";
	import CartHelper from "@/helpers/CartHelper";
	import CustomerHelper from "@/models/customer/CustomerHelper";
	import {AttachmentMediaSize} from "@/helpers/constants";
	import VueI18n from "vue-i18n";
	import TranslateResult = VueI18n.TranslateResult;

	@Component({
		components: {
			AsiSelectSimple,
			AsiAvatar,
			AsiContentContainer,
			AsiListTableLayout, AsiTextFieldSimple, AsiListTable
		}
	})
	export default class CartList extends AsiListTable<ICartAdminListEntry, CartListFilter> implements IAsiListTableImplementation<ICartAdminListEntry, CartListFilter> {

		@Prop({type: String, default: null})
		public customerId!: string | null;

		@Prop({type: Boolean, default: false})
		public hideToolbar!: boolean;

		private icons = Icon;
		private initialized: boolean = false;

		public created(): void {
			this.reload(false, false);
			this.$nextTick(() => this.initialized = true);
		}

		public createFilterModel(): CartListFilter {
			return new CartListFilter();
		}

		public createHeaders(): AsiListTableHeader[] {
			const ret = this.customerId === null ? [
				new AsiListTableHeader('', 'avatar', false, false, 'center', '4rem'),
				new AsiListTableHeader(this.$t('customer.name'), 'customer', true, true, 'start', '30%'),
			] : [];
			ret.push(new AsiListTableHeader(this.$t('cart.name'), 'name', true, true, 'start', this.customerId !== null ? '10rem' : null));
			return ret;
		}

		public fetchData(filterModel: CartListFilter | null, tableOptions: AsiListTableOptions | null): Promise<IPaginatedResponse<ICartAdminListEntry>> {
			return this.customerId === null
				? this.$cartServiceAdmin.carts(filterModel, tableOptions)
				: this.$customerServiceAdmin.carts(this.customerId, filterModel, tableOptions);
		}

		public filterTextChanged(filterText: string): void {
			if (this.filterModel === null) return;
			this.filterModel.fulltext = filterText;
		}

		public openModelDetailPage(model: ICartAdminListEntry): void {
			this.$router.push(CartHelper.detailRouteAdmin(model.id));
		}

		public reload(goToFirstPage: boolean = false, debounce: boolean = true): void {
			this.reloadInternal(this, goToFirstPage, debounce);
		}

		protected prepareOptions(options: AsiListTableOptions): AsiListTableOptions {
			if (options.sortBy.length === 0 && this.defaultSortBy.length === 0) {
				options.sortBy = ['name'];
				options.sortDesc = [false];
			}
			return options;
		}

		@Watch('tableOptions', {deep: true})
		private onTableOptionsChanged(): void {
			if (!this.initialized) return;
			this.reload();
		}

		@Watch('filterModel', {deep: true})
		private onFilterModelChanged(): void {
			if (!this.initialized) return;
			this.reload(true);
		}

		// noinspection JSMethodCanBeStatic
		private cartLabel(cart: ICartAdminListEntry): string | TranslateResult {
			return CartHelper.label(cart);
		}

		// noinspection JSMethodCanBeStatic
		private avatarUrl(cart: ICartAdminListEntry): string | null {
			if (cart.customer === null) return null;
			return CustomerHelper.avatarUrl(cart.customer, AttachmentMediaSize.s);
		}

	}
</script>

<style lang="scss" scoped>

</style>
