<template>
	<div class="wrapper">
		<div class="frame-assembly-grid" :class="{'is-mobile': sMobile}">
			<div class="x-description">
				{{ width }} mm
			</div>
			<div class="y-description">
				<div>{{ height }} mm</div>
			</div>
			<div class="content">
				<svg :viewBox="'0 0 ' + widthInternal + ' ' + heightInternal" :width="widthInternal + '%'" :height="heightInternal + '%'" :class="typeClass"
				     preserveAspectRatio="xMidYMid meet" x="0" y="0">
					<line class="top" x1="0" y1="0" :x2="widthInternal" y2="0" stroke="black" :stroke-width="sMobile ? 5 : 3"/>
					<line class="right" :x1="widthInternal" y1="0" :x2="widthInternal" :y2="heightInternal" stroke="black" :stroke-width="sMobile ? 5 : 3"/>
					<line class="bottom" :x1="widthInternal" :y1="heightInternal" x2="0" :y2="heightInternal" stroke="black" :stroke-width="sMobile ? 5 : 3"/>
					<line class="left" x1="0" :y1="heightInternal" x2="0" y2="0" stroke="black" :stroke-width="sMobile ? 5 : 3"/>
				</svg>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop} from "vue-property-decorator";
	import {FrameAssemblyType} from "@/helpers/constants";
	import {mixins} from "vue-class-component";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";

	@Component
	export default class ItemCardFrameAssemblyFrame extends mixins(ResponsiveChecks) {

		@Prop({type: Number, required: true})
		public type!: FrameAssemblyType;

		@Prop({type: Number, required: true})
		public width!: number;

		@Prop({type: Number, required: true})
		public height!: number;


		private get ratio(): number {
			const gcd = (x: number, y: number): number => (y ? gcd(y, x % y) : x);
			const d = gcd(this.width, this.height);
			return this.width / d / (this.height / d);
		}

		private get heightInternal(): number {
			return this.height < this.width ? 100 / this.ratio : 100;
		}

		private get widthInternal(): number {
			return this.width < this.height ? 100 * this.ratio : 100;
		}

		private get typeClass(): string {
			switch (this.type) {
				case FrameAssemblyType.frame:
					return 'frame';
				case FrameAssemblyType.cFrame:
					return 'c-frame';
				case FrameAssemblyType.uFrame:
					return 'u-frame';
				case FrameAssemblyType.corner:
					return 'corner';
			}
			return 'frame';
		}
	}
</script>

<style lang="scss" scoped>

	.wrapper {
		display: flex;
		justify-content: center;
	}

	.frame-assembly-grid {
		display: grid;;
		grid-template-areas: 'empty xdesc' 'ydesc content';
		grid-template-columns: 3rem 17em;
		grid-template-rows: 3rem 17em;

		&:not(.is-mobile) {
			grid-template-columns: 3rem 25em;
			grid-template-rows: 3rem 25em;
		}

		&.is-mobile {
			grid-template-columns: 3rem 17em;
			grid-template-rows: 3rem 17em;
		}
	}

	.x-description {
		grid-area: xdesc;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.y-description {
		grid-area: ydesc;
		display: flex;
		justify-content: center;
		align-items: center;

		> div {
			writing-mode: vertical-rl;
			transform: rotate(180deg);
		}
	}

	.content {
		grid-area: content;
		padding: 0.5rem;
		display: flex;
		justify-content: center;
		align-items: center;

		svg {
			background-color: rgba(0, 0, 0, 0.1);
		}

		.u-frame {
			.top {
				display: none;
			}
		}

		.c-frame {
			.right {
				display: none;
			}
		}

		.corner {
			.right {
				display: none;
			}

			.bottom {
				display: none;
			}
		}
	}
</style>
