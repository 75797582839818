<template>
	<v-list-item v-on="hasClickListener ? {click: click} : {}" bg @mouseenter="hover = true" @mouseleave="hover = false">
		<v-list-item-avatar>
			<AsiAvatar v-if="!hasAvatar" :bg-color="iconBgColor" :icon="icon" :icon-color="iconColor"
			           :image-url="imageUrl" :size="45"/>
			<slot v-else name="avatar"/>
		</v-list-item-avatar>

		<v-list-item-content>
			<v-list-item-title v-if="label !== null" class="d-flex flex-row flex-wrap align-center">
				{{ label }}
				<v-tooltip v-if="labelHint" top>
					<template v-slot:activator="{on, attrs}">
						<v-icon v-bind="attrs" v-on="on" class="ml-1" small>
							{{ icons.info }}
						</v-icon>
					</template>
					{{ labelHint }}
				</v-tooltip>
			</v-list-item-title>

			<component :is="label !== null ? 'vListItemSubtitle' : 'vListItemTitle'" v-if="label !== null">
				<span v-if="value === null">-</span>
				<div v-else class="lang-grid">
					<div class="font-weight-medium">DE</div>
					<div class="text-wrap">{{ value.de !== null ? value.de : '-' }}</div>
					<div class="font-weight-medium">FR</div>
					<div class="text-wrap">{{ value.fr !== null ? value.fr : '-' }}</div>
					<div class="font-weight-medium">EN</div>
					<div class="text-wrap">{{ value.en !== null ? value.en : '-' }}</div>
				</div>
			</component>
		</v-list-item-content>

		<v-list-item-action v-if="hasActionButton">
			<div class="action-button d-flex flex-row flex-wrap justify-end"
			     :class="{shown: !actionButtonOnHover || hover }">
				<slot name="actionButton"/>
			</div>
		</v-list-item-action>
	</v-list-item>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import Icon from "@/plugins/icons";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";
	import AsiFadeTransition from "@/components/common/AsiFadeTransition.vue";
	import {ITranslatedValue} from "@/models/translated-value/TranslatedValueModels";

	@Component({
		components: {AsiFadeTransition, AsiAvatar}
	})
	export default class AsiCardDetailEntryTranslatedValue extends Vue {

		@Prop({type: Object, required: true})
		public value!: ITranslatedValue | null;

		@Prop({type: String, default: Icon.list})
		public icon!: string | null;

		@Prop({type: String, default: 'grey'})
		public iconColor!: string | null;

		@Prop({type: String, default: 'transparent'})
		public iconBgColor!: string | null;

		@Prop({type: String, default: null})
		public imageUrl!: string | null;

		@Prop({type: String, default: null})
		public label!: string | null;

		@Prop({type: String, default: null})
		public labelHint!: string | null;

		@Prop({type: Boolean, default: false})
		public hideIfEmpty!: boolean;

		@Prop({type: Boolean, default: false})
		public actionButtonOnHover!: boolean;

		private icons = Icon;
		private hover: boolean = false;

		private get hasAvatar(): boolean {
			return !!this.$slots.avatar || !!this.$scopedSlots['avatar'];
		}

		private get hasActionButton(): boolean {
			return !!this.$slots.actionButton || !!this.$scopedSlots['actionButton'];
		}

		@Emit('click')
		public click(event: Event): Event {
			return event;
		}

		private get hasClickListener(): boolean {
			return !!this.$listeners.click;
		}

	}
</script>

<style lang="scss" scoped>
	.lang-grid {
		display: grid;
		grid-template-columns: 2rem 1fr;
	}

	.action-button {
		opacity: 0;
		transition: opacity .3s ease-out;

		&.shown {
			opacity: 1;
		}
	}
</style>
