<template>
	<div v-if="item.additionalAttachments.length > 0" :class="{'wrapper': !sMobile}">
		<AsiCard v-for="attachment in item.additionalAttachments" :key="attachment.id" @click="download(attachment)"
		         :icon="icon(attachment)" no-bottom-margin>
			<template v-slot:title>
				<div class="subtitle-2">
					{{ nameFinal(attachment) }}
				</div>
				<div class="caption grey--text mt-n1">
					{{ subtitle(attachment) }}
				</div>
			</template>
			<template v-slot:headerActions>
				<div class="d-flex flex-row">
					<AsiBtn v-if="canPreview(attachment)" :icon="icons.preview" @click.native.stop="preview(attachment)"/>
					<AsiBtn :icon="icons.download" @click.native.stop="download(attachment)"/>
				</div>
			</template>
		</AsiCard>

		<AttachmentPreviewDialog v-if="previewDialog.isLoaded" :open="previewDialog.isOpen"
		                         :attachment="previewAttachment" @cancel="previewDialog.close()"/>
	</div>
	<div v-else class="grey--text">
		{{ $t('item.terms.noDownloads') }}
	</div>
</template>

<script lang="ts">
	import {Component, Prop} from "vue-property-decorator";
	import {IItemShop} from "@/models/item/ItemShopModels";
	import {IAttachment, IAttachmentFile} from "@/models/attachment/AttachmentModels";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import AttachmentHelper from "@/models/attachment/AttachmentHelper";
	import {AttachmentMediaSize} from "@/helpers/constants";
	import AsiCard from "@/components/common/AsiCard.vue";
	import FileHelper from "@/helpers/FileHelper";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import AttachmentPreviewDialog from "@/components/attachment/AttachmentPreviewDialog.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
	import {mixins} from "vue-class-component";

	@Component({
		components: {AttachmentPreviewDialog, AsiBtn, AsiCard}
	})
	export default class ItemAttachments extends mixins(ResponsiveChecks) {

		@Prop({type: Object, required: true})
		public item!: IItemShop;

		private icons = Icon;
		private previewDialog: DialogHandler = new DialogHandler(() => this.previewAttachment = null);
		private previewAttachment: IAttachment | null = null;

		private subtitle(attachment: IAttachment): string | null {
			const origFile: IAttachmentFile | null = attachment.files.find(a => a.mediaSize === AttachmentMediaSize.orig) ?? null;
			if (origFile === null) return null;
			const ret: string[] = [];

			if (this.nameTranslated(attachment) === null) {
				ret.push(attachment.filename);
			}

			const ext: string | null = FileHelper.extensionFromFilename(attachment.filename)?.toUpperCase() ?? null;
			if (ext !== null) ret.push(ext);

			ret.push(AttachmentHelper.fileSizeFormatted(origFile.fileSize));

			return ret.join(', ');
		}

		private nameTranslated(attachment: IAttachment): string | null {
			return attachment.name === null ? null : TranslatedValueHelper.get(attachment.name, this.$i18n.locale, false);
		}

		private nameFinal(attachment: IAttachment): string | null {
			if (attachment.name === null) return attachment.filename;
			return this.nameTranslated(attachment) ?? attachment.filename;
		}

		// noinspection JSMethodCanBeStatic
		private canPreview(attachment: IAttachment): boolean {
			return AttachmentHelper.isImageAttachment(attachment);
		}

		// noinspection JSMethodCanBeStatic
		private icon(attachment: IAttachment): string {
			return AttachmentHelper.icon(attachment);
		}

		private preview(attachment: IAttachment): void {
			this.previewAttachment = attachment;
			this.previewDialog.open();
		}

		private download(attachment: IAttachment): void {
			AttachmentHelper.download(attachment);
		}

	}
</script>

<style lang="scss" scoped>
	.wrapper {
		display: grid;
		grid-auto-rows: auto;
		grid-gap: 1rem;
		grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));

	}
</style>
