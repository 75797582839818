<template>
	<section class="d-flex flex-row justify-center align-center fill-height">
		<AsiCard :icon="icons.login" :loading.sync="loading" :title="$t('ui.login')"
		         highlighted unwrapped no-bottom-margin>
			<AsiLoginForm :loading.sync="loading"/>
		</AsiCard>
	</section>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component} from "vue-property-decorator";
	import AsiLoginForm from "@/components/common/AsiLoginForm.vue";
	import AsiCard from "@/components/common/AsiCard.vue";
	import Icon from "@/plugins/icons";

	@Component({
		components: {AsiCard, AsiLoginForm}
	})
	export default class Login extends Vue {

		private icons = Icon;
		private loading: boolean = false;

	}
</script>

<style lang="scss" scoped>

</style>
