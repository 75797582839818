<template>
	<AsiDialogFormLayout v-if="model !== null" :icon="icons.promotion" :title="$t('promotion.singular')"
	                     :open="open" :loading="loading" :valid="valid" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped scrollable>
		<v-card-text class="pa-0">
			<v-form v-model="valid">
				<div class="pa-6">
					<ItemAutocompleteInput v-model="model.itemId" :rules="model.rules('itemId')"
					                       :label="$t('item.singular')" :placholder="$t('item.singular')"
					                       class="input-required"/>
				</div>
				<v-divider/>
				<div class="pa-6">
					<v-row>
						<v-col cols="6">
							<AsiDatePickerCombined v-model="model.validFrom" :label="$t('promotion.validFrom')"
							                       :rules="model.rules('validFrom')" class="input-required"
							                       :max-date="model.validTo === null ? undefined : model.validTo"/>
						</v-col>
						<v-col cols="6">
							<AsiDatePickerCombined v-model="model.validTo" :label="$t('promotion.validTo')"
							                       :rules="model.rules('validTo')" class="input-required"
							                       :min-date="model.validFrom === null ? undefined : model.validFrom"/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<AsiTextField v-model="model.minimumOrderPrice" :rules="model.rules('minimumOrderPrice')"
							              :label="$t('promotion.minimumOrderPrice')" :placeholder="$t('promotion.minimumOrderPrice')"
							              class="input-required" :suffix="currencyCode"/>
						</v-col>
					</v-row>
				</div>
			</v-form>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiDialogFormLayout from "../../common/AsiDialogFormLayout.vue";
	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
	import Snackbar from '@/helpers/Snackbar';
	import AsiTextField from "@/components/common/AsiTextField";
	import PromotionUpdate from "@/models/promotion/PromotionUpdate";
	import {IPromotionAdminListEntry} from "@/models/promotion/PromotionAdminModels";
	import AsiDatePickerCombined from "@/components/common/AsiDatePickerCombined.vue";
	import ItemAutocompleteInput from "@/components/item/admin/ItemAutocompleteInput.vue";

	@Component({
		components: {
			ItemAutocompleteInput,
			AsiDatePickerCombined, AsiTextField, AsiDialogFormLayout
		}
	})
	export default class PromotionUpdateDialog extends AsiDialogForm {

		@Prop({type: String, required: true})
		public id!: string;

		private model: PromotionUpdate | null = null;
		private detailModel: IPromotionAdminListEntry | null = null;

		private get currencyCode(): string {
			//TODO: maybe later replace with configurable currency
			return 'CHF';
		}

		private setPromotionInternal(): void {
			if (this.detailModel === null) {
				this.model = null;
				return;
			}

			if (this.model === null) {
				this.model = new PromotionUpdate(this.detailModel);
				this.valid = true;
			} else {
				this.model.setDetailModel(this.detailModel);
			}
		}

		private requestSave(): void {
			if (this.model === null) return;

			this.loading = true;
			this.$promotionServiceAdmin.update(this.id, this.model)
				.then(this.save)
				.catch(this.handleErrors)
				.finally(() => this.loading = false);
		}

		private loadPromotion(): void {
			this.loading = true;
			this.$promotionServiceAdmin.promotion(this.id)
				.then(model => {
					this.detailModel = model;
					this.setPromotionInternal();
				})
				.catch(() => Snackbar.loadingError())
				.finally(() => this.loading = false);
		}


		@Watch('id', {immediate: true})
		private onIdChanged(): void {
			this.loadPromotion();
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			this.setPromotionInternal();
			const form = this.$refs.form as HTMLFormElement;
			this.reset([], form);
			return event;
		}

	}
</script>

<style lang="scss" scoped>

</style>
