import { render, staticRenderFns } from "./ItemAttributesEntry.vue?vue&type=template&id=dc9f4d40&scoped=true"
import script from "./ItemAttributesEntry.vue?vue&type=script&lang=ts"
export * from "./ItemAttributesEntry.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc9f4d40",
  null
  
)

export default component.exports