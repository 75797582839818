<template>
	<v-banner :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm">
		<v-card flat tile>
			<v-list-item class="pa-0">
				<v-list-item-avatar color="transparent" height="64" width="64">
					<AsiAvatar :size="64" :icon="icon" :image-url="avatarUrl" :bg-color="avatarBgColor" :icon-color="avatarIconColor" />
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title v-if="!$slots.title" class="headline text-wrap">
						{{ title }}
					</v-list-item-title>
					<v-list-item-title v-else>
						<slot name="title"/>
					</v-list-item-title>

					<v-list-item-subtitle v-if="subtitle && !$slots.subtitle">{{ subtitle }}</v-list-item-subtitle>
					<v-list-item-subtitle v-else>
						<slot name="subtitle"/>
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</v-card>

		<AsiFadeTransition v-if="loading || progressPersistent">
			<v-progress-linear :indeterminate="loading || progressIndeterminate" :value="progressValue" :color="progressColor" />
		</AsiFadeTransition>

		<template v-if="(hasCreateListener && showCreate) || (hasMenuActions && showMenuActions) || (hasFixedActions && showFixedActions)" v-slot:actions>
			<div v-if="hasFixedActions && showFixedActions">
				<slot name="fixed-actions"/>
			</div>

			<AsiBtn v-if="hasCreateListener && showCreate" :icon="icons.create" @click="create"/>

			<v-menu v-if="hasMenuActions && showMenuActions" bottom right transition="slide-y-transition">
				<template v-slot:activator="{ on }">
					<v-btn v-on="on" icon>
						<v-icon>{{ icons.menu }}</v-icon>
					</v-btn>
				</template>

				<v-list>
					<slot name="menu-actions"/>
				</v-list>
			</v-menu>
		</template>
	</v-banner>
</template>

<script lang="ts">
	import Vue from 'vue';
	import Icon from "@/plugins/icons";
	import {Component, Emit, Prop} from "vue-property-decorator";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import AsiFadeTransition from "@/components/common/AsiFadeTransition.vue";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";

	@Component({
		components: {AsiAvatar, AsiFadeTransition, AsiBtn},
	})
	export default class AsiPageTitle extends Vue {

		@Prop({type: String, default: null})
		public title!: string | null;

		@Prop({type: String, default: null})
		public subtitle!: string | null;

		@Prop({type: String, default: null})
		public icon!: string | null;

		@Prop({type: String, default: null})
		public avatarUrl!: string | null;

		@Prop({type: String, default: 'white'})
		public avatarIconColor!: string | null;

		@Prop({type: String, default: 'primary'})
		public avatarBgColor!: string | null;

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		@Prop({type: Boolean, default: true})
		public showCreate!: boolean;

		@Prop({type: Boolean, default: true})
		public showMenuActions!: boolean;

		@Prop({type: Boolean, default: true})
		public showFixedActions!: boolean;

		@Prop({type: String, default: 'primary'})
		public progressColor!: string;

		@Prop({type: Boolean, default: true})
		public progressIndeterminate!: boolean;

		@Prop({type: Number, default: 0})
		public progressValue!: number;

		@Prop({type: Boolean, default: false})
		public progressPersistent!: boolean;

		private icons = Icon;

		private get hasCreateListener(): boolean {
			return !!this.$listeners.create;
		}

		private get hasMenuActions(): boolean {
			return !!this.$slots['menu-actions'];
		}

		private get hasFixedActions(): boolean {
			return !!this.$slots['fixed-actions'];
		}

		@Emit('create')
		public create(event: Event): Event {
			return event;
		}

	}
</script>

<style lang="scss" scoped>
	.v-progress-linear {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	}
</style>
