<template>
	<AsiCardDetail :icon="icons.shoppingList" :title="$t('shoppingListPosition.plural')">
		<div v-if="positions.length > 0">
			<AsiCardDetailEntry v-for="position in positions" :key="position.id" :icon="icons.shoppingList" :label="$t('shoppingListPosition.singular')" :value="itemName(position)">
				<template v-slot:actionButton>
					{{ position.quantity }}
				</template>
			</AsiCardDetailEntry>
		</div>
		<p v-else class="d-block text-center">
			{{ $t('shoppingListPosition.terms.noPositions') }}
		</p>
	</AsiCardDetail>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import Icon from "@/plugins/icons";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import {IShoppingListPositionAdminSimple} from "@/models/shopping-list-position/ShoppingListPositionAdminModels";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import i18n from "@/plugins/i18n";

	@Component({
		components: {AsiCardDetailEntry, AsiCardDetail}
	})
	export default class ShoppingListCardPositions extends Vue {

		@Prop({type: Array, required: true})
		public positions!: IShoppingListPositionAdminSimple[];

		private icons = Icon;

		private itemName(position: IShoppingListPositionAdminSimple): string {
			return TranslatedValueHelper.get(position.item.name, i18n.locale, true) ?? '?';
		}
	}
</script>

<style lang="scss" scoped>

</style>
