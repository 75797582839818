<template>
	<div class="list-entry py-2">
		<div class="list-entry-content">
			<div class="img-container" :class="{'no-attributes': itemAttributes.length === 0}">
				<v-img :src="avatarUrl" :alt="nameTranslated" :aspect-ratio="1" :contain="itemAttributes.length > 0" :cover="itemAttributes.length === 0" class="rounded-t" :style="imageStyles"/>
			</div>
			<v-container v-if="itemAttributes.length > 0">
				<v-row v-for="item in itemAttributes" :key="item.key">
					<v-col class="font-weight-bold py-0">{{ item.key }}</v-col>
					<v-col class="py-0 text-right">{{ item.value }}</v-col>
				</v-row>
			</v-container>
		</div>
		<div :class="['list-entry-bottom', 'pa-2', stockType + '-border']">
			<div class="d-flex flex-column">
				<span class="font-weight-bold">{{ nameTranslated }}</span>
				<ItemAlertStockStatus :item="item" small simple @type="stockTypeChanged" />
			</div>
			<ItemAddToCart :item="item" />
		</div>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import {IItemShopListEntry} from "@/models/item/ItemShopModels";
	import ItemAddToCart from "@/components/item/shop/ItemAddToCart.vue";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import EnumHelper from "@/helpers/EnumHelper";
	import {AttachmentMediaSize, ItemAttribute} from "@/helpers/constants";
	import ItemHelper from "@/models/item/ItemHelper";
	import {TranslateResult} from "vue-i18n";
	import ItemAlertStockStatus from "@/components/item/ItemAlertStockStatus.vue";
	import {Tenant} from "@/models/ui/Tenant";
	import {ITranslatedValue} from "@/models/translated-value/TranslatedValueModels";
	import i18n from "@/plugins/i18n";

	@Component({
		components: {ItemAlertStockStatus, ItemAddToCart}
	})
	export default class ItemListViewTableMobileEntry extends Vue {
        @Prop({type: Object, required: false})
        public item!: IItemShopListEntry;

		@Prop({default: null})
		public showAttributeCallback!: ((column: string) => boolean) | null;

		private stockType = '';

		private get nameTranslated(): string | null {
			return this.item === null ? null : TranslatedValueHelper.get(this.item.name, this.$i18n.locale);
		}

		public get itemAttributes(): {key: TranslateResult, value: string | number}[] {
			let ret: {key: TranslateResult, value: string | number}[] = [];

			EnumHelper
				.toArray(ItemAttribute)
				.filter(attr => !ItemHelper.isBaseAttribute(attr.value as string))
				.forEach(attr => {
					if (this.showAttributeCallback !== null ? this.showAttributeCallback(attr.key) : true) {
						let value = this.item[attr.key as keyof IItemShopListEntry];
						if (typeof value === 'object') {
							value = TranslatedValueHelper.get(value as ITranslatedValue, i18n.locale);
						}

						let element = {
							key: ItemHelper.attributeLabel(attr.key),
							//@ts-ignore
							value: value as string | number
						};
						ret.push(element);
					}
				});
			return ret;
		}

		private get avatarUrl(): string | null {
			return ItemHelper.avatarUrl(this.item, AttachmentMediaSize.m);
		}

		// noinspection JSUnusedLocalSymbols
		private get imageStyles(): object {
			switch (this.$isTenant) {
				case Tenant.herzog:
					return {
						'background-image': 'linear-gradient(45deg, #00000044, transparent)',
						'background-color': ItemHelper.colorHierarchical(this.item) ?? undefined,
					};
				case Tenant.mk:
					return {
						'background-color': '#FFFFFF',
					};
				default:
					return {};
			}
		}

		private stockTypeChanged(type: string): void {
			this.stockType = type;
		}
	}
</script>

<style lang="scss" scoped>
	.list-entry {
		display: flex;
		flex-direction: column;

		.list-entry-content {
			display: flex;
			flex-direction: row;
			align-items: center;

			> .img-container {
				width: 45%;
				height: auto;
				aspect-ratio: 1;
			}

			> .img-container.no-attributes {
				width: 100%;
				aspect-ratio: unset;

				.v-image {
					max-height: 111px;
				}
			}
		}

		.list-entry-bottom {
			z-index: 1;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			background-color: var(--v-default-lighten3);
			box-shadow: 0 2px 20px rgba(0, 0, 0, 0.25);

			border-left: 2px solid black;

			&.success-border {
				border-left-color: var(--v-success-base);
			}

			&.warning-border {
				border-left-color: var(--v-warning-base);
			}

			&.error-border {
				border-left-color: var(--v-error-base);
			}

			&.info-border {
				border-left-color: var(--v-info-base);
			}
		}
	}
</style>
