<template>
	<AsiTextField v-if="!simple" :key="key" :clearable="clearable" :label="label" :value="displayValue"
	              @change="displayValue = $event" @focusin="focus" @click:clear="displayValue = null"
	              @keydown.enter.prevent="keydownEnter">
		<template v-slot:append>
			<v-menu ref="menu" v-model="menu" :close-on-content-click="!multiple && !range" min-width="290px"
			        offset-y origin="top right" right transition="fade-transition">
				<template v-slot:activator="{on}">
					<v-icon :class="clearable ? 'ml-1' : null" tabindex="-1" @click="menu = !menu">
						{{ icons.calendar }}
					</v-icon>
				</template>
				<v-date-picker ref="picker" v-model="pickerValue" :disabled="disabled" :locale="locale" :max="maxDate"
				               :min="minDate" :multiple="multiple" :range="range"
				               color="secondary" first-day-of-week="1"/>
			</v-menu>
		</template>
	</AsiTextField>

	<AsiTextFieldSimple v-else :key="key" :clearable="clearable" :label="label" :value="displayValue"
	                    @change="displayValue = $event" @focusin="focus" @click:clear="displayValue = null"
	                    @keydown.enter.prevent="keydownEnter">
		<template v-slot:append>
			<v-menu ref="menu" v-model="menu" :close-on-content-click="!multiple && !range" min-width="290px"
			        offset-y origin="top right" right transition="fade-transition">
				<template v-slot:activator="{on}">
					<v-icon :class="clearable ? 'ml-1' : null" tabindex="-1" @click="menu = !menu">
						{{ icons.calendar }}
					</v-icon>
				</template>
				<v-date-picker ref="picker" v-model="pickerValue" :disabled="disabled" :locale="locale" :max="maxDate"
				               :min="minDate" :multiple="multiple" :range="range"
				               color="secondary" first-day-of-week="1"/>
			</v-menu>
		</template>
	</AsiTextFieldSimple>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
	import AsiTextField from "@/components/common/AsiTextField";
	import {Guid} from "guid-typescript";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import DateTimeHelper from "@/helpers/DateTimeHelper";
	import StringHelper from "@/helpers/StringHelper";

	@Component({
		components: {AsiBtn, AsiTextField, AsiTextFieldSimple}
	})
	export default class AsiDatePickerCombined extends Vue {

		@Prop({type: String, required: true})
		public label!: string;

		@Prop({default: null})
		public value!: string | string[] | null;

		@Prop({type: String, default: null})
		public maxDate!: string | null;

		@Prop({type: String, default: null})
		public minDate!: string | null;

		@Prop({type: Boolean, default: false})
		public clearable!: boolean;

		@Prop({type: Boolean, default: false})
		public simple!: boolean;

		@Prop({type: Array, default: () => []})
		public rules!: (string | null | number | object | unknown | boolean)[];

		@Prop({type: Boolean, default: false})
		public range!: boolean;

		@Prop({type: Boolean, default: false})
		public multiple!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		protected menu: boolean = false;
		private key: string = Guid.raw();
		private icons = Icon;
		private valueInternal: string | string[] | null = null;

		private get displayValue(): string | null {
			//catch empty
			if (this.valueInternal === null || (Array.isArray(this.valueInternal) && this.valueInternal.length === 0)) {
				return null;
			}

			//single date
			if (!Array.isArray(this.valueInternal) && !this.multiple && !this.range) {
				return this.formatDate(this.valueInternal);
			}

			//range and multiple
			if (Array.isArray(this.valueInternal)) {
				const dates = this.valueInternal.map(d => this.formatDate(d));

				//range
				if (this.range) {
					return dates.slice(0, 2).join(' - ');
				}

				//multiple
				if (Array.isArray(this.valueInternal) && this.multiple) {
					return dates.join(', ');
				}
			}

			console.error('invalid internal value:', this.valueInternal);
			return null;
		}

		private set displayValue(value: string | null) {
			const oldValue = this.valueInternal;
			this.applyDisplayValue(value);
			this.changeKey();

			if (Array.isArray(this.valueInternal) && Array.isArray(oldValue)) {
				if (this.valueInternal.length !== oldValue.length || this.valueInternal.some((v, i) => oldValue[i] !== v)) {
					this.input();
				}
			} else if (this.valueInternal !== oldValue) {
				this.input();
			}
		}

		private get pickerValue(): string | string[] | null {
			return this.valueInternal;
		}

		private set pickerValue(value: string | string[] | null) {
			const oldValue = this.valueInternal;
			this.applyPickerValue(value);

			if (Array.isArray(this.valueInternal) && Array.isArray(oldValue)) {
				if (this.valueInternal.length !== oldValue.length || this.valueInternal.some((v, i) => oldValue[i] !== v)) {
					this.input();
				}
			} else if (this.valueInternal !== oldValue) {
				this.input();
			}
		}

		private get locale(): string {
			return this.$i18n.locale;
		}

		private static cleanDisplayDate(date: string | null): string | null {
			if (date === null) return null;

			const parts = date.split(/[\\./]/g);
			if (parts.length !== 3 || parts[0].length > 2 || parts[1].length > 2 || (parts[2].length !== 2 && parts[2].length !== 4)) {
				return null;
			}

			parts[0] = parts[0].padStart(2, '0');
			parts[1] = parts[1].padStart(2, '0');

			if (parts[2].length < 4) {
				const year = Number.parseInt(parts[2]);
				if (isNaN(year)) return null;

				const prefix = year >= 50 ? '19' : '20';
				parts[2] = prefix + parts[2];
			}

			return parts.join('.');
		}

		private static displayDateToIsoDate(date: string): string {
			return date.split('.').reverse().join('-');
		}

		private static isoDateToDisplayDate(date: string): string {
			return date.split('-').reverse().join('.');
		}

		private static convertDateShortcuts(value: string): string {
			const str = value.trim().replace(/\s/g, '');

			//plus or minus days
			if (/^([+-]\d+|0)$/g.test(str)) {
				const d = new Date();
				d.setDate(d.getDate() + parseInt(str));
				return this.isoDateToDisplayDate(DateTimeHelper.toISODateString(d));
			}

			//int variants
			if (/^\d{1,8}$/g.test(str) && str.length !== 3 && str.length !== 5 && str.length !== 7) {
				const now = new Date();

				const day = parseInt(str.substr(0, 2));
				const month = str.length > 2 ? parseInt(str.substr(2, 2)) - 1 : now.getMonth();
				let year = str.length > 4 ? parseInt(str.substr(4)) : now.getFullYear();
				if (year < 100) {
					year = year >= 50 ? 1900 + year : 2000 + year;
				}

				const d = new Date();
				d.setDate(day);
				d.setMonth(month);
				d.setFullYear(year);
				if (!isNaN(d.getTime())) return this.isoDateToDisplayDate(DateTimeHelper.toISODateString(d));
			}

			return value;
		}

		@Emit('input')
		public input(): string | string[] | null {
			return this.valueInternal;
		}

		@Emit('keydownEnter')
		public keydownEnter(event: Event): Event {
			return event;
		}

		private applyDisplayValue(value: string | null): void {
			//empty value
			if (value === null || StringHelper.isEmpty(value)) {
				this.valueInternal = null;
				return;
			}

			//TODO: care about negative numbers when performing range split: /-?\d*.*--?\d*-?\d*.*--?\d*/

			//replace whitespaces
			let dateString = value.replace(/\s/g, '');

			//replace range separator (-) with semicolon because of interference with shortcut notations
			if (this.range && /^[-+]?\d*-[-+]?\d*$/g.test(dateString)) {
				if (dateString.indexOf('--') < 0) {
					const pos = dateString.lastIndexOf('-');
					dateString = dateString.substr(0, pos) + ';' + dateString.substr(pos + 1);
				} else {
					dateString = dateString.replace('--', ';-');
				}
			}

			//extract all dates from string
			const dates = dateString
				.split(/\s*[,;]\s*/g)
				.map(d => AsiDatePickerCombined.convertDateShortcuts(d))
				.reduce((acc: string[], cur: string) => {
					const dateCleaned = AsiDatePickerCombined.cleanDisplayDate(cur);
					if (dateCleaned !== null) {
						const isoDate = AsiDatePickerCombined.displayDateToIsoDate(dateCleaned);
						if (this.isValidIsoDate(isoDate)) acc.push(isoDate);
					}
					return acc;
				}, [])
				.sort((a: string, b: string) => a.localeCompare(b));

			//catch empty array
			if (dates.length === 0) {
				this.valueInternal = null;
				return;
			}

			//single dates
			if (!this.multiple && !this.range) {
				this.valueInternal = dates[0];
				return;
			}

			//range
			if (this.range) {
				this.valueInternal = dates.slice(0, 2);
				return;
			}

			//multiple (no duplicates)
			this.valueInternal = dates.reduce((acc: string[], cur: string) => {
				if (!acc.includes(cur)) acc.push(cur);
				return acc;
			}, []);
			return;
		}

		private applyPickerValue(value: string | string[] | null): void {
			if (value === null) {
				this.valueInternal = null;
				return;
			}

			if (Array.isArray(value)) {
				this.valueInternal = value.length > 0 ? value.sort((a: string, b: string) => a.localeCompare(b)) : null;
			} else {
				this.valueInternal = StringHelper.isEmpty(value) ? null : value;
			}
		}

		private formatDate(date: string | null): string | null {
			return date === null ? null : this.$d(new Date(date), 'dayMonthYearShort');
		}

		@Watch('value', {immediate: true})
		private onValueChanged(value: string | string[] | null): void {
			this.valueInternal = value;
		}

		private changeKey(): void {
			this.key = Guid.raw();
		}

		private isValidIsoDate(isoDate: string | null): boolean {
			if (isoDate === null) return false;
			const d = new Date(isoDate);

			return !isNaN(d.valueOf())
				&& (this.maxDate === null || DateTimeHelper.dayDifference(new Date(this.maxDate), d, false) <= 0)
				&& (this.minDate === null || DateTimeHelper.dayDifference(d, new Date(this.minDate), false) <= 0);
		}

		private focus(event: FocusEvent): void {
			if (this.valueInternal === null || (Array.isArray(this.valueInternal) && this.valueInternal.length === 0)) {
				return;
			}

			const target = event.target as HTMLInputElement | undefined;
			if (target === undefined) return;

			if (!this.multiple && !this.range) {
				target.select();
				return;
			}

			setTimeout(() => {
				const displayValue = this.displayValue;
				if (displayValue === null || StringHelper.isEmpty(displayValue)) return;

				const curPos = target.selectionStart;
				if (curPos === null) return;
				let start = curPos;
				let end = curPos;

				const codes = [46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57]; //"dot", then numbers 0 to 9
				while (start > 0 && codes.includes(displayValue.charCodeAt(start - 1))) {
					start -= 1;
				}
				while (end < displayValue.length && codes.includes(displayValue.charCodeAt(end))) {
					end += 1;
				}
				target.setSelectionRange(start, end);
			}, 50);
		}

	}
</script>

<style lang="scss" scoped>

</style>
