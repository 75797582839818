<template>
	<section>
		<AsiPageTitle :title="$t('cart.plural')" :icon="icons.carts"/>
		<CartList memory-key="admin-carts"/>
	</section>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component} from "vue-property-decorator";
	import AsiPageTitle from "@/components/common/AsiPageTitle.vue";
	import Icon from "@/plugins/icons";
	import CartList from "@/components/cart/admin/CartList.vue";

	@Component({
		components: {CartList, AsiPageTitle}
	})
	export default class Carts extends Vue {

		private icons = Icon;

	}
</script>

<style lang="scss" scoped>

</style>
