<template>
	<AsiCard unwrapped>
		<v-expansion-panels v-model="expandedItems" flat multiple accordion class="rounded">
			<v-expansion-panel>
				<v-expansion-panel-header :expand-icon="icons.dropdown" color="grey lighten-5"
				                          class="rounded-t font-weight-medium">
					{{ $t('ui.terms.description') }}
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<div class="mt-4">
						<template v-if="descriptionFinalParts.length > 0">
							<p v-for="(part, i) in descriptionFinalParts" :key="i" :class="{'mb-0': i === descriptionFinalParts.length - 1}">
								{{ part }}
							</p>
						</template>
						<div v-else class="grey--text">
							{{ $t('ui.terms.noDescription') }}
						</div>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel>
				<v-expansion-panel-header :expand-icon="icons.dropdown" color="grey lighten-5"
				                          class="font-weight-medium">
					{{ $t('item.terms.attributes') }}
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<div class="mt-4">
						<ItemAttributes :item="item" :columns="sDesktop ? 2 : 1"/>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel>
				<v-expansion-panel-header :expand-icon="icons.dropdown" color="grey lighten-5"
				                          class="font-weight-medium" :class="{'rounded-b': !expandedItems.includes(2)}">
					{{ $t('item.terms.downloads') }}
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<div class="mt-4">
						<ItemAttachments :item="item"/>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>

			<v-expansion-panel v-show="!hideRelatedItems">
				<v-expansion-panel-header :expand-icon="icons.dropdown" color="grey lighten-5"
				                          class="font-weight-medium" :class="{'rounded-b': !expandedItems.includes(3)}">
					{{ $t('item.terms.relatedItems') }}
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<div class="mt-4">
						<ItemRelatedItemsList :item-id="item.id" @noRelatedItems="hideRelatedItems = $event" />
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</AsiCard>
</template>

<script lang="ts">
	import {Component, Prop} from "vue-property-decorator";
	import {IItemShop} from "@/models/item/ItemShopModels";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import Icon from "@/plugins/icons";
	import AsiCard from "@/components/common/AsiCard.vue";
	import ItemAttributes from "@/components/item/ItemAttributes.vue";
	import ItemAttachments from "@/components/item/shop/ItemAttachments.vue";
	import {mixins} from "vue-class-component";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
	import ItemRelatedItemsList from "@/components/item/shop/ItemRelatedItemsList.vue";

	@Component({
		components: {ItemRelatedItemsList, ItemAttachments, ItemAttributes, AsiCard}
	})
	export default class ItemCardData extends mixins(ResponsiveChecks) {

		@Prop({type: Object, required: true})
		public item!: IItemShop;

		private icons = Icon;
		private expandedItems: number[] = [0, 1, 2, 3];
		private hideRelatedItems: boolean = true;

		private get descriptionFinalParts(): string[] {
			return [
				this.item.description2,
				this.item.description3,
				this.item.description4,
				this.item.description5,
			]
				.map(d => d === null ? null : TranslatedValueHelper.get(d, this.$i18n.locale, false))
				.filter(v => v !== null) as string[];
		}
	}
</script>

<style lang="scss" scoped>

</style>
