<template>
	<AsiSkeletonCards v-if="customer === null" :num="3" :columns="2" loading/>

	<AsiContentContainer v-else :columns="2">
		<div>
			<CustomerCardBasicData :customer="customer" :readonly="!rMasterData"
			                       :name-disabled="isImported" :type-disabled="isImported" @change="change"/>
		</div>
		<div>
			<CustomerCardNumbers :customer="customer" :readonly="!rMasterData" :disabled="isImported" @change="change"/>
		</div>
		<div>
			<CustomerCardNotes :customer="customer" :readonly="!rMasterData" @change="change"/>
		</div>
	</AsiContentContainer>
</template>

<script lang="ts">
	import {Component, Emit, Prop} from "vue-property-decorator";
	import {ICustomerAdmin} from "@/models/customer/CustomerAdminModels";
	import AsiSkeletonCards from "@/components/common/AsiSkeletonCards.vue";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import CustomerCardBasicData from "@/components/customer/admin/CustomerCardBasicData.vue";
	import CustomerCardNotes from "@/components/customer/admin/CustomerCardNotes.vue";
	import RightChecks from "@/mixins/RightChecks.vue";
	import {mixins} from "vue-class-component";
	import CustomerCardNumbers from "@/components/customer/admin/CustomerCardNumbers.vue";
	import StringHelper from "@/helpers/StringHelper";

	@Component({
		components: {
			CustomerCardNumbers,
			CustomerCardNotes, CustomerCardBasicData, AsiContentContainer, AsiSkeletonCards
		}
	})
	export default class CustomerTabDetails extends mixins(RightChecks) {

		@Prop({required: true})
		public customer!: ICustomerAdmin | null;

		private get isImported(): boolean {
			return this.customer === null || !StringHelper.isEmpty(this.customer.externalId);
		}

		@Emit('change')
		public change(): void {
			return;
		}

	}
</script>

<style lang="scss" scoped>

</style>
