import { render, staticRenderFns } from "./GrowthLineChart.vue?vue&type=template&id=47027156&scoped=true"
import script from "./GrowthLineChart.vue?vue&type=script&lang=ts"
export * from "./GrowthLineChart.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47027156",
  null
  
)

export default component.exports