<template>
	<AsiDialog :icon="icons.cart" :title="nameTranslated" :subtitle="$t('cart.terms.addToCart')"
	           :open="open" @cancel="cancel" unwrapped scrollable>
		<template v-slot:dialog-actions>
			<div class="d-flex flex-row flex-wrap justify-space-between align-center" style="width: 100%">
				<div>
					<CartSelectInput v-if="numCarts > 1" v-model="selectedCartId" :class="{'mb-3': sMobile}" simple/>
				</div>
				<div class="d-flex flex-row" :class="{'justify-end': !sMobile, 'flex-grow-1 justify-center': sMobile}">
					<AsiBtn :icon="icons.cart" color="primary" @click="addToCart" large>
						{{ $t('cart.terms.addToCartDense') }}
					</AsiBtn>
				</div>
			</div>
		</template>

		<v-card-text class="pa-0">
			<div class="content" :class="{vertical: sMobile}">
				<div class="d-flex flex-column pa-3">
					<div class="flex-grow-1 flex-shrink-1 d-flex flex-column justify-center align-center">
						<ItemQuantitySelectionWithPrice v-model="quantityInternal" :item="item"/>
					</div>
					<AsiAlert class="mt-3" dense text :type="alertType" :icon="icons.shipping" no-bottom-margin tile>
						{{ stockState }}
					</AsiAlert>
				</div>
				<v-divider :vertical="!sMobile"/>
				<div class="pa-6">
					<ItemPriceTable v-if="item !== null" v-model="quantityInternal" :unit="item.salesUnitOfMeasure" :prices="item.prices"/>
				</div>
			</div>
		</v-card-text>
	</AsiDialog>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiDialog from "../../common/AsiDialog.vue";
	import ItemPriceTable from "@/components/item/ItemPriceTable.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import {IItemShopListEntry} from "@/models/item/ItemShopModels";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import {IItemPriceShopSimple} from "@/models/item-price/ItemPriceModelsShop";
	import CartSelectInput from "@/components/cart/shop/CartSelectInput.vue";
	import Snackbar from "@/helpers/Snackbar";
	import EnumHelper from "@/helpers/EnumHelper";
	import {StockStatus} from "@/helpers/constants";
	import AsiAlert from "@/components/common/AsiAlert.vue";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
	import {mixins} from "vue-class-component";
	import ItemQuantitySelectionWithPrice from "@/components/item/shop/ItemQuantitySelectionWithPrice.vue";

	@Component({
		components: {
			ItemQuantitySelectionWithPrice,
			AsiAlert,
			CartSelectInput,
			AsiBtn, ItemPriceTable, AsiDialog
		}
	})
	export default class ItemToCartDialog extends mixins(ResponsiveChecks) {

		@Prop({type: Boolean, required: true})
		public open!: boolean;

		@Prop({type: Number, required: true})
		public quantity!: number;

		@Prop({type: Object, required: true})
		public item!: IItemShopListEntry;

		private icons = Icon;
		private quantityInternal: number = 1;
		private selectedCartId: string | null = null;

		public created(): void {
			this.selectedCartId = this.$store.state.cart.activeCartId;
		}

		private get nameTranslated(): string | null {
			return TranslatedValueHelper.get(this.item.name, this.$i18n.locale);
		}

		private get stockState(): string | null {
			return EnumHelper.textFromValue(StockStatus, this.item.stockStatus, true);
		}

		private get alertType(): string {
			switch (this.item.stockStatus) {
				case StockStatus.stockHigh:
					return "success";
				case StockStatus.stockMedium:
					return "warning";
				case StockStatus.stockLow:
					return "warning";
				case StockStatus.stockNone:
					return "error";
				default:
					return "info";
			}
		}

		private get sortedPrices(): IItemPriceShopSimple[] {
			return this.item.prices.map(p => p).sort((a: IItemPriceShopSimple, b: IItemPriceShopSimple) => b.fromQuantity - a.fromQuantity);
		}

		private get currencyCode(): string | null {
			return this.item.price?.price.currency.currencyCode ?? null;
		}

		private get price(): number | undefined {
			return this.sortedPrices.map(p => p).find(p => p.fromQuantity <= (this.quantity as number))?.price.amount ?? undefined;
		}

		private get priceTotal(): number | undefined {
			return this.price !== undefined ? this.price * this.quantity : undefined;
		}

		private get numCarts(): number {
			return this.$store.state.cart.carts.length;
		}

		private addToCart(): void {
			this.$store.dispatch('cart/addPosition', {
				item: this.item,
				quantity: this.quantityInternal,
				cartId: this.selectedCartId,
			})
				.then(() => {
					this.added(this.quantityInternal);
					this.$store.commit('cart/setExpanded', true);
					}
				)
				.catch(() => Snackbar.createError());
		}

		@Watch('quantity', {immediate: true})
		private onQuantityChanged(value: number): void {
			this.quantityInternal = value;
		}

		@Watch('quantityInternal')
		private onQuantityInternalChanged(value: number): void {
			if (this.quantity !== value) this.$emit('update:quantity', value);
		}

		@Emit('added')
		public added(quantity: number): number {
			return quantity;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			return event;
		}
	}
</script>

<style lang="scss" scoped>
	.content {
		&:not(.vertical) {
			display: grid;
			grid-template-columns: 1fr auto 1fr;
		}
	}

	.actions {
		> * {
			flex: 0 0 33%;
		}
	}
</style>
