<template>
	<div v-if="relatedItems.length > 0" :class="['item-wrapper', 'pa-1', {'mobile': sMobile}]">
		<ItemCard v-for="relatedItem in relatedItems" :key="relatedItem.id"
		          :item="relatedItem" />
	</div>
	<div v-else class="grey--text">
		{{ $t('item.terms.noRelatedItems') }}
	</div>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import ItemCard from "@/components/item/shop/ItemCard.vue";
	import {IItemShopListEntry} from "@/models/item/ItemShopModels";
	import ItemListFilterShop from "@/models/item/ItemListFilterShop";
	import {mixins} from "vue-class-component";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";

	@Component({
		components: {ItemCard}
	})
	export default class ItemRelatedItemsList extends mixins(ResponsiveChecks) {
        @Prop({type: String, required: true})
        public itemId!: string;

		private relatedItems: IItemShopListEntry[] = [];
		private relatedItemsFilter = new ItemListFilterShop();

		private loadRelatedItems(): void {
			this.$itemServiceShop.items(this.relatedItemsFilter, null)
				.then(res => {
					this.relatedItems = [];
					this.relatedItems = res.data;
					this.noRelatedItems(this.relatedItems.length === 0);
				});
		}

		@Watch('itemId', {immediate: true})
		private onItemIdChanged(value: string): void {
			this.relatedItemsFilter.relatedToItemIds = [];
			this.relatedItemsFilter.relatedToItemIds.push(value);
			this.loadRelatedItems();
		}

		@Emit('noRelatedItems')
		public noRelatedItems(value: boolean): boolean {
			return value;
		}
	}
</script>

<style lang="scss" scoped>
	.item-wrapper {
		width: 100%;
		overflow-x: scroll;

		display: flex;
		flex-direction: row;
		gap: 1rem;

		&.mobile {
			flex-wrap: wrap;
		}

		> * {
			width: 17rem;
		}

		&.mobile > * {
			width: 100%;
		}
	}

</style>
