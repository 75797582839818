<template>
	<AsiDialogFormLayout v-if="model !== null" :icon="icons.paymentType" :title="$t('paymentType.singular')"
	                     :open="open" :loading="loading" :valid="valid" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped scrollable>
		<v-card-text class="pa-0">
			<v-form v-model="valid">
				<div class="pa-6">
					<TranslatedValueFormPart :label="$t('paymentType.name')" :model="model.name" required/>
				</div>
				<v-divider/>
				<div class="pa-6">
					<v-row>
						<v-col>
							<AsiTextField v-model="model.code" :rules="model.rules('code')"
							              :label="$t('paymentType.code')" :placeholder="$t('paymentType.code')"
							              class="input-required" clearable/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<AsiTextField v-model="model.paymentProviderCode" :rules="model.rules('paymentProviderCode')"
							              :label="$t('paymentType.paymentProviderCode')" :placeholder="$t('paymentType.paymentProviderCode')" clearable/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<AsiTextField v-model="model.externalId" :rules="model.rules('externalId')"
							              :label="$t('paymentType.externalId')" :placeholder="$t('paymentType.externalId')" clearable/>
						</v-col>
					</v-row>
				</div>
				<v-divider/>
				<div class="pa-6">
					<p><strong>{{$t('paymentType.allowedCustomerTypes')}}</strong></p>
					<v-data-table
						v-model="selectedCustomerTypes"
						:headers="customerTypesHeaders"
						:items="customerTypes"
						item-key="index"
						show-select
					>
						<template v-slot:item.label="{item}">
							<td>{{customerTypeLabel(item.index)}}</td>
						</template>
					</v-data-table>
				</div>
			</v-form>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
	import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
	import {IPaymentTypeAdmin} from "@/models/payment-type/PaymentTypeAdminModels";
	import PaymentTypeUpdate from "@/models/payment-type/PaymentTypeUpdate";
	import AsiTextField from "@/components/common/AsiTextField";
	import TranslatedValueFormPart from "@/components/translated-value/admin/TranslatedValueFormPart.vue";
	import Snackbar from "@/helpers/Snackbar";
	import {CustomerType} from "@/helpers/constants";
	import AsiListTableHeader from "@/components/common/AsiListTableHeader";
	import EnumHelper from "../../../helpers/EnumHelper";
	import {TranslateResult} from "vue-i18n";

	@Component({
		components: {TranslatedValueFormPart, AsiTextField, AsiDialogFormLayout}
	})
	export default class PaymentTypeUpdateDialog extends AsiDialogForm {

		@Prop({type: String, required: true})
		public id!: string;

		private model: PaymentTypeUpdate | null = null;
		private detailModel: IPaymentTypeAdmin | null = null;
		private customerTypes: { 'index': CustomerType }[] = EnumHelper.toArray(CustomerType).map(ct => ({'index': Number(ct.value)}));
		private selectedCustomerTypes: { 'index': CustomerType }[] = [];

		@Watch('id', {immediate: true})
		private onIdChanged(): void {
			this.loadPaymentType();
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			this.setPaymentTypeInternal();
			const form = this.$refs.form as HTMLFormElement;
			this.reset([], form);
			return event;
		}

		private setPaymentTypeInternal(): void {
			if (this.detailModel === null) {
				this.model = null;
				return;
			}

			if (this.model === null) {
				this.model = new PaymentTypeUpdate(this.detailModel);
				this.valid = true;
			} else {
				this.model.setDetailModel(this.detailModel);
			}
		}

		private requestSave(): void {
			if (this.model === null) return;

			this.loading = true;
			this.updateAllowedCustomerTypes();
			this.$paymentTypeServiceAdmin.update(this.id, this.model)
				.then(this.save)
				.catch(this.handleErrors)
				.finally(() => this.loading = false);
		}

		private loadPaymentType(): void {
			this.loading = true;
			this.$paymentTypeServiceAdmin.paymentType(this.id)
				.then(model => {
					this.detailModel = model;
					this.setPaymentTypeInternal();
				})
				.catch(() => Snackbar.loadingError())
				.finally(() => {
					this.loadCustomerTypes();
					this.loading = false;
				});
		}

		private loadCustomerTypes(): void {
			this.selectedCustomerTypes = this.model?.allowedCustomerTypes?.map(ct => ({'index': ct})) ?? [];
		}

		private updateAllowedCustomerTypes(): void {
			if (this.model) {
				this.model.allowedCustomerTypes = this.selectedCustomerTypes.map(ct => ct.index);
			}
		}

		private customerTypeLabel(customerType: CustomerType): string | TranslateResult | null {
			return EnumHelper.textFromValue(CustomerType, customerType, true);
		}

		private get customerTypesHeaders(): AsiListTableHeader[] {
			return  [
				new AsiListTableHeader(this.$t('customer.type'), 'label', false, false, null),
			];
		}
	}
</script>

<style lang="scss" scoped>

</style>
