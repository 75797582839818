<template>
	<AsiCard :title="labelFinal" :icon="iconFinal" :no-bottom-margin="noBottomMargin" unwrapped :loading="loading"
	         class="overflow-hidden">
		<template v-if="!readonly" v-slot:headerActions>
			<div class="d-flex flex-row flex-wrap">
				<AsiBtn :disabled="disabled || loading" :icon="icons.edit" small @click="updateDialog.open()"/>
				<AsiBtn :disabled="disabled || loading" :icon="icons.delete" small @click="requestDelete"/>
			</div>
		</template>

		<v-list dense class="pa-0">
			<v-list-item dense class="px-6">
				<v-list-item-content>
					<v-list-item-content>
						<v-list-item-title>
							<address class="body-2">
								<template v-for="(line, i) in lines">
									<span :key="`${i}-content`" :class="{'font-weight-bold': i === 0}">
										{{ line }}
									</span>
									<br v-if="i < lines.length - 1" :key="`${i}-linebreak`"/>
								</template>
							</address>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item-content>
				<v-list-item-action>
					<v-tooltip top>
						<template v-slot:activator="{on, attrs}">
							<span v-on="on" v-bind="attrs">
								<AsiBtn :icon="icons.map" @click="openMap"/>
							</span>
						</template>
						{{ $t('ui.terms.showOnMap') }}
					</v-tooltip>
				</v-list-item-action>
			</v-list-item>
		</v-list>

		<template v-if="!readonly">
			<v-divider/>
			<div class="grey lighten-4 text-center d-flex flex-row">
				<v-tooltip top>
					<template v-slot:activator="{on, attrs}">
						<div v-on="on" v-bind="attrs"
						     class="default-btn d-flex flex-row align-center justify-center"
						     :class="{'success': isDefaultShippingAddress}">

							<AsiBtn v-if="!readonly && !isDefaultShippingAddress" :disabled="loading" :icon="icons.shipping"
							        color="transparent" depressed block tile prevent-icon-mode
							        @click="requestDefaultShippingAddress"/>
							<v-icon v-else :color="isDefaultShippingAddress ? 'white' : undefined">
								{{ icons.shipping }}
							</v-icon>
						</div>
					</template>
					{{
						$t(isDefaultShippingAddress
							? 'address.terms.defaultShippingAddress'
							: 'address.terms.setDefaultShippingAddress')
					}}
				</v-tooltip>
				<v-divider vertical style="z-index: 1"/>
				<v-tooltip top>
					<template v-slot:activator="{on, attrs}">
						<div v-on="on" v-bind="attrs"
						     class="default-btn d-flex flex-row align-center justify-center"
						     :class="{'success': isDefaultBillingAddress}">

							<AsiBtn v-if="!readonly && !isDefaultBillingAddress" :disabled="loading" :icon="icons.currency"
							        color="transparent" depressed block tile prevent-icon-mode
							        @click="requestDefaultBillingAddress"/>
							<v-icon v-else :color="isDefaultBillingAddress ? 'white' : undefined">
								{{ icons.currency }}
							</v-icon>
						</div>
					</template>
					{{
						$t(isDefaultBillingAddress
							? 'address.terms.defaultBillingAddress'
							: 'address.terms.setDefaultBillingAddress')
					}}
				</v-tooltip>
			</div>
		</template>

		<AsiConfirmDialog v-if="!readonly" ref="confirm"/>
		<AddressUpdateDialog v-if="!readonly && updateDialog.isLoaded" :open="updateDialog.isOpen"
		                     :service="service"
		                     :customer-id="customerId" :address="address"
		                     :business="business" :allow-type-selection="allowTypeSelection"
		                     @save="changed(); updateDialog.close();" @cancel="updateDialog.close()"/>
	</AsiCard>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import {IAddressSimple} from "@/models/address/AddressModels";
	import AsiCard from "@/components/common/AsiCard.vue";
	import VueI18n from "vue-i18n";
	import Icon from "@/plugins/icons";
	import AddressHelper from "@/helpers/AddressHelper";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";
	import Snackbar from "@/helpers/Snackbar";
	import AsiChip from "@/components/common/AsiChip.vue";
	import AddressUpdateDialog from "@/components/address/AddressUpdateDialog.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import TranslateResult = VueI18n.TranslateResult;
	import ICustomerServiceAdmin from "@/services/definition/ICustomerServiceAdmin";
	import ICustomerServiceShop from "@/services/definition/ICustomerServiceShop";

	@Component({
		components: {AddressUpdateDialog, AsiChip, AsiConfirmDialog, AsiBtn, AsiCard}
	})
	export default class AddressCard extends Vue {

		@Prop({type: Object, required: true})
		public address!: IAddressSimple;

		@Prop({type: String, required: true})
		public customerId!: string;

		@Prop({type: Boolean, required: true})
		public business!: boolean;

		@Prop({type: Boolean, required: true})
		public allowTypeSelection!: boolean;

		@Prop({type: Object, required: true})
		public service!: ICustomerServiceAdmin | ICustomerServiceShop;

		@Prop({type: Boolean, default: false})
		public isDefaultShippingAddress!: boolean;

		@Prop({type: Boolean, default: false})
		public isDefaultBillingAddress!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		@Prop({type: Boolean, default: false})
		public noBottomMargin!: boolean;

		private icons = Icon;
		private loading: boolean = false;
		private updateDialog: DialogHandler = new DialogHandler();

		private get labelFinal(): string | TranslateResult {
			return this.address.label ?? this.$t('address.terms.noLabel');
		}

		private get iconFinal(): string {
			return AddressHelper.hasBusinessFieldData(this.address) ? Icon.customerBusiness : Icon.customerPrivate;
		}

		private get iconsFinal(): string[] {
			const ret: string[] = [];
			if (this.isDefaultShippingAddress) ret.push(Icon.shipping);
			if (this.isDefaultBillingAddress) ret.push(Icon.currency);
			return ret.length === 0 ? [Icon.address] : ret;
		}

		private get lines(): string[] {
			return AddressHelper.addressLines(this.address);
		}

		@Emit('changed')
		public changed(): void {
			return;
		}

		@Emit('changedDefault')
		public changedDefault(): void {
			return;
		}

		@Emit('deleted')
		public deleted(id: string): string {
			return id;
		}

		private requestDefaultShippingAddress(): void {
			if (this.readonly || this.isDefaultShippingAddress) return;

			this.loading = true;
			this.service.setDefaultShippingAddress(this.customerId, this.address.id)
				.then(() => this.changedDefault())
				.catch(() => Snackbar.updateError())
				.finally(() => this.loading = false);
		}

		private requestDefaultBillingAddress(): void {
			if (this.readonly || this.isDefaultBillingAddress) return;

			this.loading = true;
			this.service.setDefaultBillingAddress(this.customerId, this.address.id)
				.then(() => this.changedDefault())
				.catch(() => Snackbar.updateError())
				.finally(() => this.loading = false);
		}

		private requestDelete(): void {
			if (this.readonly) return;
			const addressId = this.address.id;

			const confirm = this.$refs.confirm as unknown as AsiConfirmDialog;
			confirm.openDialog().then((res: boolean) => {
				if (!res) return;
				this.loading = true;
				this.service.deleteAddress(this.customerId, addressId)
					.then(() => this.deleted(addressId))
					.catch(() => Snackbar.deleteError())
					.finally(() => this.loading = false);
			});
		}

		private openMap(): void {
			const w = window.open(AddressHelper.googleMapsLink(this.address), '_blank');
			if (w !== null) w.focus();
		}

	}
</script>

<style lang="scss" scoped>
	.default-btn {
		flex: 1 1 50%;
		min-height: 2.25rem;
	}

	address {
		font-style: normal;
	}
</style>
