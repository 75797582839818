<template>
	<AsiDialog :icon="icon" :max-width="maxWidth" :open="open" :subtitle="subtitle" :title="titleFinal" @cancel="cancel">
		<v-progress-linear :color="loading ? 'primary' : 'transparent'" :indeterminate="progressPercent === 0 || progressPercent === 100" :value="progressPercent" height="3"/>
		<AsiFormErrors :errors="errors" class="mb-6"/>

		<AsiContentContainer :columns="hasExisting ? 2 : 1" no-margin>
			<v-card class="d-flex flex-column justify-center" color="grey lighten-4" flat tile>
				<v-card-text class="text-center pa-6">
					<v-form ref="form" v-model="valid">
						<v-file-input
							ref="fileInput"
							v-model="avatarModel.file"
							:disabled="loading"
							:label="$t('ui.terms.selectImageFile')"
							:prepend-icon="null"
							:rules="imageRules"
							:accept="accept"
							filled
							hide-details/>
					</v-form>

					<AsiBtn :disabled="!valid || loading" class="mt-6" color="success" @click="upload">
						{{ $t(hasExisting ? 'ui.replace' : 'ui.upload') }}
					</AsiBtn>
				</v-card-text>
			</v-card>

			<v-card v-if="hasExisting" class="d-flex flex-column justify-center" color="grey lighten-4" flat tile>
				<v-card-text class="text-center pa-6">
					<div>
						<AsiAvatar :icon="icons.avatar" :image-url="existingAvatarUrl" :size="128"/>
					</div>

					<AsiBtn :disabled="loading" class="mt-6" color="error" @click="deleteExisting">
						{{ $t('ui.delete') }}
					</AsiBtn>
				</v-card-text>
			</v-card>
		</AsiContentContainer>

		<AsiConfirmDialog ref="confirm"/>
	</AsiDialog>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiDialog from "./AsiDialog.vue";
	import Icon from "@/plugins/icons";
	import AsiContentContainer from "./AsiContentContainer.vue";
	import AsiCard from "./AsiCard.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import AsiFormErrors from "@/components/common/AsiFormErrors.vue";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";
	import AvatarCreate from "@/models/attachment/AvatarCreate";
	import IAvatarService from "@/services/IAvatarService";
	import {AxiosError} from "axios";
	import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";
	import RequiredValidator from "@/validators/RequiredValidator";
	import Snackbar from "@/helpers/Snackbar";
	import AsiConfirmDialogDefinition from "@/helpers/AsiConfirmDialogDefinition";
	import StringHelper from "@/helpers/StringHelper";
	import IModel from "@/models/IModel";

	@Component({
		components: {AsiConfirmDialog, AsiAvatar, AsiFormErrors, AsiBtn, AsiCard, AsiContentContainer, AsiDialog}
	})
	export default class AsiDialogAvatarUpload extends Vue {

		@Prop({required: true})
		public service!: IAvatarService;

		@Prop({type: Object, required: true})
		public model!: IModel;

		@Prop({type: Boolean, required: true})
		public open!: boolean;

		@Prop({type: String, default: null})
		public existingAvatarUrl!: string | null;

		@Prop({type: String, default: '50rem'})
		public maxWidth!: string;

		@Prop({type: String, default: null})
		public title!: string | null;

		@Prop({type: String, default: null})
		public subtitle!: string | null;

		@Prop({type: String, default: Icon.avatar})
		public icon!: string;

		@Prop({type: String, default: null})
		public iconAvatar!: string | null;

		@Prop({type: String, default: "image/png, image/jpeg, image/jpg"})
		public accept!: string;

		private icons = Icon;
		private avatarModel: AvatarCreate = new AvatarCreate();
		private valid: boolean = false;
		private loading: boolean = false;
		private errors: string[] = [];
		private progressPercent: number = 0;
		private imageRules = [
			(new RequiredValidator('ui.terms.selectImageFile')).validationRule(),
		];

		private get hasExisting(): boolean {
			return this.existingAvatarUrl !== null;
		}

		private get titleFinal(): string {
			return this.title === null || StringHelper.isEmpty(this.title) ? this.$t('ui.terms.manageImage').toString() : this.title;
		}

		public mounted(): void {
			this.reset();
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			this.reset();
			return event;
		}

		@Emit('change')
		public change(): void {
			return;
		}

		private reset(): void {
			this.valid = false;
			this.errors = [];
			this.progressPercent = 0;
			this.avatarModel.reset();
			this.avatarModel.model = this.model;

			const form = this.$refs.form as HTMLFormElement | undefined;
			if (form !== undefined) {
				form.resetValidation();
			}
		}

		private upload(): void {
			this.loading = true;
			this.service.uploadAvatar(this.avatarModel, (event: ProgressEvent) => {
				this.progressPercent = Math.round((event.loaded * 100) / event.total);
			})
				.then(() => {
					this.reset();
					this.change();
				})
				.catch((err: AxiosError) => {
					this.errors = err.response?.data;
					Snackbar.uploadError(err);
				})
				.finally(() => {
					this.loading = false;
					this.progressPercent = 0;
				});
		}

		private deleteExisting(): void {
			const confirm = this.$refs.confirm as unknown as AsiConfirmDialogDefinition;
			confirm.openDialog().then((res: boolean) => {
				if (!res) return;

				this.loading = true;
				this.service.deleteAvatar(this.model)
					.then(this.change)
					.catch(() => Snackbar.deleteError())
					.finally(() => this.loading = false);
			});
		}

		@Watch('model', {deep: true})
		private onModelChanged(): void {
			this.reset();
		}

	}
</script>

<style lang="scss" scoped>

</style>
