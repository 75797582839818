<template>
	<AsiChip :color="color" :icon="!hideIcon ? icon : undefined" label small>
		{{ label }}
	</AsiChip>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import AsiChip from "@/components/common/AsiChip.vue";
	import {ICustomerShopSimple} from "@/models/customer/CustomerShopModels";
	import {ICustomerAdminSimple} from "@/models/customer/CustomerAdminModels";
	import Icon from "@/plugins/icons";
	import {CustomerType} from "@/helpers/constants";
	import EnumHelper from "@/helpers/EnumHelper";

	@Component({
		components: {AsiChip}
	})
	export default class CustomerTypeChip extends Vue {

		@Prop({type: Object, required: true})
		public customer!: ICustomerShopSimple | ICustomerAdminSimple;

		@Prop({type: Boolean, default: false})
		public hideIcon!: boolean;

		private get icon(): string {
			return this.customer.type === CustomerType.private
				? Icon.customerPrivate
				: Icon.customerBusiness;
		}

		private get color(): string {
			return this.customer.type === CustomerType.private
				? 'secondary'
				: 'info';
		}

		private get label(): string | null {
			return EnumHelper.textFromValue(CustomerType, this.customer.type, true);
		}

	}
</script>

<style lang="scss" scoped>

</style>
