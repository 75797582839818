<template>
	<AsiSkeletonCards v-if="shoppingList === null" :num="1" :columns="2" loading/>

	<AsiContentContainer v-else :columns="2">

		<ShoppingListCardBasicData v-if="shoppingList != null" :shopping-list="shoppingList"/>

		<ShoppingListCardPositions v-if="shoppingList != null" :positions="shoppingList.positions"/>

	</AsiContentContainer>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import AsiSkeletonCards from "@/components/common/AsiSkeletonCards.vue";
	import {IShoppingListAdmin} from "@/models/shopping-list/ShoppingListAdminModels";
	import ShoppingListCardBasicData from "@/components/cart/admin/ShoppingListCardBasicData.vue";
	import ShoppingListCardPositions from "@/components/cart/admin/ShoppingListCardPositions.vue";

	@Component({
		components: {ShoppingListCardPositions, ShoppingListCardBasicData, AsiSkeletonCards, AsiContentContainer}
	})
	export default class ShoppingListTabDetails extends Vue {
		@Prop({required: true})
		public shoppingList!: IShoppingListAdmin | null;
	}
</script>

<style lang="scss" scoped>

</style>
