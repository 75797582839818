import { render, staticRenderFns } from "./ItemAttributes.vue?vue&type=template&id=7a11a74a&scoped=true"
import script from "./ItemAttributes.vue?vue&type=script&lang=ts"
export * from "./ItemAttributes.vue?vue&type=script&lang=ts"
import style0 from "./ItemAttributes.vue?vue&type=style&index=0&id=7a11a74a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a11a74a",
  null
  
)

export default component.exports