<template>
	<div>
		<AsiToolbar v-if="!hideToolbar" :show-expander-toggle-button="hasAdvancedFiltersContent">
			<AsiTextFieldSimple :placeholder="$t('ui.search')" :value="filterText" clearable
			                    style="max-width: 25rem;"
			                    @input="$emit('update:filterText', $event)"
			                    @keyup.esc="clearFilterText"/>

			<v-menu v-if="filterTemplates.length > 0">
				<template v-slot:activator="{on, attrs}">
					<span v-bind="attrs" v-on="on" class="ml-3">
						<AsiBtn :icon="icons.filter"/>
					</span>
				</template>

				<v-list class="py-0">
					<v-list-item dense class="grey lighten-3">
						<v-list-item-title>
							{{ $t('ui.terms.filterTemplates') }}
						</v-list-item-title>
					</v-list-item>
					<template v-for="(tpl, i) in filterTemplates">
						<v-divider :key="i + '-divider'"/>
						<v-list-item :key="i + '-item'" @click="applyFilterTemplate(tpl)">
							<v-list-item-icon>
								<v-icon>{{ tpl.icon }}</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								{{ tpl.label }}
							</v-list-item-content>
						</v-list-item>
					</template>
				</v-list>
			</v-menu>
			<slot name="action-buttons" />

			<template v-slot:content-right>
				<AsiFadeTransition v-if="hasFilter">
					<AsiBtn @click="clearSearch">{{ $t('ui.clearSearch') }}</AsiBtn>
				</AsiFadeTransition>
			</template>
			<template v-if="hasAdvancedFiltersContent" v-slot:content-expander>
				<AsiListTableAdvancedFilters>
					<slot name="advanced-filters"/>
				</AsiListTableAdvancedFilters>
			</template>
		</AsiToolbar>

		<slot/>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import AsiToolbar from "./AsiToolbar.vue";
	import AsiTextFieldSimple from "./AsiTextFieldSimple";
	import AsiBtn from "./AsiBtn.vue";
	import AsiListTableAdvancedFilters from "./AsiListTableAdvancedFilters.vue";
	import AsiFadeTransition from "@/components/common/AsiFadeTransition.vue";
	import AsiListTableFilterTemplate from "@/components/common/AsiListTableFilterTemplate";
	import Icon from "@/plugins/icons";

	@Component({
		components: {AsiFadeTransition, AsiListTableAdvancedFilters, AsiBtn, AsiTextFieldSimple, AsiToolbar}
	})
	export default class AsiListTableLayout extends Vue {

		@Prop({type: Boolean, default: false})
		public hideToolbar!: boolean;

		@Prop({type: String, default: null})
		public filterText!: string | null;

		@Prop({type: Boolean, default: false})
		public hasFilter!: boolean;

		@Prop({type: Array, default: () => []})
		public filterTemplates!: AsiListTableFilterTemplate<object>[];

		private icons = Icon;

		private get hasAdvancedFiltersContent(): boolean {
			return !!this.$slots['advanced-filters'];
		}

		@Emit('clearSearch')
		public clearSearch(event: Event): Event {
			return event;
		}

		@Emit('clearFilterText')
		public clearFilterText(event: Event): Event {
			return event;
		}

		@Emit('applyFilterTemplate')
		public applyFilterTemplate(filterTemplate: AsiListTableFilterTemplate<object>): AsiListTableFilterTemplate<object> {
			return filterTemplate;
		}

	}
</script>

<style lang="scss" scoped>

</style>
