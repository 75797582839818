<template>
	<AsiDialogFormLayout v-if="model !== null" :icon="icons.item" :title="$t('item.terms.create')"
	                     :open="open" :loading="loading" :valid="valid" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped>

		<v-card-text class="pa-0">
			<v-form v-model="valid">
				<div class="pa-6">
					<v-row>
						<v-col>
							<AsiSelect v-model="categoryId" :items="categories" :label="$t('category.singular')" required/>
						</v-col>
					</v-row>
				</div>

				<v-divider />

				<div class="pa-6">
					<v-row>
						<v-col>
							<TranslatedValueFormPart class="pa-0" :label="$t('item.name')" :model="model.name" required/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<AsiRadioGroup v-model="model.itemType" :column="false" class="ma-0">
								<v-radio v-for="option in itemTypes" :key="option.value" class="mr-6"
								         :label="option.text" :value="option.value" :disabled="option.value === itemType.shopItem"/>
							</AsiRadioGroup>
						</v-col>
					</v-row>
					<v-expand-transition>
						<v-row v-if="model.itemType === itemType.linkItem">
							<v-col>
								<AsiTextField v-model="model.remoteUrl" :rules="model.rules('remoteUrl')"
								              :label="$t('item.remoteUrl')" :placeholder="$t('item.remoteUrl')" required/>
							</v-col>
						</v-row>
					</v-expand-transition>
				</div>

				<v-divider />

				<div class="pa-6">
					<v-row>
						<v-col>
							<AsiTextField v-model="model.itemNumber" :rules="model.rules('itemNumber')"
							              :label="$t('item.itemNumber')" :placeholder="$t('item.itemNumber')"/>
						</v-col>
						<v-col>
							<AsiTextField v-model="model.alternativeItemNumber" :rules="model.rules('alternativeItemNumber')"
							              :label="$t('item.alternativeItemNumber')" :placeholder="$t('item.alternativeItemNumber')"/>
						</v-col>
						<v-col>
							<AsiTextField v-model="model.itemNumberShort" :rules="model.rules('itemNumberShort')"
							              :label="$t('item.itemNumberShort')" :placeholder="$t('item.itemNumberShort')"/>
						</v-col>
					</v-row>
				</div>
			</v-form>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit} from "vue-property-decorator";
	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
	import ItemCreate from "@/models/item/ItemCreate";
	import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
	import AsiSelect from "@/components/common/AsiSelect";
	import {ItemType} from "@/helpers/constants";
	import EnumHelper from "@/helpers/EnumHelper";
	import AsiTextField from "@/components/common/AsiTextField";
	import AsiRadioGroup from "@/components/common/AsiRadioGroup";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import i18n from "@/plugins/i18n";
	import Snackbar from "@/helpers/Snackbar";
	import StringHelper from "@/helpers/StringHelper";
	import TranslatedValueFormPart from "@/components/translated-value/admin/TranslatedValueFormPart.vue";

	@Component({
		components: {TranslatedValueFormPart, AsiRadioGroup, AsiTextField, AsiSelect, AsiDialogFormLayout, AsiDialogForm}
	})
	export default class ItemCreateDialog extends AsiDialogForm {

		private model: ItemCreate = new ItemCreate();
		private categoryId = '';

		private itemType = ItemType;
		private categories: { text: string; value: number | string }[] = [];

		private get itemTypes(): { text: string; value: number | string }[] {
			return EnumHelper.toSelectItems(ItemType, true);
		}

		public created(): void {
			this.loadCategories();
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			const form = this.$refs.form as HTMLFormElement;
			this.reset([this.model], form);
			return event;
		}

		@Emit('save')
		public save(id: string): string {
			const form = this.$refs.form as HTMLFormElement;
			this.reset([], form);
			this.model = new ItemCreate();
			return id;
		}

		private loadCategories(): void {
			this.loading = true;
			this.$categoryServiceAdmin.categories(null, null)
				.then(res => {
					this.categories = res.data.map(c => ({ text: TranslatedValueHelper.get(c.name, i18n.locale, true) ?? '?', value: c.id }));
				})
				.finally(() => this.loading = false);
		}

		private requestSave(): void {
			if (!this.valid || StringHelper.isEmpty(this.categoryId)) return;

			this.loading = true;
			this.$categoryServiceAdmin.createItem(this.categoryId, this.model)
				.then(res => {
					this.save(res.id);
				})
				.catch(() => Snackbar.createError())
				.finally(() => this.loading = false);
		}
	}
</script>

<style lang="scss" scoped>

</style>
