<template>
	<AsiDialogFormLayout :icon="icons.promotion" :title="$t('promotion.terms.create')"
	                     :open="open" :loading="loading" :valid="valid" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped scrollable>
		<v-card-text class="pa-0">
			<v-form v-if="model !== null" v-model="valid">
				<div class="pa-6">
					<ItemAutocompleteInput v-model="model.itemId" :rules="model.rules('itemId')"
					                       :label="$t('item.singular')" :placholder="$t('item.singular')"
					                       class="input-required"/>
				</div>
				<v-divider/>
				<div class="pa-6">
					<v-row>
						<v-col cols="6">
							<AsiDatePickerCombined v-model="validFromInput" :label="$t('promotion.validFrom')"
							                       :rules="model.rules('validFrom')" class="input-required"
							                       :max-date="model.validTo === null ? undefined : model.validTo"/>
						</v-col>
						<v-col cols="6">
							<AsiDatePickerCombined v-model="validToInput" :label="$t('promotion.validTo')"
							                       :rules="model.rules('validTo')" class="input-required"
							                       :min-date="model.validFrom === null ? undefined : model.validFrom"/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<AsiTextField v-model="model.minimumOrderPrice" :rules="model.rules('minimumOrderPrice')"
							              :label="$t('promotion.minimumOrderPrice')" :placeholder="$t('promotion.minimumOrderPrice')"
							              class="input-required"/>
						</v-col>
					</v-row>
				</div>
			</v-form>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit} from "vue-property-decorator";
	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
	import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
	import AsiTextField from "@/components/common/AsiTextField";
	import PromotionCreate from "@/models/promotion/PromotionCreate";
	import ItemAutocompleteInput from "@/components/item/admin/ItemAutocompleteInput.vue";
	import AsiDatePickerCombined from "@/components/common/AsiDatePickerCombined.vue";
	import DateTimeHelper from "@/helpers/DateTimeHelper";

	@Component({
		components: {
			AsiDatePickerCombined,
			ItemAutocompleteInput, AsiTextField, AsiDialogFormLayout
		}
	})
	export default class PromotionCreateDialog extends AsiDialogForm {

		private model: PromotionCreate = new PromotionCreate();

		private get validFromInput(): string | null {
			return this.model.validFrom === null ? null : DateTimeHelper.toISODateString(new Date(this.model.validFrom));
		}

		private set validFromInput(value: string | null) {
			if (value === null) {
				this.model.validFrom = null;
				return;
			}

			const date = new Date(value);
			date.setHours(0, 0, 0);
			this.model.validFrom = DateTimeHelper.toISODateTimeString(date);
		}

		private get validToInput(): string | null {
			return this.model.validTo === null ? null : DateTimeHelper.toISODateString(new Date(this.model.validTo));
		}

		private set validToInput(value: string | null) {
			if (value === null) {
				this.model.validTo = null;
				return;
			}

			const date = new Date(value);
			date.setHours(23, 59, 59);
			this.model.validTo = DateTimeHelper.toISODateTimeString(date);
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			const form = this.$refs.form as HTMLFormElement;
			this.reset([this.model], form);
			return event;
		}

		private requestSave(): void {
			if (!this.valid) return;

			this.loading = true;
			this.$promotionServiceAdmin.create(this.model)
				.then(this.save)
				.catch(this.handleErrors)
				.finally(() => this.loading = false);
		}

	}
</script>

<style lang="scss" scoped>

</style>
