<template>
	<AsiSkeletonCards v-if="user === null" :num="3" :columns="2" loading/>

	<AsiContentContainer v-else :columns="2">
		<div>
			<UserCardName :user="user" :readonly="!rMasterData" @change="change"/>
		</div>
		<div>
			<UserCardPersonalData :user="user" :readonly="!rMasterData" @change="change"/>
		</div>
		<div>
			<UserCardContact :user="user" :readonly="!rMasterData" @change="change"/>
		</div>
		<div>
			<UserCardState :user="user" readonly/>
		</div>
	</AsiContentContainer>
</template>

<script lang="ts">
	import {Component, Emit, Prop} from "vue-property-decorator";
	import {IUserAdmin} from "@/models/user/UserAdminModels";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import AsiSkeletonCards from "@/components/common/AsiSkeletonCards.vue";
	import UserCardPersonalData from "@/components/user/UserCardPersonalData.vue";
	import UserCardName from "@/components/user/UserCardName.vue";
	import RightChecks from "@/mixins/RightChecks.vue";
	import {mixins} from "vue-class-component";
	import UserCardState from "@/components/user/admin/UserCardState.vue";
	import UserCardContact from "@/components/user/UserCardContact.vue";

	@Component({
		components: {
			UserCardContact,
			UserCardState, UserCardName, UserCardPersonalData, AsiSkeletonCards, AsiContentContainer}
	})
	export default class UserTabDetails extends mixins(RightChecks) {

		@Prop({required: true})
		public user!: IUserAdmin | null;

		@Emit('change')
		public change(): void {
			return;
		}

	}
</script>

<style lang="scss" scoped>

</style>
