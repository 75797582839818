<template>
	<AsiDialogFormLayout :icon="icons.state" :title="$t('category.state')"
	                     :open="open" :loading="loading" :valid="valid" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped scrollable>
		<v-card-text class="pa-6">
			<v-form v-model="valid">
				<v-row>
					<v-col>
						<AsiSelect v-model="state" :label="$t('category.state')" :placeholder="$t('category.state')"
						           :items="options" :rules="rules" class="input-required"/>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiDialogForm from "../../common/AsiDialogForm.vue";
	import AsiDialogFormLayout from "../../common/AsiDialogFormLayout.vue";
	import AsiSelect from "../../common/AsiSelect";
	import {CategoryState} from "@/helpers/constants";
	import EnumHelper from "../../../helpers/EnumHelper";
	import {ValidationRule} from "@/declarations/ValidationRule";
	import {ICategoryShopSimple} from "@/models/category/CategoryShopModels";
	import CategoryRules from "@/models/category/CategoryRules";

	@Component({
		components: {AsiSelect, AsiDialogFormLayout}
	})
	export default class CategoryUpdateDialogState extends AsiDialogForm {
		@Prop({type: Object, required: true})
		public category!: ICategoryShopSimple;

		private state: CategoryState | null = null;

		// noinspection JSMethodCanBeStatic
		private get options(): { text: string, value: string | number }[] {
			return EnumHelper
				.toSelectItems(CategoryState, true);
		}

		// noinspection JSMethodCanBeStatic
		private get rules(): ValidationRule[] {
			return CategoryRules.stateRules;
		}

		@Watch('category.state', {immediate: true})
		private onCategoryStateChanged(value: CategoryState): void {
			this.state = value;
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			this.state = this.category.state;
			const form = this.$refs.form as HTMLFormElement;
			this.reset([], form);
			return event;
		}

		private requestSave(): void {
			if (this.state === null) return;

			this.loading = true;
			this.$categoryServiceAdmin.state(this.category.id, this.state)
				.then(this.save)
				.catch(this.handleErrors)
				.finally(() => this.loading = false);
		}
	}
</script>

<style lang="scss" scoped>

</style>
