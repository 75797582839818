import { render, staticRenderFns } from "./DashboardGrowth.vue?vue&type=template&id=d2f8e2ae&scoped=true"
import script from "./DashboardGrowth.vue?vue&type=script&lang=ts"
export * from "./DashboardGrowth.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2f8e2ae",
  null
  
)

export default component.exports