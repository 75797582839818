<template>
	<AsiListTableLayout :filter-text="filterModel.fulltext" :has-filter="hasFilter" :hide-toolbar="hideToolbar"
	                    @clearFilterText="filterTextChanged(null)" @clearSearch="clearSearch()"
	                    @update:filterText="filterTextChanged">

		<AsiContentContainer no-bottom-margin>
			<v-data-table
				:footer-props="{itemsPerPageOptions: perPageOptions}"
				:headers="createHeaders()"
				:items="items"
				:loading="loadingInternal || loadingDelete"
				:options.sync="tableOptions"
				:server-items-length="total"
				item-key="id"
				multi-sort
				@click:row="openModelDetailPage">

				<template v-slot:item.name="{item}">
					<div class="d-flex flex-column">
						<span>{{ item.name.de }}</span>
						<small class="grey--text">{{ item.name.fr !== null ? item.name.fr : '-' }}</small>
						<small class="grey--text">{{ item.name.en !== null ? item.name.en : '-' }}</small>
					</div>
				</template>
				<template v-if="showActions" v-slot:item.actions="{item}">
					<div class="d-flex flex-row flex-wrap">
						<AsiBtn :disabled="loadingDelete" :icon="icons.edit" small @click.native.stop="updatePaymentTypeId = item.id; updateDialog.open();"/>
						<AsiBtn :disabled="loadingDelete" :icon="icons.delete" small @click="performDelete(item)"/>
					</div>
				</template>
			</v-data-table>
		</AsiContentContainer>

		<template v-slot:advanced-filters>
			<AsiTextFieldSimple v-model="filterModel.name" :placeholder="$t('paymentType.name')" clearable/>
			<AsiTextFieldSimple v-model="filterModel.description" :placeholder="$t('paymentType.description')" clearable/>
			<AsiTextFieldSimple v-model="filterModel.code" :placeholder="$t('paymentType.code')" clearable/>
			<AsiTextFieldSimple v-model="filterModel.paymentProviderCode" :placeholder="$t('paymentType.paymentProviderCode')" clearable/>
			<AsiTextFieldSimple v-model="filterModel.externalId" :placeholder="$t('paymentType.externalId')" clearable/>
		</template>

		<AsiConfirmDialog v-if="showActions" ref="confirm"/>
		<PaymentTypeUpdateDialog v-if="showActions && updateDialog.isLoaded && updatePaymentTypeId !== null"
		                         :open="updateDialog.isOpen" :id="updatePaymentTypeId" @cancel="updateDialog.close()"
		                         @save="changed(updatePaymentTypeId); updateDialog.close(); reload(false, false)"/>
	</AsiListTableLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiListTable from "@/components/common/AsiListTable.vue";
	import AsiListTableHeader from "@/components/common/AsiListTableHeader";
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";
	import IAsiListTableImplementation from "@/components/common/IAsiListTableImplementation";
	import AsiListTableLayout from "@/components/common/AsiListTableLayout.vue";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import Icon from "@/plugins/icons";
	import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import Snackbar from "@/helpers/Snackbar";
	import PaymentTypeListFilter from "@/models/payment-type/PaymentTypeListFilter";
	import {IPaymentTypeAdminListEntry} from "@/models/payment-type/PaymentTypeAdminModels";
	import IPaginatedResponse from "@/models/IPaginatedResponse";
	import PaymentTypeUpdateDialog from "@/components/payment-type/admin/PaymentTypeUpdateDialog.vue";

	@Component({
		components: {
			PaymentTypeUpdateDialog,
			AsiConfirmDialog,
			AsiBtn,
			AsiTextFieldSimple,
			AsiContentContainer,
			AsiListTableLayout, AsiListTable
		}
	})
	export default class PaymentTypeList extends AsiListTable<IPaymentTypeAdminListEntry, PaymentTypeListFilter> implements IAsiListTableImplementation<IPaymentTypeAdminListEntry, PaymentTypeListFilter> {

		@Prop({type: Boolean, default: false})
		public hideToolbar!: boolean;

		@Prop({type: Boolean, default: false})
		public showActions!: boolean;

		private icons = Icon;
		private initialized: boolean = false;
		private loadingDelete: boolean = false;
		private updatePaymentTypeId: string | null = null;
		private updateDialog: DialogHandler = new DialogHandler(() => this.updatePaymentTypeId = null);

		public created(): void {
			this.reload(false, false);
			this.$nextTick(() => this.initialized = true);
		}

		public createFilterModel(): PaymentTypeListFilter {
			return new PaymentTypeListFilter();
		}

		public openModelDetailPage(): void {
			//do nothing...
		}

		public createHeaders(): AsiListTableHeader[] {
			const ret: AsiListTableHeader[] = [
				new AsiListTableHeader(this.$t('paymentType.name'), 'name', true, true, 'start'),
				new AsiListTableHeader(this.$t('paymentType.code'), 'code', true, true, 'start', '15%'),
				new AsiListTableHeader(this.$t('paymentType.paymentProviderCode'), 'paymentProviderCode', true, true, 'start', '15%'),
				new AsiListTableHeader(this.$t('paymentType.externalId'), 'externalId', true, true, 'start', '15%'),
			];

			if (this.showActions) {
				ret.push(
					new AsiListTableHeader('', 'actions', false, false, 'end', '6rem'),
				);
			}

			return ret;
		}

		public fetchData(filterModel: PaymentTypeListFilter | null, tableOptions: AsiListTableOptions | null): Promise<IPaginatedResponse<IPaymentTypeAdminListEntry>> {
			return this.$paymentTypeServiceAdmin.paymentTypes(filterModel, tableOptions);
		}

		public filterTextChanged(filterText: string): void {
			if (this.filterModel === null) return;
			this.filterModel.fulltext = filterText;
		}

		public reload(goToFirstPage: boolean = false, debounce: boolean = true): void {
			this.reloadInternal(this, goToFirstPage, debounce);
		}

		@Emit('changed')
		public changed(id: string): string {
			return id;
		}

		@Emit('deleted')
		public deleted(id: string): string {
			return id;
		}

		protected prepareOptions(options: AsiListTableOptions): AsiListTableOptions {
			if (options.sortBy.length === 0 && this.defaultSortBy.length === 0) {
				options.sortBy = ['name'];
				options.sortDesc = [false];
			}
			return options;
		}

		@Watch('tableOptions', {deep: true})
		private onTableOptionsChanged(): void {
			if (!this.initialized) return;
			this.reload();
		}

		@Watch('filterModel', {deep: true})
		private onFilterModelChanged(): void {
			if (!this.initialized) return;
			this.reload(true);
		}

		private performDelete(entry: IPaymentTypeAdminListEntry): void {
			const confirm = this.$refs.confirm as unknown as AsiConfirmDialog;
			confirm.openDialog().then((res: boolean) => {
				if (!res) return;

				const id = entry.id;
				this.loadingDelete = true;
				this.$paymentTypeServiceAdmin.delete(id)
					.then(() => {
						this.reload(false, false);
						this.deleted(id);
					})
					.catch(() => Snackbar.deleteError())
					.finally(() => this.loadingDelete = false);
			});
		}

	}
</script>

<style lang="scss" scoped>

</style>
