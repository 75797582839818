<template>
	<AsiChip :icon="icon" :color="color" small>
		{{ label }}
	</AsiChip>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import EnumHelper from "@/helpers/EnumHelper";
	import {CustomerState} from "@/helpers/constants";
	import AsiChip from "@/components/common/AsiChip.vue";
	import {ICustomerAdminSimple} from "@/models/customer/CustomerAdminModels";
	import {ICustomerShopSimple} from "@/models/customer/CustomerShopModels";
	import CustomerHelper from "@/models/customer/CustomerHelper";

	@Component({
		components: {AsiChip}
	})
	export default class ItemStateChip extends Vue {

		@Prop({type: Object, required: true})
		public customer!: ICustomerAdminSimple | ICustomerShopSimple;

		// noinspection JSMethodCanBeStatic
		private get label(): string | null {
			return EnumHelper.textFromValue(CustomerState, this.customer.state, true);
		}

		// noinspection JSMethodCanBeStatic
		private get icon(): string {
			return CustomerHelper.stateIcon(this.customer);
		}

		// noinspection JSMethodCanBeStatic
		private get color(): string {
			return CustomerHelper.stateColor(this.customer);
		}

	}
</script>

<style lang="scss" scoped>

</style>
