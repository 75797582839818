<template>
	<v-data-table
		:options="tableOptions"
		@update:options="$emit('update:tableOptions', $event)"
		:server-items-length="totalItems"
		:items="items"
		hide-default-footer
		item-key="id"
		hide-default-header>

		<template v-slot:body>
			<div v-if="items.length > 0" class="item-wrapper ma-3">
				<ItemCard v-for="item in items" :key="item.id" :item="item"
				          no-bottom-margin @click="itemSelected(item)" @showAddToCart="showAddToCart({id: item.id, unit: item.salesUnitOfMeasure, prices: item.prices})"/>
			</div>
			<div v-else class="pa-6 text-center grey--text">
				{{ $t('$vuetify.noDataText') }}
			</div>
		</template>

		<template v-slot:footer>
			<v-divider />
			<ItemListPagination :total-items="totalItems" :table-options="tableOptions" :per-page-options="perPageOptions" />
		</template>
	</v-data-table>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import {IItemShopListEntry} from "@/models/item/ItemShopModels";
	import ItemCard from "@/components/item/shop/ItemCard.vue";
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";
	import {UnitOfMeasure} from "@/helpers/constants";
	import {IItemPriceShopSimple} from "@/models/item-price/ItemPriceModelsShop";
	import ItemListPagination from "@/components/item/shop/ItemListPagination.vue";

	@Component({
		components: {ItemListPagination, ItemCard}
	})
	export default class ItemListViewCards extends Vue {

		@Prop({type: Array, required: true})
		public items!: IItemShopListEntry[];

		@Prop({type: Number, required: true})
		public totalItems!: number;

		@Prop({type: Object, required: true})
		public tableOptions!: AsiListTableOptions;

		@Prop({type: Array, required: true})
		public perPageOptions!: number[];

		@Prop({default: null})
		public showAttributeCallback!: ((column: string) => boolean) | null;

		@Emit('itemSelected')
		public itemSelected(model: IItemShopListEntry): IItemShopListEntry {
			return model;
		}

		@Emit('showAddToCart')
		public showAddToCart(item: { id: string, unit: UnitOfMeasure, prices: IItemPriceShopSimple[] }): { id: string, unit: UnitOfMeasure, prices: IItemPriceShopSimple[] } {
			return item;
		}

	}
</script>

<style lang="scss" scoped>
	.item-wrapper {
		display: grid;
		grid-auto-rows: auto;
		grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
		grid-gap: 1rem;
	}
</style>
