<template>
	<ShopView>
		<h1 class="mb-6 d-flex flex-column">
			<span>
				{{ $t('pageTitles.shop.termsAndConditions') }}
			</span>
			<span class="grey--text mt-n1 text-h5">
				{{ applicationName }}
			</span>
		</h1>
		<AsiCard unwrapped>
			<v-card-text class="pa-6">
				<LegalTermsAndConditions/>
			</v-card-text>
		</AsiCard>
	</ShopView>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component} from "vue-property-decorator";
	import AsiLoginForm from "@/components/common/AsiLoginForm.vue";
	import AsiCard from "@/components/common/AsiCard.vue";
	import ShopView from "@/components/layout/ShopView.vue";
	import LegalTermsAndConditions from "@/components/legal/LegalTermsAndConditions.vue";
	import {Tenant} from "@/models/ui/Tenant";
	import EnumHelper from "@/helpers/EnumHelper";

	@Component({
		components: {LegalTermsAndConditions, ShopView, AsiCard, AsiLoginForm}
	})
	export default class TermsAndConditions extends Vue {

		public get applicationName(): string {
			return EnumHelper.textFromValue(Tenant, this.$isTenant, true) ?? '';
		}
	}
</script>

<style lang="scss" scoped>

</style>
