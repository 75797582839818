<template>
	<section>
		<template v-if="!readonly">
			<v-divider/>
			<div class="pa-6 text-center grey lighten-5">
				<AsiBtn :icon="icons.add" @click="createDialog.open()" color="secondary">
					{{ $t('shipmentType.terms.create') }}
				</AsiBtn>
			</div>
		</template>

		<v-divider/>
		<ShipmentTypeList :show-actions="!readonly" ref="list" />

		<ShipmentTypeCreateDialog v-if="!readonly && createDialog.isLoaded" :open="createDialog.isOpen"
		                          @cancel="createDialog.close()" @save="reloadList(); createDialog.close();" />
	</section>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import ShipmentTypeList from "@/components/shipment-type/admin/ShipmentTypeList.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import DialogHandler from "@/components/common/DialogHandler";
	import ShipmentTypeCreateDialog from "@/components/shipment-type/admin/ShipmentTypeCreateDialog.vue";

	@Component({
		components: {ShipmentTypeCreateDialog, AsiBtn, ShipmentTypeList}
	})
	export default class SettingsTabShipmentTypes extends Vue {

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		private icons = Icon;
		private createDialog: DialogHandler = new DialogHandler();

		private reloadList(): void {
			const list = this.$refs.list as ShipmentTypeList | undefined;
			if (list !== undefined) list.reload(false, false);
		}

	}
</script>

<style lang="scss" scoped>

</style>
