<template>
	<AsiAutocomplete :clearable="clearable" :dense="simple" :disabled="loadingActual || disabled"
	                 :flat="simple" :item-text="itemText" :items="customerOptions" :label="finalLabel"
	                 :loading="loadingActual" :multiple="multiple" :rules="rules" :search-input.sync="searchInput"
	                 :solo="simple" :value="value" autocomplete="off" item-value="id" @change="change"
	                 @input="input" :filter="itemSearch">

		<template v-if="!noBusinessCards" v-slot:selection="data">
			<CustomerBusinessCard :class="{'mt-1': !simple}" :close="multiple" :color="simple ? null : 'grey lighten-1'"
			                      :customer="data.item" no-popup @close="removeFromSelection(data.item)"/>
		</template>
		<template v-slot:item="data">
			<template v-if="typeof data.item !== 'object'">
				<v-list-item-content v-text="data.item"></v-list-item-content>
			</template>
			<template v-else>
				<v-list-item-avatar>
					<AsiAvatar :icon="customerTypeIcon(data.item)" :image-url="customerAvatarUrl(data.item)" :size="32"/>
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title>
						{{ data.item.name }}
					</v-list-item-title>
					<v-list-item-subtitle>
						{{ customerTypeLabel(data.item) }}
					</v-list-item-subtitle>
				</v-list-item-content>
			</template>
		</template>
	</AsiAutocomplete>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import {AttachmentMediaSize, CustomerState, CustomerType} from "@/helpers/constants";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";
	import Icon from "@/plugins/icons";
	import Snackbar from "@/helpers/Snackbar";
	import StringHelper from "@/helpers/StringHelper";
	import EnumHelper from "@/helpers/EnumHelper";
	import {ICustomerAdminListEntry} from "@/models/customer/CustomerAdminModels";
	import CustomerListFilter from "@/models/customer/CustomerListFilter";
	import {ICustomerShopSimple} from "@/models/customer/CustomerShopModels";
	import CustomerHelper from "@/models/customer/CustomerHelper";
	import CustomerBusinessCard from "@/components/customer/admin/CustomerBusinessCard.vue";
	import AsiAutocomplete from "@/components/common/AsiAutocomplete";

	@Component({
		components: {AsiAutocomplete, CustomerBusinessCard, AsiAvatar}
	})
	export default class CustomerAutocompleteInput extends Vue {

		@Prop({default: null})
		public value!: string | string[] | null;

		@Prop({default: null})
		public customers!: ICustomerAdminListEntry[] | null;

		@Prop({type: Object, default: null})
		public customerListFilter!: CustomerListFilter | null;

		@Prop({type: Array, default: () => []})
		public rules!: (string | null | number | object | unknown | boolean)[];

		@Prop({type: Boolean, default: false})
		public clearable!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		@Prop({type: Boolean, default: false})
		public multiple!: boolean;

		@Prop({type: String, default: null})
		public label!: string | null;

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		@Prop({type: Boolean, default: false})
		public simple!: boolean;

		@Prop({type: Boolean, default: false})
		public noBusinessCards!: boolean;

		@Prop({type: Boolean, default: false})
		public keepSearch!: boolean;

		private loadingInternal: boolean = false;
		private customersInternal: ICustomerAdminListEntry[] = [];
		private icons = Icon;
		private customerListFilterInternal: CustomerListFilter | null = null;
		private searchInput: string | null = null;

		private get finalLabel(): string {
			return this.label !== null ? this.label : this.$t(this.multiple ? 'customer.plural' : 'customer.singular').toString();
		}

		private get loadingActual(): boolean {
			return this.loading || this.loadingInternal;
		}

		private get customerOptions(): object[] {
			let customerList = this.customersInternal;
			const ret: object[] = [];

			const myCustomer = this.$store.getters['user/modelCustomer'] as ICustomerShopSimple | null;
			if (myCustomer !== null) {
				const ownCustomerId = myCustomer.id;
				const ownCustomer = customerList.find(c => c.id === myCustomer.id) as ICustomerAdminListEntry | undefined;
				if (ownCustomer !== undefined) {
					ret.push(ownCustomer);
					if (customerList.length > 1) {
						ret.push({divider: true});
					}
					customerList = customerList.filter(c => c.id !== ownCustomerId);
				}
			}

			customerList.forEach(c => ret.push(c));

			return ret;
		}

		public mounted(): void {
			if (this.customers !== null) {
				this.customersInternal = this.customers;
				this.customerOptionsChanged();
			} else {
				this.setCustomerListFilterInternal();
				this.loadCustomers();
			}
		}

		@Emit('input')
		public input(selection: string | string[] | null): string | string[] | null {
			if (!this.keepSearch) this.clearSearchInput();
			return selection;
		}

		@Emit('change')
		public change(selection: string | string[] | null): string | string[] | null {
			return selection;
		}

		@Emit('customerOptionsChanged')
		public customerOptionsChanged(): ICustomerAdminListEntry[] {
			return this.customersInternal;
		}

		private clearSearchInput(): void {
			this.searchInput = null;
		}

		// noinspection JSMethodCanBeStatic
		private removeFromSelection(customer: ICustomerAdminListEntry): void {
			if (!this.multiple || !Array.isArray(this.value)) return;

			const newSelection = this.value.filter(id => id !== customer.id);
			this.input(newSelection);
		}

		@Watch('customers', {deep: true})
		private onCustomersChanged(value: ICustomerAdminListEntry[]): void {
			this.customersInternal = value;
		}

		@Watch('customerListFilter', {deep: true})
		private onCustomerListFilterChanged(): void {
			this.setCustomerListFilterInternal();
			this.loadCustomers();
		}

		private setCustomerListFilterInternal(): void {
			this.customerListFilterInternal = this.customerListFilter ?? new CustomerListFilter();
			this.customerListFilterInternal.states = [CustomerState.active];
		}

		private loadCustomers(): void {
			this.loadingInternal = true;
			this.$customerServiceAdmin.customers(this.customerListFilterInternal, null)
				.then((data) => {
					this.customersInternal = data.data;
					this.customerOptionsChanged();
				})
				.catch(() => Snackbar.loadingError())
				.finally(() => this.loadingInternal = false);
		}

		// noinspection JSMethodCanBeStatic
		private customerTypeLabel(customer: ICustomerAdminListEntry): string | null {
			return EnumHelper.textFromValue(CustomerType, customer.type, true);
		}

		// noinspection JSMethodCanBeStatic
		private customerTypeIcon(customer: ICustomerAdminListEntry): string {
			return customer.type === CustomerType.private
				? Icon.customerPrivate
				: Icon.customerBusiness;
		}

		// noinspection JSMethodCanBeStatic
		private customerAvatarUrl(customer: ICustomerAdminListEntry): string | null {
			return CustomerHelper.avatarUrl(customer, AttachmentMediaSize.s);
		}

		private itemText(customer: ICustomerAdminListEntry | []): string {
			if (Array.isArray(customer)) return '';
			// noinspection JSIncompatibleTypesComparison
			if (customer.id === undefined) return '';

			return `${customer.name} (${this.customerTypeLabel(customer)})`;
		}

		private itemSearch(item: any, queryText: string, itemText: string): boolean {
			const parts = queryText
				.toLowerCase()
				.split(/\s+/)
				.filter(p => !StringHelper.isEmpty(p));
			return !parts.some(p => itemText.toLowerCase().indexOf(p) < 0);
		}

	}
</script>

<style lang="scss" scoped>

</style>
