import { render, staticRenderFns } from "./ShoppingListCardPositions.vue?vue&type=template&id=8625a914&scoped=true"
import script from "./ShoppingListCardPositions.vue?vue&type=script&lang=ts"
export * from "./ShoppingListCardPositions.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8625a914",
  null
  
)

export default component.exports