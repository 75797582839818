<template>
	<section class="d-flex flex-row justify-center align-center" :class="{'mt-6 mb-6': sMobile, 'fill-height': !sMobile}">
		<AsiCard :icon="icons.login" :loading.sync="loading" :title="$t('ui.login')"
		         highlighted unwrapped no-bottom-margin style="width: 90vw; max-width: 30rem">
			<AsiLoginForm :loading.sync="loading"/>
		</AsiCard>
	</section>
</template>

<script lang="ts">
	import {Component} from "vue-property-decorator";
	import AsiLoginForm from "@/components/common/AsiLoginForm.vue";
	import AsiCard from "@/components/common/AsiCard.vue";
	import Icon from "@/plugins/icons";
	import {mixins} from "vue-class-component";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";

	@Component({
		components: {AsiCard, AsiLoginForm}
	})
	export default class Login extends mixins(ResponsiveChecks) {

		private icons = Icon;
		private loading: boolean = false;

	}
</script>

<style lang="scss" scoped>

</style>
