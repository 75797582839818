import Vue, {FunctionalComponentOptions, VueConstructor} from 'vue';
import {FontAwesomeIcon, FontAwesomeIconProps} from '@fortawesome/vue-fontawesome';
import {IconProp, library} from '@fortawesome/fontawesome-svg-core';
import {PropsDefinition} from 'vue/types/options';

import {
	faAlignLeft,
	faAngleDown,
	faArchive,
	faArrowsAlt,
	faArrowsAltH,
	faArrowsAltV,
	faAsterisk,
	faAt,
	faBadgeDollar, faBagsShopping,
	faBarcode,
	faBarcodeScan,
	faBox,
	faBoxCircleCheck,
	faBrainCircuit,
	faBug,
	faBuilding,
	faCalendar,
	faCalendarStar,
	faChartLineUp,
	faCheck,
	faCheckSquare,
	faChevronDown,
	faChevronLeft,
	faChevronRight,
	faChevronUp,
	faCircle,
	faCity,
	faClipboardListCheck,
	faClock,
	faCode,
	faColumns,
	faComment,
	faCopy,
	faCreditCard,
	faCreditCardBlank,
	faCropSimple,
	faCubes,
	faDatabase,
	faDigging,
	faDotCircle,
	faDownload,
	faEllipsisV,
	faEnvelope,
	faExclamationSquare,
	faEye,
	faEyeSlash,
	faFax,
	faFile,
	faFileAlt,
	faFileArchive,
	faFileCheck,
	faFileExcel,
	faFileExport,
	faFileImage,
	faFileMagnifyingGlass,
	faFilePdf,
	faFileWord,
	faFilter,
	faFingerprint,
	faFlag, faGalleryThumbnails,
	faGears,
	faGift,
	faGifts,
	faGlobeEurope,
	faHammer,
	faHandHoldingBox,
	faHandshake,
	faHashtag,
	faHistory,
	faHome,
	faHourglassClock,
	faICursor,
	faIdCardAlt,
	faImagePolaroid,
	faInfoSquare,
	faLaptop,
	faLayerPlus,
	faLegal,
	faLightbulbOn,
	faLink,
	faList,
	faListTree,
	faLock,
	faLockOpen,
	faLongArrowDown,
	faLongArrowUp,
	faMapMarked,
	faMapMarkerAlt,
	faMemo,
	faMerge,
	faMindShare,
	faMinus,
	faMinusSquare,
	faMobile,
	faMoneyBill,
	faMoneyCheckDollar,
	faObjectGroup,
	faPager,
	faPalette,
	faPaperPlane,
	faPause,
	faPencil,
	faPhone,
	faPlay,
	faPlus,
	faPrint,
	faProjectDiagram,
	faRectangleAd,
	faReply,
	faRuler,
	faSave,
	faScaleBalanced,
	faSearch,
	faSection,
	faShoppingCart,
	faSitemap,
	faSpinner,
	faStamp,
	faStar,
	faStarHalf,
	faStepBackward,
	faStepForward,
	faStickyNote,
	faSync,
	faTable,
	faTag,
	faTags,
	faTasksAlt,
	faThumbtack,
	faTimes,
	faTrash,
	faTreeChristmas,
	faTruckClock,
	faUpload,
	faUser,
	faUserSecret,
	faWrench,
	faHandWave
} from '@fortawesome/pro-solid-svg-icons';
import {faSquare, faStar as faStarEmpty, faTruckFast, IconDefinition} from '@fortawesome/pro-regular-svg-icons';
import {faSlack} from '@fortawesome/free-brands-svg-icons';

export interface CockpitIcons {
	//vuetify
	complete: string;
	cancel: string;
	close: string;
	delete: string;
	add: string;
	remove: string;
	clear: string;
	merge: string;
	success: string;
	info: string;
	warning: string;
	error: string;
	prev: string;
	next: string;
	checkboxOn: string;
	checkboxOff: string;
	checkboxIndeterminate: string;
	delimiter: string; // for carousel
	sort: string;
	sortDesc: string;
	expand: string;
	menu: string;
	subgroup: string;
	dropdown: string;
	radioOn: string;
	radioOff: string;
	edit: string;
	ratingEmpty: string;
	ratingFull: string;
	ratingHalf: string;
	loading: string;
	first: string;
	last: string;
	unfold: string;
	file: string;
	fileCheck: string;
	preview: string;
	noData: string;

	//common icons
	pin: string;
	home: string;
	frontend: string;
	backend: string;
	search: string;
	debug: string;
	rights: string;
	login: string;
	logout: string;
	yes: string;
	no: string;
	confirm: string;
	create: string;
	view: string;
	save: string;
	copy: string;
	reset: string;
	archive: string;
	unarchive: string;
	list: string;
	avatar: string;
	bannerImage: string;
	time: string;
	calendar: string;
	id: string;
	progress: string;
	showPassword: string;
	hidePassword: string;
	send: string;
	system: string;
	settings: string;
	move: string;
	moveX: string;
	moveY: string;
	favorite: string;
	noFavorite: string;
	columns: string;
	table: string;
	externalSource: string;
	reload: string;
	up: string;
	down: string;
	upload: string;
	download: string;
	filter: string;
	forgotPassword: string;
	fileImage: string;
	filePdf: string;
	fileExcel: string;
	fileWord: string;
	fileText: string;
	fileArchive: string;
	card: string;
	reply: string;
	link: string;
	history: string;
	now: string;
	phone: string;
	phoneMobile: string;
	phoneFax: string;
	phonePager: string;
	email: string;
	computer: string;
	web: string;
	currency: string;
	colorSettings: string;
	color: string;
	mailSignature: string;
	mail: string;
	slack: string;
	hierarchy: string;
	changelog: string;
	print: string;
	language: string;
	experimental: string;
	articleNumber: string;
	shipping: string;
	state: string;
	toc: string;
	legalNotice: string;
	privacy: string;
	faq: string;
	map: string;
	meta: string;
	baseUnitOfMeasure: string;
	salesUnitOfMeasure: string;
	deliveryTime: string;
	isSalesUnitFixed: string;
	canOrderSample: string;
	stock: string;
	prices: string;
	vat: string;
	export: string;
	waiting: string;
	paid: string;
	trimmingCostThreshold: string;
	pickup: string;
	christmasInfo: string;
	videoPlay: string;
	videoPause: string;
	handWave: string;
	chartLineUp: string;
	comment: string;


	//entities
	user: string;
	users: string;
	customer: string;
	customers: string;
	customerBusiness: string;
	customerPrivate: string;
	category: string;
	categories: string;
	item: string;
	items: string;
	relatedItems: string;
	cart: string;
	carts: string;
	order: string,
	orders: string,
	address: string;
	addresses: string;
	shipmentType: string;
	shipmentTypes: string;
	paymentType: string;
	paymentTypes: string;
	promotion: string;
	promotions: string;
	mailTemplates: string;
	shoppingList: string;
	bulkyGoodsSurcharge: string;
	frameAssembly: string;

	//common attributes
	created: string;
	updated: string;
	username: string;
	password: string;
	name: string;
	infos: string;
	notes: string;
	description: string;
	attributes: string;
	metaKeyword: string;
	metaKeywords: string;
	metaDescription: string;
	canonical: string;
	salutation: string;
}

const iconMap: { [name: string]: IconDefinition } = {
	//vuetify
	complete: faCheck,
	cancel: faTimes,
	close: faTimes,
	delete: faTrash,
	add: faPlus,
	remove: faMinus,
	clear: faTimes,
	merge: faMerge,
	success: faCheck,
	info: faInfoSquare,
	warning: faExclamationSquare,
	error: faExclamationSquare,
	prev: faChevronLeft,
	next: faChevronRight,
	checkboxOn: faCheckSquare,
	checkboxOff: faSquare,
	checkboxIndeterminate: faMinusSquare,
	delimiter: faCircle,
	sort: faLongArrowUp,
	sortDesc: faLongArrowDown,
	expand: faPlus,
	menu: faEllipsisV,
	subgroup: faAngleDown,
	dropdown: faAngleDown,
	radioOn: faDotCircle,
	radioOff: faCircle,
	edit: faPencil,
	ratingEmpty: faStarEmpty,
	ratingFull: faStar,
	ratingHalf: faStarHalf,
	loading: faSpinner,
	first: faStepBackward,
	last: faStepForward,
	unfold: faAngleDown,
	file: faFile,
	fileCheck: faFileCheck,
	preview: faFileMagnifyingGlass,
	noData: faTimes,

	//common icons
	pin: faThumbtack,
	home: faHome,
	frontend: faShoppingCart,
	backend: faBrainCircuit,
	search: faSearch,
	debug: faBug,
	rights: faHammer,
	login: faLockOpen,
	logout: faLock,
	yes: faCheck,
	no: faTimes,
	confirm: faCheck,
	create: faPlus,
	view: faEye,
	save: faSave,
	copy: faCopy,
	reset: faAsterisk,
	archive: faArchive,
	unarchive: faCheck,
	list: faList,
	avatar: faImagePolaroid,
	bannerImage: faGalleryThumbnails,
	time: faClock,
	calendar: faCalendar,
	id: faHashtag,
	progress: faTasksAlt,
	showPassword: faEye,
	hidePassword: faEyeSlash,
	send: faPaperPlane,
	system: faCode,
	settings: faWrench,
	move: faArrowsAlt,
	moveX: faArrowsAltH,
	moveY: faArrowsAltV,
	favorite: faStar,
	noFavorite: faStarEmpty,
	columns: faColumns,
	table: faTable,
	externalSource: faDatabase,
	reload: faSync,
	up: faChevronUp,
	down: faChevronDown,
	upload: faUpload,
	download: faDownload,
	filter: faFilter,
	forgotPassword: faMindShare,
	fileImage: faFileImage,
	filePdf: faFilePdf,
	fileExcel: faFileExcel,
	fileWord: faFileWord,
	fileText: faFileAlt,
	fileArchive: faFileArchive,
	card: faCreditCardBlank,
	reply: faReply,
	link: faLink,
	history: faHistory,
	now: faCalendarStar,
	phone: faPhone,
	phoneMobile: faMobile,
	phoneFax: faFax,
	phonePager: faPager,
	email: faAt,
	computer: faLaptop,
	web: faGlobeEurope,
	currency: faMoneyBill,
	colorSettings: faPalette,
	color: faCircle,
	mailSignature: faEnvelope,
	mail: faEnvelope,
	slack: faSlack,
	hierarchy: faProjectDiagram,
	changelog: faLayerPlus,
	print: faPrint,
	language: faFlag,
	experimental: faDigging,
	articleNumber: faBarcodeScan,
	shipping: faTruckFast,
	state: faGears,
	toc: faLegal,
	legalNotice: faScaleBalanced,
	privacy: faUserSecret,
	faq: faLightbulbOn,
	map: faMapMarked,
	meta: faRectangleAd,
	baseUnitOfMeasure: faBox,
	salesUnitOfMeasure: faHandHoldingBox,
	deliveryTime: faTruckClock,
	isSalesUnitFixed: faBoxCircleCheck,
	canOrderSample: faStamp,
	stock: faCubes,
	prices: faMoneyCheckDollar,
	vat: faSection,
	export: faFileExport,
	waiting: faHourglassClock,
	paid: faBadgeDollar,
	trimmingCostThreshold: faRuler,
	comment: faComment,
	pickup: faBox,
	christmasInfo: faTreeChristmas,
	videoPlay: faPlay,
	videoPause: faPause,
	handWave: faHandWave,
	chartLineUp: faChartLineUp,

	//entities
	user: faUser,
	users: faUser,
	customer: faBuilding,
	customers: faCity,
	customerBusiness: faBuilding,
	customerPrivate: faHome,
	category: faSitemap,
	categories: faSitemap,
	item: faBarcode,
	items: faBarcode,
	relatedItems: faBagsShopping,
	cart: faShoppingCart,
	carts: faShoppingCart,
	order: faClipboardListCheck,
	orders: faClipboardListCheck,
	address: faMapMarkerAlt,
	addresses: faMapMarkerAlt,
	shipmentType: faTruckFast,
	shipmentTypes: faTruckFast,
	paymentType: faCreditCard,
	paymentTypes: faCreditCard,
	promotion: faGift,
	promotions: faGifts,
	mailTemplates: faObjectGroup,
	shoppingList: faMemo,
	bulkyGoodsSurcharge: faBox,
	frameAssembly: faCropSimple,

	//common attributes
	created: faAsterisk,
	updated: faWrench,
	username: faIdCardAlt,
	password: faFingerprint,
	name: faUser,
	infos: faInfoSquare,
	notes: faStickyNote,
	description: faAlignLeft,
	attributes: faListTree,
	metaKeyword: faTag,
	metaKeywords: faTags,
	metaDescription: faICursor,
	canonical: faHashtag,
	salutation: faHandshake,
};

export interface VuetifyIconConfig {
	component: FunctionalComponentOptions<FontAwesomeIconProps, PropsDefinition<FontAwesomeIconProps>> & VueConstructor;
	props: { icon: IconProp };
}

Vue.component('font-awesome-icon', FontAwesomeIcon);
const Icon: CockpitIcons | { [name: string]: string } = {};
const IconConfig: { [name: string]: VuetifyIconConfig } = {};
Object.keys(iconMap).forEach((k: string) => {
	const def = iconMap[k] as IconDefinition;
	Icon[k] = '$vuetify.icons.' + k;
//@ts-ignore
	library.add(def);

	IconConfig[k] = {
		component: FontAwesomeIcon,
		props: {
			icon: [def.prefix, def.iconName]
		}
	} as VuetifyIconConfig;
});
export default Icon;
export {IconConfig};
