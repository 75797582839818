<template>
	<AsiDialogFormLayout :icon="icons.fileCheck" :title="$t('mailTemplate.terms.create')" :subtitle="subtitle" max-width="90rem"
	                     :open="open" :loading="loading" :valid="valid" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped scrollable>
		<v-card-text class="pa-0">
			<v-form ref="form" v-model="valid">
				<div>
					<v-row class="ma-0">
						<v-col class="pa-0">
							<v-row class="ma-6">
								<v-col class="pa-0">
									<AsiTextField :label="$t('mailTemplate.subject')" v-model="createModel.subject" clearable/>
								</v-col>
							</v-row>
							<v-row class="ma-6">
								<v-col class="pa-0">
									<AsiTextarea class="mt-3" style="font-family: monospace"
									             :label="$t('mailTemplate.htmlTemplate')" v-model="createModel.htmlTemplate" :rules="createModel.rules('htmlTemplate')" height="100%"/>
								</v-col>
							</v-row>
							<v-row class="ma-6">
								<v-col class="pa-0 d-flex flex-column">
									<AsiBtn class="align-self-center" :icon="icons.upload" color="primary" @click="$refs.uploader.click()">
										{{ $t('mailTemplate.upload') }}
									</AsiBtn>
									<input ref="uploader" type="file" accept="text/*" class="d-none" @change="onFileChanged">
								</v-col>
							</v-row>
						</v-col>
						<v-divider vertical/>
						<v-col class="info-section pa-6">
							<slot name="infos"/>
						</v-col>
					</v-row>
				</div>
			</v-form>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiDialogFormLayout from "../common/AsiDialogFormLayout.vue";
	import MailTemplateCreate from "@/models/mail-template/MailTemplateCreate";
	import AsiTextarea from "@/components/common/AsiTextarea";
	import AsiTextField from "@/components/common/AsiTextField";
	import AsiCard from "@/components/common/AsiCard.vue";
	import {AxiosError} from "axios";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import {IMailTemplateAdminListEntry} from "@/models/mail-template/MailTemplateAdminModels";

	@Component({
		components: {AsiBtn, AsiCard, AsiTextField, AsiTextarea, AsiDialogFormLayout}
	})
	export default class MailTemplateCreateDialog extends AsiDialogForm {

		@Prop({type: String, default: null})
		public subtitle!: string | null;

		@Prop({type: Object, required: true})
		public model!: IMailTemplateAdminListEntry;

		private createModel: MailTemplateCreate = new MailTemplateCreate();
		private uploadedFile: File | null = null;

		private requestSave(): void {

			this.createModel.locale = this.model.locale;
			this.createModel.mailType = this.model.mailType;

			if (!this.valid) return;

			this.loading = true;
			this.$mailTemplateServiceAdmin.create(this.createModel)
				.then(this.save)
				.catch((err: AxiosError) => this.errors = err.response?.data)
				.finally(() => this.loading = false);
		}

		private onFileChanged(e: any): void {
			this.uploadedFile = e.target.files[0];
		}

		@Watch('uploadedFile')
		private onUploadedFileChanged(value: File): void {
			if (value !== null) {
				const reader = new FileReader();
				reader.onload = (res) => {
					if (res.target !== null) {
						this.createModel.htmlTemplate = res.target.result as string;
					}
				};
				reader.readAsText(value);
			}
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			const form = this.$refs.form as HTMLFormElement;
			this.reset([this.createModel], form);
			return event;
		}
	}
</script>

<style lang="scss" scoped>
	.info-section {
		height: 50em;
		overflow-y: scroll;
	}
</style>
