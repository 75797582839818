<template>
	<AsiDialog :icon="icon" :open="open" :subtitle="subtitle" :title="titleFinal" @cancel="cancel"
	           :loading="loadingInternal > 0" :persistent="loadingInternal > 0"
	           unwrapped scrollable>
		<div class="drop-area pa-6" @drop="filesDropped" @dragover.prevent>
			<div>
				<div class="mb-2">
					<v-icon :size="64" color="primary">{{ icons.upload }}</v-icon>
				</div>
				<div class="">
					<strong>{{ $t('ui.terms.dropFiles') }}</strong>
				</div>
			</div>
		</div>
		<v-divider/>
		<div class="pa-6 grey lighten-4">
			<AsiFileInputSimple v-model="selectedFiles" :placeholder="$t('ui.terms.chooseFiles')"
			                    class="ma-0 mb-n3" multiple
			                    @change="selectedFilesChanged"/>
		</div>

		<v-divider v-if="uploads.length > 0"/>
		<v-expand-transition>
			<div v-show="uploads.length > 0" class="upload-wrapper overflow-y-auto" style="max-height: 40vh;">
				<v-list class="py-0">
					<template v-for="(upload, i) in uploads">
						<v-list-item :key="`${upload.identifier}-entry`">
							<v-list-item-avatar>
								<v-icon :size="24">{{ fileIcon(upload.model.file) }}</v-icon>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title>
									{{ upload.model.file.name }}
								</v-list-item-title>
								<v-list-item-subtitle>
									<small class="grey--text">{{ fileMeta(upload.model.file) }}</small>
								</v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-icon>
								<v-icon v-if="failedUploads.includes(upload.identifier)" :size="24" color="error">
									{{ icons.error }}
								</v-icon>
								<v-icon v-else-if="upload.progress === 100" :size="24" color="success">
									{{ icons.success }}
								</v-icon>
								<v-progress-circular v-else :indeterminate="upload.progress <= 0 || upload.progress >= 100" :value="upload.progress" color="primary"/>
							</v-list-item-icon>
						</v-list-item>
						<v-divider v-if="i < uploads.length - 1" :key="`${upload.identifier}-divider`"/>
					</template>
				</v-list>
			</div>
		</v-expand-transition>
	</AsiDialog>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import Icon from "@/plugins/icons";
	import Snackbar from "@/helpers/Snackbar";
	import IModel from "@/models/IModel";
	import AttachmentUpload from "@/models/attachment/AttachmentUpload";
	import AttachmentCreate from "@/models/attachment/AttachmentCreate";
	import VueI18n from "vue-i18n";
	import AttachmentHelper from "@/models/attachment/AttachmentHelper";
	import TranslateResult = VueI18n.TranslateResult;
	import AsiDialog from "@/components/common/AsiDialog.vue";
	import IAdditionalAttachmentService from "@/services/IAdditionalAttachmentService";
	import AsiFileInputSimple from "@/components/common/AsiFileInputSimple";

	@Component({
		components: {AsiFileInputSimple, AsiDialog}
	})
	export default class AttachmentUploadDialog extends Vue {

		@Prop({required: true})
		public service!: IAdditionalAttachmentService;

		@Prop({type: Object, required: true})
		public model!: IModel;

		@Prop({type: Boolean, required: true})
		public open!: boolean;

		@Prop({type: String, default: null})
		public title!: string | null;

		@Prop({type: String, default: null})
		public subtitle!: string | null;

		@Prop({type: String, default: Icon.avatar})
		public icon!: string;

		private icons = Icon;
		private loadingInternal: number = 0;
		private selectedFiles: File[] = [];
		private uploads: AttachmentUpload[] = [];
		private failedUploads: number[] = [];
		private successfulUploads: number[] = [];

		private get titleFinal(): string | TranslateResult {
			return this.title ?? this.$t('ui.terms.uploadAttachments');
		}

		@Emit('cancel')
		public cancel(): number {
			return this.successfulUploads.length;
		}

		@Emit('uploaded')
		public uploaded(): void {
			return;
		}

		public paste(event: Event): void {
			//@ts-ignore
			if (event.clipboardData === undefined) return;
			const ce = event as ClipboardEvent;

			//items
			const items = ce.clipboardData?.items;
			if (items !== undefined && items.length > 0) {
				for (const item of items) {
					if (item.kind !== 'file') continue;
					const f = item.getAsFile();
					if (f === null) continue;
					this.performUpload(f);
				}
			}
		}

		private selectedFilesChanged(): void {
			this.selectedFiles.forEach(f => this.performUpload(f));
			this.selectedFiles = [];
		}

		private filesDropped(event: DragEvent): void {
			event.preventDefault();
			if (event.dataTransfer === null) return;

			if (event.dataTransfer.items) {
				for (let i = 0; i < event.dataTransfer.items.length; i++) {
					if (event.dataTransfer.items[i].kind === 'file') {
						const file = event.dataTransfer.items[i].getAsFile();
						if (file !== null) {
							this.performUpload(file);
						}
					}
				}
			} else {
				for (let i = 0; i < event.dataTransfer.files.length; i++) {
					this.performUpload(event.dataTransfer.files[i]);
				}
			}
		}

		private performUpload(file: File): void {

			const model = new AttachmentCreate();
			model.model = this.model;
			model.file = file;
			const upload = new AttachmentUpload(model);
			this.uploads.unshift(upload);

			this.loadingInternal++;
			this.service.uploadAdditionalAttachment(upload.model, (event: ProgressEvent): void => {
				upload.progress = Math.round((event.loaded * 100) / event.total);
			})
				.then(() => {
					this.loadingInternal--;
					this.uploaded();
					this.successfulUploads.push(upload.identifier);
				})
				.catch(err => {
					Snackbar.uploadError(err);
					this.failedUploads.push(upload.identifier);
				});
		}

		// noinspection JSMethodCanBeStatic
		private fileIcon(file: File): string {
			return AttachmentHelper.iconByMimeType(file.type);
		}

		// noinspection JSMethodCanBeStatic
		private fileMeta(file: File): string {
			return [
				AttachmentHelper.fileSizeFormatted(file.size),
				file.type,
			].join(', ');
		}

	}
</script>

<style lang="scss" scoped>
	@import 'src/scss/global';

	.drop-area {
		> div {
			border: 1px dashed var(--v-primary-base);
			min-height: 10rem;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}

	.upload-wrapper {
		@include asiScrollbar();
	}
</style>
