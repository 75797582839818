<template>
	<AsiDialogFormLayout v-if="model !== null" :icon="icons.item" :title="$t('ui.terms.basicData')"
	                     :open="open" :loading="loading" :valid="valid" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped scrollable>
		<v-card-text class="pa-0">
			<v-form v-model="valid">
				<div class="pa-6">
					<TranslatedValueFormPart :label="$t('item.description')" :model="model.description"/>
				</div>
				<div class="pa-6">
					<TranslatedValueFormPart :label="$t('item.description2')" :model="model.description2"/>
				</div>
				<div class="pa-6">
					<TranslatedValueFormPart :label="$t('item.description3')" :model="model.description3"/>
				</div>
				<div class="pa-6">
					<TranslatedValueFormPart :label="$t('item.description4')" :model="model.description4"/>
				</div>
				<div class="pa-6">
					<TranslatedValueFormPart :label="$t('item.description5')" :model="model.description5"/>
				</div>
			</v-form>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiDialogFormLayout from "../../common/AsiDialogFormLayout.vue";
	import TranslatedValueFormPart from "../../translated-value/admin/TranslatedValueFormPart.vue";
	import {IItemAdmin} from "@/models/item/ItemAdminModels";
	import ItemUpdateBasicData from "../../../models/item/ItemUpdateBasicData";
	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";

	@Component({
		components: {TranslatedValueFormPart, AsiDialogFormLayout}
	})
	export default class ItemUpdateDialogDescription extends AsiDialogForm {
		@Prop({type: Object, required: true})
		public item!: IItemAdmin;

		private model: ItemUpdateBasicData | null = null;

		@Watch('item', {deep: true, immediate: true})
		private onItemChanged(): void {
			this.setItemInternal();
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			this.setItemInternal();
			const form = this.$refs.form as HTMLFormElement;
			this.reset([], form);
			return event;
		}

		private setItemInternal(): void {
			if (this.model === null) {
				this.model = new ItemUpdateBasicData(this.item);
				this.valid = true;
			} else {
				this.model.setDetailModel(this.item);
			}
		}

		private requestSave(): void {
			if (this.model === null) return;

			this.loading = true;
			this.$itemServiceAdmin.updateBasicData(this.item.id, this.model)
				.then(this.save)
				.catch(this.handleErrors)
				.finally(() => this.loading = false);
		}
	}
</script>

<style lang="scss" scoped>

</style>
