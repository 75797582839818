<template>
	<div>
		<LineChart class="pa-4"
		           :data="{labels: getChartLabels, datasets: getChartValues}"
		           :options="options"
		           :min="0"/>
	</div>
</template>

<script lang="ts">
	import {Vue, Component, Prop} from 'vue-property-decorator';
	import {Line as LineChart} from 'vue-chartjs';
	import {
		Chart as ChartJS,
		Title,
		Tooltip,
		LineElement,
		LinearScale,
		CategoryScale,
		PointElement
	} from 'chart.js';
	import {IGrowthChartChartData} from "@/models/GrowthChartModel";

	ChartJS.register(Title, Tooltip, LineElement, CategoryScale, LinearScale, PointElement);

	@Component({components: {LineChart}})
	export default class UserGrowthLineChart extends Vue {

		@Prop({type: Array, required: true})
		public chartData!: IGrowthChartChartData[];

		private options: object = {
			responsive: true,
			maintainAspectRatio: true,
			aspectRatio: 3,
			locale: 'de-CH',
			scales: {
				y: {
					suggestedMin: 0,
					beginAtZero: true,
					ticks: {
						precision: 0,
					},
				},
			},
		};

		private get getChartLabels(): string[] {
			return this.chartData.map(c => c.label);
		}

		private get getChartValues() {
			return new Array({
				label: this.$t('ui.terms.amount'),
				backgroundColor: this.$vuetify.theme.currentTheme.primary === undefined
					? '#000'
					: this.$vuetify.theme.currentTheme.primary.toString(),
				data: this.chartData.map(c => c.count),
			});
		}
	}
</script>

<style scoped lang="scss">

</style>
