<template>
	<div>
		<AsiCard :title="$t('address.terms.shippingAddress')" unwrapped class="overflow-hidden">
			<template v-slot:actions>
				<AsiBtn :icon="icons.save" color="success" :disabled="!shippingChanged || !shippingValid" class="mr-3"
				        @click="performSaveShipping">
					{{ $t('ui.save') }}
				</AsiBtn>
				<AsiBtn :icon="icons.cancel" color="error" :disabled="!shippingChanged"
				        @click="initShipping">
					{{ $t('ui.cancel') }}
				</AsiBtn>
			</template>

			<AddressForm v-if="shippingAddress" v-model="shippingValid"
			             :model="shippingAddress" allow-type-selection hide-label hide-default-controls/>
		</AsiCard>

		<AsiCard :title="$t('address.terms.billingAddress')" unwrapped no-bottom-margin class="overflow-hidden">
			<template v-slot:headerActions>
				<AsiCheckbox v-model="sameBillingAddress" :label="$t('address.terms.sameAsShippingAddress')" class="ma-0 pa-0"/>
			</template>
			<template v-slot:actions v-if="!sameBillingAddress">
				<AsiBtn :icon="icons.save" color="success" :disabled="!billingChanged || !billingValid" class="mr-3"
				        @click="performSaveBilling">
					{{ $t('ui.save') }}
				</AsiBtn>
				<AsiBtn :icon="icons.cancel" color="error" :disabled="!billingChanged"
				        @click="initBilling">
					{{ $t('ui.cancel') }}
				</AsiBtn>
			</template>

			<v-card-text class="pa-0">
				<v-expand-transition>
					<AddressForm v-if="billingAddress" v-show="!sameBillingAddress" v-model="billingValid"
					             :model="billingAddress" allow-type-selection hide-label hide-default-controls/>
				</v-expand-transition>
			</v-card-text>
		</AsiCard>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import {ICartShopListEntry} from "@/models/cart/CartShopModels";
	import AddressForm from "@/components/address/AddressForm.vue";
	import AsiCard from "@/components/common/AsiCard.vue";
	import AsiCheckbox from "@/components/common/AsiCheckbox";
	import AddressUpdate from "@/models/address/AddressUpdate";
	import AddressCreate from "@/models/address/AddressCreate";
	import Icon from "@/plugins/icons";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Snackbar from "@/helpers/Snackbar";
	import AddressHelper from "@/helpers/AddressHelper";

	@Component({
		components: {AsiBtn, AsiCheckbox, AsiCard, AddressForm}
	})
	export default class CheckoutWizardStepAddressesGuest extends Vue {

		@Prop({type: Object, required: true})
		public cart!: ICartShopListEntry;

		@Prop({type: Boolean, required: true})
		public isActiveStep!: boolean;

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		private icons = Icon;
		private loadingInternal: boolean = false;
		private sameBillingAddress: boolean = true;
		private shippingValid: boolean = false;
		private shippingChanged: boolean = false;
		private shippingAddress: AddressUpdate | AddressCreate | null = null;
		private billingValid: boolean = false;
		private billingChanged: boolean = false;
		private billingAddress: AddressUpdate | AddressCreate | null = null;

		public created(): void {
			this.initShipping();
			this.initBilling();
			this.sameBillingAddress = AddressHelper.areIdentical(this.shippingAddress, this.billingAddress);
		}

		@Watch('cart', {deep: true})
		private onCartChanged(): void {
			this.initShipping();
			this.initBilling();
			this.sameBillingAddress = AddressHelper.areIdentical(this.shippingAddress, this.billingAddress);
		}

		@Watch('isActiveStep')
		private onIsActiveStepChanged(value: boolean): void {
		    if (!value) {
			    this.initShipping();
			    this.initBilling();
			    this.sameBillingAddress = AddressHelper.areIdentical(this.shippingAddress, this.billingAddress);
		    }
		}

		@Watch('loading', {immediate: true})
		private onLoadingChanged(value: boolean): void {
			this.loadingInternal = value;
		}

		@Watch('loadingInternal')
		@Emit('loadingChanged')
		private onLoadingInternalChanged(value: boolean): boolean {
			if (this.loading !== value) this.$emit('update:loading', value);
			return value;
		}

		@Watch('shippingAddress', {deep: true})
		private onShippingAddressChanged(): void {
			this.shippingChanged = !AddressHelper.areIdentical(this.cart.oneTimeShippingAddress, this.shippingAddress);
		}

		@Watch('billingAddress', {deep: true})
		private onBillingAddressChanged(): void {
			this.billingChanged = !AddressHelper.areIdentical(this.cart.oneTimeBillingAddress, this.billingAddress);
		}

		@Watch('sameBillingAddress')
		private onSameBillingAddressChanged(value: boolean): void {
			if (!value || this.shippingAddress === null || this.billingAddress === null || AddressHelper.areIdentical(this.shippingAddress, this.billingAddress)) return;
			this.performSaveBilling();
		}

		private initShipping(): void {
			this.shippingAddress = this.cart.oneTimeShippingAddress === null
				? new AddressCreate()
				: new AddressUpdate(this.cart.oneTimeShippingAddress);
			this.shippingChanged = false;
			this.shippingValid = true;
		}

		private initBilling(): void {
			this.billingAddress = this.cart.oneTimeBillingAddress === null
				? new AddressCreate()
				: new AddressUpdate(this.cart.oneTimeBillingAddress);
			this.billingChanged = false;
			this.billingValid = true;
		}

		private performSaveShipping(): void {
			this.loadingInternal = true;
			this.$store.dispatch('cart/updateShippingAddress', {
				cartId: this.cart.id,
				addressId: null,
				addressFields: this.shippingAddress,
			})
				.catch(() => Snackbar.updateError())
				.finally(() => this.loadingInternal = false);

			if (this.sameBillingAddress) {
				this.performSaveBilling();
			}
		}

		private performSaveBilling(): void {
			this.loadingInternal = true;
			this.$store.dispatch('cart/updateBillingAddress', {
				cartId: this.cart.id,
				addressId: null,
				addressFields: this.sameBillingAddress ? this.shippingAddress : this.billingAddress,
			})
				.catch(() => Snackbar.updateError())
				.finally(() => this.loadingInternal = false);
		}

	}
</script>

<style lang="scss" scoped>

</style>
