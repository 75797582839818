<template>
	<AsiDialogFormLayout v-if="model !== null" :icon="icons.user" :title="$t('user.terms.create')"
	                     :open="open" :loading="loading" :valid="valid && customerId !== null" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped scrollable>
		<v-card-text class="pa-0">
			<v-form v-model="valid">
				<div class="pa-6">
					<v-row>
						<v-col>
							<CustomerAutocompleteInput v-model="customerId"/>
						</v-col>
					</v-row>
				</div>
				<v-divider/>

				<div v-if="customerId !== null">
					<div class="pa-6">
						<v-row>
							<v-col>
								<AsiTextField v-model="model.username"
								              :label="$t('ui.terms.username')"
								              :placeholder="$t('ui.terms.username')"
								              :rules="model.rules('username')"
								              autocomplete="username"/>
							</v-col>
						</v-row>
					</div>
					<v-divider/>

					<div class="pa-6">
						<v-row>
							<SalutationInput v-model="model.salutation"/>
						</v-row>
						<v-row>
							<v-col :cols="sMobile ? 12 : 9/2">
								<AsiTextField v-model="model.firstname" :rules="model.rules('firstname')"
								              :label="$t('user.firstname')" :placeholder="$t('user.firstname')"/>
							</v-col>
							<v-col :cols="sMobile ? 12 : 9/2">
								<AsiTextField v-model="model.lastname" :rules="model.rules('lastname')"
								              :label="$t('user.lastname')" :placeholder="$t('user.lastname')"/>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<AsiTextField v-model="model.phone" :rules="model.rules('phone')"
								              :label="$t('user.phone')" :placeholder="$t('user.phone')"/>
							</v-col>
						</v-row>
					</div>
					<v-divider/>

					<div class="pa-6">
						<v-row>
							<v-col>
								<AsiLocaleAutocompleteInput v-model="model.locale" :rules="model.rules('locale')"
								                            :label="$t('user.locale')" :placeholder="$t('user.locale')"/>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<AsiTextField v-model="model.timeZone" :rules="model.rules('timeZone')"
								              :label="$t('user.timeZone')" :placeholder="$t('user.timeZone')"
								              disabled/>
							</v-col>
						</v-row>
					</div>
					<v-divider/>

					<div class="pa-6">
						<AsiAlert type="info" :icon="icons.info" no-bottom-margin>
							{{ $t('user.terms.createMailHint') }}
						</AsiAlert>
					</div>
				</div>
			</v-form>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop} from "vue-property-decorator";
	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
	import UserCreate from "@/models/user/UserCreate";
	import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
	import Snackbar from "@/helpers/Snackbar";
	import AsiTextField from "@/components/common/AsiTextField";
	import AsiLocaleAutocompleteInput from "@/components/common/AsiLocaleAutocompleteInput.vue";
	import AsiAlert from "@/components/common/AsiAlert.vue";
	import AsiSelectSimple from "@/components/common/AsiSelectSimple";
	import CustomerAutocompleteInput from "@/components/customer/admin/CustomerAutocompleteInput.vue";
	import StringHelper from "@/helpers/StringHelper";
	import AsiRadioGroup from "@/components/common/AsiRadioGroup";
	import SalutationInput from "@/components/common/SalutationInput.vue";

	@Component({
		components: {
			SalutationInput,
			AsiRadioGroup,
			CustomerAutocompleteInput,
			AsiSelectSimple,
			AsiAlert, AsiLocaleAutocompleteInput, AsiTextField, AsiDialogFormLayout, AsiDialogForm
		}
	})
	export default class UserCreateDialog extends AsiDialogForm {

		@Prop({type: String, default: null})
		public customerId!: string | null;

		private model: UserCreate = new UserCreate();

		@Emit('cancel')
		public cancel(event: Event): Event {
			const form = this.$refs.form as HTMLFormElement;
			this.reset([this.model], form);
			return event;
		}

		@Emit('save')
		public save(id: string): string {
			const form = this.$refs.form as HTMLFormElement;
			this.reset([], form);
			this.model = new UserCreate();
			return id;
		}

		private requestSave(): void {
			if (!this.valid || null === this.customerId || StringHelper.isEmpty(this.customerId)) return;

			this.loading = true;
			this.$customerServiceShop.createUser(this.customerId, this.model)
				.then(res => {
					this.$store.dispatch('user/loadUsers');
					this.save(res.id);
				})
				.catch(err => {
					let errorMessage = null;
					const errorData = err.response.data;
					if (Array.isArray(errorData)
						&& errorData.length > 0
						&& errorData[0].toLowerCase() !== 'unspecified error') {
						errorMessage = errorData.join(', ');
					}

					Snackbar.createError(errorMessage);
				})
				.finally(() => this.loading = false);
		}

	}
</script>

<style lang="scss" scoped>

</style>
