<template>
	<AsiChip :icon="icon" :color="color" small>
		{{ label }}
	</AsiChip>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import {IUserAdminBlamable} from "@/models/user/UserAdminModels";
	import {IUserShopBlamable} from "@/models/user/UserShopModels";
	import EnumHelper from "@/helpers/EnumHelper";
	import {UserState} from "@/helpers/constants";
	import UserHelper from "@/models/user/UserHelper";
	import AsiChip from "@/components/common/AsiChip.vue";

	@Component({
		components: {AsiChip}
	})
	export default class UserStateChip extends Vue {

		@Prop({type: Object, required: true})
		public user!: IUserAdminBlamable | IUserShopBlamable;

		// noinspection JSMethodCanBeStatic
		private get label(): string | null {
			return EnumHelper.textFromValue(UserState, this.user.state, true);
		}

		// noinspection JSMethodCanBeStatic
		private get icon(): string {
			return UserHelper.stateIcon(this.user);
		}

		// noinspection JSMethodCanBeStatic
		private get color(): string {
			return UserHelper.stateColor(this.user);
		}

	}
</script>

<style lang="scss" scoped>

</style>
