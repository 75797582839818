<template>
	<section>
		<template v-if="!readonly">
			<v-divider/>
			<div class="pa-6 text-center grey lighten-5">
				<AsiBtn :icon="icons.avatar" @click="uploadDialog.open()" color="secondary">
					{{ $t('ui.terms.uploadGalleryImages') }}
				</AsiBtn>
			</div>
		</template>

		<template v-if="item !== null">
			<v-divider/>
			<AsiContentContainer v-if="item.galleryAttachments.length > 0" :columns="2" :columns-lg="4" :columns-xl="8" with-row-gap>
				<div v-for="image in imagesSorted" :key="image.id">
					<AsiCard unwrapped no-bottom-margin class="overflow-hidden">
						<v-card-text class="card-content pa-0">
							<div class="pa-3">
								<v-img :src="imageUrl(image)" aspect-ratio="1" contain/>
							</div>
							<v-divider/>

							<div v-if="isAvatar(image)" class="avatar-overlay text-center px-6 py-3">
								<AsiChip label x-small color="primary elevation-1">
									{{ $t('item.terms.avatar') }}
								</AsiChip>
							</div>
						</v-card-text>

						<template v-slot:actions>
							<AsiBtn :icon="icons.preview"
							        @click="previewAttachment = image; previewDialog.open()"/>
							<AsiBtn @click="downloadAttachment(image)" :icon="icons.download"/>
							<AsiBtn v-if="!readonly" @click="performSetAvatar(image)"
							        :icon="item.avatarAttachmentId === image.id ? icons.favorite : icons.noFavorite"/>
							<AsiBtn v-if="!readonly" @click="performDelete(image)"
							        :icon="icons.delete"/>
						</template>
					</AsiCard>
				</div>
			</AsiContentContainer>
			<div v-else class="pa-6 text-center grey--text">
				{{ $t('ui.terms.noImages') }}
			</div>
		</template>

		<AsiConfirmDialog ref="confirm"/>
		<ImageGalleryUploadDialog v-if="!readonly && uploadDialog.isLoaded" :open="uploadDialog.isOpen"
		                          :model="item" :service="$itemServiceAdmin"
		                          @cancel="uploadDialogCancelled"/>
		<AttachmentPreviewDialog v-if="previewAttachment !== null && previewDialog.isLoaded" :open="previewDialog.isOpen"
		                         :attachment="previewAttachment" @cancel="previewDialog.close()"/>
	</section>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import Icon from "@/plugins/icons";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import {AttachmentMediaSize} from "@/helpers/constants";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import {IAttachment} from "@/models/attachment/AttachmentModels";
	import AttachmentHelper from "@/models/attachment/AttachmentHelper";
	import ImageGalleryUploadDialog from "@/components/common/ImageGalleryUploadDialog.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import AsiCard from "@/components/common/AsiCard.vue";
	import AttachmentPreviewDialog from "@/components/attachment/AttachmentPreviewDialog.vue";
	import AsiChip from "@/components/common/AsiChip.vue";
	import Snackbar from "@/helpers/Snackbar";
	import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";
	import AsiConfirmDialogDefinition from "@/helpers/AsiConfirmDialogDefinition";
	import {IItemAdmin} from "@/models/item/ItemAdminModels";

	@Component({
		components: {
			AsiConfirmDialog,
			AsiChip, AttachmentPreviewDialog, AsiCard, ImageGalleryUploadDialog, AsiContentContainer, AsiBtn
		}
	})
	export default class ItemTabImages extends Vue {

		@Prop({required: true})
		public item!: IItemAdmin | null;

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		private icons = Icon;
		private loading: boolean = false;
		private uploadDialog: DialogHandler = new DialogHandler();
		private previewDialog: DialogHandler = new DialogHandler(() => this.previewAttachment = null);
		private previewAttachment: IAttachment | null = null;

		private get imagesSorted(): IAttachment[] {
			return this.item === null
				? []
				: this.item.galleryAttachments.sort((a: IAttachment, b: IAttachment) => {
					if (this.isAvatar(a)) return -1;
					if (this.isAvatar(b)) return 1;
					return a.filename.localeCompare(b.filename);
				});
		}

		@Emit('change')
		public change(): void {
			return;
		}

		// noinspection JSMethodCanBeStatic
		private imageUrl(image: IAttachment): string | null {
			return AttachmentHelper.getImageUrl(image, AttachmentMediaSize.m);
		}

		private isAvatar(image: IAttachment): boolean {
			return this.item?.avatarAttachmentId === image.id;
		}

		private uploadDialogCancelled(numSuccessful: number): void {
			this.uploadDialog.close();
			if (numSuccessful > 0) this.change();
		}

		private performSetAvatar(image: IAttachment): void {
			if (this.item === null) return;

			this.loading = true;
			this.$itemServiceAdmin.setGalleryImageAvatar(this.item.id, this.isAvatar(image) ? null : image.id)
				.then(() => this.change())
				.catch(() => Snackbar.updateError())
				.finally(() => this.loading = false);
		}

		private performDelete(image: IAttachment): void {
			if (this.item === null) return;

			const confirm = this.$refs.confirm as unknown as AsiConfirmDialogDefinition;
			confirm.openDialog().then((res: boolean) => {
				if (!res || this.item === null) return;

				this.loading = true;
				this.$itemServiceAdmin.deleteGalleryImage(this.item.id, image.id)
					.then(() => this.change())
					.catch(() => Snackbar.deleteError())
					.finally(() => this.loading = false);
			});
		}

		private downloadAttachment(attachment: IAttachment): void {
			AttachmentHelper.download(attachment);
		}

	}
</script>

<style lang="scss" scoped>
	.card-content {
		position: relative;

		.avatar-overlay {
			position: absolute;
			bottom: -1.27rem;
			width: 100%;
			left: 0;
		}
	}
</style>
