<template>
	<AsiContentContainer :columns="3" :columns-lg="4" :columns-xl="5" with-row-gap small-row-gap small-column-gap no-margin>
		<slot/>
	</AsiContentContainer>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component} from "vue-property-decorator";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	@Component({
		components: {AsiContentContainer}
	})
	export default class AsiListTableAdvancedFilters extends Vue {

	}
</script>

<style lang="scss" scoped>

</style>
