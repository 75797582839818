<template>
	<div>
		<div class="grey lighten-4">
			<DashboardGrowthFilter @update="updateFilter"/>
		</div>
		<AsiContentContainer no-bottom-margin>
			<v-row>
				<v-col lg="6" md="12">
					<AsiCardDetail v-if="userGrowth !== null" :title="$t('ui.terms.userGrowth')" :icon="icons.chartLineUp" highlighted
					               :loading="loadingUserGrowth" unwrapped>
						<GrowthLineChart v-if="userGrowthChartData.length > 0" :chart-data="userGrowthChartData"/>

						<AsiCardDetailEntry :label="$t('ui.terms.totalAmount')" :icon="icons.user" :value="userGrowth.total"/>
					</AsiCardDetail>
				</v-col>
				<v-col lg="6" md="12">
					<AsiCardDetail v-if="customerGrowth !== null" :title="$t('ui.terms.customerGrowth')" :icon="icons.chartLineUp" highlighted
					               :loading="loadingCustomerGrowth" unwrapped>
						<GrowthLineChart v-if="customerGrowthChartData.length > 0" :chart-data="customerGrowthChartData"/>

						<AsiCardDetailEntry :label="$t('ui.terms.totalAmount')" :icon="icons.customer" :value="customerGrowth.total"/>
					</AsiCardDetail>
				</v-col>
			</v-row>
		</AsiContentContainer>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Watch} from "vue-property-decorator";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import GrowthLineChart from "@/components/dashboard/admin/GrowthLineChart.vue";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import Icons from "@/plugins/icons";
	import Snackbar from "@/helpers/Snackbar";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import AsiDatePickerCombined from "@/components/common/AsiDatePickerCombined.vue";
	import DashboardGrowthFilter from "@/components/dashboard/admin/DashboardGrowthFilter.vue";
	import {IGrowthChart, IGrowthChartChartData} from "@/models/GrowthChartModel";

	@Component({
		components: {
			DashboardGrowthFilter,
			AsiDatePickerCombined,
			AsiBtn, AsiCardDetailEntry, AsiContentContainer, GrowthLineChart, AsiCardDetail
		}
	})
	export default class DashboardGrowth extends Vue {
		private icons = Icons;

		private loadingUserGrowth: boolean = false;
		private userGrowth: IGrowthChart | null = null;
		private userGrowthChartData: IGrowthChartChartData[] = [];
		private loadingCustomerGrowth: boolean = false;
		private customerGrowth: IGrowthChart | null = null;
		private customerGrowthChartData: IGrowthChartChartData[] = [];

		private dateRangeFilter: string[] = [];

		private loadUserGrowth(): void {
			const startDate = new Date(this.dateRangeFilter[0]);
			const endDate = new Date(this.dateRangeFilter[1]);

			this.loadingUserGrowth = true;
			this.$userServiceAdmin.userGrowth(startDate.toISOString(), endDate.toISOString())
				.then(data => this.userGrowth = data)
				.catch(() => Snackbar.loadingError())
				.finally(() => this.loadingUserGrowth = false);
		}

		private loadCustomerGrowth(): void {
			const startDate = new Date(this.dateRangeFilter[0]);
			const endDate = new Date(this.dateRangeFilter[1]);

			this.loadingCustomerGrowth = true;
			this.$customerServiceAdmin.customerGrowth(startDate.toISOString(), endDate.toISOString())
				.then(data => this.customerGrowth = data)
				.catch(() => Snackbar.loadingError())
				.finally(() => this.loadingCustomerGrowth = false);
		}

		private updateFilter(range: string[]): void {
			this.dateRangeFilter = range;
		}

		private prepareChartData(dates: Date[]): IGrowthChartChartData[] {
			let newestDate = new Date(this.dateRangeFilter[0]);
			let oldestDate = new Date(this.dateRangeFilter[1]);

			if (this.monthsBetween(newestDate, oldestDate) > 1) {
				// monthly count
				return this.countDatesByMonth(dates, newestDate, oldestDate);
			} else {
				// daily count
				return this.countDatesByDay(dates, newestDate, oldestDate);
			}
		}

		private monthsBetween(date1: Date, date2: Date): number {
			if (date1 > date2) {
				let temp = date1;
				date1 = date2;
				date2 = temp;
			}

			let yearsDiff = date2.getFullYear() - date1.getFullYear();
			let monthsDiff = date2.getMonth() - date1.getMonth();

			return yearsDiff * 12 + monthsDiff;
		}

		private countDatesByMonth(dates: Date[], startDate: Date, endDate: Date): IGrowthChartChartData[] {
			const monthCounts: Record<string, number> = {};

			dates
				.map(d => new Date(d))
				.forEach(date => {
					const year = date.getFullYear();
					const month = String(date.getMonth() + 1).padStart(2, '0');
					const label = `${year}-${month}`;

					if (monthCounts[label]) {
						monthCounts[label] += 1;
					} else {
						monthCounts[label] = 1;
					}
				});

			const result: IGrowthChartChartData[] = [];
			let currentDate = new Date(startDate.getTime());

			while (currentDate <= endDate) {
				const year = currentDate.getFullYear();
				const month = String(currentDate.getMonth() + 1).padStart(2, '0');
				const label = `${year}-${month}`;

				result.push({
					label,
					count: monthCounts[label] || 0,
				});

				currentDate.setMonth(currentDate.getMonth() + 1);
			}

			return result;
		}

		private countDatesByDay(dates: Date[], startDate: Date, endDate: Date): IGrowthChartChartData[] {
			const dayCounts: Record<string, number> = {};

			dates
				.map(d => new Date(d))
				.forEach(date => {
					const day = String(date.getDate()).padStart(2, '0');
					const month = String(date.getMonth() + 1).padStart(2, '0');
					const label = `${day}.${month}`;

					if (dayCounts[label]) {
						dayCounts[label] += 1;
					} else {
						dayCounts[label] = 1;
					}
				});

			const result: IGrowthChartChartData[] = [];
			let currentDate = new Date(startDate.getTime());

			while (currentDate <= endDate) {
				const day = String(currentDate.getDate()).padStart(2, '0');
				const month = String(currentDate.getMonth() + 1).padStart(2, '0');
				const label = `${day}.${month}`;

				result.push({
					label,
					count: dayCounts[label] || 0,
				});

				currentDate.setDate(currentDate.getDate() + 1);
			}

			return result;
		}

		@Watch('dateRangeFilter')
		private onDateRangeFilterChanged(): void {
			this.loadUserGrowth();
			this.loadCustomerGrowth();
		}

		@Watch('userGrowth')
		private onUserGrowthChanged(value: IGrowthChart): void {
			this.userGrowthChartData = this.prepareChartData(value.growth);
		}

		@Watch('customerGrowth')
		private onCustomerGrowthChanged(value: IGrowthChart): void {
			this.customerGrowthChartData = this.prepareChartData(value.growth);
		}
	}
</script>

<style lang="scss" scoped>

</style>
