<template>
	<ShopView :path="path" :full-width="sSemiMobile">
		<div class="top d-flex flex-row mb-6" :class="{'item-header-desktop': !sMobile}">
			<ItemCardBasicData :item="item"/>
		</div>
		<ItemCardFrameAssembly v-if="item !== null && item.hasFrameAssembly" :item="item"/>
		<ItemCardData v-if="item !== null" :item="item"/>
	</ShopView>
</template>

<script lang="ts">
	import {Component, Prop, Watch} from "vue-property-decorator";
	import AsiBreadcrumbs from "@/components/common/AsiBreadcrumbs.vue";
	import {IItemShop} from "@/models/item/ItemShopModels";
	import Snackbar from "@/helpers/Snackbar";
	import PathEntry from "@/models/PathEntry";
	import ItemHelper from "@/models/item/ItemHelper";
	import PathHelper from "@/helpers/PathHelper";
	import ItemCardBasicData from "@/components/item/shop/ItemCardBasicData.vue";
	import ItemCardData from "@/components/item/shop/ItemCardData.vue";
	import ShopView from "@/components/layout/ShopView.vue";
	import CategoryTree from "@/components/category/shop/CategoryTree.vue";
	import {mixins} from "vue-class-component";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
	import ItemCardFrameAssembly from "@/components/item/shop/ItemCardFrameAssembly.vue";
	import Gtm, {itemShopListEntryToGtmItem} from "@/plugins/gtm";
	import {AxiosError} from "axios";
	import router from "@/router";
	import RoutesHelper from "@/helpers/RoutesHelper";

	@Component({
		components: {
			ItemCardFrameAssembly,
			CategoryTree,
			ShopView,
			ItemCardData,
			ItemCardBasicData,
			AsiBreadcrumbs
		}
	})
	export default class Item extends mixins(ResponsiveChecks) {

		@Prop({type: String, required: true})
		public itemNumber!: string;

		private loading: boolean = false;
		private item: IItemShop | null = null;

		private get path(): PathEntry[] {
			return this.item === null ? [] : PathHelper.prependHomeShop(ItemHelper.path(this.item));
		}

		private get galleryDetailSize(): string {
			return this.sMobile ? '5rem' : '20rem';
		}

		@Watch('itemNumber', {immediate: true})
		private onIdChanged(): void {
			this.loadItem()
				.then(() => {
					if (this.item === null) {
						return;
					}

					Gtm.viewItem({
						currency: this.item.price?.price.currency.currencyCode ?? 'CHF',
						value: this.item.price?.price.amount ?? 0,
						items: [
							itemShopListEntryToGtmItem(this.item)
						]
					});
				});
		}

		private loadItem(): Promise<void> {
			this.loading = true;

			return this.$itemServiceShop.item(this.itemNumber)
				.then(model => {
					this.item = model;
					const rootCategoryId = model.category.path.length === 0 ? model.category.id : model.category.path[0].id;
					this.$store.commit('category/setActiveRootCategory', rootCategoryId);
				})
				.catch((err: AxiosError) => {
					if (err?.response?.status === 404) {
						router.push(RoutesHelper.notFoundRoute()).then(() => {
							router.go(0); /* refresh is needed for 404 status code (nginx.conf) */
						});
					} else {
						Snackbar.loadingError();
					}
				})
				.finally(() => this.loading = false);
		}

	}
</script>

<style lang="scss" scoped>
	.item-header-desktop {
		min-width: 1300px;
	}
</style>
