<template>
	<v-skeleton-loader :boilerplate="!loading" :elevation="2"
		type="list-item-avatar, divider, list-item-three-line"/>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";

	@Component
	export default class AsiSkeletonCard extends Vue {

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

	}
</script>

<style lang="scss" scoped>

</style>
