<template>
	<AsiSkeletonCards v-if="order === null" :num="1" :columns="2" loading/>

	<div v-else>
		<template v-if="showPaidButton">
			<v-divider/>
			<div class="pa-6 text-center grey lighten-5">
				<AsiBtn :icon="icons.paid" color="success" @click="setOrderState">
					{{ $t('order.terms.paid') }}
				</AsiBtn>
			</div>
		</template>
		<AsiContentContainer :columns="2">
			<div>
				<OrderCardBasicData :order="order"/>
			</div>
			<div>
				<OrderCardExport :order="order"/>
			</div>
			<div  v-if="order.oneTimeEmail">
				<OrderCardContactInformation :order="order"/>
			</div>
			<div>
				<OrderCardPayment :order="order"/>
			</div>
			<div>
				<OrderCardShipment :order="order"/>
			</div>
		</AsiContentContainer>

		<v-divider class="mt-n6"/>

		<AsiContentContainer :columns="1">
			<div>
				<OrderCardPositions :order="order"/>
			</div>
		</AsiContentContainer>

		<AsiConfirmDialog ref="confirm"/>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from 'vue-property-decorator';
	import {IOrderAdmin} from "@/models/order/OrderAdminModels";
	import OrderCardPayment from "@/components/order/admin/OrderCardPayment.vue";
	import AsiSkeletonCards from "@/components/common/AsiSkeletonCards.vue";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import OrderCardBasicData from "@/components/order/admin/OrderCardBasicData.vue";
	import OrderCardExport from "@/components/order/admin/OrderCardExport.vue";
	import OrderCardShipment from "@/components/order/admin/OrderCardShipment.vue";
	import OrderCardPositions from "@/components/order/admin/OrderCardPositions.vue";
	import Icon from "@/plugins/icons";
	import {OrderPaymentState} from "@/helpers/constants";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";
	import Snackbar from "@/helpers/Snackbar";
	import OrderCardContactInformation from "@/components/order/admin/OrderCardContactInformation.vue";

	@Component({
		components: {
			OrderCardContactInformation,
			AsiConfirmDialog,
			AsiBtn,
			OrderCardPositions,
			OrderCardShipment,
			OrderCardExport, OrderCardBasicData, AsiContentContainer, AsiSkeletonCards, OrderCardPayment
		}
	})
	export default class OrderTabDetails extends Vue {

		@Prop({required: true})
		public order!: IOrderAdmin | null;

		private icons = Icon;
		private loading: boolean = false;

		private get showPaidButton(): boolean {
			return this.order?.paymentType.paymentProviderCode === null && this.order.paymentState === OrderPaymentState.orderPaymentUnpaid;
		}

		private setOrderState(): void {
			const confirm = this.$refs.confirm as unknown as AsiConfirmDialog;
			confirm.openDialog().then((res: boolean) => {
				if (!res) return;
				this.loading = true;
				if (this.order !== null) {
					this.$orderServiceAdmin.paid(this.order.id)
						.then(() => this.change())
						.catch(() => Snackbar.deleteError())
						.finally(() => this.loading = false);
				}
			});
		}

		@Emit('change')
		public change(): void {
			return;
		}

	}
</script>

<style lang="scss" scoped>

</style>
