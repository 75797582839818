<template>
	<AsiCardDetail :icon="icons.shipping" :title="$t('item.terms.salesData')"
	               :show-action-button="!readonly" :action-button-disabled="disabled" @actionButtonClicked="updateDialog.open()">
		<AsiCardDetailEntry :icon="icons.stock" :label="$t('item.stockQuantity')" :value="item.stockQuantity"/>
		<AsiCardDetailEntry :icon="icons.favorite" :label="$t('item.isTopSeller')" :value="item.isTopSeller"/>
		<AsiCardDetailEntry :icon="icons.baseUnitOfMeasure" :label="$t('item.baseUnitOfMeasure')" :value="unitName(item.baseUnitOfMeasure)"/>
		<AsiCardDetailEntry :icon="icons.salesUnitOfMeasure" :label="$t('item.salesUnitOfMeasure')" :value="unitName(item.salesUnitOfMeasure)"/>
		<AsiCardDetailEntry :icon="icons.salesUnitOfMeasure" :label="$t('item.salesUnitQuantity')" :value="item.salesUnitQuantity"/>
		<AsiCardDetailEntry :icon="icons.canOrderSample" :label="$t('item.canOrderSample')" :value="item.canOrderSample"/>
		<AsiCardDetailEntry :icon="icons.frameAssembly" :label="$t('item.hasFrameAssembly')" :value="item.hasFrameAssembly"/>
		<AsiCardDetailEntry :icon="icons.deliveryTime" :label="$t('item.deliveryTime')" :value="item.deliveryTime"/>
		<AsiCardDetailEntry :icon="icons.trimmingCostThreshold" :label="$t('item.isTrimmingCostCharged')" :value="item.isTrimmingCostCharged"/>
		<AsiCardDetailEntry :icon="icons.isSalesUnitFixed" :label="$t('item.isSalesUnitFixed')" :value="item.isSalesUnitFixed"/>
		<AsiCardDetailEntry :icon="icons.bulkyGoodsSurcharge" :label="$t('item.isBulkyGoodsCharged')" :value="item.isBulkyGoodsCharged"/>

		<ItemUpdateDialogSalesData v-if="!readonly && updateDialog.isLoaded" :open="updateDialog.isOpen" :item="item"
		                           @cancel="updateDialog.close()" @save="updateDialog.close(); change();"/>
	</AsiCardDetail>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import Icon from "../../../plugins/icons";
	import DialogHandler from "../../common/DialogHandler";
	import {IItemAdmin} from "@/models/item/ItemAdminModels";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import AsiCardDetailEntryTranslatedValue from "@/components/common/AsiCardDetailEntryTranslatedValue.vue";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import EnumHelper from "@/helpers/EnumHelper";
	import {UnitOfMeasure} from "@/helpers/constants";
	import ItemUpdateDialogSalesData from "@/components/item/admin/ItemUpdateDialogSalesData.vue";
	import AsiChip from "@/components/common/AsiChip.vue";

	@Component({
		components: {
			AsiChip,
			ItemUpdateDialogSalesData, AsiCardDetailEntry, AsiCardDetailEntryTranslatedValue, AsiCardDetail
		}
	})
	export default class ItemCardSalesData extends Vue {

		@Prop({type: Object, required: true})
		public item!: IItemAdmin;

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		private icons = Icon;
		private updateDialog: DialogHandler = new DialogHandler();

		private unitName(unit: string): string | null {
			return EnumHelper.textFromValue(UnitOfMeasure, unit, true);
		}

		@Emit('change')
		public change(): void {
			return;
		}

	}
</script>

<style lang="scss" scoped>

</style>
