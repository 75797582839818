<template>
	<v-card class="pa-0"
	        @mouseover="displayVideoControls = true"
	        @mouseleave="!$refs.video.paused ? displayVideoControls = false : null">
		<video ref="video" :poster="thumbnailSrc" :autoplay="autoplay">
			<source :src="source" type="video/mp4">
		</video>
		<div v-if="displayVideoControls" class="video-controls">
			<v-btn class="video-button" fab @click="playPause()">
				<v-icon>{{ videoIcon }}</v-icon>
			</v-btn>
		</div>
	</v-card>

</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop, Watch} from "vue-property-decorator";
	import Icon from "@/plugins/icons";

	@Component
	export default class VideoCard extends Vue {

		@Prop({type: Boolean, default: false})
		public autoplay!: boolean;

		@Prop({type: String, required: true})
		public source!: string;

		@Prop({type: String, default: ''})
		public thumbnailSrc!: string;

		@Prop({type: Boolean, default: false})
		public stopVideo!: boolean;

		private icons = Icon;

		private videoIcon = this.icons.videoPlay;
		private displayVideoControls = true;

		public mounted(): void {
			if (this.autoplay) {
				const video = this.$refs.video as HTMLVideoElement;
				this.playVideo(video);
			}
		}

		private playPause(): void {
			const video = this.$refs.video as HTMLVideoElement;
			if (video.paused) {
				this.playVideo(video);
			} else {
				this.pauseVideo(video);
			}
		}

		private playVideo(video: HTMLVideoElement): void {
			var promise = video.play();
			if (promise !== undefined) {
				promise.then(() => {
					this.videoIcon = this.icons.videoPause;
					this.displayVideoControls = false;
				});
			}
		}

		private pauseVideo(video: HTMLVideoElement): void {
			video.pause();
			this.videoIcon = this.icons.videoPlay;
			this.displayVideoControls = true;
		}

		@Watch('source')
		private onSourceChanged(): void {
		    const video = this.$refs.video as HTMLVideoElement;
			video.load();
		}

		@Watch('stopVideo')
		private onStopVideoChanged(): void {
			const video = this.$refs.video as HTMLVideoElement;
			if (!video.paused) {
				this.pauseVideo(video);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.video-controls {
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		display: flex;
		justify-content: center;
		align-items: center;
	}

	video {
		width: 100%    !important;
		height: auto   !important;
		display: block;
	}
</style>
