<template>
	<div>
		<v-toolbar :dense="dense" :color="color" flat tile :dark="dark">
			<slot name="prependButton" v-if="hasPrependButton"/>
			<v-toolbar-title v-if="icon !== null || title !== null" class="d-flex flex-row flex-wrap align-center">
				<AsiAvatar v-if="icon && !sMobile" :class="{'mr-3': title}" :icon="icon" :size="iconSize"
				           :image-url="avatarUrl" :bg-color="avatarBgColor" :icon-color="avatarIconColor"/>
				<div v-if="title !== null || subtitle !== null || hasSubtitle" class="d-flex flex-column">
					<div v-if="title !== null">
						{{ title }}
					</div>
					<div v-if="subtitle !== null || hasSubtitle" class="caption mt-n1">
						<span v-if="!hasSubtitle">{{ subtitle }}</span>
						<slot v-else name="subtitle"/>
					</div>
				</div>
			</v-toolbar-title>
			<slot/>
			<v-spacer v-if="hasContentRight || showExpanderToggleButton"/>
			<slot v-if="hasContentRight && !mobileMode" name="content-right"/>
			<span>
				<AsiBtn v-if="hasContentExpander && showExpanderToggleButton" :class="{flipped: expanded}" :color="expanded ? 'error' : null" :icon="icons.dropdown"
				        class="ml-3" @click="expanded = !expanded"/>
			</span>

			<AsiFadeTransition v-if="loading">
				<v-progress-linear color="primary" indeterminate/>
			</AsiFadeTransition>
		</v-toolbar>

		<div v-if="hasContentRight && mobileMode" class="px-4 pb-2 d-flex flex-row flex-wrap justify-start align-center grey lighten-3">
			<slot name="content-right"/>
		</div>

		<AsiFadeTransition v-if="hasContentExpander">
			<div v-if="expanded">
				<v-divider/>
				<v-card color="grey lighten-4" flat tile>
					<v-card-text>
						<slot name="content-expander"/>
					</v-card-text>
				</v-card>
			</div>
		</AsiFadeTransition>

		<v-divider/>
	</div>
</template>

<script lang="ts">
	import {Component, Prop} from "vue-property-decorator";
	import Icon from "@/plugins/icons";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import AsiFadeTransition from "@/components/common/AsiFadeTransition.vue";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";
	import {mixins} from "vue-class-component";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";

	@Component({
		components: {AsiAvatar, AsiFadeTransition, AsiBtn}
	})
	export default class AsiToolbar extends mixins(ResponsiveChecks) {

		@Prop({type: String, default: null})
		public title!: string | null;

		@Prop({type: String, default: null})
		public subtitle!: string | null;

		@Prop({type: String, default: null})
		public icon!: string | null;

		@Prop({type: Number, default: 40})
		public iconSize!: number;

		@Prop({type: String, default: null})
		public avatarUrl!: string | null;

		@Prop({type: String, default: null})
		public avatarIconColor!: string | null;

		@Prop({type: String, default: 'transparent'})
		public avatarBgColor!: string | null;

		@Prop({type: Boolean, default: false})
		public showExpanderToggleButton!: boolean;

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		@Prop({type: Boolean, default: false})
		public dense!: boolean;

		@Prop({type: String, default: 'grey lighten-3'})
		public color!: string;

		@Prop({type: Boolean, default: false})
		public dark!: boolean;

		protected expanded: boolean = false;
		private icons = Icon;

		private get mobileMode(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		}

		private get hasPrependButton(): boolean {
			return !!this.$slots['prependButton'];
		}

		private get hasContentRight(): boolean {
			return !!this.$slots['content-right'];
		}

		private get hasContentExpander(): boolean {
			return !!this.$slots['content-expander'];
		}

		private get hasSubtitle(): boolean {
			return !!this.$slots['subtitle'] || !!this.$scopedSlots['subtitle'];
		}

	}
</script>

<style lang="scss" scoped>
	.flipped {
		transform: rotate(180deg);
	}

	.v-progress-linear {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	}
</style>
