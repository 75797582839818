import { render, staticRenderFns } from "./ItemQuantitySelection.vue?vue&type=template&id=f1a49422&scoped=true"
import script from "./ItemQuantitySelection.vue?vue&type=script&lang=ts"
export * from "./ItemQuantitySelection.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1a49422",
  null
  
)

export default component.exports