<template>
	<div class="wrapper d-flex flex-row justify-center align-center overflow-x-auto">
		<div v-for="category in rootCategories" :key="category.id" class="button-wrapper">
			<router-link :to="categoryDetailRoute(category)" :title="categoryNameTranslated(category)"
			             :class="['v-btn', 'v-btn--text', 'px-4', {'active': category.id === activeRootCategory}]"
			             @click.native.stop="showCategory(category)"
			             style="min-height: 4rem; text-decoration: inherit; color: inherit;">
				<small>{{ categoryNameTranslated(category) }}</small>
			</router-link>

			<div class="active-bar"
			     :class="{active: category.id === activeRootCategory}"
			     :style="{'background-color': categoryColor(category)}"/>
		</div>

	</div>
</template>

<script lang="ts">
	import {Component} from "vue-property-decorator";
	import {ICategoryShopListEntry} from "@/models/category/CategoryShopModels";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import CategoryHelper from "@/models/category/CategoryHelper";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import CategoryCard from "@/components/category/shop/CategoryCard.vue";
	import {mixins} from "vue-class-component";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
	import {RawLocation} from "vue-router";

	@Component({
		components: {CategoryCard, AsiBtn}
	})
	export default class RootCategoryNavigation extends mixins(ResponsiveChecks) {

		private get rootCategories(): ICategoryShopListEntry[] {
			return this.$store.getters['category/rootCategories'];
		}

		private get activeRootCategory(): string {
			return this.$store.state.category.activeRootCategory;
		}

		private categoryColor(category: ICategoryShopListEntry): string {
			return CategoryHelper.colorHierarchical(category) ?? 'primary';
		}

		private categoryNameTranslated(category: ICategoryShopListEntry): string | null {
			return TranslatedValueHelper.get(category.name, this.$i18n.locale);
		}

		private categoryDetailRoute(category: ICategoryShopListEntry): RawLocation {
			return CategoryHelper.detailRoute(CategoryHelper.currentCanonical(category.canonical));
		}

		private showCategory(category: ICategoryShopListEntry): void {
			this.$store.commit('category/setActiveRootCategory', category.id);
			if (this.$router.currentRoute.params.id === category.id) return;
			this.$router.push(this.categoryDetailRoute(category));
		}
	}
</script>

<style lang="scss" scoped>
	@import "src/scss/global";

	.wrapper {
		@include asiScrollbar();

		.button-wrapper {
			position: relative;

			button,
			a {
				position: relative;

				&:hover {
					background-color: var(--v-default-lighten3);
				}
			}

			.active-bar {
				position: absolute;
				height: 0.25rem;
				width: 0;
				bottom: 0;
				left: 50%;
				//background-color: var(--v-primary-base);
				transition: width 0.3s ease-out, left 0.3s ease-out;

				&.active {
					width: 100%;
					left: 0;
				}
			}
		}
	}
</style>
