<template>
	<AsiCardDetail :icon="icons.description" :title="$t('ui.terms.description')"
	               :show-action-button="!readonly" :action-button-disabled="disabled" @actionButtonClicked="updateDialog.open()">
		<AsiCardDetailEntryTranslatedValue :icon="icons.description" :label="$t('item.description')" :value="item.description"/>
		<AsiCardDetailEntryTranslatedValue :icon="icons.description" :label="$t('item.description2')" :value="item.description2"/>
		<AsiCardDetailEntryTranslatedValue :icon="icons.description" :label="$t('item.description3')" :value="item.description3"/>
		<AsiCardDetailEntryTranslatedValue :icon="icons.description" :label="$t('item.description4')" :value="item.description4"/>
		<AsiCardDetailEntryTranslatedValue :icon="icons.description" :label="$t('item.description5')" :value="item.description5"/>

		<ItemUpdateDialogDescription v-if="!readonly && updateDialog.isLoaded" :open="updateDialog.isOpen" :item="item"
		                           @cancel="updateDialog.close()" @save="updateDialog.close(); change();"/>
	</AsiCardDetail>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import Icon from "../../../plugins/icons";
	import DialogHandler from "../../common/DialogHandler";
	import {IItemAdmin} from "@/models/item/ItemAdminModels";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import AsiCardDetailEntryTranslatedValue from "@/components/common/AsiCardDetailEntryTranslatedValue.vue";
	import ItemUpdateDialogDescription from "@/components/item/admin/ItemUpdateDialogDescription.vue";
	@Component({
		components: {ItemUpdateDialogDescription, AsiCardDetailEntryTranslatedValue, AsiCardDetail}
	})
	export default class ItemCardDescription extends Vue {

		@Prop({type: Object, required: true})
		public item!: IItemAdmin;

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		private icons = Icon;
		private updateDialog: DialogHandler = new DialogHandler();

		@Emit('change')
		public change(): void {
			return;
		}

	}
</script>

<style lang="scss" scoped>

</style>
