<template>
	<v-card :class="{'mb-6': !noBottomMargin}" :disabled="loading" :flat="flat" :loading="loadingValue" :tile="tile" :ripple="false"
	        v-on="hasClickListener ? {click: click} : {}">
		<template v-slot:progress>
			<v-progress-linear v-if="loading"
			                   :color="loaderColor === null ? 'primary' : loaderColor"
			                   :indeterminate="loaderProgress === null"
			                   :value="loaderProgress !== null ? loaderProgress : undefined"
			                   height="3px" class="rounded-t" absolute top/>
		</template>
		<div class="pa-0 mt-0" v-if="hasTopContent">
			<slot name="top-content"/>
		</div>

		<div>
			<v-list-item v-if="title || $slots.title || subtitle || $slots.subtitle || icon" :class="titleClasses" :color="titleBg" :dense="titleDense">
				<v-list-item-avatar v-if="icon || imageUrl" :color="iconBg" :size="avatarSize">
					<AsiAvatar :bg-color="iconBgColor" :icon="icon" :icon-color="iconColor" :image-url="imageUrl" :size="avatarSize"/>
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title v-if="!$slots.title" :class="{'white--text': highlighted, 'text-wrap': wrapTitle}" class="headline">
						{{ title }}
					</v-list-item-title>
					<v-list-item-title v-if="$slots.title">
						<slot name="title"/>
					</v-list-item-title>
					<v-list-item-subtitle v-if="!$slots.subtitle && subtitle" :class="{'white--text': highlighted, 'text-wrap': wrapSubtitle}">
						{{ subtitle }}
					</v-list-item-subtitle>
					<v-list-item-subtitle v-if="$slots.subtitle">
						<slot name="subtitle"/>
					</v-list-item-subtitle>
				</v-list-item-content>
				<v-list-item-action v-if="$slots.headerActions">
					<slot name="headerActions"/>
				</v-list-item-action>
			</v-list-item>
		</div>

		<v-card-text v-if="hasContent && !unwrapped" class="pa-3 py-0">
			<slot/>
		</v-card-text>
		<slot v-else-if="hasContent"/>

		<v-card-actions v-if="$slots.actions" :class="['px-4', 'justify-center', 'action-container', {'mobile': sMobile}, actionBgColor]">
			<slot name="actions"/>
		</v-card-actions>
	</v-card>
</template>

<script lang="ts">
	import {Component, Emit, Prop} from "vue-property-decorator";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";
	import VueI18n from "vue-i18n";
	import TranslateResult = VueI18n.TranslateResult;
	import {mixins} from "vue-class-component";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";

	@Component({
		components: {AsiAvatar}
	})
	export default class AsiCard extends mixins(ResponsiveChecks) {

		@Prop({type: String, default: ''})
		public titleBgColor!: string | null;

		@Prop({type: String, default: 'grey lighten-5'})
		public actionBgColor!: string | null;

		@Prop({default: ''})
		public title!: string | TranslateResult;

		@Prop({type: Boolean, default: false})
		public wrapTitle!: boolean;

		@Prop({type: Boolean, default: false})
		public titleDense!: boolean;

		@Prop()
		public subtitle!: string;

		@Prop({type: Boolean, default: false})
		public wrapSubtitle!: boolean;

		@Prop({type: String})
		public icon!: string;

		@Prop({type: String, default: 'white'})
		public iconColor!: string;

		@Prop({type: String, default: ''})
		public iconBgColor!: string;

		@Prop({type: Number, default: 40})
		public avatarSize!: number | null;

		@Prop({type: String, default: null})
		public imageUrl!: string | null;

		@Prop({type: Boolean, default: false})
		public highlighted!: boolean;

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		@Prop({type: Boolean, default: false})
		public unwrapped!: boolean;

		@Prop({type: Boolean, default: false})
		public noBottomMargin!: boolean;

		@Prop({type: Boolean, default: false})
		public flat!: boolean;

		@Prop({type: Boolean, default: false})
		public tile!: boolean;

		@Prop({type: String, default: null})
		public loaderColor!: string | null;

		@Prop({type: Number, default: null})
		public loaderProgress!: number | null;

		private get titleClasses(): string {
			let ret = this.titleBg;
			if (!this.tile) {
				if (!this.hasTopContent) ret = ret + ' rounded-t';
				if (!this.hasContent) ret = ret + ' rounded-b';
			}
			return ret;
		}

		private get titleBg(): string {
			if (this.titleBgColor === null || this.titleBgColor.length === 0) {
				return this.highlighted ? 'primary' : 'grey lighten-4';
			}

			return this.titleBgColor;
		}

		private get iconBg(): string {
			if (this.iconBgColor === null || this.iconBgColor.length === 0) {
				return this.highlighted ? 'secondary' : 'grey';
			}

			return this.iconBgColor;
		}

		private get loadingValue(): string | null {
			return this.loading ? 'secondary' : null;
		}

		protected get hasContent(): boolean {
			return (this.$slots.default ?? []).length > 0;
		}

		private get hasTopContent(): boolean {
			return !!this.$slots['top-content'];
		}

		@Emit('click')
		public click(event: Event): Event {
			return event;
		}

		private get hasClickListener(): boolean {
			return !!this.$listeners.click;
		}

	}
</script>

<style lang="scss" scoped>
	@import '~vuetify/src/styles/styles';

	.action-container {
		gap: $spacer * 3;

		&.mobile {
			flex-direction: column;
		}
	}
</style>
