<template>
	<div>

		<div class="pa-6">
			<v-row v-if="cart.oneTimeEmail !== null">
				<v-col :cols="6">
					<div class="subtitle-2 mb-2">
						{{ $t('cart.oneTimeEmail') }}
					</div>
					<div>{{ cart.oneTimeEmail }}</div>
				</v-col>
			</v-row>
			<v-row v-if="cart.oneTimePhone !== null">
				<v-col :cols="6">
					<div class="subtitle-2 mb-2">
						{{ $t('cart.oneTimePhone') }}
					</div>
					<div>{{ cart.oneTimePhone }}</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col :cols="6">
					<div class="subtitle-2 mb-2">
						{{ $t('address.terms.shippingAddress') }}
					</div>
					<div v-if="shippingAddressLines === null" class="grey--text">-</div>
					<div v-else class="d-flex flex-column">
					<span v-for="(line, i) in shippingAddressLines" :key="`sa-${i}`">
						{{ line }}
					</span>
					</div>
				</v-col>
				<v-col :cols="6">
					<div class="subtitle-2 mb-2">
						{{ $t('address.terms.billingAddress') }}
					</div>
					<div v-if="billingAddressLines === null" class="grey--text">-</div>
					<div v-else class="d-flex flex-column">
					<span v-for="(line, i) in billingAddressLines" :key="`ba-${i}`">
						{{ line }}
					</span>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col :cols="6">
					<div class="subtitle-2 mb-2">
						{{ $t('shipmentType.singular') }}
					</div>
					<div v-if="cart === null || cart.shipmentType === null" class="grey--text">-</div>
					<div v-else>
						{{ shipmentTypeName(cart.shipmentType) }}
					</div>
				</v-col>
				<v-col :cols="6">
					<div class="subtitle-2 mb-2">
						{{ $t('paymentType.singular') }}
					</div>
					<div v-if="cart === null || cart.paymentType === null" class="grey--text">-</div>
					<div v-else>{{ paymentTypeName(cart.paymentType) }}</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col :cols="6">
					<div class="subtitle-2 mb-2">
						{{ $t('cart.customerReference') }}
					</div>
					<div v-if="cart === null || cart.customerReference === null" class="grey--text">-</div>
					<div v-else>
						{{ cart.customerReference }}
					</div>
				</v-col>
				<v-col :cols="6">
					<div class="subtitle-2 mb-2">
						{{ $t('cart.remark') }}
					</div>
					<div v-if="cart === null || cart.remark === null" class="grey--text">-</div>
					<div v-else>
						{{ cart.remark }}
					</div>
				</v-col>
			</v-row>
		</div>
		<CartTotalEntries :cart="cart" include-shipment :include-vat="amountsInclVat"/>
		<v-divider/>
		<div class="pb-6 grey lighten-5 d-flex flex-column align-center">
			<AsiCheckbox v-model="tacConfirmed" class="ma-6">
				<template v-slot:label>
					<i18n path="cart.checkout.confirmTermsAndConditions">
						<template v-slot:link>
							<span class="primary--text" style="cursor: pointer" @click="tacDialog.open()">
								{{ $t('cart.checkout.termsAndConditionsAbbreviation') }}
							</span>
						</template>
					</i18n>
				</template>
			</AsiCheckbox>

			<AsiBtn color="primary" :icon="icons.cart" @click="requestOrder"
			        :loading="loadingInternal" :disabled="loadingInternal || !tacConfirmed">
				{{ $t('order.terms.orderNow') }}
			</AsiBtn>
		</div>

		<AsiDialog v-if="tacDialog.isLoaded" :open="tacDialog.isOpen"
		           :title="$t('pageTitles.shop.termsAndConditions')"
		           :subtitle="tenantName"
		           :icon="icons.toc"
		           @cancel="tacDialog.close()" scrollable>
			<LegalTermsAndConditions/>
		</AsiDialog>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import CartTotalEntries from "@/components/cart/shop/CartTotalEntries.vue";
	import AsiCheckbox from "@/components/common/AsiCheckbox";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import {ICartShopListEntry} from "@/models/cart/CartShopModels";
	import DialogHandler from "@/components/common/DialogHandler";
	import AsiDialog from "@/components/common/AsiDialog.vue";
	import LegalTermsAndConditions from "@/components/legal/LegalTermsAndConditions.vue";
	import {IShipmentTypeShopListEntry} from "@/models/shipment-type/ShipmentTypeShopModels";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import {IPaymentTypeShopListEntry} from "@/models/payment-type/PaymentTypeShopModels";
	import {IAddressFields} from "@/models/address/AddressModels";
	import AddressHelper from "@/helpers/AddressHelper";
	import {Tenant} from "@/models/ui/Tenant";
	import EnumHelper from "@/helpers/EnumHelper";

	@Component({
		components: {LegalTermsAndConditions, AsiDialog, AsiBtn, AsiCheckbox, CartTotalEntries}
	})
	export default class CheckoutWizardStepSummary extends Vue {

		@Prop({type: Object, required: true})
		public cart!: ICartShopListEntry;

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		private icons = Icon;
		private loadingInternal: boolean = false;
		private tacDialog: DialogHandler = new DialogHandler();
		private tacConfirmed: boolean = false;

		private get amountsInclVat(): boolean {
			return this.$store.state.ui.amountsInclVat;
		}

		private get tenantName(): string {
			return EnumHelper.textFromValue(Tenant, this.$isTenant, true) ?? '';
		}

		@Watch('loading', {immediate: true})
		private onLoadingChanged(value: boolean): void {
			this.loadingInternal = value;
		}

		@Watch('loadingInternal')
		@Emit('loadingChanged')
		private onLoadingInternalChanged(value: boolean): boolean {
			if (this.loading !== value) this.$emit('update:loading', value);
			return value;
		}

		@Emit('performOrder')
		public performOrder(): void {
			return;
		}

		private requestOrder(): void {
			if (!this.tacConfirmed) return;
			this.performOrder();
		}

		private shipmentTypeName(shipmentType: IShipmentTypeShopListEntry): string | null {
			return TranslatedValueHelper.get(shipmentType.name, this.$i18n.locale);
		}

		private paymentTypeName(paymentType: IPaymentTypeShopListEntry): string | null {
			return TranslatedValueHelper.get(paymentType.name, this.$i18n.locale);
		}

		private get shippingAddressLines(): string[] | null {
			if (this.cart.customerShippingAddress === null && this.cart.oneTimeShippingAddress === null) {
				return null;
			}
			const address = this.cart.customerShippingAddress ?? this.cart.oneTimeShippingAddress as IAddressFields;
			return AddressHelper.addressLines(address);
		}

		private get billingAddressLines(): string[] | null {
			if (this.cart.customerBillingAddress === null && this.cart.oneTimeBillingAddress === null) {
				return null;
			}
			const address = this.cart.customerBillingAddress ?? this.cart.oneTimeBillingAddress as IAddressFields;
			return AddressHelper.addressLines(address);
		}

	}
</script>

<style lang="scss" scoped>

</style>
