<template>
	<AsiAlert v-if="hasErrors" type="error" :tile="tile">
		<ul v-if="errors instanceof Array">
			<li v-for="(err, index) in errors" :key="index">
				{{ err }}
			</li>
		</ul>
		<p v-else-if="errors instanceof Object" class="ma-0">
			{{ $t('error.clientSide') }}
		</p>
	</AsiAlert>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import AsiAlert from "@/components/common/AsiAlert.vue";

	@Component({
		components: {AsiAlert}
	})
	export default class AsiFormErrors extends Vue {

		@Prop({type: [Array, Object], default: null})
		public errors!: string[] | object;

		@Prop({type: Boolean, default: false})
		public tile!: boolean;

		private get hasErrors(): boolean {
			if (Array.isArray(this.errors)) {
				return this.errors.length > 0;
			}
			if (typeof this.errors === 'object') {
				return Object.keys(this.errors).length > 0;
			}

			return true;
		}

	}
</script>

<style lang="scss" scoped>

</style>
