<template>
	<AsiContentContainer :columns="columns">
		<AsiSkeletonCard v-for="i in num" :key="`skeleton-card-${i}`"
		                 :loading="loading" class="mb-6"/>
	</AsiContentContainer>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import AsiSkeletonCard from "@/components/common/AsiSkeletonCard.vue";

	@Component({
		components: {AsiSkeletonCard, AsiContentContainer}
	})
	export default class AsiSkeletonCards extends Vue {

		@Prop({type: Number, default: 4})
		public num!: number;

		@Prop({type: Number, default: 2})
		public columns!: number;

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

	}
</script>

<style lang="scss" scoped>

</style>
