<template>
	<section>
		<AsiPageTitle :title="$t('category.plural')" :icon="icons.categories" @create="createDialog.open()"/>
		<CategoryList memory-key="admin-categories" ref="categoryList"/>

		<CategoryCreateDialog v-if="rMasterData && createDialog.isLoaded" :open="createDialog.isOpen" @save="categoryCreated" @cancel="createDialog.close()" />
	</section>
</template>

<script lang="ts">
	import {Component} from "vue-property-decorator";
	import CategoryList from "@/components/category/admin/CategoryList.vue";
	import AsiPageTitle from "@/components/common/AsiPageTitle.vue";
	import Icon from "@/plugins/icons";
	import DialogHandler from "@/components/common/DialogHandler";
	import CategoryCreateDialog from "@/components/category/admin/CategoryCreateDialog.vue";
	import ItemCreateDialog from "@/components/item/admin/ItemCreateDialog.vue";
	import {mixins} from "vue-class-component";
	import RightChecks from "@/mixins/RightChecks.vue";

	@Component({
		components: {ItemCreateDialog, CategoryCreateDialog, AsiPageTitle, CategoryList}
	})
	export default class Categories extends mixins(RightChecks) {

		private icons = Icon;
		private createDialog: DialogHandler = new DialogHandler();

		private categoryCreated(): void {
			this.createDialog.close();

			let list = this.$refs.categoryList;
			if (list !== undefined) {
				(this.$refs.categoryList as CategoryList).reload();
			}
		}
	}
</script>

<style lang="scss" scoped>

</style>
