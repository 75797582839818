<template>
	<AsiSkeletonCards v-if="cart === null" :num="1" :columns="2" loading/>

	<AsiContentContainer v-else :columns="2">
		<div>
			<AsiContentContainer no-margin>
				<div>
					<CartCardBasicData v-if="cart" :cart="cart"/>
				</div>
				<div>
					<AddressCardSimple :address="shippingAddress"
					                   :icon="icons.shipping" :label="$t('address.terms.shippingAddress')"/>
				</div>
				<div>
					<AddressCardSimple :address="billingAddress"
					                   :icon="icons.currency" :label="$t('address.terms.billingAddress')"/>
				</div>
			</AsiContentContainer>
		</div>
		<div>
			<CartCardPositions :cart="cart"/>
		</div>
	</AsiContentContainer>
</template>

<script lang="ts">
	import {Component, Emit, Prop} from "vue-property-decorator";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import AsiSkeletonCards from "@/components/common/AsiSkeletonCards.vue";
	import {ICartAdmin} from "@/models/cart/CartAdminModels";
	import CartCardBasicData from "./CartCardBasicData.vue";
	import Vue from "vue";
	import CartCardPositions from "@/components/cart/admin/CartCardPositions.vue";
	import AddressCardSimple from "@/components/address/AddressCardSimple.vue";
	import {IAddressFields} from "@/models/address/AddressModels";
	import Icon from "@/plugins/icons";

	@Component({
		components: {
			AddressCardSimple,
			CartCardPositions,
			CartCardBasicData,
			AsiSkeletonCards, AsiContentContainer
		}
	})
	export default class CartTabDetails extends Vue {

		@Prop({required: true})
		public cart!: ICartAdmin | null;

		private icons = Icon;

		private get billingAddress(): IAddressFields | null {
			return this.cart?.customerBillingAddress ?? this.cart?.oneTimeBillingAddress ?? null;
		}

		private get shippingAddress(): IAddressFields | null {
			return this.cart?.customerShippingAddress ?? this.cart?.oneTimeShippingAddress ?? null;
		}

		@Emit('change')
		public change(): void {
			return;
		}

	}
</script>

<style lang="scss" scoped>

</style>
