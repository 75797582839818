<template>
	<ShopView>
		<h1 class="mb-6 d-flex align-center">
			{{ $t('pageTitles.shop.privacy') }}
		</h1>
		<AsiCard unwrapped>
			<v-card-text class="pa-6">
				<PrivacyContent/>
			</v-card-text>
		</AsiCard>
	</ShopView>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator';
	import LegalTermsAndConditions from "@/components/legal/LegalTermsAndConditions.vue";
	import AsiCard from "@/components/common/AsiCard.vue";
	import ShopView from "@/components/layout/ShopView.vue";
	import PrivacyContent from "@/views/shop/legal/PrivacyContent.vue";

	@Component({
		components: {PrivacyContent, ShopView, AsiCard, LegalTermsAndConditions}
	})
	export default class Privacy extends Vue {

	}
</script>

<style scoped lang="scss">

</style>
