<template>
	<ItemAttributeFilter :attribute="attribute" :value-formatted="valueFormatted" :disabled="disabled"
	                     :filter-active="filterActive" @clear="clear">

		<v-list dense class="pa-0">
			<v-list-item-group :value="value" @change="input" multiple>
				<template v-for="(option, i) in optionsSorted">
					<v-list-item :key="`${option.name}-item`" :value="option.name" active-class="active">
						<template v-slot:default="{active}">
							<v-list-item-content>
								<v-list-item-title :class="{'font-weight-bold': active, 'font-weight-regular': !active}">
									<div class="d-flex flex-row justify-space-between">
										<span v-if="!hasLabelSlot">{{ option.name }}</span>
										<slot v-else name="label" v-bind:optionName="option.name"/>

										<span class="grey--text text-right" style="min-width: 2.75rem">
											{{ $n(option.count) }}
										</span>
									</div>
								</v-list-item-title>
							</v-list-item-content>
							<v-list-item-action>
								<v-checkbox :input-value="active" dense/>
							</v-list-item-action>
						</template>
					</v-list-item>
					<v-divider v-if="i < data.options.length - 1" :key="`${option.name}-divider`"/>
				</template>
			</v-list-item-group>
		</v-list>
	</ItemAttributeFilter>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import ItemAttributeFilter from "@/components/item/shop/attribute-filters/ItemAttributeFilter.vue";
	import AsiSelectSimple from "@/components/common/AsiSelectSimple";
	import {FilterEntrySelection, FilterEntrySelectionOption} from "@/models/item/IItemShopPaginatedResponse";
	import VueI18n from "vue-i18n";
	import TranslateResult = VueI18n.TranslateResult;

	@Component({
		components: {AsiSelectSimple, ItemAttributeFilter}
	})
	export default class ItemAttributeFilterSelection extends Vue {

		@Prop({type: String, required: true})
		public attribute!: string;

		@Prop({type: Array, required: true})
		public value!: string[];

		@Prop({type: Object, required: true})
		public data!: FilterEntrySelection;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		private get filterActive(): boolean {
			return this.value.length > 0;
		}

		private get valueFormatted(): string | TranslateResult | null {
			return this.value.length > 0 ? this.value.join(', ') : null;
		}

		private get optionsSorted(): FilterEntrySelectionOption[] {
			return this.data.options.sort((a: FilterEntrySelectionOption, b: FilterEntrySelectionOption) => a.name.localeCompare(b.name));
		}

		private clear(): void {
			this.input([]);
		}

		@Emit('input')
		public input(value: string[]): string[] {
			return value;
		}

		private get hasLabelSlot(): boolean {
			return !!this.$slots.label || !!this.$scopedSlots['label'];
		}

	}
</script>

<style lang="scss" scoped>
	.active:before {
		opacity: 0.05;
	}
</style>
