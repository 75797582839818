<template>
	<div>
		<template v-for="(faq, index) in faqs">
			<AsiCard :key="index" :title="faq.title" title-bg-color="transparent" class="mt-3" flat no-bottom-margin>
				<v-card-text class="pa-0 px-md-3">
					<v-expansion-panels flat multiple accordion class="rounded">
						<v-expansion-panel v-for="(qAndA, i) in faq.faq" :key="i">
							<v-expansion-panel-header :expand-icon="icons.dropdown" color="grey lighten-5"
							                          class="rounded-t body-1 font-weight-medium">
								{{ qAndA.question }}
							</v-expansion-panel-header>
							<v-expansion-panel-content eager class="body-2">
								<p class="ma-0 pa-0 pt-3" v-html="qAndA.answer"/>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-card-text>
			</AsiCard>
			<v-divider :key="'divider' + index" class="my-12 mx-6"/>
		</template>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component} from 'vue-property-decorator';
	import Icon from "@/plugins/icons";
	import AsiCard from "@/components/common/AsiCard.vue";

	class faqs {
		title!: string;
		faq!: { question: string, answer: string }[];
	}

	@Component({
		components: {AsiCard}
	})
	export default class Faqs extends Vue {

		private icons = Icon;
		private faqs!: faqs

		public created(): void {
			if (this.$isHerzogShop) {
				this.faqs = this.$t('faqs-herzog') as unknown as faqs;
			} else {
				this.faqs = this.$t('faqs-mk') as unknown as faqs;
			}
		}

	}
</script>

<style lang="scss" scoped>

</style>
