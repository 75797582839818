<template>
	<AsiCard no-bottom-margin unwrapped
	         :style="cardStyles" class="overflow-hidden" :class="{'with-gallery': showGallery}">
		<div class="pa-6 content" :style="{'min-height': showGallery ? '15rem' : undefined}">
			<h1 class="mb-0 ">
				{{ nameTranslated }}
			</h1>
			<div v-if="descriptionTranslated !== null" class="mt-6 mb-0 text-overflow-clamp" :class="{'text-overflow-release': !textCollapsed}">
				{{ descriptionTranslated }}
			</div>
			<p v-if="descriptionTranslatedTooLong" class="primary--text ma-0" style="cursor: pointer" @click="textCollapsed = !textCollapsed">
				<template v-if="textCollapsed">
					{{ $t('ui.terms.showMore') }}
				</template>
				<template v-else>
					{{ $t('ui.terms.showLess') }}
				</template>
			</p>
		</div>
		<template v-if="showGallery">
			<v-spacer/>
			<div class="px-6 py-3">
				<GalleryPreview v-if="category !== null && category.galleryAttachments.length > 0" auto
				                v-model="selectedImageId" :images="category.galleryAttachments" small/>
			</div>
		</template>

		<v-progress-linear absolute top :indeterminate="loading" :value="100" :color="categoryColor.toString()"
		                   class="rounded-0"/>
	</AsiCard>
</template>

<script lang="ts">
	import {Component, Prop, Watch} from "vue-property-decorator";
	import CategoryList from "./CategoryNavigation.vue";
	import {ICategoryShop} from "@/models/category/CategoryShopModels";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import AsiCard from "@/components/common/AsiCard.vue";
	import GalleryDetail from "@/components/gallery/GalleryDetail.vue";
	import GalleryPreview from "@/components/gallery/GalleryPreview.vue";
	import {IAttachment} from "@/models/attachment/AttachmentModels";
	import {mixins} from "vue-class-component";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
	import AttachmentHelper from "@/models/attachment/AttachmentHelper";
	import {AttachmentMediaSize} from "@/helpers/constants";
	import CategoryHelper from "@/models/category/CategoryHelper";
	import AsiBtn from "@/components/common/AsiBtn.vue";

	@Component({
		components: {AsiBtn, GalleryPreview, GalleryDetail, AsiCard, CategoryList}
	})
	export default class CategoryHeader extends mixins(ResponsiveChecks) {

		@Prop({type: Object, default: null})
		public category!: ICategoryShop | null;

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		private selectedImageId: string | null = null;
		private textCollapsed: boolean = true;

		private get nameTranslated(): string | null {
			return this.category === null
				? null
				: TranslatedValueHelper.get(this.category.name, this.$i18n.locale);
		}

		private get descriptionTranslated(): string | null {
			return this.category === null || this.category.description === null
				? null
				: TranslatedValueHelper.get(this.category.description, this.$i18n.locale);
		}

		private get descriptionTranslatedTooLong(): boolean {
			if (this.descriptionTranslated === null) return false;
			if (this.sMobile) {
				return this.descriptionTranslated.length > 150;
			} else if ((this.category?.galleryAttachments.length ?? 0) > 0) {
				return this.descriptionTranslated.length > 390;
			} else {
				return this.descriptionTranslated.length > 820;
			}
		}

		private get showGallery(): boolean {
			return !this.sMobile && (this.category?.galleryAttachments.length ?? 0) > 0;
		}

		private get selectedImage(): IAttachment | null {
			return this.category?.galleryAttachments.find(a => a.id === this.selectedImageId) ?? null;
		}

		private get selectedImageUrl(): string | null {
			return this.selectedImage !== null ? AttachmentHelper.getImageUrl(this.selectedImage, AttachmentMediaSize.l) : null;
		}

		private get categoryColor(): string {
			return this.category === null
				? 'primary'
				: CategoryHelper.colorHierarchical(this.category) ?? 'primary';
		}

		private get cardStyles(): object {
			if (!this.showGallery || this.selectedImageUrl === null) return {};
			return {
				'background-image': `linear-gradient(90deg, #fff 30%, transparent 75%), url(${this.selectedImageUrl}`,
			};
		}

		@Watch('category.id', {immediate: true})
		private onCategoryIdChanged(value: string | null): void {
			if (this.category?.bannerAttachmentId !== null){
				this.selectedImageId = this.category?.bannerAttachmentId ?? null;
			}
			else if (this.category?.avatarAttachmentId !== null){
				this.selectedImageId = this.category?.avatarAttachmentId ?? null;
			}

			if (this.category?.galleryAttachments != null && this.category?.galleryAttachments.length > 0){
				this.selectedImageId = this.category?.galleryAttachments[0].id;
			}
		}

	}
</script>

<style lang="scss" scoped>
	.v-card {
		background-size: cover;
		background-position: 75% center;

		.text-overflow-clamp {
			overflow: hidden;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 5;
			display: -webkit-box;
		}

		.text-overflow-release {
			display: block;
		}

		&.with-gallery {
			.content {
				padding-right: 50% !important;
			}
		}
	}
</style>
