<template>
	<AsiDialogFormLayout :icon="icons.fileCheck" :title="$t('mailTemplate.terms.create')" :subtitle="subtitle" max-width="90rem"
	                     :open="open" :loading="loading" :valid="valid" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped scrollable>
		<v-card-text class="pa-0">
			<v-form ref="form" v-model="valid">
				<div>
					<v-row class="ma-0">
						<v-col class="pa-0">
							<v-row class="ma-6">
								<v-col class="pa-0">
									<AsiTextField :label="$t('mailTemplate.subject')" v-model="updateModel.subject" clearable/>
								</v-col>
							</v-row>
							<v-row class="ma-6">
								<v-col class="pa-0">
									<AsiTextarea class="mt-3" style="font-family: monospace"
									             :label="$t('mailTemplate.htmlTemplate')" v-model="updateModel.htmlTemplate" :rules="updateModel.rules('htmlTemplate')" height="100%"/>
								</v-col>
							</v-row>
							<v-row class="ma-6">
								<v-col class="pa-0 d-flex flex-column">
									<AsiBtn class="align-self-center" :icon="icons.upload" color="primary" @click="$refs.uploader.click()">
										{{ $t('mailTemplate.upload') }}
									</AsiBtn>
									<input ref="uploader" type="file" accept="text/*" class="d-none" @change="onFileChanged">
								</v-col>
							</v-row>
						</v-col>
						<v-divider vertical/>
						<v-col class="info-section pa-6">
							<slot name="infos"/>
						</v-col>
					</v-row>
				</div>
			</v-form>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiDialogFormLayout from "../common/AsiDialogFormLayout.vue";
	import AsiTextField from "../common/AsiTextField";
	import AsiTextarea from "../common/AsiTextarea";
	import MailTemplateUpdate from "../../models/mail-template/MailTemplateUpdate";
	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
	import {AxiosError} from "axios";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import {IMailTemplateAdminSimple} from "@/models/mail-template/MailTemplateAdminModels";

	@Component({
		components: {AsiBtn, AsiTextarea, AsiTextField, AsiDialogFormLayout}
	})
	export default class MailTemplateUpdateDialog extends AsiDialogForm {

		@Prop({type: String, default: null})
		public subtitle!: string | null;

		@Prop({type: Object, required: true})
		public model!: IMailTemplateAdminSimple;

		private updateModel: MailTemplateUpdate | null = null;
		private uploadedFile: File | null = null;

		public created(): void {
			this.setMailTemplateInternal();
		}

		private setMailTemplateInternal(): void {
			if (this.updateModel === null) {
				this.updateModel = new MailTemplateUpdate(this.model);
			} else {
				this.updateModel.setDetailModel(this.model);
			}
		}

		private requestSave(): void {

			if (!this.valid) return;

			if (this.updateModel === null) return;

			this.loading = true;
			this.$mailTemplateServiceAdmin.update(this.model.id, this.updateModel)
				.then(this.save)
				.catch((err: AxiosError) => this.errors = err.response?.data)
				.finally(() => this.loading = false);
		}

		private onFileChanged(e: any): void {
			this.uploadedFile = e.target.files[0];
		}

		private clearHtmlInput(): void {
			if (this.updateModel === null) return;
			this.updateModel.htmlTemplate = "";
		}

		@Watch('uploadedFile')
		private onUploadedFileChanged(value: File): void {
			if (value !== null) {
				const reader = new FileReader();
				reader.onload = (res) => {
					if (this.updateModel !== null && res.target !== null) {
						this.updateModel.htmlTemplate = res.target.result as string;
					}
				};
				reader.readAsText(value);
			}
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			const form = this.$refs.form as HTMLFormElement;
			this.reset([], form);
			return event;
		}

	}
</script>

<style lang="scss" scoped>
	.info-section {
		height: 50em;
		overflow-y: scroll;
	}
</style>
