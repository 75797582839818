import { render, staticRenderFns } from "./ItemQuantitySelectionWithPrice.vue?vue&type=template&id=d87374ec&scoped=true"
import script from "./ItemQuantitySelectionWithPrice.vue?vue&type=script&lang=ts"
export * from "./ItemQuantitySelectionWithPrice.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d87374ec",
  null
  
)

export default component.exports