<template>
	<v-simple-table :dense="prices.length > 5" :class="{interactive: this.hasInputListener}" class="price-table">
		<colgroup>
			<col style="width: 40%;"/>
			<col v-if="!hideDiscount" style="width: 40%;"/>
			<col v-if="!hideDiscount"/>
		</colgroup>
		<thead>
			<tr>
				<th>
					{{ unitName }}
				</th>
				<th class="text-right">
					{{ $t('itemPrice.singular') }} / {{ unitName }}
				</th>
				<th v-if="!hideDiscount" class="text-right">
					{{ $t('itemPrice.terms.discountPercent') }}
				</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="(price, i) in sortedPrices" :key="i" :class="rowClasses(price)" @click="input(price.fromQuantity)">
				<td class="text-left">
					{{ price.fromQuantity }}
				</td>
				<td class="text-right">
					{{ $n(finalAmount(price.price), {key: 'currencyDisplay', currency: price.price.currency.currencyCode}) }}
				</td>
				<td v-if="!hideDiscount" class="text-right">
					<span v-if="getDiscount(price) === 0" class="grey--text">-</span>
					<span v-else>{{ $n(getDiscount(price), {style: 'percent'}) }}</span>
				</td>
			</tr>
		</tbody>
	</v-simple-table>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import EnumHelper from "@/helpers/EnumHelper";
	import {UnitOfMeasure} from "@/helpers/constants";
	import {IItemPriceShopSimple} from "@/models/item-price/ItemPriceModelsShop";
	import {IPrice} from "@/models/price/PriceModels";
	import TotalHelper from "@/helpers/TotalHelper";

	@Component
	export default class ItemPriceTable extends Vue {

		@Prop({type: Number, default: null})
		public value!: number | null;

		@Prop({type: Array, required: true})
		public prices!: IItemPriceShopSimple[];

		@Prop({type: Number, default: null})
		public unit!: number | null;

		@Prop({type: Boolean, default: false})
		public hideDiscount!: boolean;

		private get unitName(): string | null {
			return EnumHelper.textFromValue(UnitOfMeasure, this.unit, true, 1);
		}

		private get sortedPrices(): IItemPriceShopSimple[] {
			return this.prices.map(p => p).sort((a: IItemPriceShopSimple, b: IItemPriceShopSimple) => a.fromQuantity - b.fromQuantity);
		}

		private get activeFromQuantity(): number | null {
			return this.value === null
				? null
				: this.sortedPrices.map(p => p).reverse().find(p => p.fromQuantity <= (this.value as number))?.fromQuantity ?? null;
		}

		private get amountsInclVat(): boolean {
			return this.$store.state.ui.amountsInclVat;
		}

		private get hasInputListener(): boolean {
			return !!this.$listeners.input;
		}

		@Emit('input')
		public input(quantity: number): number {
			return quantity;
		}

		private rowClasses(price: IItemPriceShopSimple): object {
			const isActive = this.activeFromQuantity !== null && price.fromQuantity === this.activeFromQuantity;
			return {
				'primary--text font-weight-bold': isActive,
				'grey lighten-5': isActive && this.prices !== null && this.prices.length > 1,
			};
		}

		private getDiscount(price: IItemPriceShopSimple): number | null {
			const basePrice: IItemPriceShopSimple[] | undefined = this.prices?.filter(price => price.fromQuantity === 1);
			return (basePrice !== undefined && basePrice.length > 0)
				? 1 - price.price.amount / basePrice[0].price.amount
				: null;
		}

		private finalAmount(price: IPrice): number {
			return price.amount + (this.amountsInclVat ? TotalHelper.vatAmount(price) : 0);
		}

	}
</script>

<style lang="scss" scoped>
	.price-table {
		background-color: transparent !important;
	}

	.interactive {
		tbody tr {
			cursor: pointer;
		}
	}
</style>
