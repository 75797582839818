<template>
	<ShopView aside-inverted>
		<template v-slot:banner>
			<h1 class="ma-0">{{ $t('pageTitles.shop.checkout') }}</h1>
		</template>
		<template v-slot:aside>
			<AsiAlert v-if="displayChristmasInfo" type="info" :icon="icons.christmasInfo">
				<p class="font-weight-bold mt-1">{{ $t('christmasInfoTitle') }}</p>
				<p v-if="$isHerzogShop" class="christmas-info-text">{{ $t('christmasInfoHerzog') }}</p>
				<p v-else-if="$isMkShop" class="christmas-info-text">{{ $t('christmasInfoMk') }}</p>
			</AsiAlert>

			<AsiCard :title="cartLabel" unwrapped class="overflow-hidden">
				<CartContent :id="id" hide-checkout-button show-shipment show-customer-references/>
			</AsiCard>
		</template>

		<CheckoutWizard v-if="checkoutPossible" :id="id"/>
		<div v-else>
			<AsiAlert :icon="icons.cart" type="info">
				{{ $t('cart.terms.noActiveOrEmptyCart') }}
			</AsiAlert>
			<div class="text-center">
				<AsiBtn :icon="icons.home" color="primary" @click="showHome">
					{{ $t('ui.terms.backToShop') }}
				</AsiBtn>
			</div>
		</div>
	</ShopView>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import ShopView from "@/components/layout/ShopView.vue";
	import {TranslateResult} from "vue-i18n";
	import CheckoutWizard from "@/components/checkout/CheckoutWizard.vue";
	import AsiCard from "@/components/common/AsiCard.vue";
	import CartContent from "@/components/cart/shop/CartContent.vue";
	import CartHelper from "@/helpers/CartHelper";
	import {ICartShopListEntry} from "@/models/cart/CartShopModels";
	import AsiAlert from "@/components/common/AsiAlert.vue";
	import Icon from "@/plugins/icons";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import RoutesHelper from "@/helpers/RoutesHelper";
	import DateTimeHelper from "@/helpers/DateTimeHelper";

	@Component({
		components: {AsiBtn, AsiAlert, CartContent, AsiCard, CheckoutWizard, ShopView}
	})
	export default class Checkout extends Vue {

		@Prop({type: String, required: true})
		public id!: string;

		private icons = Icon;

		private get displayChristmasInfo(): boolean {
			const until = DateTimeHelper.createDateObjectFromDateString('3.1.2024');
			return until != null ? new Date() < until : false;
		}

		private get cart(): ICartShopListEntry | null {
			return this.$store.getters['cart/cartById'](this.id);
		}

		private get checkoutPossible(): boolean {
			return (this.cart?.positions.length ?? 0) > 0;
		}

		private get cartLabel(): string | TranslateResult {
			return CartHelper.label(this.cart);
		}

		private showHome(): void {
			this.$router.push(RoutesHelper.homeShopRoute());
		}

	}
</script>

<style lang="scss" scoped>
	.christmas-info-text {
		white-space: pre-line;
	}
</style>
