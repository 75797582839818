<template>
	<AsiDialogFormLayout :icon="icons.shoppingList" :title="$t('shoppingList.singular')" :subtitle="shoppingList.name"
	                     :open="open" :loading="loading" :valid="valid" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped scrollable>
		<v-card-text class="pa-6">
			<v-form v-model="valid">
				<v-row>
					<v-col>
						<AsiTextField v-model="nameInternal" :label="$t('shoppingList.name')" :placeholder="$t('shoppingList.name')"
						              @keydown.enter.prevent="requestSave" :rules="[rules.required]" />
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
	import {IShoppingListShop} from "@/models/shopping-list/ShoppingListShopModels";
	import AsiTextField from "@/components/common/AsiTextField";
	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
	import Snackbar from "@/helpers/Snackbar";

	@Component({
		components: {AsiTextField, AsiDialogFormLayout}
	})
	export default class ShoppingListUpdateDialogName extends AsiDialogForm {

		@Prop({type: Object, required: true})
		public shoppingList!: IShoppingListShop;

		private nameInternal!: string;
		private rules = {
			required: (value: string) => !!value || 'Required.',
		};

		@Watch('shoppingList.name', {immediate: true})
		private onNameChanged(value: string): void {
			this.nameInternal = value;
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			const form = this.$refs.form as HTMLFormElement;
			this.reset([], form);
			this.nameInternal = this.shoppingList.name;
			return event;
		}

		private requestSave(): void {
			this.loading = true;
			this.$store.dispatch('shoppingList/updateName', {
				shoppingListId: this.shoppingList.id,
				name: this.nameInternal
			})
				.then(() => this.save())
				.catch(() => Snackbar.updateError())
				.finally(() => this.loading = false);
		}
	}
</script>

<style lang="scss" scoped>

</style>
