<template>
	<AsiCardDetail :icon="icons.paymentTypes" :title="$t('globalConfig.terms.surcharge')"
	               :show-action-button="!readonly" @actionButtonClicked="updateDialog.open()">
		<AsiCardDetailEntry :label="$t('globalConfig.terms.surcharge')"
		                    :icon="icons.paymentType" :value="globalConfig.minimumOrderSurchargeAmount">
			<template v-slot:default v-if="globalConfig.minimumOrderSurchargeAmount !== null">
				{{
					$n(globalConfig.minimumOrderSurchargeAmount.amount, {
						key: 'currencyDisplay',
						currency: globalConfig.minimumOrderSurchargeAmount.currency.currencyCode,
					})
				}}
			</template>
		</AsiCardDetailEntry>
		<AsiCardDetailEntry :label="$t('globalConfig.terms.surchargeUntil')"
		                    :icon="icons.no" :value="globalConfig.minimumOrderSurchargeUntilAmount">
			<template v-slot:default v-if="globalConfig.minimumOrderSurchargeUntilAmount !== null">
				{{
					$n(globalConfig.minimumOrderSurchargeUntilAmount.amount, {
						key: 'currencyDisplay',
						currency: globalConfig.minimumOrderSurchargeUntilAmount.currency.currencyCode,
					})
				}}
			</template>
		</AsiCardDetailEntry>

		<GlobalConfigUpdateDialogSurcharge v-if="!readonly && updateDialog.isLoaded" :open="updateDialog.isOpen"
		                                   :global-config="globalConfig"
		                                   @cancel="updateDialog.close()" @save="updateDialog.close(); change();"/>
	</AsiCardDetail>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from 'vue-property-decorator';
	import Icon from "@/plugins/icons";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import {IGlobalConfigAdmin} from "@/models/global-config/GlobalConfigAdminModels";
	import GlobalConfigUpdateDialogSurcharge
		from "@/components/global-config/admin/GlobalConfigUpdateDialogSurcharge.vue";
	import DialogHandler from "@/components/common/DialogHandler";

	@Component({
		components: {GlobalConfigUpdateDialogSurcharge, AsiCardDetailEntry, AsiCardDetail}
	})
	export default class GlobalConfigCardSurcharge extends Vue {

		@Prop({type: Object, required: true})
		public globalConfig!: IGlobalConfigAdmin;

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		private icons = Icon;
		private updateDialog: DialogHandler = new DialogHandler();

		@Emit('change')
		public change(): void {
			return;
		}

	}
</script>

<style lang="scss" scoped>

</style>
