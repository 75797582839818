<template>
	<AsiCardDetail :icon="icons.shoppingList" :title="$t('ui.terms.basicData')">
		<AsiCardDetailEntry :icon="icons.shoppingList" :label="$t('user.terms.name')" :value="shoppingList.name" />
		<AsiCardDetailEntry :icon="icons.name" :label="$t('user.singular')" :value="userName(shoppingList.user)" />
		<AsiCardDetailEntry :icon="icons.customer" :label="$t('customer.singular')" :value="shoppingList.user.customer.name" />
	</AsiCardDetail>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import Icon from "@/plugins/icons";
	import {IShoppingListAdmin} from "@/models/shopping-list/ShoppingListAdminModels";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import {IUserAdminSimple} from "@/models/user/UserAdminModels";
	import UserHelper from "@/models/user/UserHelper";
	@Component({
		components: {AsiCardDetailEntry, AsiCardDetail}
	})
	export default class ShoppingListCardBasicData extends Vue {

		@Prop({type: Object, required: true})
		public shoppingList!: IShoppingListAdmin;

		private icons = Icon;

		private userName(user: IUserAdminSimple): string | null {
			return UserHelper.fullName(user);
		}
	}
</script>

<style lang="scss" scoped>

</style>
