<template>
	<v-menu top left :close-on-content-click="closeOnContentClick" max-height="50vh" :disabled="disabled">
		<template v-slot:activator="{on, attrs}">
			<v-list-item v-on="on" v-bind="attrs" class="rounded elevation-1 pl-3 pr-1" dense :disabled="disabled"
			             :class="{'grey lighten-4': !filterActive, 'primary white--text': filterActive}">
				<v-list-item-icon v-if="showIcon" class="mr-4">
					<v-icon :color="filterActive ? 'white' : undefined">
						{{ icon }}
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content class="py-2">
					<v-list-item-title>
						<template v-if="!filterActive">
							<span class="font-weight-bold">{{ label }}</span>
						</template>
						<template v-else>
							<span class="font-weight-bold">{{ label }}</span>:
							<span class="font-weight-regular">{{ valueFormatted }}</span>
						</template>
					</v-list-item-title>
				</v-list-item-content>
				<v-list-item-action class="my-1">
					<AsiBtn :icon="icons.clear" small :color="filterActive ? 'white' : undefined"
					        v-show="filterActive"
					        @click.native.stop="clear"/>
				</v-list-item-action>
			</v-list-item>
		</template>

		<AsiCard no-bottom-margin unwrapped>
			<v-card-text class="pa-0 filter-wrapper">
				<slot/>
			</v-card-text>
		</AsiCard>
	</v-menu>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import Icon from "@/plugins/icons";
	import VueI18n from "vue-i18n";
	import ItemHelper from "@/models/item/ItemHelper";
	import AsiCard from "@/components/common/AsiCard.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import AsiFadeTransition from "@/components/common/AsiFadeTransition.vue";
	import TranslateResult = VueI18n.TranslateResult;

	@Component({
		components: {AsiFadeTransition, AsiBtn, AsiCard}
	})
	export default class ItemAttributeFilter extends Vue {

		@Prop({type: String, required: true})
		public attribute!: string;

		@Prop({required: true})
		public valueFormatted!: string | null | TranslateResult;

		@Prop({type: Boolean, required: true})
		public filterActive!: boolean;

		@Prop({type: Boolean, default: false})
		public showIcon!: boolean;

		@Prop({type: Boolean, default: false})
		public closeOnContentClick!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		private icons = Icon;

		private get label(): string | TranslateResult | null {
			return ItemHelper.attributeLabel(this.attribute);
		}

		private get icon(): string {
			return ItemHelper.attributeIcon(this.attribute);
		}

		@Emit('clear')
		public clear(): void {
			return;
		}

	}
</script>

<style lang="scss" scoped>
	@import 'src/scss/global';

	.filter-wrapper {
		@include asiScrollbar();
		overflow-y: auto;
		max-height: 50vh;
	}

	.v-list-item {
		min-height: 2.25rem;
		transition: background-color .25s ease-out;

		.v-list-item-title,
		.v-list-item-subtitle,
		.v-icon {
			transition: color .25s ease-out;
		}
	}

	.ellipsis {
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
</style>
