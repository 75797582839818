<template>
	<v-footer dark class="grey darken-3 py-6">
		<v-progress-linear :value="100" absolute top/>
		<v-container>
			<v-row>
				<v-col>
					<LogoHerzogDichtungen v-if="$isHerzogShop" height="1.5rem"/>
					<LogoMkDichtungen v-if="$isMkShop" height="1.5rem"/>
				</v-col>
			</v-row>
			<v-row>
				<v-col :cols="12" :md="4" :lg="4" :xl="4">
					<h5 class="text-h6">
						{{ $t('address.singular') }}
					</h5>
					<v-divider class="mt-1 mb-4"/>
					<template v-if="$isHerzogShop">
						<ul class="footer-list body-2">
							<li>
								<div class="d-flex flex-row">
									<v-icon size="16" class="mr-3">{{ icons.address }}</v-icon>
									<address class="mb-3" style="margin-top: -2px;">
										<span class="font-weight-medium">Herzog Dichtungen AG</span><br/>
										Steinackerstrasse 31<br/>
										8902 Urdorf
									</address>
								</div>
							</li>
						</ul>

						<AsiBtn :icon="icons.map" color="primary" x-small @click="showMap">
							{{ $t('ui.terms.showOnMap') }}
						</AsiBtn>

						<p class="font-weight-bold mt-6 mb-1">
							{{ $t('address.terms.pickupAddress') }}
						</p>
						<ul class="footer-list body-2">
							<li>
								<div class="d-flex flex-row">
									<v-icon size="16" class="mr-3">{{ icons.pickup }}</v-icon>
									<address class="mb-3" style="margin-top: -2px;">
										<span class="font-weight-medium">poesia-gruppe</span><br/>
										Industrie Feldmatte<br/>
										Grenzweg 3<br/>
										5726 Unterkulm
									</address>
								</div>
							</li>
						</ul>

						<AsiBtn :icon="icons.map" color="primary" x-small @click="showMapPickup">
							{{ $t('ui.terms.showOnMap') }}
						</AsiBtn>
					</template>
					<template v-if="$isMkShop">
						<ul class="footer-list body-2">
							<li>
								<div class="d-flex flex-row">
									<v-icon size="16" class="mr-3">{{ icons.address }}</v-icon>
									<address class="mb-3" style="margin-top: -2px;">
										<span class="font-weight-medium">mk dichtungs ag</span><br/>
										Industrie Feldmatte<br/>
										Grenzweg 3<br/>
										5726 Unterkulm
									</address>
								</div>
							</li>
						</ul>

						<AsiBtn :icon="icons.map" color="primary" x-small @click="showMap">
							{{ $t('ui.terms.showOnMap') }}
						</AsiBtn>
					</template>
				</v-col>
				<v-col :cols="12" :md="4" :lg="4" :xl="4">
					<h5 class="text-h6">{{ $t('pageTitles.shop.contact') }}</h5>
					<v-divider class="mt-1 mb-4"/>

					<ul class="footer-list body-2" v-if="$isHerzogShop">
						<li>
							<v-icon size="16" class="mr-3">{{ icons.phone }}</v-icon>
							<a href="tel:+41447342510">
								+41 44 734 25 10
							</a>
						</li>
						<li>
							<v-icon size="16" class="mr-3">{{ icons.email }}</v-icon>
							<a href="mailto:herzogdichtungen@poesia-gruppe.ch">
								herzogdichtungen@poesia-gruppe.ch
							</a>
						</li>
						<li>
							<v-icon size="16" class="mr-3">{{ icons.mail }}</v-icon>
							<a @click="showContact">{{ $t('contactForm.singular') }}</a>
						</li>
					</ul>
					<ul class="footer-list body-2" v-if="$isMkShop">
						<li>
							<v-icon size="16" class="mr-3">{{ icons.phone }}</v-icon>
							<a href="tel:+41627687080">
								+41 (0)62 768 70 80
							</a>
						</li>
						<li>
							<v-icon size="16" class="mr-3">{{ icons.phoneFax }}</v-icon>
							<a href="tel:+41627687081">
								+41 (0)62 768 70 81
							</a>
						</li>
						<li>
							<v-icon size="16" class="mr-3">{{ icons.email }}</v-icon>
							<a href="mailto:mk-ag@poesia-gruppe.ch">
								mk-ag@poesia-gruppe.ch
							</a>
						</li>
						<li>
							<v-icon size="16" class="mr-3">{{ icons.mail }}</v-icon>
							<a @click="showContact">{{ $t('contactForm.singular') }}</a>
						</li>
					</ul>
				</v-col>
				<v-col :cols="12" :md="4" :lg="4" :xl="4">
					<h5 class="text-h6">
						{{ $t('ui.terms.furtherInformation') }}
					</h5>
					<v-divider class="mt-1 mb-4"/>
					<ul class="footer-list body-2">
						<li>
							<v-icon size="16" class="mr-3">{{ icons.faq }}</v-icon>
							<router-link :to="faqRoute">
								{{ $t('pageTitles.shop.faq') }}
							</router-link>
						</li>
						<li>
							<v-icon size="16" class="mr-3">{{ icons.privacy }}</v-icon>
							<router-link :to="privacyRoute">
								{{ $t('pageTitles.shop.privacy') }}
							</router-link>
						</li>
						<li>
							<v-icon size="16" class="mr-3">{{ icons.toc }}</v-icon>
							<router-link :to="termsAndConditionsRoute">
								{{ $t('pageTitles.shop.termsAndConditions') }}
							</router-link>
						</li>
						<li>
							<v-icon size="16" class="mr-3">{{ icons.legalNotice }}</v-icon>
							<router-link :to="legalNoticeRoute">
								{{ $t('pageTitles.shop.legalNotice') }}
							</router-link>
						</li>
					</ul>

					<AsiLocaleSwitcher class="mt-6" dark/>
				</v-col>
			</v-row>
		</v-container>
	</v-footer>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component} from "vue-property-decorator";
	import LogoHerzogDichtungen from "@/components/layout/LogoHerzogDichtungen.vue";
	import Icon from "@/plugins/icons";
	import AsiLocaleSwitcher from "@/components/common/AsiLocaleSwitcher.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import {Routes} from "@/helpers/constants";
	import {RawLocation} from "vue-router";
	import LogoMkDichtungen from "@/components/layout/LogoMkDichtungen.vue";

	@Component({
		components: {LogoMkDichtungen, AsiBtn, AsiLocaleSwitcher, LogoHerzogDichtungen}
	})
	export default class Footer extends Vue {

		private icons = Icon;

		private get faqRoute(): RawLocation {
			return {
				name: Routes.faq,
			};
		}

		private get privacyRoute(): RawLocation {
			return {
				name: Routes.privacy,
			};
		}

		private get termsAndConditionsRoute(): RawLocation {
			return {
				name: Routes.termsAndConditions,
			};
		}

		private get legalNoticeRoute(): RawLocation {
			return {
				name: Routes.legalNotice,
			};
		}

		// noinspection JSUnusedLocalSymbols
		private showMap(): void {
			const url = this.$isHerzogShop ? 'https://goo.gl/maps/oLFbRRFVWztJaBWRA'
				: this.$isMkShop ? 'https://goo.gl/maps/gPcCkjGgGPLhwdg36'
					: null;
			if (url === null) {
				return;
			}

			const w = window.open(url, '_blank');
			if (w !== null) w.focus();
		}

		// noinspection JSUnusedLocalSymbols
		private showMapPickup(): void {
			const w = window.open('https://goo.gl/maps/gPcCkjGgGPLhwdg36', '_blank');
			if (w !== null) w.focus();
		}

		private showContact(): void {
			if (this.$router.currentRoute.name === Routes.contact) return;
			this.$router.push({name: Routes.contact});
		}
	}
</script>

<style lang="scss" scoped>
	address {
		font-style: normal;
	}

	a {
		text-decoration: none;
	}

	.row > div {
		.v-divider {
			border-color: var(--v-secondary-lighten3);
			border-style: dotted;
			transition: border-color .3s ease-out;
		}

		&:hover .v-divider {
			border-color: var(--v-primary-base);
		}
	}

	.footer-list {
		list-style-type: none;
		padding: 0;


		> li {
			padding: 0.25rem 0;

			.v-icon {

			}

			&:hover .v-icon {
				color: var(--v-primary-base);
				transform: scale(1.2) rotate(-3deg);
			}
		}
	}
</style>
