<template>
	<AsiCardDetail :icon="icons.order" :title="$t('ui.terms.basicData')" multi-column>
		<AsiCardDetailEntry :label="$t('order.paymentState')" :icon="icons.state">
			<OrderPaymentStateChip v-if="order" :order="order"/>
		</AsiCardDetailEntry>
		<AsiCardDetailEntry :label="$t('order.shipmentState')" :icon="icons.state">
			<OrderShipmentStateChip v-if="order" :order="order"/>
		</AsiCardDetailEntry>
		<AsiCardDetailEntry :label="$t('order.orderNumber')" :value="order.orderNumber" :icon="icons.order"/>
		<AsiCardDetailEntry :label="$t('order.createdAt')" :icon="icons.time">
			{{ $d(new Date(order.createdAt), 'long') }}
			<template v-if="createdBy !== null">
				<br>
				<router-link :to="createdByRoute">
					{{ createdBy }}
				</router-link>
			</template>
		</AsiCardDetailEntry>
	</AsiCardDetail>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import Icon from "@/plugins/icons";
	import {IOrderAdmin} from "@/models/order/OrderAdminModels";
	import UserHelper from "@/models/user/UserHelper";
	import {RawLocation} from "vue-router";
	import OrderStateChip from "@/components/order/OrderShipmentStateChip.vue";
	import OrderShipmentStateChip from "@/components/order/OrderShipmentStateChip.vue";
	import OrderPaymentStateChip from "@/components/order/OrderPaymentStateChip.vue";

	@Component({
		components: {OrderPaymentStateChip, OrderShipmentStateChip, OrderStateChip, AsiCardDetail, AsiCardDetailEntry}
	})
	export default class OrderCardBasicData extends Vue {

		@Prop({required: true})
		public order!: IOrderAdmin;

		private icons = Icon;

		private get createdBy(): string | null {
			return this.order.createdBy === null
				? null
				: UserHelper.fullName(this.order.createdBy);
		}

		private get createdByRoute(): RawLocation | null {
			return this.order.createdBy === null
				? null
				: UserHelper.detailRouteAdmin(this.order.createdBy.id);
		}

	}
</script>

<style lang="scss" scoped>

</style>
