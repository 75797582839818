<template>
	<AsiCardDetail :icon="icons.customer" :title="$t('ui.terms.basicData')">
		<AsiCardDetailEntry :icon="icons.name" :label="$t('customer.name')" :value="customer.name">
			<template v-slot:actionButton v-if="!readonly">
				<AsiBtn :icon="icons.edit" :disabled="nameDisabled" @click="nameDialog.open()" small/>
			</template>
		</AsiCardDetailEntry>
		<AsiCardDetailEntry :icon="icons.phone" :label="$t('customer.phone')">

			<span v-if="customer.phone !== null" @click="call(customer.phone)">{{ customer.phone }}</span>
			<span v-else>-</span>

			<template v-slot:actionButton v-if="!readonly">
				<AsiBtn :icon="icons.edit" :disabled="nameDisabled" @click.stop="phoneDialog.open()" small/>
			</template>
		</AsiCardDetailEntry>
		<AsiCardDetailEntry :icon="icons.customer" :label="$t('customer.type')">
			<template v-slot:actionButton v-if="!readonly">
				<AsiBtn :icon="icons.edit" :disabled="typeDisabled" @click="typeDialog.open()" small/>
			</template>

			<CustomerTypeChip :customer="customer"/>
		</AsiCardDetailEntry>
		<AsiCardDetailEntry :icon="icons.state" :label="$t('customer.state')">
			<template v-slot:actionButton v-if="!readonly">
				<AsiBtn :icon="icons.edit" :disabled="stateDisabled" @click="stateDialog.open()" small/>
			</template>

			<CustomerStateChip :customer="customer"/>
		</AsiCardDetailEntry>

		<CustomerUpdateDialogName v-if="!readonly && nameDialog.isLoaded" :open="nameDialog.isOpen" :customer="customer"
		                          @cancel="nameDialog.close()" @save="nameDialog.close(); change();"/>
		<CustomerUpdateDialogPhone v-if="!readonly && phoneDialog.isLoaded" :open="phoneDialog.isOpen" :customer="customer"
		                           @cancel="phoneDialog.close()" @save="phoneDialog.close(); change();"/>
		<CustomerUpdateDialogState v-if="!readonly && stateDialog.isLoaded" :open="stateDialog.isOpen" :customer="customer"
		                           @cancel="stateDialog.close()" @save="stateDialog.close(); change();"/>
		<CustomerUpdateDialogType v-if="!readonly && typeDialog.isLoaded" :open="typeDialog.isOpen" :customer="customer"
		                          @cancel="typeDialog.close()" @save="typeDialog.close(); change();"/>
	</AsiCardDetail>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import {ICustomerAdmin} from "@/models/customer/CustomerAdminModels";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import Icon from "@/plugins/icons";
	import CustomerTypeChip from "@/components/customer/CustomerTypeChip.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import CustomerUpdateDialogState from "@/components/customer/admin/CustomerUpdateDialogState.vue";
	import CustomerUpdateDialogType from "@/components/customer/admin/CustomerUpdateDialogType.vue";
	import CustomerUpdateDialogName from "@/components/customer/admin/CustomerUpdateDialogName.vue";
	import CustomerStateChip from "@/components/customer/CustomerStateChip.vue";
	import CustomerUpdateDialogPhone from "@/components/customer/admin/CustomerUpdateDialogPhone.vue";

	@Component({
		components: {
			CustomerUpdateDialogPhone,
			CustomerStateChip,
			CustomerUpdateDialogName,
			CustomerUpdateDialogType,
			CustomerUpdateDialogState, AsiBtn, CustomerTypeChip, AsiCardDetail, AsiCardDetailEntry
		}
	})
	export default class CustomerCardBasicData extends Vue {

		@Prop({type: Object, required: true})
		public customer!: ICustomerAdmin;

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		@Prop({type: Boolean, default: false})
		public nameDisabled!: boolean;

		@Prop({type: Boolean, default: false})
		public typeDisabled!: boolean;

		@Prop({type: Boolean, default: false})
		public stateDisabled!: boolean;

		private icons = Icon;
		private nameDialog: DialogHandler = new DialogHandler();
		private stateDialog: DialogHandler = new DialogHandler();
		private typeDialog: DialogHandler = new DialogHandler();
		private phoneDialog: DialogHandler = new DialogHandler();

		private call(nr: string | null): void {
			if (nr !== null) {
				const finalNumber = nr.replace(/\s/g, '');
				window.location.href = 'tel:' + finalNumber;
			}
		}

		@Emit('change')
		public change(): void {
			return;
		}

	}
</script>

<style lang="scss" scoped>

</style>
