<template>
	<AsiCard unwrapped :loading="loading">
		<v-card-text class="pa-6">
			<div class="d-flex flex-row" :class="{'flex-column align-center': sMobile}">
				<div class="mr-md-6 mr-md-12 avatar-wrapper" @mouseenter="showAvatarActions = true">
					<AsiAvatar :icon="icons.avatar" :image-url="avatarUrl" :size="128"
					           :icon-color="showAvatarActions ? 'transparent' : 'white'"/>

					<v-fade-transition v-if="!readonly">
						<v-overlay v-if="showAvatarActions" absolute @mouseleave="showAvatarActions = false" class="rounded-circle">
							<AsiBtn :icon="icons.edit" @click="avatarDialog.open()"/>
						</v-overlay>
					</v-fade-transition>
				</div>
				<div v-if="customer !== null" class="d-flex flex-column justify-center" :class="{'mt-3': sMobile}">
					<div class="mb-3" :class="{'d-flex justify-center': sMobile}">
						<CustomerTypeChip v-if="customer !== null" :customer="customer"/>
					</div>
					<h1>{{ customer.name }}</h1>
					<div class="mt-3" :class="{'phone-actions': showPhoneActions}"
					     @mouseenter="showPhoneActions = true"
					     @mouseleave="showPhoneActions = false"
					     @click="phoneDialog.open()"
					>
						<transition>
							<v-icon v-if="!showPhoneActions" small>{{ icons.phone }}</v-icon>
							<v-icon v-else small>{{ icons.edit }}</v-icon>
						</transition>
						<span v-if="customer.phone !== null" class="ml-3">{{ customer.phone }}</span>
						<span v-else class="ml-3">-</span>
					</div>
					<v-fade-transition v-if="!readonly">
					</v-fade-transition>
				</div>
			</div>
		</v-card-text>

		<CustomerUpdateDialogPhone v-if="!readonly && phoneDialog.isLoaded" :open="phoneDialog.isOpen" :customer="customer"
		                           @cancel="phoneDialog.close()" @save="phoneDialog.close(); change();"/>
		<AsiDialogAvatarUpload v-if="!readonly && customer !== null && avatarDialog.isLoaded" :open="avatarDialog.isOpen"
		                       :service="$customerServiceShop" :existing-avatar-url="avatarUrl"
		                       :model="customer" @change="change(); avatarDialog.close()" @cancel="avatarDialog.close()"/>
	</AsiCard>
</template>

<script lang="ts">
	import {Component, Emit, Prop} from "vue-property-decorator";
	import {ICustomerShop, ICustomerShopSimple} from "@/models/customer/CustomerShopModels";
	import AsiCard from "@/components/common/AsiCard.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";
	import Icon from "@/plugins/icons";
	import AsiDialogAvatarUpload from "@/components/common/AsiDialogAvatarUpload.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import {AttachmentMediaSize} from "@/helpers/constants";
	import {IUserShop} from "@/models/user/UserShopModels";
	import CustomerHelper from "@/models/customer/CustomerHelper";
	import CustomerTypeChip from "@/components/customer/CustomerTypeChip.vue";
	import CustomerUpdateDialogPhone from "@/components/customer/admin/CustomerUpdateDialogPhone.vue";
	import {mixins} from "vue-class-component";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";

	@Component({
		components: {CustomerUpdateDialogPhone, CustomerTypeChip, AsiDialogAvatarUpload, AsiAvatar, AsiBtn, AsiCard}
	})
	export default class CustomerHeader extends mixins(ResponsiveChecks) {

		@Prop({required: true})
		public customer!: ICustomerShop | null;

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		private icons = Icon;
		private showAvatarActions: boolean = false;
		private showPhoneActions: boolean = false;
		private avatarDialog: DialogHandler = new DialogHandler();
		private phoneDialog: DialogHandler = new DialogHandler();

		private get customerFromStore(): ICustomerShopSimple | null {
			const userModel = this.$store.getters['user/model'] as IUserShop | null;
			return userModel?.customer ?? null;
		}

		private get avatarUrl(): string | null {
			return this.customerFromStore === null ? null : CustomerHelper.avatarUrl(this.customerFromStore, AttachmentMediaSize.l);
		}


		@Emit('change')
		public change(): void {
			return;
		}

	}
</script>

<style lang="scss" scoped>
	.avatar-wrapper {
		position: relative;
	}

	.phone-actions {
		cursor: pointer;
	}
</style>
