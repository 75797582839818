<template>
	<div>
		<AsiContentContainer v-if="errors.length > 0">
			<AsiFormErrors :errors="[$t('ui.terms.loginFailed')]" class="ma-0"/>
		</AsiContentContainer>

		<AsiContentContainer>
			<v-form ref="formEmail" v-model="formEmailValid" @submit.prevent="">
				<v-row>
					<v-col>
						<AsiTextField v-model="username"
						              :append-icon="icons.next"
						              :disabled="state === STATE_AUTH_TOKEN"
						              :label="$t('ui.terms.username')"
						              :rules="emailRules"
						              autocomplete="username"
						              required
						              @input="reset(false)"
						              @click:append="performAuthType"
						              @keyup.enter="performAuthType"/>
					</v-col>
				</v-row>
			</v-form>
		</AsiContentContainer>
		<v-expand-transition>
			<v-divider v-show="state !== STATE_EMAIL"/>
		</v-expand-transition>

		<v-expand-transition>
			<div v-show="state === STATE_AUTH_PW">
				<AsiContentContainer>
					<v-form ref="formPw" v-model="formPwValid" @submit.prevent="">
						<v-row v-if="!forgotPasswordMode">
							<v-col>
								<AsiTextField v-model="password"
								              :append-icon="showPassword ? icons.hidePassword : icons.showPassword"
								              :label="$t('ui.terms.password')"
								              :rules="passwordRules"
								              :type="showPassword ? 'text' : 'password'"
								              autocomplete="current-password"
								              required
								              @click:append="showPassword = !showPassword"
								              @keyup.enter="performAuthPw"/>
							</v-col>
						</v-row>
					</v-form>
				</AsiContentContainer>

				<v-divider/>
				<div class="text-center grey lighten-4 px-4 py-2 button-wrapper d-flex flex-wrap justify-center">
					<AsiBtn :disabled="loadingInternal || password === null || password.length === 0" color="success" @click="performAuthPw" :icon="icons.password" :block="sMobile">
						{{ $t('ui.loginPw') }}
					</AsiBtn>
					<AsiBtn :disabled="loadingInternal || !username || !formEmailValid" @click="state = STATE_FORGOT_PW" :icon="icons.forgotPassword" :block="sMobile">
						{{ $t('ui.terms.forgotPassword') }}
					</AsiBtn>
				</div>
			</div>
		</v-expand-transition>


		<v-expand-transition>
			<div v-show="state === STATE_FORGOT_PW">
				<AsiContentContainer class="text-center">
					<div class="text-center">
						<AsiBtn color="success" @click="performForgotPassword" :disabled="loadingInternal" :icon="icons.send">
							{{ $t('ui.terms.sendNewPassword') }}
						</AsiBtn>
					</div>
				</AsiContentContainer>

				<v-divider/>
				<div class="text-center grey lighten-4 px-4 py-2">
					<AsiBtn @click="state = STATE_AUTH_PW" :icon="icons.prev">
						{{ $t('ui.back') }}
					</AsiBtn>
				</div>
			</div>
		</v-expand-transition>

		<v-expand-transition>
			<div v-show="state === STATE_AUTH_AD" class="text-center grey lighten-4 px-4 py-2">
				<AsiBtn @click="performAdRedirect" color="info" :icon="icons.cloud">
					{{ $t('ui.terms.loginAd') }}
				</AsiBtn>
			</div>
		</v-expand-transition>

		<AsiContentContainer v-if="state === STATE_AUTH_TOKEN">
			<div class="text-center">
				<p class="text-center">{{ $t('ui.terms.tokenValidationInProgress') }}</p>
				<div class="d-flex flex-row justify-center">
					<v-progress-circular indeterminate/>
				</div>
			</div>
		</AsiContentContainer>
		<v-expand-transition>
			<div v-show="state === STATE_EMAIL_NOT_CONFIRMED">
				<AsiContentContainer class="text-center">
					<div class="text-center">
						<AsiBtn color="success" @click="performResendConfirmation" :disabled="loadingInternal" :icon="icons.send">
							{{ $t('user.terms.resendConfirmation') }}
						</AsiBtn>
					</div>
				</AsiContentContainer>

				<v-divider/>
				<div class="text-center grey lighten-4 px-4 py-2">
					<AsiBtn @click="state = STATE_AUTH_PW" :icon="icons.prev">
						{{ $t('ui.back') }}
					</AsiBtn>
				</div>
			</div>
		</v-expand-transition>
	</div>
</template>

<script lang="ts">
	import Icon from "@/plugins/icons";
	import {AxiosError} from "axios";
	import {Component, Prop, Watch} from "vue-property-decorator";
	import AsiCard from "@/components/common/AsiCard.vue";
	import AsiTextField from "@/components/common/AsiTextField";
	import AsiFormErrors from "@/components/common/AsiFormErrors.vue";
	import TokenResult from "@/models/auth/TokenResult";
	import RequiredValidator from "@/validators/RequiredValidator";
	import AsiAlert from "@/components/common/AsiAlert.vue";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import AsiFadeTransition from "@/components/common/AsiFadeTransition.vue";
	import EmailValidator from "@/validators/EmailValidator";
	import AuthTypeResult from "@/models/auth/AuthTypeResult";
	import AuthTypeRequest from "@/models/auth/AuthTypeRequest";
	import LoginRequestAd from "@/models/auth/LoginRequestAd";
	import ResetPwRequest from "@/models/auth/ResetPwRequest";
	import LoginRequestPw from "@/models/auth/LoginRequestPw";
	import {AuthType, Routes} from "@/helpers/constants";
	import Snackbar from "@/helpers/Snackbar";
	import MsalHelper from "@/helpers/MsalHelper";
	import ActiveDirectoryHelper from "@/helpers/ActiveDirectoryHelper";
	import {ICartShopListEntry} from "@/models/cart/CartShopModels";
	import {mixins} from "vue-class-component";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";

	@Component({
		components: {AsiFadeTransition, AsiBtn, AsiContentContainer, AsiAlert, AsiCard, AsiTextField, AsiFormErrors},
	})
	export default class AsiLoginForm extends mixins(ResponsiveChecks) {

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		private readonly STATE_EMAIL: number = 0;
		private readonly STATE_FORGOT_PW: number = 1;
		private readonly STATE_AUTH_PW: number = 2;
		private readonly STATE_AUTH_AD: number = 3;
		private readonly STATE_AUTH_TOKEN: number = 4;
		private readonly STATE_EMAIL_NOT_CONFIRMED: number = 5;

		private stateInternal: number = this.STATE_EMAIL;
		private icons = Icon;
		private loadingInternal = false;
		private authTypeResult: AuthTypeResult | null = null;
		private showPassword = false;
		private formEmailValid = true;
		private formPwValid = true;
		private forgotPasswordMode = false;
		private username = '';
		private password = '';
		private emailRules = [
			(new RequiredValidator('ui.terms.username')).validationRule(),
			(new EmailValidator('ui.terms.username')).validationRule(),
		];
		private passwordRules = [
			(new RequiredValidator('ui.terms.password')).validationRule(),
		];
		private errors = [] as string[];

		private get state(): number {
			return this.stateInternal;
		}

		private set state(state: number) {
			this.stateInternal = state;
			this.errors = [];
			this.formEmailValid = true;
		}

		public created(): void {
			this.reset();
			if (this.$route.hash) {
				this.state = this.STATE_AUTH_TOKEN;
				const adToken = ActiveDirectoryHelper.extractAdToken(this.$route.hash);
				if (adToken !== null) {
					this.performAuthToken(adToken);
				}
			}
		}

		@Watch('loading')
		private onLoadingChanged(value: boolean): void {
			if (this.loadingInternal === value) return;
			this.loadingInternal = value;
		}

		@Watch('loadingInternal')
		private onLoadingInternalChanged(value: boolean): void {
			if (this.loading === value) return;
			this.$emit('update:loading', value);
		}

		private reset(resetUserName: boolean = true): void {
			this.authTypeResult = null;
			if (resetUserName) {
				this.username = this.$store.state.login.username ?? '';
			}
			this.password = '';
			this.state = this.STATE_EMAIL;
			this.errors = [];
			this.formEmailValid = true;
			this.formPwValid = true;
		}

		private performAuthType(): void {
			this.loadingInternal = true;
			this.errors = [];
			this.$authService.authType(new AuthTypeRequest(this.username))
				.then((authTypeResult: AuthTypeResult) => {
					this.authTypeResult = authTypeResult;
					if (authTypeResult.name === AuthType.password) {
						this.state = this.STATE_AUTH_PW;
					} else if (authTypeResult.name === AuthType.azureAd) {
						this.state = this.STATE_AUTH_AD;
						this.$store.commit('login/setLoginInfo', {
							username: this.username,
							authTypeResult: this.authTypeResult
						});
					} else if (authTypeResult.name === AuthType.emailNotConfirmed) {
						this.state = this.STATE_EMAIL_NOT_CONFIRMED;
						this.$store.commit('login/setLoginInfo', {
							username: this.username,
							authTypeResult: this.authTypeResult
						});
					} else {
						this.errors = [this.$t('snackbar.loginError', {msg: this.$t('ui.terms.invalidAuthType')}).toString()];
						Snackbar.show(this.$t('snackbar.loginError', {msg: this.$t('ui.terms.invalidAuthType')}), 'error');
					}
				})
				.catch((err: AxiosError) => {
					this.errors = err.response?.data;
				})
				.finally(() => this.loadingInternal = false);
		}

		private performAuthPw(): void {
			this.loadingInternal = true;
			this.$authService.loginPw(new LoginRequestPw(this.username, this.password))
				.then((tokenResult: TokenResult) => {
					const activeCart = this.$store.getters['cart/activeCart'] as ICartShopListEntry | null;
					const payloadUserData = activeCart !== null && activeCart.positions.length > 0 ? {assignCartId: activeCart.id} : null;

					this.$store.commit('user/setTokens', tokenResult);
					this.$store.dispatch('user/loadLoggedInUserData', payloadUserData);
					this.reset();
					if (this.$route.name !== Routes.home) {
						this.$router.push({name: Routes.home});
					}
				})
				.catch((err: AxiosError) => {
					this.errors = err.response?.data;
				})
				.finally(() => this.loadingInternal = false);
		}

		private performAdRedirect(): void {
			const clientId = this.authTypeResult?.clientId ?? null;

			if (clientId === null) {
				//TODO: handle this
				console.error('add redirect without auth type in store');
				return;
			}

			const msalInstance = MsalHelper.createInstance(clientId);
			try {
				msalInstance.loginRedirect(MsalHelper.createAuthParams());
			} catch (e) {
				console.error(this.$t('snackbar.loginError', {msg: e}).toString());
				this.errors = [this.$t('snackbar.loginError', {msg: e}).toString()];
				Snackbar.show(this.$t('snackbar.loginError', {msg: e}), 'error');
			}
		}

		private performAuthToken(adToken: string): void {
			this.loadingInternal = true;
			this.$authService.loginAd(new LoginRequestAd(this.username, adToken))
				.then((tokenResult: TokenResult) => {
					const activeCart = this.$store.getters['cart/activeCart'] as ICartShopListEntry | null;
					const payloadUserData = activeCart !== null && activeCart.positions.length > 0 ? {assignCartId: activeCart.id} : null;

					this.$store.commit('user/setTokens', tokenResult);
					this.$store.dispatch('user/loadLoggedInUserData', payloadUserData);
					this.reset();
					if (this.$route.name !== Routes.home) {
						this.$router.push({name: Routes.home});
					}
				})
				.catch((err: AxiosError) => {
					//TODO: show proper massage (not found)
					this.errors = err.response?.data;
					this.reset();
				})
				.finally(() => this.loadingInternal = false);
		}

		private performForgotPassword(): void {
			this.loadingInternal = true;
			this.$authService.resetPassword(new ResetPwRequest(this.username))
				.then(() => {
					this.errors = [];
					this.state = this.STATE_AUTH_PW;

					Snackbar.show(this.$t('snackbar.forgotPasswordSuccessful'), 'success');
				})
				.catch((err: AxiosError) => {
					this.errors = err.response?.data;
				})
				.finally(() => this.loadingInternal = false);
		}

		private performResendConfirmation(): void {
			this.loadingInternal = true;
			this.$authService.resendRegistrationConfirmation(this.username)
				.then(() => {
					this.errors = [];
					this.state = this.STATE_EMAIL;
					Snackbar.show(this.$t('user.terms.resendConfirmationSuccess'), 'success');
				})
				.catch((err: AxiosError) => {
					this.errors = err.response?.data;
				})
				.finally(() => this.loadingInternal = false);
		}

	}
</script>

<style lang="scss" scoped>
	.button-wrapper {
		gap: 1em;
	}
</style>
