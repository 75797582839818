<template>
	<AsiAutocomplete v-model="valueInternal" :clearable="clearable" :dense="simple"
	                 :disabled="disabled" :flat="simple" :item-text="itemText" :item-value="itemValue"
	                 :items="countriesFinal" :label="labelFinal" :loading="loadingActual"
	                 :multiple="multiple" :persistant-hint="persistentHint"
	                 :rules="rules" :solo="simple" autocomplete="off"
	                 @change="change" @input="input">

		<template v-slot:selection="data">
			<div class="d-flex flex-row align-center mt-1">
				<AsiChip class="px-2 mr-2" label small monospace color="secondary">
					{{ data.item.code }}
				</AsiChip>
				{{ data.item.labelTranslated }}
			</div>
		</template>
		<template v-slot:item="data">
			<template v-if="typeof data.item !== 'object'">
				<v-list-item-content v-text="data.item"></v-list-item-content>
			</template>
			<template v-else>
				<v-list-item-content>
					<v-list-item-title>
						<div class="d-flex flex-row align-center">
							<AsiChip class="px-2 mr-2" label small monospace>
								{{ data.item.code }}
							</AsiChip>
							{{ data.item.labelTranslated }}
						</div>
					</v-list-item-title>
				</v-list-item-content>
			</template>
		</template>
	</AsiAutocomplete>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiAutocomplete from "./AsiAutocomplete";
	import {getNames} from 'i18n-iso-countries';
	import Snackbar from "@/helpers/Snackbar";
	import VueI18n from "vue-i18n";
	import AsiChip from "@/components/common/AsiChip.vue";
	import CountryEntryTranslated from "@/models/country/CountryEntryTranslated";
	import CountryHelper from "@/helpers/CountryHelper";
	import TranslateResult = VueI18n.TranslateResult;

	@Component({
		components: {AsiChip, AsiAutocomplete}
	})
	export default class AsiCountryAutocompleteInput extends Vue {

		@Prop({default: null})
		public value!: string | string[] | null;

		@Prop({default: null})
		public countries!: string[] | null;

		@Prop({type: Array, default: () => []})
		public rules!: (string | null | number | object | unknown | boolean)[];

		@Prop({type: Boolean, default: false})
		public clearable!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		@Prop({type: Boolean, default: false})
		public multiple!: boolean;

		@Prop({type: Boolean, default: false})
		public simple!: boolean;

		@Prop({type: Boolean, default: false})
		public persistentHint!: boolean;

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		@Prop({type: String, default: null})
		public label!: string | null;

		private loadingInternal: boolean = false;
		private countriesInternal: string[] = [];
		private valueInternal: string | string[] | null = null;

		public mounted(): void {
			if (this.countries !== null) {
				this.countriesInternal = this.countries;
				this.countryOptionsChanged();
			} else {
				this.loadCountries();
			}
		}

		private get labelFinal(): string | TranslateResult {
			return this.label ?? this.$t(`country.${this.multiple ? 'plural' : 'singular'}`);
		}

		private get loadingActual(): boolean {
			return this.loading || this.loadingInternal;
		}

		private get countriesFinal(): (CountryEntryTranslated | object)[] {
			const locale = this.$i18n.locale;
			const lang = locale.substring(0, 2).toUpperCase();
			const codes = getNames(lang);

			const countryOptions = Object.keys(codes)
				.filter(k => this.countriesInternal.includes(k))
				.map(k => new CountryEntryTranslated(k, codes[k]))
				.sort(CountryHelper.sort);

			const finalOptions: (CountryEntryTranslated | object)[] = [];
			const otherCountries = countryOptions.filter(o => !CountryHelper.PRIORITY_COUNTRIES.flat().includes(o.code.toLowerCase()));

			const addGroupToOptions = (options: (CountryEntryTranslated | object)[]) => {
				if (options.length === 0) {
					return;
				}

				if (finalOptions.length > 0) {
					finalOptions.push({divider: true});
				}

				finalOptions.push(...options);
			};

			CountryHelper.PRIORITY_COUNTRIES.forEach((group) => {
				const optionGroup: (CountryEntryTranslated | object)[] = countryOptions.filter(o => group.includes(o.code.toLowerCase()));
				addGroupToOptions(optionGroup);
			});

			addGroupToOptions(otherCountries);

			return finalOptions;
		}

		@Emit('input')
		public input(): string | string[] | null {
			return this.valueInternal;
		}

		@Emit('change')
		public change(): string | string[] | null {
			return this.valueInternal;
		}

		@Emit('countryOptionsChanged')
		public countryOptionsChanged(): string[] {
			return this.countriesInternal;
		}

		private removeCountry(id: string): void {
			if (!this.multiple) return;
			const val = this.valueInternal as string[];
			this.valueInternal = val.filter((c: string) => c !== id);
			this.input();
			this.change();
		}

		// noinspection JSMethodCanBeStatic
		private itemValue(item: CountryEntryTranslated | string): string | null {
			return typeof item === 'object' ? item.code : item;
		}

		// noinspection JSMethodCanBeStatic
		private itemText(item: CountryEntryTranslated): string | TranslateResult | null {
			return typeof item === 'object' ? `${item.code} ${item.labelTranslated}` : item;
		}

		@Watch('value', {immediate: true})
		private onValueChanged(): void {
			this.valueInternal = this.value;
		}

		private loadCountries(): void {
			this.loadingInternal = true;
			this.$countryService.countries()
				.then((data) => {
					this.countriesInternal = data;
					this.countryOptionsChanged();
				})
				.catch(() => Snackbar.loadingError())
				.finally(() => this.loadingInternal = false);
		}

	}
</script>

<style lang="scss" scoped>

</style>
