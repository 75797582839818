<template>
	<div class="d-flex flex-row align-center">
		<slot name="prepend"/>

		<AsiBtn v-if="!hidePrev" :icon="icons.prev" :disabled="prevDisabled" @click="prev"
		        :color="prevColor" :small="small" class="mr-3">
			<template v-slot:default v-if="!hideLabels">
				{{ $t('ui.back') }}
			</template>
		</AsiBtn>

		<AsiBtn v-if="!hideNext" :icon="icons.next" :disabled="nextDisabled" @click="next"
		        :color="nextColor" :small="small" append-icon>
			<template v-slot:default v-if="!hideLabels">
				{{ $t('ui.forward') }}
			</template>
		</AsiBtn>

		<slot name="append"/>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import Icon from "@/plugins/icons";
	import AsiBtn from "@/components/common/AsiBtn.vue";

	@Component({
		components: {AsiBtn}
	})
	export default class AsiStepperNavigation extends Vue {

		@Prop({type: Boolean, default: false})
		public hidePrev!: boolean;

		@Prop({type: Boolean, default: false})
		public hideNext!: boolean;

		@Prop({type: Boolean, default: false})
		public prevDisabled!: boolean;

		@Prop({type: Boolean, default: false})
		public nextDisabled!: boolean;

		@Prop({type: Boolean, default: false})
		public small!: boolean;

		@Prop({type: String, default: undefined})
		public prevColor!: string | undefined;

		@Prop({type: String, default: 'primary'})
		public nextColor!: string | undefined;

		@Prop({type: Boolean, default: false})
		public hideLabels!: boolean;

		private icons = Icon;

		private get hasPrepend(): boolean {
			return !!this.$slots['prepend'];
		}

		private get hasAppend(): boolean {
			return !!this.$slots['append'];
		}

		@Emit('prev')
		public prev(): void {
			return;
		}

		@Emit('next')
		public next(): void {
			return;
		}

	}
</script>

<style lang="scss" scoped>

</style>
