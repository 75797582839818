<template>
	<v-menu v-if="!noPopup" v-model="expanded" bottom origin="top">
		<template v-slot:activator="{on}">
			<v-badge :color="badgeColor" :dot="badgeDot && !badgeIcon" :icon="badgeIcon" :value="badge" bordered left overlap top>
				<v-chip v-on="on" :class="{'pa-0': hideText}" :close="close" :close-icon="icons.close" :color="color"
				        :label="label" :pill="!label" @click:close="onClose">
					<AsiAvatar v-if="!hideAvatar" :bg-color="avatarBgColor" :icon="typeIcon" :image-url="avatarUrl"
					           :left="!hideText" :rounded="label" :size="40"/>
					<span v-if="!hideText">{{ textFinal }}</span>
				</v-chip>
			</v-badge>
		</template>

		<AsiCard :icon="icons.customer" :image-url="avatarUrl" :subtitle="typeLabel" :loading="loading"
		         :title="textFinal" :title-bg-color="titleBgColor" no-bottom-margin unwrapped>
			<template v-slot:headerActions>
				<AsiBtn :icon="icons.view" @click="openDetail"/>
			</template>

			<slot v-if="hasContent"/>
		</AsiCard>
	</v-menu>

	<v-badge v-else :color="badgeColor" :dot="badgeDot && !badgeIcon" :icon="badgeIcon" :value="badge" bordered left overlap top>
		<v-chip :class="{'pa-0': hideText}" :close="close" :close-icon="icons.close" :color="color"
		        :label="label" :pill="!label" @click:close="onClose">
			<AsiAvatar v-if="!hideAvatar" :bg-color="avatarBgColor" :icon="typeIcon" :image-url="avatarUrl"
			           :left="!hideText" :rounded="label" :size="40"/>
			<span v-if="!hideText">{{ textFinal }}</span>
		</v-chip>
	</v-badge>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiCard from "@/components/common/AsiCard.vue";
	import Icon from "@/plugins/icons";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import {AttachmentMediaSize, CustomerType} from "@/helpers/constants";
	import EnumHelper from "@/helpers/EnumHelper";
	import RightChecks from "@/mixins/RightChecks.vue";
	import {mixins} from "vue-class-component";
	import Snackbar from "@/helpers/Snackbar";
	import {ICustomerAdmin, ICustomerAdminSimple} from "@/models/customer/CustomerAdminModels";
	import CustomerHelper from "@/models/customer/CustomerHelper";

	@Component({
		components: {AsiBtn, AsiAvatar, AsiCard}
	})
	export default class CompanyBusinessCard extends mixins(RightChecks) {

		@Prop({type: Object, required: true})
		public customer!: ICustomerAdminSimple;

		@Prop({type: Boolean, default: false})
		public noPopup!: boolean;

		@Prop({type: Boolean, default: false})
		public hideAvatar!: boolean;

		@Prop({type: Boolean, default: false})
		public hideText!: boolean;

		@Prop({type: String, default: 'grey lighten-3'})
		public color!: string;

		@Prop({type: String, default: 'primary'})
		public avatarBgColor!: string;

		@Prop({type: String, default: 'grey lighten-3'})
		public titleBgColor!: string;

		@Prop({type: Boolean, default: false})
		public badge!: boolean;

		@Prop({type: String, default: 'primary'})
		public badgeColor!: string;

		@Prop({type: Boolean, default: false})
		public badgeDot!: boolean;

		@Prop({type: String, default: null})
		public badgeIcon!: string | null;

		@Prop({type: Boolean, default: false})
		public close!: boolean;

		@Prop({type: Boolean, default: false})
		public label!: boolean;

		private icons = Icon;
		private expanded: boolean = false;
		private detailModel: ICustomerAdmin | null = null;
		private loading: boolean = false;

		private get typeLabel(): string | null {
			return EnumHelper.textFromValue(CustomerType, this.customer.type, true);
		}

		private get typeIcon(): string | null {
			return this.customer.type === CustomerType.private
				? Icon.customerPrivate
				: Icon.customerBusiness;
		}

		private get avatarUrl(): string | null {
			return CustomerHelper.avatarUrl(this.customer, AttachmentMediaSize.s);
		}

		private get textFinal(): string | null {
			return this.customer.name;
		}

		private get hasContent(): boolean {
			return (this.$slots.default ?? []).length > 0;
		}

		@Emit('close')
		public onClose(event: Event): Event {
			return event;
		}

		@Watch('expanded')
		private onExpandedChanged(value: boolean): void {
			if (!value || this.detailModel !== null) return;

			this.loading = true;
			this.$customerServiceAdmin.customer(this.customer.id)
				.then(c => this.detailModel = c)
				.catch(() => Snackbar.loadingError())
				.finally(() => this.loading = false);
		}

		private openDetail(): void {
			this.$router.push(CustomerHelper.detailRouteAdmin(this.customer.id));
		}

	}
</script>

<style lang="scss" scoped>

</style>
