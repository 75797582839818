<template>
	<AsiListTableLayout hide-toolbar>
		<v-data-table
			:items="item.additionalAttachments"
			:headers="createHeaders()"
			:loading="loadingInternal || loadingDelete"
			:hide-default-footer="true"
		>
			<template v-slot:item.avatar="{item}">
				<AsiAvatar :size="32" :icon="icon(item)"/>
			</template>

			<template v-slot:item.name="{item}">
				<div class="d-flex flex-column">
					<span>{{ item.name.de !== null ? item.name.de : '-' }}</span>
					<small class="grey--text">{{ item.name.fr !== null ? item.name.fr : '-' }}</small>
					<small class="grey--text">{{ item.name.en !== null ? item.name.en : '-' }}</small>
				</div>
			</template>

			<template v-slot:item.files="{item}">
				<span>{{ fileSize(item) }}</span>
			</template>

			<template v-if="showActions" v-slot:item.actions="{item}">
				<div class="d-flex flex-row flex-wrap justify-end">
					<AsiBtn v-if="canPreview(item)" :icon="icons.preview" small @click.native.stop="preview(item)"/>
					<AsiBtn :disabled="loadingDelete" :icon="icons.edit" small @click.native.stop="updateAttachment = item; updateDialog.open();"/>
					<AsiBtn :disabled="loadingDelete" :icon="icons.delete" small @click="performDelete(item)"/>
				</div>
			</template>
		</v-data-table>

		<AsiConfirmDialog v-if="showActions" ref="confirm"/>
		<AttachmentUpdateDialog v-if="showActions && updateDialog.isLoaded && updateAttachment !== null"
		                        :service="$itemServiceAdmin" :subject-id="item.id"
		                        :open="updateDialog.isOpen" :attachment-model="updateAttachment" @cancel="updateDialog.close()"
		                        @save="changed(updateAttachment.id); updateDialog.close();"/>
		<AttachmentPreviewDialog v-if="previewDialog.isLoaded" :open="previewDialog.isOpen"
		                         :attachment="previewAttachment" @cancel="previewDialog.close()"/>
	</AsiListTableLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop} from "vue-property-decorator";
	import AsiListTableHeader from "../common/AsiListTableHeader";
	import {IAttachment, IAttachmentFile, IAttachmentListEntry} from "@/models/attachment/AttachmentModels";
	import AsiListTable from "@/components/common/AsiListTable.vue";
	import AttachmentListFilter from "@/models/attachment/AttachmentListFilter";
	import {AttachmentMediaSize} from "@/helpers/constants";
	import AttachmentHelper from "@/models/attachment/AttachmentHelper";
	import Icon from "@/plugins/icons";
	import AsiListTableLayout from "@/components/common/AsiListTableLayout.vue";
	import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";
	import {IItemAdmin} from "@/models/item/ItemAdminModels";
	import Snackbar from "@/helpers/Snackbar";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";
	import AttachmentUpdateDialog from "@/components/attachment/AttachmentUpdateDialog.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import AttachmentPreviewDialog from "@/components/attachment/AttachmentPreviewDialog.vue";

	@Component({
		components: {AttachmentPreviewDialog, AttachmentUpdateDialog, AsiAvatar, AsiConfirmDialog, AsiListTableLayout}
	})
	export default class AttachmentList extends AsiListTable<IAttachmentListEntry, AttachmentListFilter> {

		@Prop({required: true})
		public item!: IItemAdmin | null;

		@Prop({type: Boolean, default: false})
		public showActions!: boolean;

		private icons = Icon;
		private loadingDelete: boolean = false;
		private updateAttachment: IItemAdmin | null = null;
		private updateDialog: DialogHandler = new DialogHandler(() => this.updateAttachment = null);

		private previewDialog: DialogHandler = new DialogHandler(() => this.previewAttachment = null);
		private previewAttachment: IAttachment | null = null;

		public createHeaders(): AsiListTableHeader[] {
			const ret: AsiListTableHeader[] = [
				new AsiListTableHeader('', 'avatar', false, false, 'center', '4rem'),
				new AsiListTableHeader(this.$t('ui.terms.name'), 'name', true, true, 'start'),
				new AsiListTableHeader(this.$t('ui.terms.fileName'), 'filename', true, true, 'start'),
				new AsiListTableHeader(this.$t('attachment.mimeType'), 'mimeType', true, true, 'start', '15%'),
				new AsiListTableHeader(this.$t('attachment.fileSize'), 'files', true, true, 'end', '15%'),
			];

			if (this.showActions) {
				ret.push(
					new AsiListTableHeader('', 'actions', false, false, 'end', '8rem'),
				);
			}

			return ret;
		}

		private fileSize(attachment: IAttachment): string {
			const origFile: IAttachmentFile | null = attachment.files.find(a => a.mediaSize === AttachmentMediaSize.orig) ?? null;
			return origFile !== null ? AttachmentHelper.fileSizeFormatted(origFile.fileSize) : '';
		}

		private icon(attachment: IAttachment): string {
			return AttachmentHelper.icon(attachment);
		}

		private canPreview(attachment: IAttachment): boolean {
			return AttachmentHelper.isImageAttachment(attachment);
		}

		private preview(attachment: IAttachment): void {
			this.previewAttachment = attachment;
			this.previewDialog.open();
		}

		private performDelete(entry: IAttachmentListEntry): void {
			const confirm = this.$refs.confirm as unknown as AsiConfirmDialog;
			confirm.openDialog().then((res: boolean) => {
				if (!res) return;

				const id = entry.id;
				this.loadingDelete = true;
				if (this.item === null) return;
				this.$itemServiceAdmin.deleteAdditionalAttachment(this.item.id, entry.id)
					.then(() => {
						this.deleted(id);
					})
					.catch(() => Snackbar.deleteError())
					.finally(() => this.loadingDelete = false);
			});
		}

		@Emit('changed')
		public changed(id: string): string {
			return id;
		}

		@Emit('deleted')
		public deleted(id: string): string {
			return id;
		}
	}
</script>

<style lang="scss" scoped>

</style>
