<template>
	<AsiCardDetail :title="$t('user.terms.name')" :icon="icons.name"
	               :show-action-button="!readonly" @actionButtonClicked="updateDialog.open()">
		<AsiCardDetailEntry :icon="icons.salutation" :label="$t('user.salutation')" :value="getSalutation(user.salutation)"/>
		<AsiCardDetailEntry :icon="icons.name" :label="$t('user.firstname')" :value="user.firstname"/>
		<AsiCardDetailEntry :icon="icons.name" :label="$t('user.lastname')" :value="user.lastname"/>
		<AsiCardDetailEntry :icon="icons.name" :label="$t('user.acronym')" :value="user.acronym"/>
		<AsiCardDetailEntry :icon="icons.mail" :label="$t('ui.terms.username')" :value="user.username"/>

		<UserUpdateDialogName v-if="!readonly && updateDialog.isLoaded" :open="updateDialog.isOpen" :user="user"
		                      @cancel="updateDialog.close()" @save="updateDialog.close(); change();"/>
	</AsiCardDetail>
</template>

<script lang="ts">
	import {Component, Emit, Prop} from "vue-property-decorator";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import {IUserShop} from "@/models/user/UserShopModels";
	import {IUserAdmin} from "@/models/user/UserAdminModels";
	import Icon from "@/plugins/icons";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import UserUpdateDialogName from "@/components/user/UserUpdateDialogName.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import Vue from "vue";
	import {Salutation} from "@/helpers/constants";
	import EnumHelper from "@/helpers/EnumHelper";

	@Component({
		components: {UserUpdateDialogName, AsiCardDetailEntry, AsiCardDetail}
	})
	export default class UserCardName extends Vue {

		@Prop({type: Object, required: true})
		public user!: IUserShop | IUserAdmin;

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		private icons = Icon;
		private updateDialog: DialogHandler = new DialogHandler();

		@Emit('change')
		public change(): void {
			return;
		}

		private getSalutation(salutation: Salutation | null): string {
			return EnumHelper.textFromValue(Salutation, salutation, true) ?? '';
		}

	}
</script>

<style lang="scss" scoped>

</style>
