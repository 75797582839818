<template>
	<div>
		<AsiCard :title="title" :icon="icon" :no-bottom-margin="noBottomMargin">
			<v-simple-table dense>
				<thead>
					<tr>
						<th class="text-left">{{ $t('mailTemplate.placeholder') }}</th>
						<th class="text-left">{{ $t('ui.terms.description') }}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(info, i) in infos" :key="i">
						<td class="field-name"><code>{{ info.fieldName }}</code></td>
						<td class="font-weight-light">{{ $t(info.fieldDescription) }}</td>
					</tr>
				</tbody>
			</v-simple-table>
		</AsiCard>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import MailTemplateInfo from "@/models/mail-template/MailTemplateInfo";
	import AsiCard from "@/components/common/AsiCard.vue";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	@Component({
		components: {AsiCardDetail, AsiCard}
	})
	export default class MailTemplateInfoCard extends Vue {

		@Prop({type: String, required: true})
		public title!: string;

		@Prop({type: Array, required: true})
		public infos!: MailTemplateInfo[];

		@Prop({default: null})
		public icon!: string | null;

		@Prop({type: Boolean, default: false})
		public noBottomMargin!: boolean;
	}
</script>

<style lang="scss" scoped>
	.field-name {
		width: 25em;
	}
</style>
