<template>
	<AsiChip :icon="stateIcon(order.shipmentState)" :color="stateColor(order.shipmentState)" small v-if="!expanded">
		{{ stateLabel(order.shipmentState) }}
	</AsiChip>
	<ul v-else>
		<template v-for="(state, i) in states">
			<li v-if="i > 0" :key="'sep-' + state">
				<v-icon :class="{'muted': order.shipmentState !== null && state < order.shipmentState }" x-small>
					{{ icons.next }}
				</v-icon>
			</li>
			<li :key="'entry-' + state">
				<AsiChip :icon="stateIcon(state)" :color="stateColor(state)" small :disabled="state < order.shipmentState" :outlined="state > order.shipmentState">
					{{ stateLabel(state) }}
				</AsiChip>
			</li>
		</template>
	</ul>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import EnumHelper from "@/helpers/EnumHelper";
	import AsiChip from "@/components/common/AsiChip.vue";
	import {IOrderAdminSimple} from "@/models/order/OrderAdminModels";
	import {IOrderShopSimple} from "@/models/order/OrderShopModels";
	import OrderHelper from "@/helpers/OrderHelper";
	import {OrderShipmentState} from "@/helpers/constants";
	import Icon from "@/plugins/icons";

	@Component({
		components: {AsiChip}
	})
	export default class OrderShipmentStateChip extends Vue {

		@Prop({type: Object, required: true})
		public order!: IOrderAdminSimple | IOrderShopSimple;

		@Prop({type: Boolean, default: false})
		public expanded!: boolean;

		private icons = Icon;

		private stateLabel(shipmentState: OrderShipmentState): string|null {
			return EnumHelper.textFromValue(OrderShipmentState, shipmentState, true);
		}
		private stateIcon(shipmentState: OrderShipmentState): string {
			return OrderHelper.shipmentStateIcon(shipmentState);
		}
		private stateColor(shipmentState: OrderShipmentState): string {
			return OrderHelper.shipmentStateColor(shipmentState);
		}

		private get states(): OrderShipmentState[] {
			return EnumHelper.toSelectItems(OrderShipmentState, true).map(s => s.value) as OrderShipmentState[];
		}

	}
</script>

<style lang="scss" scoped>
	ul {
		list-style-type: none;
		padding: 0 !important;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;

		> li {
			.v-icon {
				margin: 0 5px;

				&.muted {
					opacity: 0.3;
				}
			}
		}
	}
</style>
