<template>
	<AsiCardDetail :title="$t('paymentType.singular')" multi-column
	               prepend-unwrapped append-unwrapped>
		<AsiCardDetailEntry :label="$t('paymentType.singular')" :value="translatedValue(order.paymentType.name)"
		                    :icon="icons.paymentType"/>
		<AsiCardDetailEntry :label="$t('constants.orderPaid')" :value="isPaid"
		                    :icon="isPaid ? icons.yes : icons.no" :icon-color="isPaid ? 'success' : 'error'"/>

		<template v-slot:prepend v-if="paymentFailed">
			<v-divider/>
			<div class="pa-6">
				<AsiAlert type="error" :icon="icons.error" no-bottom-margin>
					{{ $t('order.terms.paymentFailed') }}
				</AsiAlert>
			</div>
		</template>

		<template v-slot:append v-if="canReinitialize">
			<div class="px-6 py-3 grey lighten-4 text-center">
				<AsiBtn :icon="icons.paymentType" color="success" @click="reinitializePaymentDialog.open()">
					{{ $t('order.terms.payNow') }}
				</AsiBtn>
			</div>
		</template>

		<OrderPaymentRedirectionDialog v-if="initializePaymentDialog.isLoaded && paymentUrlInternal !== null"
		                               :open="initializePaymentDialog.isOpen" :redirection-timeout-seconds="5"
		                               :url="paymentUrlInternal" @cancel="initializePaymentDialog.close()"/>
		<OrderPaymentAssertDialog v-if="assertPaymentDialog.isLoaded" :id="order.id" :open="assertPaymentDialog.isOpen"
		                          @cancel="assertPaymentDialog.close()"/>
		<OrderPaymentReinitializeDialog v-if="canReinitialize && reinitializePaymentDialog.isLoaded" :id="order.id"
		                                :open="reinitializePaymentDialog.isOpen"
		                                @cancel="reinitializePaymentDialog.close()" @save="requestPayment"/>
	</AsiCardDetail>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import {IOrderShop} from "@/models/order/OrderShopModels";
	import DialogHandler from "@/components/common/DialogHandler";
	import Snackbar from "@/helpers/Snackbar";
	import AsiCard from "@/components/common/AsiCard.vue";
	import OrderPaymentRedirectionDialog from "@/components/order/shop/OrderPaymentRedirectionDialog.vue";
	import OrderPaymentAssertDialog from "@/components/order/shop/OrderPaymentAssertDialog.vue";
	import {OrderPaymentState, ParamPaymentState} from "@/helpers/constants";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import {ITranslatedValue} from "@/models/translated-value/TranslatedValueModels";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import AsiAlert from "@/components/common/AsiAlert.vue";
	import OrderPaymentReinitializeDialog from "@/components/order/shop/OrderPaymentReinitializeDialog.vue";
	import IOrderPaymentResponse from "@/models/IOrderPaymentResponse";

	@Component({
		components: {
			OrderPaymentReinitializeDialog,
			AsiAlert,
			AsiBtn,
			AsiCardDetailEntry,
			AsiCardDetail,
			OrderPaymentAssertDialog,
			OrderPaymentRedirectionDialog,
			AsiCard
		}
	})
	export default class OrderCardPayment extends Vue {

		@Prop({required: true})
		public order!: IOrderShop;

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		@Prop({type: String, default: null})
		public paymentUrl!: string | null;

		@Prop({type: String, default: null})
		public paymentState!: string | null;

		@Prop({type: String, default: null})
		public paymentId!: string | null;

		private icons = Icon;
		private loadingInternal: boolean = false;
		private initializePaymentDialog: DialogHandler = new DialogHandler();
		private assertPaymentDialog: DialogHandler = new DialogHandler();
		private reinitializePaymentDialog: DialogHandler = new DialogHandler();
		private paymentUrlInternal: string | null = null;

		public created(): void {
			this.initialize();
		}

		private initialize(): void {
			if (this.paymentUrlInternal !== null) {
				this.initializePaymentDialog.open();
			} else if (this.paymentState === ParamPaymentState.success && this.paymentId !== null) {
				this.performAssertPayment(this.paymentId);
			}
		}

		private get isPaid(): boolean {
			return this.order.paymentState === OrderPaymentState.orderPaymentPaid;
		}

		private get canReinitialize(): boolean {
			return !this.isPaid && this.order.paymentType.paymentProviderCode !== null;
		}

		private get paymentFailed(): boolean {
			return this.paymentState === 'failure';
		}

		@Watch('order', {deep: true})
		@Watch('paymentState')
		@Watch('paymentId')
		private onOrderChanged(): void {
			this.initialize();
		}

		@Watch('loading')
		private onLoadingChanged(value: boolean): void {
			this.loadingInternal = value;
		}

		@Watch('loadingInternal')
		private onLoadingInternalChanged(value: boolean): void {
			if (this.loading !== value) this.$emit('update:loading', value);
		}

		@Watch('paymentUrl', {immediate: true})
		private onPaymentUrlChanged(value: string | null): void {
			this.paymentUrlInternal = value;
		}

		@Watch('paymentUrlInternal')
		private onPaymentUrlInternalChanged(value: string | null): void {
			if (this.paymentUrl !== value) this.$emit('update:paymentUrl', value);
			this.initialize();
		}

		@Emit('change')
		public change(): void {
			return;
		}

		@Emit('requestPayment')
		public requestPayment(result: IOrderPaymentResponse): IOrderPaymentResponse {
			this.reinitializePaymentDialog.close();
			this.paymentUrlInternal = result.redirectUrl;
			return result;
		}

		private performAssertPayment(paymentId: string): void {
			this.loadingInternal = true;
			this.assertPaymentDialog.open();
			this.$orderServiceShop.assertPayment(paymentId)
				.catch(() => Snackbar.updateError())
				.finally(() => {
					this.loadingInternal = false;
					this.assertPaymentDialog.close();
					this.change();
				});
		}

		private translatedValue(value: ITranslatedValue): string | null {
			return TranslatedValueHelper.get(value, this.$i18n.locale, true);
		}

	}
</script>

<style lang="scss" scoped>

</style>
