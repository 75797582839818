<template>
	<div>
		<v-stepper :value="value" @change="input" :vertical="!hasHeaderSlot" :alt-labels="hasHeaderSlot" flat tile>
			<template v-if="hasHeaderSlot">
				<v-stepper-header v-if="hasHeaderSlot">
					<slot name="header"/>
				</v-stepper-header>
				<v-stepper-items>
					<slot/>
				</v-stepper-items>
			</template>

			<slot v-else/>
		</v-stepper>
		<template v-if="nav">
			<v-divider/>
			<div class="px-6 py-3 grey lighten-4">
				<AsiStepperNavigation :small="navSmall"
				                      :hide-prev="prevStep === null" :hide-next="nextStep === null"
				                      :prev-disabled="!editableSteps.includes(prevStep)" :next-disabled="!editableSteps.includes(nextStep)"
				                      @prev="input(prevStep)" @next="input(nextStep)"/>
			</div>
		</template>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import AsiStepperNavigation from "@/components/common/AsiStepperNavigation.vue";

	@Component({
		components: {AsiStepperNavigation}
	})
	export default class AsiStepper extends Vue {

		@Prop({type: [String, Number], required: true})
		public value!: number | string;

		@Prop({type: Array, default: () => []})
		public steps!: (number | string)[];

		@Prop({type: Array, default: () => []})
		public editableSteps!: (number | string)[];

		@Prop({type: Boolean, default: false})
		public nav!: boolean;

		@Prop({type: Boolean, default: true})
		public navSmall!: boolean;

		@Emit('input')
		public input(value: number | string): number | string {
			return value;
		}

		private get currentStepIndex(): number | null {
			const index = this.steps.indexOf(this.value);
			return index === -1 ? null : index;
		}

		private get prevStep(): string | number | null {
			if (this.currentStepIndex === null || this.currentStepIndex === 0) return null;
			return this.steps[this.currentStepIndex - 1];
		}

		private get nextStep(): string | number | null {
			if (this.currentStepIndex === null || this.currentStepIndex === this.steps.length - 1) return null;
			return this.steps[this.currentStepIndex + 1];
		}

		private get hasHeaderSlot(): boolean {
			return !!this.$slots['header'] || !!this.$scopedSlots['header'];
		}

	}
</script>

<style lang="scss" scoped>

</style>
