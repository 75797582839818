<template>
	<ShopView>
		<h1 class="mb-6 d-flex align-center">
			{{ $t('pageTitles.shop.faq') }}
		</h1>

		<AsiCard unwrapped>
			<v-card-text class="pa-6">
				<Faqs/>
			</v-card-text>
		</AsiCard>
	</ShopView>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component} from 'vue-property-decorator';
	import Faqs from "@/components/faq/Faqs.vue";
	import ShopView from "@/components/layout/ShopView.vue";
	import AsiCard from "@/components/common/AsiCard.vue";

	@Component({
		components: {AsiCard, ShopView, Faqs}
	})
	export default class Faq extends Vue {

	}
</script>

<style lang="scss" scoped>

</style>
