<template>
	<ShopView>
		<h1 class="mb-6">
			{{ $t('pageTitles.shop.registration') }}
		</h1>

		<template v-if="rLoggedIn">
			<AsiAlert type="info" no-bottom-margin>
				{{ $t('ui.terms.alreadyLoggedIn') }}
			</AsiAlert>
			<div class="d-flex flex-row justify-center">
				<AsiBtn @click="showHome" color="primary" :icons="icons.home" large class="mt-12">
					{{ $t('ui.terms.backToShop') }}
				</AsiBtn>
			</div>
		</template>
		<RegistrationWizard v-else simple/>
	</ShopView>
</template>

<script lang="ts">
	import {Component} from "vue-property-decorator";
	import {mixins} from "vue-class-component";
	import RightChecks from "@/mixins/RightChecks.vue";
	import AsiAlert from "@/components/common/AsiAlert.vue";
	import RoutesHelper from "@/helpers/RoutesHelper";
	import ShopView from "@/components/layout/ShopView.vue";
	import Icon from "@/plugins/icons";
	import RegistrationWizard from "@/components/user/shop/RegistrationWizard.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";

	@Component({
		components: {AsiBtn, RegistrationWizard, ShopView, AsiAlert}
	})
	export default class Registration extends mixins(RightChecks) {

		private icons = Icon;

		private showHome(): void {
			this.$router.push(RoutesHelper.homeShopRoute());
		}

	}
</script>

<style lang="scss" scoped>

</style>
