<template>
	<AsiDialogFormLayout v-if="model !== null" :icon="icons.shipping" :title="$t('item.terms.salesData')"
	                     :open="open" :loading="loading" :valid="valid" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped scrollable>
		<v-card-text class="pa-0">
			<v-form v-model="valid">
				<div class="pa-6">
					<v-row>
						<v-col>
							<AsiCheckbox v-model="stockNoInformation" :label="$t('constants.stockNoInformation')" class="ma-0" />
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<AsiTextField v-model="model.stockQuantity" :label="$t('item.stockQuantity')" type="number"
							              :placeholder="$t('item.stockQuantity')"
							              :disabled="stockNoInformation"
							              :class="{'input-required': !stockNoInformation}"
							              :rules="!stockNoInformation ? model.rules('stockQuantity') : []" />
						</v-col>
					</v-row>
				</div>
				<v-divider />
				<div class="pa-6">
					<v-row>
						<v-col>
							<AsiSelect v-model="model.baseUnitOfMeasure" :label="$t('item.baseUnitOfMeasure')" :placeholder="$t('item.baseUnitOfMeasure')"
							           :items="options" :rules="model.rules('baseUnitOfMeasure')" class="input-required"/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<AsiSelect v-model="model.salesUnitOfMeasure" :label="$t('item.salesUnitOfMeasure')" :placeholder="$t('item.salesUnitOfMeasure')"
							           :items="options" :rules="model.rules('salesUnitOfMeasure')" class="input-required"/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<AsiTextField v-model="model.salesUnitQuantity" :label="$t('item.salesUnitQuantity')" type="number" :placeholder="$t('item.salesUnitQuantity')" :rules="model.rules('salesUnitQuantity')"/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<AsiCheckbox v-model="model.isTopSeller" :label="$t('item.isTopSeller')"/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<AsiCheckbox v-model="model.canOrderSample" :label="$t('item.canOrderSample')"/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<AsiTextField v-model="model.deliveryTime" :label="$t('item.deliveryTime')" type="number" :placeholder="$t('item.deliveryTime')" clearable/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<AsiCheckbox v-model="model.isSalesUnitFixed" :label="$t('item.isSalesUnitFixed')"/>
						</v-col>
					</v-row>
				</div>
			</v-form>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiDialogForm from "../../common/AsiDialogForm.vue";
	import {IItemAdmin} from "@/models/item/ItemAdminModels";
	import ItemUpdateSalesData from "../../../models/item/ItemUpdateSalesData";
	import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
	import AsiTextField from "@/components/common/AsiTextField";
	import AsiSelect from "@/components/common/AsiSelect";
	import AsiCheckbox from "@/components/common/AsiCheckbox";
	import EnumHelper from "@/helpers/EnumHelper";
	import {UnitOfMeasure} from "@/helpers/constants";

	@Component({
		components: {AsiCheckbox, AsiSelect, AsiTextField, AsiDialogFormLayout}
	})
	export default class ItemUpdateDialogSalesData extends AsiDialogForm {
		@Prop({type: Object, required: true})
		public item!: IItemAdmin;

		private model: ItemUpdateSalesData | null = null;
		private stockNoInformation: boolean = false;

		// noinspection JSMethodCanBeStatic
		private get options(): { text: string, value: string | number }[] {
			return EnumHelper
				// eslint-disable-next-line no-undef
				.toSelectItems(UnitOfMeasure, true);
		}

		@Watch('item', {deep: true, immediate: true})
		private onItemChanged(): void {
			this.setItemInternal();
		}

		@Watch('stockNoInformation')
		private onStockNoInformationChanged(value: boolean): void {
			if (this.model === null) return;

		    if (value) {
				this.model.stockQuantity = null;
		    }
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			this.setItemInternal();
			const form = this.$refs.form as HTMLFormElement;
			this.reset([], form);
			return event;
		}

		private setItemInternal(): void {
			if (this.model === null) {
				this.model = new ItemUpdateSalesData(this.item);
				this.valid = true;
			} else {
				this.model.setDetailModel(this.item);
			}
		}

		private requestSave(): void {
			if (this.model === null) return;

			this.loading = true;
			this.$itemServiceAdmin.updateSalesData(this.item.id, this.model)
				.then(this.save)
				.catch(this.handleErrors)
				.finally(() => this.loading = false);
		}
	}
</script>

<style lang="scss" scoped>

</style>
