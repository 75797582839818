<template>
	<section>
		<AsiPageTitle :title="$t('item.plural')" :icon="icons.items" @create="createDialog.open()"/>
		<ItemList memory-key="admin-items" ref="itemList"/>

		<ItemCreateDialog v-if="rMasterData && createDialog.isLoaded" :open="createDialog.isOpen" @save="itemCreated" @cancel="createDialog.close()" />
	</section>
</template>

<script lang="ts">
	import {Component} from "vue-property-decorator";
	import ItemList from "@/components/item/admin/ItemList.vue";
	import AsiPageTitle from "@/components/common/AsiPageTitle.vue";
	import Icon from "@/plugins/icons";
	import DialogHandler from "@/components/common/DialogHandler";
	import ItemCreateDialog from "@/components/item/admin/ItemCreateDialog.vue";
	import {mixins} from "vue-class-component";
	import RightChecks from "@/mixins/RightChecks.vue";

	@Component({
		components: {ItemCreateDialog, AsiPageTitle, ItemList}
	})
	export default class Items extends mixins(RightChecks) {

		private icons = Icon;
		private createDialog: DialogHandler = new DialogHandler();

		private itemCreated(): void {
			this.createDialog.close();

			let list = this.$refs.itemList;
			if (list !== undefined) {
				(this.$refs.itemList as ItemList).reload();
			}
		}

	}
</script>

<style lang="scss" scoped>

</style>
