<template>
	<AsiDialogFormLayout v-if="model !== null" :icon="icons.shipmentType" :title="$t('shipmentType.singular')"
							:open="open" :loading="loading" :valid="valid" :errors="errors"
							@save="requestSave" @cancel="cancel" unwrapped scrollable>
		<v-card-text class="pa-0">
			<v-form v-model="valid">
				<div class="pa-6">
					<v-row>
						<v-col>
							<TranslatedValueFormPart :label="$t('shipmentType.name')" :model="model.name" required/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<AsiRadioGroup v-model="model.deliveryMethod" row class="ma-0">
								<v-radio v-for="dm in deliveryMethods" :key="dm.value" :value="dm.value" :label="dm.text" class="mr-6"/>
							</AsiRadioGroup>
						</v-col>
					</v-row>
				</div>
				<v-divider/>
				<div class="pa-6">
					<v-row>
						<v-col>
							<AsiTextField v-model="model.postage" :rules="model.rules('postage')"
							              :label="$t('shipmentType.postage')" :placeholder="$t('shipmentType.postage')"
							              class="input-required" :suffix="currencyCode" clearable/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<AsiTextField v-model="model.externalId" :rules="model.rules('externalId')"
							              :label="$t('paymentType.externalId')" :placeholder="$t('paymentType.externalId')" clearable/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<AsiCheckbox v-model="model.isBulkyGoodsCharged" :label="$t('shipmentType.isBulkyGoodsCharged')"
							             class="mr-6 mt-0"/>
						</v-col>
					</v-row>
				</div>
			</v-form>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiDialogFormLayout from "../../common/AsiDialogFormLayout.vue";
	import ShipmentTypeUpdate from "@/models/shipment-type/ShipmentTypeUpdate";
	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
	import Snackbar from '@/helpers/Snackbar';
	import TranslatedValueFormPart from "@/components/translated-value/admin/TranslatedValueFormPart.vue";
	import AsiTextField from "@/components/common/AsiTextField";
	import {IShipmentTypeAdmin} from "@/models/shipment-type/ShipmentTypeAdminModels";
	import AsiCheckbox from "@/components/common/AsiCheckbox";
	import AsiRadioGroup from "@/components/common/AsiRadioGroup";
	import EnumHelper from "@/helpers/EnumHelper";
	import {DeliveryMethod} from "@/helpers/constants";
	@Component({
		components: {AsiRadioGroup, AsiCheckbox, AsiTextField, TranslatedValueFormPart, AsiDialogFormLayout}
	})
	export default class ShipmentTypeUpdateDialog extends AsiDialogForm {

		@Prop({type: String, required: true})
		public id!: string;

		private model: ShipmentTypeUpdate | null = null;
		private detailModel: IShipmentTypeAdmin | null = null;

		private get currencyCode(): string {
			//TODO: maybe later replace with configurable currency
			return 'CHF';
		}

		private get deliveryMethods(): {text: string, value: string | number}[] {
			return EnumHelper.toSelectItems(DeliveryMethod, true);
		}

		private setShipmentTypeInternal(): void {
			if (this.detailModel === null) {
				this.model = null;
				return;
			}

			if (this.model === null) {
				this.model = new ShipmentTypeUpdate(this.detailModel);
				this.valid = true;
			} else {
				this.model.setDetailModel(this.detailModel);
			}
		}

		private requestSave(): void {
			if (this.model === null) return;

			this.loading = true;
			this.$shipmentTypeServiceAdmin.update(this.id, this.model)
				.then(this.save)
				.catch(this.handleErrors)
				.finally(() => this.loading = false);
		}

		private loadShipmentType(): void {
			this.loading = true;
			this.$shipmentTypeServiceAdmin.shipmentType(this.id)
				.then(model => {
					this.detailModel = model;
					this.setShipmentTypeInternal();
				})
				.catch(() => Snackbar.loadingError())
				.finally(() => this.loading = false);
		}


		@Watch('id', {immediate: true})
		private onIdChanged(): void {
			this.loadShipmentType();
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			this.setShipmentTypeInternal();
			const form = this.$refs.form as HTMLFormElement;
			this.reset([], form);
			return event;
		}

	}
</script>

<style lang="scss" scoped>

</style>
