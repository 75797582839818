<template>
	<AsiCardDetail :icon="icons.id" :title="$t('customer.terms.numbers')"
	               :show-action-button="!readonly" :action-button-disabled="disabled" @actionButtonClicked="updateDialog.open()">
		<AsiCardDetailEntry :icon="icons.id" :label="$t('customer.customerNumber')" :value="customer.customerNumber"/>
		<AsiCardDetailEntry :icon="icons.externalSource" :label="$t('customer.externalId')" :value="customer.externalId"/>

		<CustomerUpdateDialogNumbers v-if="!readonly && updateDialog.isLoaded" :open="updateDialog.isOpen" :customer="customer"
		                             @cancel="updateDialog.close()" @save="updateDialog.close(); change();"/>
	</AsiCardDetail>
</template>

<script lang="ts">
	import Vue from "vue";
	import {Component, Emit, Prop} from "vue-property-decorator";
	import {ICustomerAdmin} from "@/models/customer/CustomerAdminModels";
	import Icon from "@/plugins/icons";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import CustomerUpdateDialogNumbers from "@/components/customer/admin/CustomerUpdateDialogNumbers.vue";

	@Component({
		components: {CustomerUpdateDialogNumbers, AsiCardDetailEntry, AsiCardDetail}
	})
	export default class CustomerCardNumbers extends Vue {

		@Prop({type: Object, required: true})
		public customer!: ICustomerAdmin;

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		private icons = Icon;
		private updateDialog: DialogHandler = new DialogHandler();

		@Emit('change')
		public change(): void {
			return;
		}

	}
</script>

<style lang="scss" scoped>

</style>
