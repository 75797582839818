<template>
	<div @mouseover="slideShowRunning = true" @mouseleave="slideShowRunning = false">
		<AsiCard unwrapped :no-bottom-margin="noBottomMargin" @click="showCategory">
			<v-fade-transition>
				<v-img :src="visibleImageUrl" :alt="nameTranslated" :aspect-ratio="1" class="rounded-t" :style="imageStyles"/>
			</v-fade-transition>
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="font-weight-medium">
						<router-link v-if="detailRoute !== null" :to="detailRoute" :title="nameTranslated"
						             @click.native.stop
						             style="text-decoration: inherit; color: inherit;">
							{{ nameTranslated }}
						</router-link>
						<template v-else>{{ nameTranslated }}</template>
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</AsiCard>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop, Watch} from "vue-property-decorator";
	import AsiCard from "@/components/common/AsiCard.vue";
	import {ICategoryShopListEntry} from "@/models/category/CategoryShopModels";
	import CategoryHelper from "@/models/category/CategoryHelper";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import {AttachmentMediaSize} from "@/helpers/constants";
	import {RawLocation} from "vue-router";
	import {IAttachment} from "@/models/attachment/AttachmentModels";
	import AttachmentHelper from "@/models/attachment/AttachmentHelper";

	@Component({
		components: {AsiCard}
	})
	export default class CategoryCard extends Vue {

		@Prop({type: String, required: true})
		public id!: string;

		@Prop({type: Boolean, default: false})
		public small!: boolean;

		@Prop({type: Boolean, default: false})
		public noBottomMargin!: boolean;

		private visibleImageUrl: string | null = null;
		private slideShowImageUrls: string[] = [];
		private slideShowRunning: boolean = false;
		private slideShowIntervalId: number | undefined;
		private slideShowTimeoutId: number | undefined;

		private get category(): ICategoryShopListEntry | null {
			return this.$store.getters['category/categoryById'](this.id);
		}

		// noinspection JSUnusedLocalSymbols
		private get imageStyles(): object {
			return {
				'border-bottom': '3px solid',
				'box-sizing': 'border-box',
				'border-bottom-color': this.category === null ? undefined : (CategoryHelper.colorHierarchical(this.category) ?? undefined),
				'background-image': 'linear-gradient(45deg, #00000044, transparent)',
				'background-color': this.category === null ? undefined : (CategoryHelper.colorHierarchical(this.category) ?? undefined),
			};
		}

		private get nameTranslated(): string | null {
			return this.category === null
				? null
				: TranslatedValueHelper.get(this.category.name, this.$i18n.locale);
		}

		private created(): void {
			this.visibleImageUrl = this.avatarUrl;
			if (this.category === null) return;

			const galleryAttachmentUrls: string[] = this.category.galleryAttachments.map(a => this.imageUrl(a) ?? '').filter(url => url !== '');
			this.slideShowImageUrls.push(...galleryAttachmentUrls);

			if (this.avatarUrl === null) return;

			if (!this.slideShowImageUrls.includes(this.avatarUrl)) {
				this.slideShowImageUrls.push(this.avatarUrl);
			}
		}

		private get avatarUrl(): string | null {
			return this.category === null ? null : CategoryHelper.avatarUrl(this.category, AttachmentMediaSize.m);
		}

		private get detailRoute(): RawLocation | null {
			return this.category === null ? null : CategoryHelper.detailRoute(CategoryHelper.currentCanonical(this.category.canonical));
		}

		private showCategory(): void {
			if (this.category === null || this.$router.currentRoute.params.canonical === CategoryHelper.currentCanonical(this.category.canonical) || this.detailRoute === null) {
				return;
			}
			this.$router.push(this.detailRoute);
		}

		private imageUrl(attachment: IAttachment): string | null {
			return AttachmentHelper.getImageUrl(attachment, AttachmentMediaSize.m);
		}

		@Watch('slideShowRunning')
		private onSlideShowRunningChanged(value: boolean): void {
			if (this.slideShowImageUrls.length < 2) return;

			if (value) {
				let index = this.slideShowImageUrls[0] === this.avatarUrl ? 1 : 0;

				this.slideShowTimeoutId = setTimeout(() => {
					this.visibleImageUrl = this.slideShowImageUrls[index];

					index = index === this.slideShowImageUrls.length ? 0 : index + 1;
					this.slideShowIntervalId = setInterval(() => {
						this.visibleImageUrl = this.slideShowImageUrls[index];
						if (index < this.slideShowImageUrls.length - 1) {
							index++;
						} else {
							index = 0;
						}
					}, 2500);

				}, 350);

			} else {
				clearTimeout(this.slideShowTimeoutId);
				clearInterval(this.slideShowIntervalId);
				this.visibleImageUrl = this.avatarUrl;
			}
		}
	}
</script>

<style lang="scss" scoped>
</style>
