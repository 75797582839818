<template>
	<AsiCardDetail :icon="icons.category" :title="$t('ui.terms.description')"
	               :show-action-button="!readonly" :action-button-disabled="disabled" @actionButtonClicked="updateDialog.open()">
		<AsiCardDetailEntryTranslatedValue :icon="icons.description" :label="$t('category.description')" :value="category.description"/>

		<CategoryUpdateDialogDescription v-if="!readonly && updateDialog.isLoaded" :open="updateDialog.isOpen" :category="category"
		                                 @cancel="updateDialog.close()" @save="updateDialog.close(); change();"/>
	</AsiCardDetail>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import {ICategoryAdmin} from "@/models/category/CategoryAdminModels";
	import Icon from "@/plugins/icons";
	import DialogHandler from "@/components/common/DialogHandler";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import AsiCardDetailEntryTranslatedValue from "@/components/common/AsiCardDetailEntryTranslatedValue.vue";
	import CategoryUpdateDialogBasicData from "@/components/category/admin/CategoryUpdateDialogBasicData.vue";
	import EnumHelper from "@/helpers/EnumHelper";
	import {CategoryView} from "@/helpers/constants";
	import CategoryUpdateDialogDescription from "@/components/category/admin/CategoryUpdateDialogDescription.vue";

	@Component({
		components: {
			CategoryUpdateDialogDescription,
			CategoryUpdateDialogBasicData,
			AsiCardDetailEntryTranslatedValue, AsiCardDetail, AsiCardDetailEntry
		}
	})
	export default class CategoryCardDescription extends Vue {

		@Prop({type: Object, required: true})
		public category!: ICategoryAdmin;

		@Prop({type: Boolean, default: false})
		public readonly!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		private icons = Icon;
		private updateDialog: DialogHandler = new DialogHandler();

		private get viewValue(): string | null {
			return EnumHelper.textFromValue(CategoryView, this.category.view, true);
		}

		@Emit('change')
		public change(): void {
			return;
		}

	}
</script>

<style lang="scss" scoped>

</style>
