<template>
	<v-list-item>
		<v-list-item-content>
			<v-list-item-title class="body-2 position-entry" @click="openModelDetailPage(position.item)">
				{{ itemName }}
			</v-list-item-title>
		</v-list-item-content>
		<v-list-item-action class="d-flex flex-row align-center">
			<ItemQuantitySelection x-small :value="quantityInternal" @input="quantityChanged"/>
			<AsiBtn class="ml-3" small :icon="icons.delete" icon-color="error" @click="remove"/>
		</v-list-item-action>
	</v-list-item>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import ItemQuantitySelection from "@/components/item/shop/ItemQuantitySelection.vue";
	import {IShoppingListPositionShopSimple} from "@/models/shopping-list-position/ShoppingListPositionShopModels";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import i18n from "@/plugins/i18n";
	import {IItemShopSimple} from "@/models/item/ItemShopModels";
	import ItemHelper from "@/models/item/ItemHelper";

	@Component({
		components: {ItemQuantitySelection, AsiBtn}
	})
	export default class ShoppingListPosition extends Vue {
		private icons = Icon;
		private quantityInternal!: number;

		@Prop({type: Object, required: true})
		public position!: IShoppingListPositionShopSimple;

		public created(): void {
			this.quantityInternal = this.position.quantity;
		}

		private get itemName(): string {
			return TranslatedValueHelper.get(this.position.item.name, i18n.locale, true) ?? '?';
		}

		private remove(): void {
			this.$store.dispatch('shoppingList/deletePosition', this.position.id);
		}

		private quantityChanged(value: number): void {
			this.$store.dispatch('shoppingList/updateQuantity', {
				positionId: this.position.id,
				quantity: value,
			}).finally(() => {
				this.quantityInternal = value;
			});
		}

		private openModelDetailPage(model: IItemShopSimple): void {
			this.$router.push(ItemHelper.detailRoute(model.itemNumber));
		}
	}
</script>

<style lang="scss" scoped>
	.position-entry {
		cursor: pointer;
	}

	.position-entry:hover {
		text-decoration: underline;
	}
</style>
