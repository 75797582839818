<template>
	<div>
		<v-menu v-model="show" bottom offset-y :nudge-top="-10" :close-on-content-click="false" min-width="25em" max-width="30em" >
			<template v-slot:activator="{on, attrs}">
				<AsiBtn v-on="on" v-bind="attrs" :icon="icons.shoppingList"/>
			</template>
			<AsiCard highlighted unwrapped no-bottom-margin>
				<span v-if="shoppingLists.length === 0" class="d-block text-center pa-3 caption">
					{{ $t('shoppingList.terms.noLists') }}
				</span>
				<v-expansion-panels v-else>
					<v-expansion-panel
						v-for="(shoppingList, i) in shoppingLists"
						:key="i"
					>
						<v-expansion-panel-header>
							<template v-slot:default="{ open }">
								<div class="d-flex align-center">
									<v-menu offset-y>
										<template v-slot:activator="{ on, attrs }">
											<AsiBtn v-bind="attrs" v-on.stop="on" :icon="icons.menu" icon-color="primary" small class="mr-1"/>
										</template>
										<v-list>
											<v-list-item link @click="openDialog(shoppingList)">
												<v-list-item-title>
													{{ $t('ui.rename') }}
												</v-list-item-title>
											</v-list-item>
											<v-list-item link @click="remove(shoppingList.id)">
												<v-list-item-title>
													{{ $t('ui.delete') }}
												</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
									<span>{{ shoppingList.name }}</span>
								</div>
							</template>
							<template v-slot:actions>
								<AsiBtn icon-color="primary" :icon="icons.dropdown"/>
							</template>
							<v-divider/>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-list v-if="shoppingList.positions.length > 0">
								<ShoppingListPosition v-for="position in shoppingList.positions" :key="position.id" :position="position"/>
							</v-list>
							<span v-else class="d-block text-center pa-3 caption">
							{{ $t('shoppingListPosition.terms.noPositions') }}
						</span>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>

				<template v-slot:actions>
					<AsiBtn small block :text="true" @click="create">
						<v-icon small class="mr-1">{{ icons.add }}</v-icon>
						<span>{{ $t('shoppingList.terms.create') }}</span>
					</AsiBtn>
				</template>
			</AsiCard>
		</v-menu>

		<ShoppingListUpdateDialogName v-if="nameDialog.isLoaded" :open="nameDialog.isOpen" :shopping-list="shoppingListInternal"
		                              @cancel="nameDialog.close()" @save="nameDialog.close()"/>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component} from "vue-property-decorator";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import AsiCard from "@/components/common/AsiCard.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import ShoppingListPosition from "@/components/shopping-list-position/ShoppingListPosition.vue";
	import {IShoppingListShopListEntry} from "@/models/shopping-list/ShoppingListShopModels";
	import ShoppingListUpdateDialogName from "@/components/shopping-list/shop/ShoppingListUpdateDialogName.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import Icon from "@/plugins/icons";

	@Component({
		components: {ShoppingListUpdateDialogName, ShoppingListPosition, AsiBtn, AsiCard, AsiCardDetail}
	})
	export default class ShoppingList extends Vue {

		private icons = Icon;
		private show: boolean = false;
		private nameDialog: DialogHandler = new DialogHandler();
		private shoppingListInternal!: IShoppingListShopListEntry

		private get shoppingLists(): IShoppingListShopListEntry[] {
			return this.$store.state.shoppingList.shoppingLists;
		}

		private openDialog(shoppingList: IShoppingListShopListEntry): void {
			this.shoppingListInternal = shoppingList;
			this.nameDialog.open();
		}

		private remove(id: string): void {
			this.$store.dispatch('shoppingList/deleteShoppingList', id);
		}

		private create(): void {
			this.$store.dispatch('shoppingList/createShoppingList');
		}

	}
</script>

<style lang="scss" scoped>

	.v-expansion-panel-header--active {
		/*border-bottom: 1px solid grey;*/
		box-shadow: 0 4px 14px -4px rgba(0, 0, 0, 0.2);
	}

	.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
		padding: 0 1em 0 1em !important;
	}
</style>
