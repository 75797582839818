<template>
	<svg id="logo-herzog-dichtungen" :class="{clickable: hasClickListener}"
	     :viewBox="dimensions" :style="`enable-background:new ${dimensions};`"
	     :width="width" :height="height"
	     xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" xml:space="preserve"
	     @click="click">
		<g id="icon" :style="{fill: this.iconColorFinal}">
			<path class="st0" d="M15.05,6.88c1.49,0,2.7-1.21,2.7-2.7c0-1.49-1.21-2.7-2.7-2.7c-1.49,0-2.7,1.21-2.7,2.7
				C12.35,5.68,13.56,6.88,15.05,6.88z"/>
			<path class="st0" d="M6.17,4.18c0,1.49,1.21,2.7,2.7,2.7c1.49,0,2.7-1.21,2.7-2.7c0-1.49-1.21-2.7-2.7-2.7
				C7.38,1.48,6.17,2.69,6.17,4.18"/>
			<path class="st0" d="M0,4.18c0,1.49,1.21,2.7,2.7,2.7c1.49,0,2.7-1.21,2.7-2.7c0-1.49-1.21-2.7-2.7-2.7C1.21,1.48,0,2.69,0,4.18"/>
		</g>
		<g id="typo" :style="{fill: this.typoColorFinal}">
			<g id="ag">
				<path class="st0" d="M95.87,7.04h1.37c0.04,0.12,0.11,0.22,0.21,0.28c0.1,0.06,0.24,0.09,0.42,0.09c0.29,0,0.5-0.07,0.62-0.2
					c0.12-0.13,0.18-0.37,0.18-0.71V6.06c-0.14,0.22-0.33,0.39-0.55,0.5c-0.11,0.05-0.22,0.09-0.34,0.12
					c-0.12,0.03-0.26,0.04-0.4,0.04c-0.51,0-0.93-0.2-1.26-0.6c-0.33-0.4-0.49-0.91-0.49-1.54c0-0.65,0.16-1.18,0.49-1.58
					c0.32-0.4,0.75-0.61,1.27-0.61c0.29,0,0.54,0.05,0.75,0.16c0.21,0.11,0.4,0.28,0.57,0.51V2.51H100v3.45c0,0.52-0.02,0.88-0.06,1.1
					c-0.04,0.22-0.1,0.39-0.19,0.53c-0.16,0.26-0.4,0.45-0.72,0.59c-0.16,0.07-0.33,0.12-0.51,0.15c-0.18,0.03-0.39,0.05-0.61,0.05
					c-0.63,0-1.11-0.11-1.44-0.32C96.13,7.83,95.93,7.5,95.87,7.04z M97.05,4.58c0,0.33,0.07,0.59,0.21,0.78
					c0.14,0.19,0.33,0.29,0.58,0.29c0.26,0,0.46-0.09,0.6-0.28c0.14-0.19,0.22-0.45,0.22-0.79c0-0.37-0.07-0.64-0.2-0.83
					c-0.13-0.19-0.33-0.28-0.6-0.28c-0.26,0-0.46,0.1-0.6,0.29C97.12,3.94,97.05,4.22,97.05,4.58z"/>
				<path class="st0" d="M95.38,6.69h-1.39l-0.04-0.21l-0.03-0.22c-0.19,0.19-0.4,0.34-0.61,0.43c-0.22,0.09-0.45,0.14-0.71,0.14
					c-0.4,0-0.72-0.11-0.95-0.33c-0.23-0.22-0.35-0.52-0.35-0.9c0-0.35,0.09-0.63,0.27-0.84c0.18-0.21,0.46-0.37,0.83-0.46
					c0.21-0.05,0.47-0.1,0.78-0.15c0.47-0.08,0.71-0.19,0.71-0.34v-0.1c0-0.12-0.05-0.21-0.14-0.27c-0.09-0.07-0.23-0.1-0.4-0.1
					c-0.19,0-0.35,0.04-0.46,0.12c-0.11,0.08-0.19,0.2-0.22,0.35h-1.26c0.07-0.47,0.26-0.82,0.58-1.05c0.32-0.23,0.78-0.35,1.38-0.35
					c0.37,0,0.69,0.04,0.94,0.13c0.25,0.09,0.45,0.23,0.6,0.41c0.1,0.13,0.17,0.29,0.21,0.47c0.04,0.19,0.06,0.48,0.06,0.86v1.58
					c0,0.19,0.02,0.34,0.05,0.45c0.03,0.11,0.09,0.18,0.16,0.21V6.69z M93.87,4.72c-0.1,0.06-0.29,0.13-0.55,0.18
					c-0.04,0.01-0.09,0.02-0.15,0.03c-0.05,0.01-0.1,0.02-0.15,0.04c-0.16,0.05-0.28,0.11-0.35,0.18c-0.07,0.07-0.1,0.17-0.1,0.29
					c0,0.14,0.05,0.26,0.14,0.36c0.09,0.09,0.22,0.13,0.36,0.13c0.23,0,0.42-0.07,0.57-0.2c0.15-0.13,0.22-0.31,0.22-0.53V4.72z"/>
			</g>
			<g id="dichtungen">
				<path class="st0" d="M84.7,6.69V2.51h1.34v0.57c0.14-0.23,0.32-0.4,0.53-0.51c0.21-0.11,0.46-0.17,0.74-0.17
					c0.49,0,0.84,0.12,1.06,0.37c0.22,0.24,0.33,0.64,0.33,1.2v2.72h-1.37v-2.1c0-0.49-0.04-0.81-0.12-0.94
					c-0.08-0.13-0.23-0.2-0.45-0.2c-0.24,0-0.41,0.08-0.52,0.24c-0.11,0.16-0.17,0.42-0.17,0.76v2.24H84.7z"/>
				<path class="st0" d="M82.83,5.38h1.31c-0.12,0.46-0.36,0.81-0.72,1.06c-0.36,0.25-0.81,0.38-1.34,0.38c-0.64,0-1.15-0.2-1.53-0.59
					c-0.38-0.39-0.57-0.93-0.57-1.61c0-0.69,0.19-1.24,0.57-1.64c0.38-0.4,0.89-0.61,1.53-0.61c0.66,0,1.18,0.2,1.56,0.61
					c0.38,0.41,0.57,0.98,0.57,1.7l0,0.15l0,0.09h-2.82c0,0.29,0.06,0.52,0.19,0.67c0.13,0.16,0.31,0.23,0.56,0.23
					c0.18,0,0.33-0.04,0.44-0.11C82.69,5.64,82.78,5.53,82.83,5.38z M81.35,4.16l1.56,0c0-0.26-0.07-0.47-0.21-0.63
					c-0.14-0.15-0.33-0.23-0.57-0.23c-0.22,0-0.4,0.07-0.53,0.22C81.46,3.66,81.38,3.87,81.35,4.16z"/>
				<path class="st0" d="M75.36,7.04h1.37c0.04,0.12,0.11,0.22,0.21,0.28c0.1,0.06,0.24,0.09,0.42,0.09c0.29,0,0.5-0.07,0.62-0.2
					c0.12-0.13,0.18-0.37,0.18-0.71V6.06c-0.14,0.22-0.33,0.39-0.55,0.5c-0.11,0.05-0.22,0.09-0.34,0.12
					c-0.12,0.03-0.26,0.04-0.4,0.04c-0.51,0-0.93-0.2-1.26-0.6c-0.33-0.4-0.49-0.91-0.49-1.54c0-0.65,0.16-1.18,0.49-1.58
					c0.32-0.4,0.75-0.61,1.27-0.61c0.29,0,0.54,0.05,0.75,0.16c0.21,0.11,0.4,0.28,0.57,0.51V2.51h1.28v3.45
					c0,0.52-0.02,0.88-0.06,1.1c-0.04,0.22-0.1,0.39-0.19,0.53c-0.16,0.26-0.4,0.45-0.72,0.59c-0.16,0.07-0.33,0.12-0.51,0.15
					c-0.18,0.03-0.39,0.05-0.61,0.05c-0.63,0-1.11-0.11-1.44-0.32C75.62,7.83,75.42,7.5,75.36,7.04z M76.54,4.58
					c0,0.33,0.07,0.59,0.21,0.78c0.14,0.19,0.33,0.29,0.58,0.29c0.26,0,0.46-0.09,0.6-0.28c0.14-0.19,0.22-0.45,0.22-0.79
					c0-0.37-0.07-0.64-0.2-0.83c-0.13-0.19-0.33-0.28-0.6-0.28c-0.26,0-0.46,0.1-0.6,0.29C76.61,3.94,76.54,4.22,76.54,4.58z"/>
				<path class="st0" d="M70.65,6.69V2.51h1.34v0.57c0.14-0.23,0.32-0.4,0.53-0.51c0.21-0.11,0.46-0.17,0.74-0.17
					c0.49,0,0.84,0.12,1.06,0.37c0.22,0.24,0.33,0.64,0.33,1.2v2.72h-1.37v-2.1c0-0.49-0.04-0.81-0.12-0.94
					c-0.08-0.13-0.23-0.2-0.45-0.2c-0.24,0-0.41,0.08-0.52,0.24c-0.11,0.16-0.17,0.42-0.17,0.76v2.24H70.65z"/>
				<path class="st0" d="M69.92,2.51v4.18h-1.34V6.11c-0.16,0.23-0.34,0.4-0.56,0.51c-0.1,0.05-0.21,0.09-0.33,0.12
					c-0.12,0.03-0.24,0.04-0.38,0.04c-0.46,0-0.81-0.12-1.04-0.36c-0.23-0.24-0.35-0.59-0.35-1.05V2.51h1.37v2.34
					c0,0.34,0.04,0.57,0.13,0.7c0.08,0.13,0.23,0.2,0.45,0.2c0.24,0,0.41-0.08,0.52-0.24c0.11-0.16,0.16-0.41,0.16-0.77V2.51H69.92z"
				/>
				<path class="st0" d="M62.92,3.34V2.51h0.56V1.33h1.37v1.18h0.7v0.83h-0.7v1.81c0,0.28,0.04,0.46,0.11,0.55
					c0.07,0.09,0.21,0.13,0.41,0.13h0.1c0.03,0,0.06,0,0.07-0.01V6.7c-0.03,0.01-0.09,0.02-0.18,0.03c-0.08,0.01-0.18,0.03-0.29,0.04
					c-0.11,0.01-0.23,0.02-0.35,0.02c-0.47,0-0.8-0.12-0.97-0.35c-0.09-0.12-0.15-0.3-0.2-0.54c-0.04-0.24-0.07-0.56-0.07-0.94V3.34
					H62.92z"/>
				<path class="st0" d="M58.65,6.69V1.03h1.36v2.02c0.16-0.23,0.34-0.39,0.55-0.5c0.21-0.1,0.45-0.16,0.73-0.16
					c0.44,0,0.77,0.12,1,0.36c0.23,0.24,0.35,0.59,0.35,1.05v2.87h-1.37v-2.1c0-0.49-0.04-0.81-0.12-0.94
					c-0.08-0.13-0.23-0.2-0.45-0.2c-0.24,0-0.41,0.08-0.52,0.24c-0.11,0.16-0.17,0.42-0.17,0.76v2.24H58.65z"/>
				<path class="st0" d="M58.19,5.14c-0.07,0.53-0.28,0.95-0.64,1.24c-0.36,0.3-0.82,0.44-1.4,0.44c-0.62,0-1.12-0.2-1.5-0.6
					c-0.38-0.4-0.57-0.93-0.57-1.58c0-0.71,0.19-1.27,0.58-1.67c0.38-0.41,0.91-0.61,1.57-0.61c0.58,0,1.04,0.14,1.38,0.43
					c0.35,0.29,0.54,0.69,0.59,1.2h-1.38c-0.04-0.19-0.11-0.34-0.21-0.44c-0.1-0.1-0.23-0.15-0.4-0.15c-0.23,0-0.41,0.1-0.54,0.3
					c-0.12,0.2-0.19,0.5-0.19,0.89c0,0.38,0.06,0.67,0.17,0.86c0.12,0.19,0.29,0.28,0.53,0.28c0.18,0,0.32-0.05,0.43-0.15
					c0.11-0.1,0.18-0.25,0.22-0.46H58.19z"/>
				<path class="st0" d="M52.22,2.13v-1.1h1.36v1.1H52.22z M52.22,6.69V2.51h1.36v4.18H52.22z"/>
				<path class="st0" d="M51.49,1.03v5.66h-1.36V6.14c-0.15,0.23-0.33,0.4-0.53,0.51c-0.2,0.11-0.44,0.16-0.71,0.16
					c-0.52,0-0.95-0.2-1.27-0.61c-0.33-0.4-0.49-0.93-0.49-1.58c0-0.66,0.16-1.19,0.49-1.61c0.33-0.42,0.74-0.62,1.25-0.62
					c0.27,0,0.52,0.05,0.73,0.16c0.21,0.11,0.39,0.27,0.53,0.49V1.03H51.49z M48.54,4.56c0,0.37,0.07,0.65,0.21,0.85
					c0.14,0.2,0.34,0.3,0.6,0.3c0.26,0,0.46-0.1,0.59-0.29c0.13-0.2,0.2-0.48,0.2-0.86c0-0.35-0.07-0.62-0.21-0.81
					c-0.14-0.19-0.34-0.29-0.6-0.29c-0.24,0-0.44,0.1-0.58,0.3C48.61,3.95,48.54,4.22,48.54,4.56z"/>
			</g>
			<g id="herzog">
				<path class="st0" d="M40.38,7.04h1.37c0.04,0.12,0.11,0.22,0.21,0.28c0.1,0.06,0.24,0.09,0.42,0.09c0.29,0,0.5-0.07,0.62-0.2
					c0.12-0.13,0.18-0.37,0.18-0.71V6.06c-0.14,0.22-0.33,0.39-0.55,0.5c-0.11,0.05-0.22,0.09-0.34,0.12
					c-0.12,0.03-0.26,0.04-0.4,0.04c-0.51,0-0.93-0.2-1.26-0.6c-0.33-0.4-0.49-0.91-0.49-1.54c0-0.65,0.16-1.18,0.49-1.58
					c0.32-0.4,0.75-0.61,1.27-0.61c0.29,0,0.54,0.05,0.75,0.16c0.21,0.11,0.4,0.28,0.57,0.51V2.51h1.28v3.45
					c0,0.52-0.02,0.88-0.06,1.1c-0.04,0.22-0.1,0.39-0.19,0.53c-0.16,0.26-0.4,0.45-0.72,0.59c-0.16,0.07-0.33,0.12-0.51,0.15
					c-0.18,0.03-0.39,0.05-0.61,0.05c-0.63,0-1.11-0.11-1.44-0.32C40.64,7.83,40.44,7.5,40.38,7.04z M41.56,4.58
					c0,0.33,0.07,0.59,0.21,0.78c0.14,0.19,0.33,0.29,0.58,0.29c0.26,0,0.46-0.09,0.6-0.28c0.14-0.19,0.22-0.45,0.22-0.79
					c0-0.37-0.07-0.64-0.2-0.83c-0.13-0.19-0.33-0.28-0.6-0.28c-0.26,0-0.46,0.1-0.6,0.29C41.63,3.94,41.56,4.22,41.56,4.58z"/>
				<path class="st0" d="M35.44,4.59c0-0.7,0.2-1.24,0.59-1.64c0.39-0.4,0.93-0.59,1.62-0.59c0.69,0,1.23,0.2,1.63,0.59
					s0.59,0.94,0.59,1.64s-0.2,1.25-0.59,1.64c-0.39,0.4-0.94,0.59-1.63,0.59c-0.69,0-1.24-0.2-1.63-0.59
					c-0.19-0.2-0.34-0.43-0.44-0.7C35.48,5.26,35.44,4.95,35.44,4.59z M36.84,4.59c0,0.38,0.07,0.67,0.21,0.88s0.34,0.31,0.6,0.31
					c0.26,0,0.46-0.1,0.61-0.31c0.14-0.21,0.21-0.5,0.21-0.87s-0.07-0.67-0.21-0.87s-0.34-0.31-0.61-0.31c-0.26,0-0.46,0.1-0.6,0.3
					C36.91,3.92,36.84,4.21,36.84,4.59z"/>
				<path class="st0" d="M31.41,6.69V5.68l2.08-2.19h-1.94V2.51h3.65V3.5l-2.06,2.19h2.13v1.01H31.41z"/>
				<path class="st0" d="M28.64,6.69V2.51h1.31v0.73c0.15-0.28,0.33-0.49,0.55-0.63c0.11-0.07,0.23-0.12,0.37-0.15
					c0.14-0.04,0.29-0.05,0.46-0.05v1.35l-0.18-0.02h-0.16c-0.21,0-0.38,0.03-0.52,0.09c-0.14,0.06-0.25,0.16-0.33,0.29
					c-0.05,0.08-0.08,0.2-0.11,0.38C30.01,4.67,30,4.93,30,5.29v1.4H28.64z"/>
				<path class="st0" d="M26.78,5.38h1.31c-0.12,0.46-0.36,0.81-0.72,1.06c-0.36,0.25-0.81,0.38-1.34,0.38c-0.64,0-1.15-0.2-1.53-0.59
					c-0.38-0.39-0.57-0.93-0.57-1.61c0-0.69,0.19-1.24,0.57-1.64c0.38-0.4,0.89-0.61,1.53-0.61c0.66,0,1.18,0.2,1.56,0.61
					c0.38,0.41,0.57,0.98,0.57,1.7l0,0.15l0,0.09h-2.82c0,0.29,0.06,0.52,0.19,0.67c0.13,0.16,0.31,0.23,0.56,0.23
					c0.18,0,0.33-0.04,0.44-0.11C26.64,5.64,26.72,5.53,26.78,5.38z M25.29,4.16l1.56,0c0-0.26-0.07-0.47-0.21-0.63
					c-0.14-0.15-0.33-0.23-0.57-0.23c-0.22,0-0.4,0.07-0.53,0.22C25.41,3.66,25.32,3.87,25.29,4.16z"/>
				<path class="st0" d="M19.47,6.69V1.03h1.36v2.02c0.16-0.23,0.34-0.39,0.55-0.5c0.21-0.1,0.45-0.16,0.73-0.16
					c0.44,0,0.77,0.12,1,0.36c0.23,0.24,0.35,0.59,0.35,1.05v2.87h-1.37v-2.1c0-0.49-0.04-0.81-0.12-0.94
					c-0.08-0.13-0.23-0.2-0.45-0.2c-0.24,0-0.41,0.08-0.52,0.24c-0.11,0.16-0.17,0.42-0.17,0.76v2.24H19.47z"/>
			</g>
		</g>
</svg>

</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";

	@Component
	export default class LogoHerzogDichtungen extends Vue {

		@Prop({type: Boolean, default: false})
		public small!: boolean;

		@Prop({type: Boolean, default: false})
		public hideTypo!: boolean;

		@Prop({type: String, default: null})
		public iconColor!: string | null;

		@Prop({type: String, default: null})
		public typoColor!: string | null;

		@Prop({type: String, default: null})
		public width!: string | null;

		@Prop({type: String, default: null})
		public height!: string | null;

		private get dimensions(): string {
			return this.hideTypo ? '0 0  17.75 8.37' : '0 0 100 8.37';
		}

		private get iconColorFinal(): string {
			return this.iconColor ?? '#eb2918';
		}

		private get typoColorFinal(): string {
			return this.typoColor ?? '#eb2918';
		}

		private get hasClickListener(): boolean {
			return !!this.$listeners.click;
		}

		@Emit('click')
		public click(event: Event): Event {
			return event;
		}

	}
</script>

<style lang="scss" scoped>
	.clickable {
		cursor: pointer;
	}
</style>
