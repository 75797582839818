<template>
	<ShopView>
		<h1 class="mb-6">{{ $t('cart.plural') }}</h1>

		<AsiCard unwrapped>
			<v-card-text class="pa-0">
				<v-card-text class="pa-6 text-center grey lighten-5">
					<AsiBtn :icon="icons.create" :loading="loadingCreate" color="secondary" @click="createCart">
						{{ $t('cart.terms.create') }}
					</AsiBtn>
				</v-card-text>
				<v-divider/>
				<div class="py-6">
					<v-slide-group v-model="activeCartId" :mandatory="carts.length > 0" center-active show-arrows>
						<v-slide-item v-for="cart in carts" :key="cart.id" :value="cart.id" v-slot="{ active, toggle }">
							<CartCard :id="cart.id" :cart="cart" :active="active"/>
						</v-slide-item>
					</v-slide-group>
				</div>
			</v-card-text>
		</AsiCard>
	</ShopView>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component} from "vue-property-decorator";
	import ShopView from "@/components/layout/ShopView.vue";
	import {ICartShopListEntry} from "@/models/cart/CartShopModels";
	import AsiCard from "@/components/common/AsiCard.vue";
	import Snackbar from "@/helpers/Snackbar";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import CartCard from "@/components/cart/shop/CartCard.vue";

	@Component({
		components: {
			CartCard,
			AsiBtn, AsiCard, ShopView
		}
	})
	export default class Carts extends Vue {

		private icons = Icon;
		private loadingCreate: boolean = false;

		private get activeCartId(): string | null {
			return this.$store.state.cart.activeCartId;
		}

		private set activeCartId(id: string | null) {
			this.$store.commit('cart/setActiveCartId', id);
		}

		private get carts(): ICartShopListEntry[] {
			return this.$store.state.cart.carts;
		}

		private createCart(): void {
			this.loadingCreate = true;
			this.$store.dispatch('cart/createCart', {
				name: null,
				positions: [],
			})
				.catch(() => Snackbar.createError())
				.finally(() => this.loadingCreate = false);
		}

	}
</script>

<style lang="scss" scoped>

</style>
