<template>
	<AsiListTableLayout :filter-text="filterModel.fulltext" :has-filter="hasFilter" :hide-toolbar="hideToolbar"
	                    @clearFilterText="filterTextChanged(null)" @clearSearch="clearSearch" @update:filterText="filterTextChanged">

		<AsiContentContainer no-bottom-margin>
			<v-data-table
				:footer-props="{itemsPerPageOptions: perPageOptions}"
				:headers="createHeaders()"
				:items="items"
				:loading="loadingInternal"
				:options.sync="tableOptions"
				:server-items-length="total"
				item-key="id"
				multi-sort
				@click:row="openModelDetailPage">

				<template v-slot:item.avatar="{item}">
					<AsiAvatar :size="32" :icon="icons.order" :image-url="avatarUrl(item)"/>
				</template>
				<template v-slot:item.customer.name="{item}">
					<span v-if="item.customer">
						{{ item.customer.name }}
					</span>
					<span v-else>
						{{ $t('order.terms.guestOrder') }}
					</span>
				</template>

				<template v-slot:item.paymentType="{item}">
					<p class="ellipsis" :style="'width: ' + paymentTypeWidth">{{ paymentTypeNameTranslated(item) }}</p>
				</template>
				<template v-slot:item.shipmentType="{item}">
					<p class="ellipsis" :style="'width: ' + shipmentTypeWidth">{{
							shipmentTypeNameTranslated(item)
						}}</p>
				</template>

				<template v-slot:item.billingAddress="{item}">
					<v-icon size="16" class="mr-3">{{ icons.paymentType }}</v-icon>
					<small>{{ addressHelper.addressLines(item.billingAddress).join(', ') }}</small>
					<br/>
					<v-icon size="16" class="mr-3">{{ icons.shipmentType }}</v-icon>
					<small>{{ addressHelper.addressLines(item.shippingAddress).join(', ') }}</small>
				</template>
				<template v-slot:item.createdAt="{item}">
					<span>
						{{ $d(new Date(item.createdAt), 'short') }} {{ $d(new Date(item.createdAt), 'timeShort') }}
					</span>
				</template>
				<template v-slot:item.paymentState="{item}">
					<OrderPaymentStateChip :order="item"/>
				</template>
				<template v-slot:item.shipmentState="{item}">
					<OrderShipmentStateChip :order="item"/>
				</template>
				<template v-slot:item.totalWithVat="{item}">
					{{
						$n(item.totalWithVat.amount, {
							key: 'currencyDisplay',
							currency: item.totalWithVat.currency.currencyCode,
						})
					}}
				</template>
			</v-data-table>
		</AsiContentContainer>

		<template v-slot:advanced-filters>
			<AsiTextFieldSimple v-if="customerId === null" v-model="filterModel.customerName" :placeholder="$t('customer.singular')" clearable/>
			<AsiSelectSimple v-model="filterModel.shipmentStates" :items="shipmentStateOptions" :label="$t('order.shipmentState')" :placeholder="$t('order.shipmentState')" multiple clearable/>
			<AsiSelectSimple v-model="filterModel.paymentStates" :items="paymentStateOptions" :label="$t('order.paymentState')" :placeholder="$t('order.paymentState')" multiple clearable/>
			<AsiDatePickerCombined v-model="createdAtDateRange" :label="$t('order.createdAt')" clearable range simple/>
		</template>
	</AsiListTableLayout>
</template>

<script lang="ts">
	import {Component, Prop, Watch} from "vue-property-decorator";
	import AsiListTable from "@/components/common/AsiListTable.vue";
	import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
	import AsiListTableHeader from "@/components/common/AsiListTableHeader";
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";
	import IAsiListTableImplementation from "@/components/common/IAsiListTableImplementation";
	import AsiListTableLayout from "@/components/common/AsiListTableLayout.vue";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";
	import IPaginatedResponse from "@/models/IPaginatedResponse";
	import {AttachmentMediaSize, OrderPaymentState, OrderShipmentState} from "@/helpers/constants";
	import Icon from "@/plugins/icons";
	import EnumHelper from "@/helpers/EnumHelper";
	import AsiSelectSimple from "@/components/common/AsiSelectSimple";
	import {IOrderAdminListEntry} from "@/models/order/OrderAdminModels";
	import OrderListFilterAdmin from "@/models/order/OrderListFilterAdmin";
	import OrderHelper from "@/helpers/OrderHelper";
	import CustomerHelper from "@/models/customer/CustomerHelper";
	import OrderStateChip from "@/components/order/OrderShipmentStateChip.vue";
	import OrderShipmentStateChip from "@/components/order/OrderShipmentStateChip.vue";
	import AsiDatePickerCombined from "@/components/common/AsiDatePickerCombined.vue";
	import DateTimeHelper from "@/helpers/DateTimeHelper";
	import OrderPaymentStateChip from "@/components/order/OrderPaymentStateChip.vue";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import AddressHelper from "@/helpers/AddressHelper";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";

	@Component({
		components: {
			AsiCardDetailEntry,
			OrderShipmentStateChip,
			OrderPaymentStateChip,
			AsiDatePickerCombined,
			OrderStateChip,
			AsiSelectSimple,
			AsiAvatar,
			AsiContentContainer,
			AsiListTableLayout, AsiTextFieldSimple
		}
	})
	export default class OrderList extends AsiListTable<IOrderAdminListEntry, OrderListFilterAdmin> implements IAsiListTableImplementation<IOrderAdminListEntry, OrderListFilterAdmin> {

		@Prop({type: String, default: null})
		public customerId!: string | null;

		@Prop({type: Boolean, default: false})
		public hideToolbar!: boolean;

		private icons = Icon;
		private addressHelper = AddressHelper;
		private initialized: boolean = false;

		private shipmentTypeWidth = '10rem';
		private paymentTypeWidth = '5rem';

		public created(): void {
			this.reload(false, false);
			this.$nextTick(() => this.initialized = true);
		}

		// noinspection JSMethodCanBeStatic
		private get paymentStateOptions(): { text: string, value: string | number }[] {
			return EnumHelper.toSelectItems(OrderPaymentState, true);
		}

		// noinspection JSMethodCanBeStatic
		private get shipmentStateOptions(): { text: string, value: string | number }[] {
			return EnumHelper.toSelectItems(OrderShipmentState, true);
		}

		private get createdAtDateRange(): string[] | null {
			if (this.filterModel === null) return [];
			return [this.filterModel.createdAtFrom, this.filterModel.createdAtTo].filter(d => d !== null) as string[];
		}

		private set createdAtDateRange(range: string[] | null) {
			if (this.filterModel === null) return;

			const dateFrom = range !== null && range.length > 0 ? new Date(range[0]) : null;
			if (dateFrom !== null) dateFrom.setHours(0, 0, 0);
			const dateTo = range !== null && range.length > 1 ? new Date(range[1]) : null;
			if (dateTo !== null) dateTo.setHours(23, 59, 59);

			this.filterModel.createdAtFrom = dateFrom === null ? null : DateTimeHelper.toISODateTimeString(dateFrom);
			this.filterModel.createdAtTo = dateTo === null ? null : DateTimeHelper.toISODateTimeString(dateTo);
		}

		public createFilterModel(): OrderListFilterAdmin {
			return new OrderListFilterAdmin();
		}

		public createHeaders(): AsiListTableHeader[] {
			const ret = this.customerId === null
				? [
					new AsiListTableHeader('', 'avatar', false, false, 'center', '4rem'),
					new AsiListTableHeader(this.$t('order.orderNumber'), 'orderNumber', true, true, 'start', '5rem'),
					new AsiListTableHeader(this.$t('customer.singular'), 'customer.name', true, true, 'start'),
				]
				: [
					new AsiListTableHeader(this.$t('order.orderNumber'), 'orderNumber', true, true, 'right', '10rem'),
				];
			ret.push(
				new AsiListTableHeader(this.$t('shipmentType.singular'), 'shipmentType', false, false, 'start', this.shipmentTypeWidth),
				new AsiListTableHeader(this.$t('paymentType.singular'), 'paymentType', false, false, 'start', this.paymentTypeWidth),
				new AsiListTableHeader(this.$t('address.plural'), 'billingAddress', false, false, 'start'),
				new AsiListTableHeader(this.$t('order.createdAt'), 'createdAt', true, true, 'center', '15rem'),
				new AsiListTableHeader(this.$t('order.paymentState'), 'paymentState', true, true, 'center', '10rem'),
				new AsiListTableHeader(this.$t('order.shipmentState'), 'shipmentState', true, true, 'center', '10rem'),
				new AsiListTableHeader(this.$t('ui.terms.inclVat'), 'totalWithVat', true, true, 'end', '10rem'),
			);
			return ret;
		}

		public fetchData(filterModel: OrderListFilterAdmin | null, tableOptions: AsiListTableOptions | null): Promise<IPaginatedResponse<IOrderAdminListEntry>> {
			return this.customerId === null
				? this.$orderServiceAdmin.orders(filterModel, tableOptions)
				: this.$customerServiceAdmin.orders(this.customerId, filterModel, tableOptions);
		}

		public filterTextChanged(filterText: string): void {
			if (this.filterModel === null) return;
			this.filterModel.fulltext = filterText;
		}

		public openModelDetailPage(model: IOrderAdminListEntry): void {
			this.$router.push(OrderHelper.detailRouteAdmin(model.id));
		}

		public reload(goToFirstPage: boolean = false, debounce: boolean = true): void {
			this.reloadInternal(this, goToFirstPage, debounce);
		}

		protected prepareOptions(options: AsiListTableOptions): AsiListTableOptions {
			if (options.sortBy.length === 0 && this.defaultSortBy.length === 0) {
				options.sortBy = ['createdAt'];
				options.sortDesc = [true];
			}
			return options;
		}

		private paymentTypeNameTranslated(order: IOrderAdminListEntry): string | null {
			return TranslatedValueHelper.get(order.paymentType.name, this.$i18n.locale);
		}

		private shipmentTypeNameTranslated(order: IOrderAdminListEntry): string | null {
			return TranslatedValueHelper.get(order.shipmentType.name, this.$i18n.locale);
		}

		@Watch('tableOptions', {deep: true})
		private onTableOptionsChanged(): void {
			if (!this.initialized) return;
			this.reload();
		}

		@Watch('filterModel', {deep: true})
		private onFilterModelChanged(): void {
			if (!this.initialized) return;
			this.reload(true);
		}

		// noinspection JSMethodCanBeStatic
		private avatarUrl(order: IOrderAdminListEntry): string | null {
			return order.customer === null
				? null
				: CustomerHelper.avatarUrl(order.customer, AttachmentMediaSize.s);
		}

	}
</script>

<style lang="scss" scoped>
	.ellipsis {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		margin: 0;
	}
</style>
