<template>
	<v-app-bar app :flat="flat" clipped-right :clipped-left="clippedLeft">
		<LogoHerzogDichtungen v-if="$isHerzogShop" style="height: 100%; max-height: 1.75rem;" :hide-typo="sMobile" @click="showHome"/>
		<LogoMkDichtungen v-if="$isMkShop" style="height: 100%; max-height: 1.75rem;" :hide-typo="sMobile" @click="showHome"/>

		<v-spacer/>

		<GlobalSearch v-if="!isBackend"/>
		<AsiBtn v-if="!isBackend" :icon="icons.mail" @click="showContact"/>
		<ShoppingList v-if="!isBackend && isLoggedIn"/>
		<StampButton v-if="!isBackend && hasSampleOrders"/>
		<CartButton v-if="!isBackend" class="mr-4"/>
		<UserMenu/>

		<AsiFadeTransition>
			<v-progress-linear absolute bottom :indeterminate="loading" :value="100" color="primary"/>
		</AsiFadeTransition>
	</v-app-bar>
</template>

<script lang="ts">
	import {Component, Prop} from "vue-property-decorator";
	import LogoHerzogDichtungen from "@/components/layout/LogoHerzogDichtungen.vue";
	import UserMenu from "@/components/layout/UserMenu.vue";
	import {Routes} from "@/helpers/constants";
	import AsiFadeTransition from "@/components/common/AsiFadeTransition.vue";
	import {mixins} from "vue-class-component";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import CartButton from "@/components/layout/CartButton.vue";
	import RoutesHelper from "@/helpers/RoutesHelper";
	import GlobalSearch from "@/components/global-search/GlobalSearch.vue";
	import ShoppingList from "@/components/shopping-list/shop/ShoppingList.vue";
	import Icon from "@/plugins/icons";
	import StampButton from "@/components/layout/StampButton.vue";
	import LogoMkDichtungen from "@/components/layout/LogoMkDichtungen.vue";

	@Component({
		components: {
			LogoMkDichtungen,
			StampButton,
			ShoppingList,
			GlobalSearch,
			CartButton,
			AsiBtn,
			AsiFadeTransition,
			UserMenu,
			LogoHerzogDichtungen
		}
	})
	export default class TopBar extends mixins(ResponsiveChecks) {

		private icons = Icon;

		@Prop({type: Boolean, default: false})
		public clippedLeft!: boolean;

		@Prop({type: Boolean, default: false})
		public flat!: boolean;

		private get isBackend(): boolean {
			return this.$route.meta?.adminLayout === true;
		}

		private get loading(): boolean {
			return this.loadingCategories || this.loadingCart;
		}

		private get loadingCategories(): boolean {
			return this.$store.state.category.loading;
		}

		private get loadingCart(): boolean {
			return this.$store.state.cart.loadingCart;
		}

		private get isLoggedIn(): boolean {
			return this.$store.getters['user/isLoggedIn'];
		}

		private showHome(): void {
			if (this.$router.currentRoute.name === Routes.home) return;
			this.$router.push(RoutesHelper.homeShopRoute());
		}

		private showContact(): void {
			if (this.$router.currentRoute.name === Routes.contact) return;
			this.$router.push({name: Routes.contact});
		}

		private get hasSampleOrders(): boolean {
			return this.$store.getters["sampleOrder/items"] !== null;
		}
	}
</script>

<style lang="scss" scoped>

</style>
