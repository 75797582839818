<template>
	<AsiCardDetail :icon="icons.cart" :title="$t('ui.terms.basicData')">
		<div v-if="cart.updatedBy !== null && cart.updatedAt !== null">
			<AsiCardDetailEntry :icon="icons.name" :label="$t('user.terms.name')" :value="fullname(cart.updatedBy)"/>
			<AsiCardDetailEntry :icon="icons.time" :label="$t('ui.terms.updated')" :value="$d(new Date(cart.updatedAt), 'long')"/>
		</div>
		<div v-else>
			<AsiCardDetailEntry :icon="icons.name" :label="$t('user.terms.name')" :value="fullname(cart.createdBy)"/>
			<AsiCardDetailEntry :icon="icons.time" :label="$t('ui.terms.created')" :value="$d(new Date(cart.createdAt), 'long')"/>
		</div>
		<AsiCardDetailEntry :icon="icons.id" :label="$t('cart.customerReference')" :value="cart.customerReference"/>
		<AsiCardDetailEntry :icon="icons.email" :label="$t('cart.oneTimeEmail')" :value="cart.oneTimeEmail"/>
		<AsiCardDetailEntry :icon="icons.notes" :label="$t('cart.remark')" :value="cart.remark" multiline/>
	</AsiCardDetail>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import {IUserAdminBlamable} from "@/models/user/UserAdminModels";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import Icon from "@/plugins/icons";
	import {ICartAdmin} from "@/models/cart/CartAdminModels";
	import CustomerTypeChip from "@/components/customer/CustomerTypeChip.vue";
	@Component({
		components: {CustomerTypeChip, AsiCardDetailEntry, AsiCardDetail}
	})
	export default class CartCardBasicData extends Vue {
        @Prop({type: Object, required: true})
        public cart!: ICartAdmin;

		private icons = Icon;

		private fullname(blamable: IUserAdminBlamable): string {
			return blamable.firstname + ' ' + blamable.lastname;
		}
	}
</script>

<style lang="scss" scoped>

</style>
