<template>
	<AsiSelectSimple :clearable="clearable" :dense="simple" :disabled="disabled" :flat="simple"
	                 :item-text="itemText" item-value="id" :items="carts"
	                 :solo="simple" :value="value" autocomplete="off"
	                 :label="$t(multiple ? 'cart.plural' : 'cart.singular')"
	                 :multiple="multiple" :rules="rules"
	                 :prepend-inner-icon="icon"
	                 @change="change" @input="input">

		<template v-slot:selection="data">
			{{ cartLabel(data.item) }}
		</template>

		<template v-slot:item="data">
			<template v-if="typeof data.item !== 'object'">
				<v-list-item-content v-text="data.item"></v-list-item-content>
			</template>
			<template v-else>
				<v-list-item-avatar>
					<AsiAvatar :icon="icons.cart" :size="32"/>
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title>
						{{ cartLabel(data.item) }}
					</v-list-item-title>
				</v-list-item-content>
			</template>
		</template>
	</AsiSelectSimple>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import {ICartShopListEntry} from "@/models/cart/CartShopModels";
	import AsiSelectSimple from "@/components/common/AsiSelectSimple";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";
	import Icon from "@/plugins/icons";
	import VueI18n from "vue-i18n";
	import TranslateResult = VueI18n.TranslateResult;
	import CartHelper from "@/helpers/CartHelper";

	@Component({
		components: {AsiAvatar, AsiSelectSimple}
	})
	export default class CartSelectInput extends Vue {

		@Prop({type: String, default: null})
		public value!: string | string[] | null;

		@Prop({type: Array, default: () => []})
		public rules!: (string | null | number | object | unknown | boolean)[];

		@Prop({type: Boolean, default: false})
		public clearable!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		@Prop({type: Boolean, default: false})
		public multiple!: boolean;

		@Prop({type: Boolean, default: false})
		public simple!: boolean;

		@Prop({type: String, default: null})
		public icon!: string | null;

		private icons = Icon;

		@Emit('change')
		public change(selection: string | string[] | null): string | string[] | null {
			return selection;
		}

		@Emit('input')
		public input(selection: string | string[] | null): string | string[] | null {
			return selection;
		}

		private get carts(): ICartShopListEntry[] {
			return this.$store.state.cart.carts;
		}

		// noinspection JSMethodCanBeStatic
		private cartLabel(cart: ICartShopListEntry): string | TranslateResult {
			return CartHelper.label(cart);
		}

		// noinspection JSMethodCanBeStatic
		private itemText(cart: ICartShopListEntry | []): string {
			if (Array.isArray(cart)) return '';
			// noinspection JSIncompatibleTypesComparison
			if (cart.id === undefined) return '';

			return this.cartLabel(cart).toString();
		}

	}
</script>

<style lang="scss" scoped>

</style>
