<template>
	<AsiAutocomplete :items="availableLocales" :label="finalLabel" :rules="rules"
	                 :value="value" autocomplete="off" :clearable="clearable" :multiple="multiple"
	                 @change="change" @input="input" :solo="simple" :dense="simple" :flat="simple"
	                 item-value="value" :item-text="itemText">
		<template v-slot:selection="data">
			{{ data.item.text }}
		</template>
		<template v-slot:item="data">
			<template v-if="typeof data.item !== 'object'">
				<v-list-item-content v-text="data.item"></v-list-item-content>
			</template>
			<template v-else>
				<v-list-item-content>
					<v-list-item-title class="d-flex flex-row justify-space-between">
						<span>{{ data.item.text }}</span>
						<AsiChip label small color="secondary">
							<code>{{ data.item.value }}</code>
						</AsiChip>
					</v-list-item-title>
				</v-list-item-content>
			</template>
		</template>
	</AsiAutocomplete>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import AsiAutocomplete from "./AsiAutocomplete";
	import AsiChip from "@/components/common/AsiChip.vue";
	import StringHelper from "@/helpers/StringHelper";
	import VueI18n from "vue-i18n";
	import LocaleHelper from "@/helpers/LocaleHelper";
	import TranslateResult = VueI18n.TranslateResult;

	@Component({
		components: {AsiChip, AsiAutocomplete}
	})
	export default class AsiLocaleAutocompleteInput extends Vue {

		@Prop({default: null})
		public value!: string | string[] | null;

		@Prop({type: String, default: null})
		public label!: string | null;

		@Prop({type: Array, default: () => []})
		public rules!: (string | null | number | object | unknown | boolean)[];

		@Prop({type: Boolean, default: false})
		public clearable!: boolean;

		@Prop({type: Boolean, default: false})
		public multiple!: boolean;

		@Prop({type: Boolean, default: false})
		public simple!: boolean;

		// noinspection JSMethodCanBeStatic
		private get availableLocales(): { value: string; text: string | TranslateResult }[] {
			return LocaleHelper.supportedLocaleOptions();
		}

		private get finalLabel(): string {
			return !StringHelper.isEmpty(this.label) && this.label !== null ? this.label : this.$t('user.locale').toString();
		}

		@Emit('input')
		public input(event: Event): Event {
			return event;
		}

		@Emit('change')
		public change(event: Event): Event {
			return event;
		}

		// noinspection JSMethodCanBeStatic
		private itemText(entry: { text: string; value: string } | []): string {
			if (Array.isArray(entry)) return '';

			return `${entry.text} (${entry.value})`;
		}

	}
</script>

<style lang="scss" scoped>

</style>
