<template>
	<div>
		<AsiBtn :icon="icons.search" @click="open"/>

		<AsiDialog v-if="dialog.isLoaded" :open="dialog.isOpen" @cancel="close" :loading="loading"
		           :icon="icons.search" :title="$t('ui.search')" unwrapped scrollable max-width="70rem">
			<v-card-text class="pa-0 overflow-y-auto">
				<div class="pa-6 grey lighten-4">
					<AsiTextFieldSimple v-model="term" ref="searchInput"
					                    :label="$t('ui.search')" :placeholder="$t('ui.search')"
					                    clearable
					                    @focus="$event.target.select()"
					                    @click:clear="term = null"
					                    @keydown.esc.stop="term = null"/>
				</div>
				<v-divider/>

				<div class="search-results pa-6" :class="{mobile: sMobile}">
					<div>
						<AsiCard :icon="icons.items" :title="$t('item.plural')" unwrapped no-bottom-margin>
							<v-divider/>
							<v-list v-if="resultsItem !== null && resultsItem.total > 0" class="pa-0">
								<v-list-item v-for="item in resultsItem.data" :key="item.id"
								             @click="showItem(item)">
									<v-list-item-avatar>
										<AsiAvatar :size="32" :icon="icons.item"/>
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title>
											<div class="d-flex flex-row justify-space-between flex-wrap">
												<span>{{ translatedValue(item.name) }}</span>
												<span :class="{'font-weight-medium': sMobile}">
													{{
														item.price === null ? '-' :
														$n(itemPrice(item), {
															key: 'currencyDisplay',
															currency: itemCurrencyCode(item)
														})
													}}
												</span>
											</div>
										</v-list-item-title>
										<v-list-item-subtitle v-if="item.price !== null">
											{{ translatedValue(item.category.name) }}
										</v-list-item-subtitle>
										<div v-if="(item.itemNumber !== null || item.alternativeItemNumber !== null)" class="d-flex flex-row flex-wrap align-center flex-gap">
											<ItemChipNumber v-if="item.itemNumber !== null" :item="item" class="pr-2" small/>
											<ItemChipAltNumber v-if="item.alternativeItemNumber !== null" :item="item" class="px-2" small/>
										</div>
									</v-list-item-content>
								</v-list-item>
							</v-list>
							<div v-else class="pa-6 text-center grey--text">
								{{ $t('$vuetify.noDataText') }}
							</div>

							<template v-if="resultsItem !== null && resultsItem.total / resultsItemPerPage > 1">
								<v-divider/>
								<div class="px-6 py-2 d-flex flex-row justify-center">
									<AsiPagination :value="resultsItemPage" @input="resultsItemPage = $event; performSearchItems();"
									               :per-page.sync="resultsItemPerPage" :total="resultsItem.total" hide-per-page-options/>
								</div>
							</template>
						</AsiCard>
					</div>
					<div>
						<AsiCard :icon="icons.categories" :title="$t('category.plural')" unwrapped no-bottom-margin>
							<v-divider/>
							<v-list v-if="resultsCategory !== null && resultsCategory.total > 0" class="pa-0">
								<v-list-item v-for="category in resultsCategory.data" :key="category.id"
								             @click="showCategory(category)">
									<v-list-item-avatar>
										<AsiAvatar :size="32" :bg-color="categoryColor(category)" :image-url="categoryAvatar(category)"
										           :icon="icons.category"/>
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title>
											{{ translatedValue(category.name) }}
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</v-list>
							<div v-else class="pa-6 text-center grey--text">
								{{ $t('$vuetify.noDataText') }}
							</div>

							<template v-if="resultsCategory !== null && resultsCategory.total / resultsCategoryPerPage > 1">
								<v-divider/>
								<div class="px-6 py-2 d-flex flex-row justify-center">
									<AsiPagination :value="resultsCategoryPage" @input="resultsCategoryPage = $event; performSearchCategories();"
									               :per-page.sync="resultsCategoryPerPage" :total="resultsCategory.total" hide-per-page-options/>
								</div>
							</template>
						</AsiCard>
					</div>
				</div>
			</v-card-text>
		</AsiDialog>
	</div>
</template>

<script lang="ts">
	import {Component, Watch} from 'vue-property-decorator';
	import AsiTextField from "@/components/common/AsiTextField";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
	import AsiDialog from "@/components/common/AsiDialog.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import {ICategoryShopListEntry} from "@/models/category/CategoryShopModels";
	import {IItemShopListEntry} from "@/models/item/ItemShopModels";
	import StringHelper from "@/helpers/StringHelper";
	import ItemListFilterShop from "@/models/item/ItemListFilterShop";
	import IPaginatedResponse from "@/models/IPaginatedResponse";
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";
	import {AttachmentMediaSize, PerPageOptions} from "@/helpers/constants";
	import Snackbar from "@/helpers/Snackbar";
	import AsiCard from "@/components/common/AsiCard.vue";
	import AsiPagination from "@/components/common/AsiPagination.vue";
	import {ITranslatedValue} from "@/models/translated-value/TranslatedValueModels";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import LocaleHelper from "@/helpers/LocaleHelper";
	import ItemHelper from "@/models/item/ItemHelper";
	import CategoryHelper from "@/models/category/CategoryHelper";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
	import {mixins} from "vue-class-component";
	import ItemChipNumber from "@/components/item/ItemChipNumber.vue";
	import ItemChipAltNumber from "@/components/item/ItemChipAltNumber.vue";

	@Component({
		components: {
			ItemChipAltNumber,
			ItemChipNumber,
			AsiAvatar, AsiPagination, AsiCard, AsiDialog, AsiTextFieldSimple, AsiBtn, AsiTextField}
	})
	export default class GlobalSearch extends mixins(ResponsiveChecks) {

		private static readonly DEBOUNCE_TIMEOUT = 1000;

		private icons = Icon;
		private loading: boolean = false;
		private term: string | null = null;
		private dialog: DialogHandler = new DialogHandler();
		private searchTimeout: any = null;

		private resultsCategory: IPaginatedResponse<ICategoryShopListEntry> | null = null;
		private resultsCategoryPage: number = 1;
		private resultsCategoryPerPage: number = PerPageOptions[0];
		private resultsItem: IPaginatedResponse<IItemShopListEntry> | null = null;
		private resultsItemPerPage: number = PerPageOptions[0];
		private resultsItemPage: number = 1;

		private expand(): void {
			setTimeout(() => {
				const input = this.$refs.searchInput as any;
				if (input === undefined || input.$refs?.input === undefined) return;
				input.$refs.input.select();
			}, 50);
		}

		@Watch('term')
		private onTermChanged(value: string | null): void {
			clearTimeout(this.searchTimeout);

			if (value === null || StringHelper.isEmpty(value)) {
				this.reset();
			} else {
				if (value.length < 4) return;

				this.searchTimeout = setTimeout(() => {
					this.resultsCategoryPage = 1;
					this.performSearchCategories();
					this.resultsItemPage = 1;
					this.performSearchItems();
				}, GlobalSearch.DEBOUNCE_TIMEOUT);
			}
		}

		private open(): void {
			this.dialog.open();

			setTimeout(() => {
				const input = this.$refs.searchInput as any;
				if (input === undefined || input.$refs?.input === undefined) return;
				input.$refs.input.select();
			}, 50);
		}

		private close(): void {
			this.dialog.close();
			this.reset();
		}

		private reset(): void {
			this.term = null;
			this.resultsCategory = null;
			this.resultsCategoryPage = 1;
			this.resultsItem = null;
			this.resultsItemPage = 1;
		}

		private performSearchCategories(): void {
			if (this.term === null || StringHelper.isEmpty(this.term)) {
				return;
			}
			const termFinal = this.term.toLowerCase();

			const result = this.$store.state.category.categories.filter((c: ICategoryShopListEntry) => {
				const catSearchString = [c.name.de, c.name.fr, c.name.en].filter(s => s !== null).join().toLowerCase();
				return catSearchString.indexOf(termFinal) > -1;
			});

			const start = (this.resultsCategoryPage - 1) * this.resultsCategoryPerPage;
			this.resultsCategory = {
				total: result.length,
				data: result.slice(start, start + this.resultsCategoryPerPage),
			} as IPaginatedResponse<ICategoryShopListEntry>;
		}

		private performSearchItems(): void {
			if (this.term === null || StringHelper.isEmpty(this.term)) {
				return;
			}
			const termFinal = this.term.toLowerCase();

			const filter = new ItemListFilterShop();
			filter.fulltext = termFinal;
			const options = new AsiListTableOptions();
			options.itemsPerPage = this.resultsItemPerPage;
			options.page = this.resultsItemPage;
			options.sortBy = ['name.' + LocaleHelper.extractLanguage(this.$i18n.locale)];
			options.sortDesc = [false];

			this.loading = true;
			this.$itemServiceShop.items(filter, options)
				.then(data => this.resultsItem = data)
				.catch(() => {
					Snackbar.loadingError();
					this.resultsItem = null;
				})
				.finally(() => this.loading = false);
		}

		private translatedValue(value: ITranslatedValue): string | null {
			return TranslatedValueHelper.get(value, this.$i18n.locale, true);
		}

		private showItem(item: IItemShopListEntry): void {
			try {
				this.$router.push(ItemHelper.detailRoute(item.itemNumber));
			} catch (e) {
				//do nothing
			} finally {
				this.close();
			}
		}

		// noinspection JSMethodCanBeStatic
		private itemPrice(item: IItemShopListEntry): number | null {
			return item.price?.price.amount ?? null;
		}

		// noinspection JSMethodCanBeStatic
		private itemCurrencyCode(item: IItemShopListEntry): string | null {
			const c = item.price?.price.currency.currencyCode ?? null;
			if (null === c) {
				console.log(item);
			}
			return c;
		}

		private showCategory(category: ICategoryShopListEntry): void {
			try {
				this.$router.push(CategoryHelper.detailRoute(CategoryHelper.currentCanonical(category.canonical)));
			} catch (e) {
				//do nothing
			} finally {
				this.close();
			}
		}

		// noinspection JSMethodCanBeStatic
		private categoryColor(category: ICategoryShopListEntry): string | null {
			return CategoryHelper.colorHierarchical(category);
		}

		// noinspection JSMethodCanBeStatic
		private categoryAvatar(category: ICategoryShopListEntry): string | null {
			return CategoryHelper.avatarUrl(category, AttachmentMediaSize.s);
		}
	}
</script>

<style lang="scss" scoped>
	@import '../../../node_modules/vuetify/src/styles/styles';

	.search-results {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: $spacer * 6;

		&:not(.mobile) {
			grid-template-columns: 1fr 1fr;
		}

		&.mobile {
			grid-template-columns: 1fr;
			grid-template-rows: auto auto;
		}

		& .flex-gap {
			gap: 8px;
		}
	}
</style>
