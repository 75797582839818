<template>
	<AsiSkeletonCards v-if="item === null" :num="1" :columns="2" loading/>

	<AsiContentContainer v-else :columns="2">
		<div>
			<ItemCardBasicData :item="item" :readonly="!rMasterData" :disabled="isImported" @change="change"/>
		</div>
		<div>
			<ItemCardMetaData :item="item" :readonly="!rMasterData" @change="change"/>
		</div>
		<div>
			<ItemCardSalesData :item="item" :readonly="!rMasterData" :disabled="isImported" @change="change"/>
		</div>
		<div>
			<ItemCardDescription :item="item" :readonly="!rMasterData" :disabled="isImported" @change="change"/>
		</div>
		<div>
			<ItemCardPrices :item="item"/>
		</div>
		<div>
			<ItemCardState :item="item" :readonly="!rMasterData" @change="change"/>
		</div>
	</AsiContentContainer>
</template>

<script lang="ts">
	import {Component, Emit, Prop} from "vue-property-decorator";
	import {IItemAdmin} from "@/models/item/ItemAdminModels";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import AsiSkeletonCards from "@/components/common/AsiSkeletonCards.vue";
	import ItemCardBasicData from "@/components/item/admin/ItemCardBasicData.vue";
	import ItemCardDescription from "@/components/item/admin/ItemCardDescription.vue";
	import ItemCardPrices from "@/components/item/admin/ItemCardPrices.vue";
	import ItemCardState from "@/components/item/admin/ItemCardState.vue";
	import ItemCardSalesData from "@/components/item/admin/ItemCardSalesData.vue";
	import {mixins} from "vue-class-component";
	import RightChecks from "@/mixins/RightChecks.vue";
	import ItemCardMetaData from "@/components/item/admin/ItemCardMetaData.vue";
	import ItemHelper from "@/models/item/ItemHelper";

	@Component({
		components: {
			ItemCardMetaData,
			ItemCardSalesData,
			ItemCardState,
			ItemCardPrices,
			ItemCardDescription, ItemCardBasicData, AsiSkeletonCards, AsiContentContainer}
	})
	export default class ItemTabDetails extends mixins(RightChecks) {

		@Prop({required: true})
		public item!: IItemAdmin | null;

		private get isImported(): boolean | null {
			if (this.item) {
				return ItemHelper.isImported(this.item);
			}
			return null;
		}

		@Emit('change')
		public change(): void {
			return;
		}

	}
</script>

<style lang="scss" scoped>

</style>
