<template>
	<AsiChip :icon="icon" :color="color" small>
		{{ label }}
	</AsiChip>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import EnumHelper from "@/helpers/EnumHelper";
	import {ItemState} from "@/helpers/constants";
	import AsiChip from "@/components/common/AsiChip.vue";
	import ItemHelper from "@/models/item/ItemHelper";
	import {IItemAdminSimple} from "@/models/item/ItemAdminModels";
	import {IItemShopSimple} from "@/models/item/ItemShopModels";

	@Component({
		components: {AsiChip}
	})
	export default class ItemStateChip extends Vue {

		@Prop({type: Object, required: true})
		public item!: IItemAdminSimple | IItemShopSimple;

		// noinspection JSMethodCanBeStatic
		private get label(): string | null {
			return EnumHelper.textFromValue(ItemState, this.item.state, true);
		}

		// noinspection JSMethodCanBeStatic
		private get icon(): string {
			return ItemHelper.stateIcon(this.item);
		}

		// noinspection JSMethodCanBeStatic
		private get color(): string {
			return ItemHelper.stateColor(this.item);
		}

	}
</script>

<style lang="scss" scoped>

</style>
