<template>
	<div>
		<v-navigation-drawer v-model="expanded" app right clipped width="20rem">
			<div class="d-flex flex-column fill-height">
				<div class="flex-grow-0 flex-shrink-0">
					<div class="pa-3 grey lighten-3 d-flex flex-row align-center">
						<template v-if="numCarts > 1">
							<AsiBtn :icon="icons.prev" :disabled="!$store.getters['cart/hasPrev']" small @click="activatePrev"/>
							<AsiBtn :icon="icons.next" :disabled="!$store.getters['cart/hasNext']" small @click="activateNext"/>
						</template>
						<div class="flex-grow-1 flex-shrink-1 px-3 ellipsis" :class="{'text-center': numCarts > 1}">
							<span v-if="cart === null">{{ $t('cart.singular') }}</span>
							<span v-else>{{ cartLabel }}</span>
						</div>
						<v-menu v-if="cart !== null" v-model="menu" bottom offset-y left>
							<template v-slot:activator="{on, attrs}">
								<span v-on="on" v-bind="attrs">
									<AsiBtn :icon="icons.menu"/>
								</span>
							</template>

							<AsiCard unwrapped no-bottom-margin>
								<v-list class="pa-0">
									<template v-if="rLoggedIn">
										<AsiMenuItem :icon="icons.view" :label="$t(numCarts > 1 ? 'cart.terms.showCarts' : 'cart.terms.showCart')"
										             :disabled="cart.positions.length === 0" @click="showCarts"/>
										<v-divider/>
									</template>
									<AsiMenuItem :icon="icons.clear" :label="$t('cart.terms.clear')"
									             :disabled="cart.positions.length === 0" @click="clearCart"/>
									<v-divider/>
									<AsiMenuItem :icon="icons.delete" :label="$t('cart.terms.delete')" @click="deleteCart"/>
								</v-list>
							</AsiCard>
						</v-menu>
					</div>
					<v-progress-linear :indeterminate="$store.state.cart.loading" :value="100" top/>
				</div>

				<CartContent :id="$store.state.cart.activeCartId" class="flex-grow-1 flex-shrink-1"/>
			</div>
		</v-navigation-drawer>

		<AsiConfirmDialog ref="confirm"/>
	</div>
</template>

<script lang="ts">
	import {Component} from "vue-property-decorator";
	import RightChecks from "@/mixins/RightChecks.vue";
	import {mixins} from "vue-class-component";
	import CartContent from "@/components/cart/shop/CartContent.vue";
	import AsiMenuItem from "@/components/common/AsiMenuItem.vue";
	import Icon from "@/plugins/icons";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import AsiCard from "@/components/common/AsiCard.vue";
	import CartHelper from "@/helpers/CartHelper";
	import VueI18n from "vue-i18n";
	import {Routes} from "@/helpers/constants";
	import AsiConfirmDialog from "@/components/common/AsiConfirmDialog.vue";
	import Snackbar from "@/helpers/Snackbar";
	import {ICartShopListEntry} from "@/models/cart/CartShopModels";
	import TranslateResult = VueI18n.TranslateResult;

	@Component({
		components: {AsiConfirmDialog, AsiCard, AsiBtn, AsiMenuItem, CartContent}
	})
	export default class CartDrawer extends mixins(RightChecks) {

		private icons = Icon;
		private menu: boolean = false;

		private get expanded(): boolean {
			return this.$store.state.cart.expanded;
		}

		// noinspection JSUnusedLocalSymbols
		private set expanded(expanded: boolean) {
			this.$store.commit('cart/setExpanded', expanded);
		}

		private get cart(): ICartShopListEntry | null {
			return this.$store.getters['cart/activeCart'];
		}

		private get cartLabel(): string | TranslateResult {
			return CartHelper.label(this.cart);
		}

		private get numCarts(): number {
			return this.$store.state.cart.carts.length;
		}

		private showCarts(): void {
			if (!this.rLoggedIn || this.$router.currentRoute.name === Routes.carts) return;
			this.$router.push({name: Routes.carts});
		}

		private activatePrev(): void {
			const prev = this.$store.getters['cart/prevCart'] as ICartShopListEntry | null;
			if (prev === null) return;
			this.$store.commit('cart/setActiveCartId', prev.id);
		}

		private activateNext(): void {
			const next = this.$store.getters['cart/nextCart'] as ICartShopListEntry | null;
			if (next === null) return;
			this.$store.commit('cart/setActiveCartId', next.id);
		}

		private clearCart(): void {
			const cart = this.cart;
			if (cart === null || cart.positions.length === 0) return;

			const confirm = this.$refs.confirm as unknown as AsiConfirmDialog;
			confirm.openDialog().then((res: boolean) => {
				if (!res) return;
				this.$store.dispatch('cart/clearCart', cart.id).catch(() => Snackbar.updateError());
			});
		}

		private deleteCart(): void {
			const cart = this.cart;
			if (cart === null) return;

			const confirm = this.$refs.confirm as unknown as AsiConfirmDialog;
			confirm.openDialog().then((res: boolean) => {
				if (!res) return;
				this.$store.dispatch('cart/deleteCart', cart.id).catch(() => Snackbar.deleteError());
			});
		}

	}
</script>

<style lang="scss" scoped>

</style>
