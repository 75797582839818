<template>
	<AsiChip :icon="stateIcon(order.paymentState)" :color="stateColor(order.paymentState)" small v-if="!expanded">
		{{ stateLabel(order.paymentState) }}
	</AsiChip>
	<ul v-else>
		<template v-for="(state, i) in states">
			<li v-if="i > 0" :key="'sep-' + state">
				<v-icon :class="{'muted': order.paymentState !== null && state < order.paymentState }" x-small>
					{{ icons.next }}
				</v-icon>
			</li>
			<li :key="'entry-' + state">
				<AsiChip :icon="stateIcon(state)" :color="stateColor(state)" small :disabled="state < order.paymentState" :outlined="state > order.paymentState">
					{{ stateLabel(state) }}
				</AsiChip>
			</li>
		</template>
	</ul>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import EnumHelper from "@/helpers/EnumHelper";
	import AsiChip from "@/components/common/AsiChip.vue";
	import {IOrderAdminSimple} from "@/models/order/OrderAdminModels";
	import {IOrderShopSimple} from "@/models/order/OrderShopModels";
	import OrderHelper from "@/helpers/OrderHelper";
	import {OrderPaymentState} from "@/helpers/constants";
	import Icon from "@/plugins/icons";

	@Component({
		components: {AsiChip}
	})
	export default class OrderPaymentStateChip extends Vue {

		@Prop({type: Object, required: true})
		public order!: IOrderAdminSimple | IOrderShopSimple;

		@Prop({type: Boolean, default: false})
		public expanded!: boolean;

		private icons = Icon;

		private stateLabel(paymentState: OrderPaymentState): string|null {
			return EnumHelper.textFromValue(OrderPaymentState, paymentState, true);
		}
		private stateIcon(paymentState: OrderPaymentState): string {
			return OrderHelper.paymentStateIcon(paymentState);
		}
		private stateColor(paymentState: OrderPaymentState): string {
			return OrderHelper.paymentStateColor(paymentState);
		}

		private get states(): OrderPaymentState[] {
			return EnumHelper.toSelectItems(OrderPaymentState, true).map(s => s.value) as OrderPaymentState[];
		}

	}
</script>

<style lang="scss" scoped>
	ul {
		list-style-type: none;
		padding: 0 !important;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;

		> li {
			.v-icon {
				margin: 0 5px;

				&.muted {
					opacity: 0.3;
				}
			}
		}
	}
</style>
