<template>
	<AsiDialog :title="$t('order.terms.onlinePayment')" :icon="icons.paymentType"
	           :open="open" unwrapped scrollable @cancel="cancel" persistent>
		<template v-if="redirectionTimeoutSeconds > 0">
			<div class="text-center pa-6 grey lighten-4">
				{{ $t('order.terms.redirectionHint') }}
			</div>
			<v-divider/>
			<div class="pa-6 text-center">
				<v-progress-circular indeterminate :size="64" :width="5" color="secondary">
					<span class="font-weight-bold primary--text">
						{{ secondsLeft }}
					</span>
				</v-progress-circular>
			</div>
		</template>
		<div v-else class="pa-6 text-center">
			{{ $t('order.terms.noRedirectionHint') }}
		</div>

		<v-divider/>
		<template v-slot:dialog-actions>
			<AsiBtn :icon="icons.paymentType" color="success" @click="performRedirect" class="mr-3">
				{{ $t('order.terms.payNow') }}
			</AsiBtn>
			<AsiBtn :icon="icons.cancel" color="error" @click="cancel">
				{{ $t('ui.cancel') }}
			</AsiBtn>
		</template>
	</AsiDialog>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from 'vue-property-decorator';
	import AsiDialog from "@/components/common/AsiDialog.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";

	@Component({
		components: {AsiBtn, AsiDialog}
	})
	export default class OrderPaymentRedirectionDialog extends Vue {

		@Prop({type: String, required: true})
		public url!: string;

		@Prop({type: Boolean, default: false})
		public open!: boolean;

		@Prop({type: Number, default: 0})
		public redirectionTimeoutSeconds!: number;

		@Prop({type: Boolean, default: false})
		public newTab!: boolean;

		private icons = Icon;
		private interval: any = null;
		private secondsLeft: number = 0;

		@Watch('open', {immediate: true})
		private onOpenChanged(value: boolean): void {
			clearInterval(this.interval);
			this.secondsLeft = this.redirectionTimeoutSeconds;

			if (value && this.redirectionTimeoutSeconds > 0) {
				this.interval = setInterval(() => {
					this.secondsLeft--;
					if (this.secondsLeft === 0) {
						this.performRedirect();
						this.cancel();
					}
				}, 1000);
			}
		}

		@Emit('cancel')
		public cancel(): void {
			clearInterval(this.interval);
		}

		private performRedirect(): void {
			clearInterval(this.interval);
			if (this.newTab) {
				const w = window.open(this.url, '_blank');
				if (w !== null) w.focus();
			} else {
				window.location.href = this.url;
			}
		}

	}
</script>

<style lang="scss" scoped>

</style>
