<template>
	<div :class="computeClass" :style="computeStyle">
		<slot />
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";

	@Component
	export default class AsiContentContainer extends Vue {

		@Prop({type: Number, default: 1})
		public columns!: number;

		@Prop({type: Number, default: null})
		public columnsLg!: number | null;

		@Prop({type: Number, default: null})
		public columnsXl!: number | null;

		@Prop({type: String, default: null})
		public itemMinWidth!: string | null;

		@Prop({type: Boolean, default: false})
		public smallMargin!: boolean;

		@Prop({type: Boolean, default: false})
		public noMargin!: boolean;

		@Prop({type: Boolean, default: false})
		public noTopMargin!: boolean;

		@Prop({type: Boolean, default: false})
		public noRightMargin!: boolean;

		@Prop({type: Boolean, default: false})
		public noBottomMargin!: boolean;

		@Prop({type: Boolean, default: false})
		public noLeftMargin!: boolean;

		@Prop({type: Boolean, default: false})
		public smallColumnGap!: boolean;

		@Prop({type: Boolean, default: false})
		public withRowGap!: boolean;

		@Prop({type: Boolean, default: false})
		public smallRowGap!: boolean;

		protected get currentColumns(): number {
			if (this.$vuetify.breakpoint.smAndDown) {
				return 1;
			}

			if (this.$vuetify.breakpoint.lg && this.columnsLg !== null) {
				return this.columnsLg;
			} else if (this.$vuetify.breakpoint.xl && this.columnsXl !== null) {
				return this.columnsXl;
			} else if (this.$vuetify.breakpoint.xl && this.columnsLg !== null) {
				return this.columnsLg;
			}

			return this.columns;
		}

		protected get computeClass(): object {
			return {
				'ma-6': !this.noMargin && !this.smallMargin,
				'ma-3': !this.noMargin && this.smallMargin,
				'mt-0': this.noTopMargin && !this.noMargin,
				'mr-0': this.noRightMargin && !this.noMargin,
				'mb-0': this.noBottomMargin && !this.noMargin,
				'ml-0': this.noLeftMargin && !this.noMargin,
			};
		}

		protected get computeStyle(): object {
			const spacer = '24px';
			const smallSpacer = '12px';

			const columnGap = this.smallColumnGap ? smallSpacer : spacer;
			const rowGap = this.withRowGap ? (this.smallRowGap ? smallSpacer : spacer) : 0;

			return {
				'display': 'grid',
				'grid-auto-rows': 'auto',
				'grid-column-gap': columnGap,
				'grid-row-gap': rowGap,
				'grid-template-columns': this.itemMinWidth !== null
					? `repeat(auto-fill, minmax(max(${this.itemMinWidth}, calc(100% / ${this.currentColumns} - ${columnGap})), 1fr))`
					: `repeat(auto-fill, minmax(min(calc(100% - ${columnGap}), calc(100% / ${this.currentColumns} - ${columnGap})), 1fr))`,
			};
		}

	}
</script>

<style lang="scss" scoped>

</style>
