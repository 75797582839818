<template>
	<div class="flex-grow-0 flex-shrink-0">
		<template v-for="(totalEntry, i) in totalEntries">
			<v-divider :key="`${i}-divider`"/>
			<div :key="`${i}-entry`">
				<div class="px-3 py-1 d-flex flex-row justify-space-between align-center" :class="bgColor">
					<div class="font-weight-medium">
						{{ $t('ui.terms.total') }}
					</div>
					<div class="d-flex flex-column align-end">
						<span>
							{{
								$n(totalEntry.amount, {
									key: 'currencyDisplay',
									currency: totalEntry.currency.currencyCode,
								})
							}}
						</span>
						<small class="grey--text">
							{{ $t(includeVat ? 'ui.terms.inclVat' : 'ui.terms.exclVat') }}
						</small>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {ICartShopListEntry} from "@/models/cart/CartShopModels";
	import TotalHelper, {CurrencyAmount} from "@/helpers/TotalHelper";

	@Component
	export default class CartTotalEntries extends Vue {

		@Prop({required: true})
		public cart!: ICartShopListEntry | null;

		@Prop({type: String, default: null})
		public bgColor!: string | null;

		@Prop({type: Boolean, default: false})
		public includeShipment!: boolean;

		@Prop({type: Boolean, default: false})
		public includeVat!: boolean;

		private get totalEntries(): CurrencyAmount[] {
			if (this.cart === null) return [];

			return this.includeVat
				? TotalHelper.totalInclVat(this.cart, this.includeShipment)
				: TotalHelper.totalExclVat(this.cart, this.includeShipment);
		}

	}
</script>

<style lang="scss" scoped>

</style>
