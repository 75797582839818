<template>
	<v-badge :value="showBadge" offset-x="20" offset-y="20" color="primary">
		<template v-slot:badge>
			<span>
				{{ numPositions }}
			</span>
		</template>

		<AsiBtn :icon="icons.canOrderSample" @click="showSampleOrderForm"/>
	</v-badge>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component} from "vue-property-decorator";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import {Routes} from "@/helpers/constants";
	import SampleOrderHelper from "@/helpers/SampleOrderHelper";

	@Component({
		components: {AsiBtn}
	})
	export default class StampButton extends Vue {

		private icons = Icon;

		private get showBadge(): boolean {
			return this.numPositions > 0;
		}

		private get numPositions(): number {
			return this.$store.getters['sampleOrder/items'].length ?? 0;
		}

		private showSampleOrderForm(): void {
			if (this.$router.currentRoute.name === Routes.sampleOrder) {
				return;
			}
			this.$router.push(SampleOrderHelper.detailRoute());
		}

	}
</script>

<style lang="scss" scoped>

</style>
