<template>
	<section class="d-flex flex-row justify-center align-center fill-height">
		<AsiCard v-if="token !== null && userId !== null" :loading="loading" :title="$t('user.terms.confirmation')" :icon="icons.user"
		         :title-bg-color="cardColor" :highlighted="cardColor !== undefined" unwrapped
		         style="width: 90vw; max-width: 30rem">
			<template v-slot:actions>
				<div class="d-flex flex-row justify-center">
					<template v-if="success !== null">
						<AsiBtn v-if="success === true" :icon="icons.login" color="success"
						        @click="showLogin">
							{{ $t('ui.login') }}
						</AsiBtn>
						<AsiBtn v-if="success === false" :icon="icons.reload" color="error" :disabled="!isValid"
						        @click="performConfirm">
							{{ $t('ui.retry') }}
						</AsiBtn>
					</template>
					<AsiBtn v-if="success === null" :icon="icons.yes" color="success" :disabled="loading || !isValid"
					        @click="performConfirm">
						{{ $t('ui.confirm') }}
					</AsiBtn>
				</div>
			</template>

			<v-card-text class="pa-0">
				<div class="body-1 text-center ma-0 pa-6">
					{{ confirmationStateLabel }}
				</div>
				<template v-if="success !== true">
					<v-divider/>
					<div class="pa-6">
						<v-form v-model="passwordFormValid">
							<AsiTextField v-model="password"
							              :disabled="loading"
							              :append-icon="showPassword ? icons.hidePassword : icons.showPassword"
							              :label="$t('ui.terms.password')"
							              :placeholder="$t('ui.terms.password')"
							              :rules="passwordRules"
							              :type="showPassword ? 'text' : 'password'"
							              class="input-required"
							              autocomplete="new-password"
							              required
							              @click:append="showPassword = !showPassword"
							              @keydown.enter.prevent="performConfirm"/>
						</v-form>
					</div>
				</template>
			</v-card-text>
		</AsiCard>
		<AsiAlert v-else type="error" :icon="icons.error" no-bottom-margin>
			{{ $t('user.terms.confirmationDataError') }}
		</AsiAlert>
	</section>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component} from "vue-property-decorator";
	import ShopView from "@/components/layout/ShopView.vue";
	import AsiAlert from "@/components/common/AsiAlert.vue";
	import AsiCard from "@/components/common/AsiCard.vue";
	import VueI18n from "vue-i18n";
	import Icon from "@/plugins/icons";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import RoutesHelper from "@/helpers/RoutesHelper";
	import AsiTextField from "@/components/common/AsiTextField";
	import {ValidationRule} from "@/declarations/ValidationRule";
	import UserRules from "@/models/user/UserRules";
	import TranslateResult = VueI18n.TranslateResult;

	@Component({
		components: {AsiTextField, AsiBtn, AsiCard, AsiAlert, ShopView}
	})
	export default class RegistrationConfirm extends Vue {

		private icons = Icon;
		private loading: boolean = false;
		private success: boolean | null = null;
		private passwordFormValid: boolean = false;
		private password: string | null = null;
		private showPassword: boolean = false;

		private get cardColor(): string | undefined {
			switch (this.success) {
				case true:
					return 'success';
				case false:
					return 'error';
				case null:
				default:
					return undefined;
			}
		}

		private get passwordRules(): ValidationRule[] {
			return UserRules.passwordRules;
		}

		private get confirmationStateLabel(): string | TranslateResult | null {
			if (this.loading) {
				return this.$t('user.terms.confirmationInProgress');
			} else if (this.success === true) {
				return this.$t('user.terms.confirmationSuccessful');
			} else if (this.success === false) {
				return this.$t('user.terms.confirmationFailed');
			}
			return this.$t('user.terms.confirmationEnterData');
		}

		private get isValid(): boolean {
			return this.token !== null && this.userId !== null && this.passwordFormValid;
		}

		private get token(): string | null {
			return (this.$route.query.token ?? null) as string | null;
		}

		private get userId(): string | null {
			return (this.$route.query.userId ?? null) as string | null;
		}

		private performConfirm(): void {
			if (!this.isValid || this.userId === null || this.token === null || this.password === null) {
				return;
			}

			this.loading = true;
			this.$userServiceShop.confirmRegistration(this.userId, this.token, this.password)
				.then(() => this.success = true)
				.catch(() => this.success = false)
				.finally(() => this.loading = false);
		}

		private showLogin(): void {
			this.$router.push(RoutesHelper.loginShopRoute());
		}

	}
</script>

<style lang="scss" scoped>

</style>
