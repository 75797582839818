<template>
	<section>
		<AsiPageTitle :title="$t('pageTitles.admin.debug')" :icon="icons.debug"/>
		<AsiContentContainer>
			<AsiBtn @click="paymentDialog.open()" :disabled="paymentUrl === null">
				open payment dialog
			</AsiBtn>
			<AsiBtn @click="orderWithUrl" :disabled="paymentUrl === null || paymentOrderId === null">
				open order detail with url
			</AsiBtn>

			<AsiTextField v-model="paymentTimeout" label="timeout" placeholder="timeout" class="mt-6"/>
			<AsiTextField v-model="paymentUrl" label="url" placeholder="url" class="mt-6"/>
			<AsiTextField v-model="paymentOrderId" label="order id" placeholder="order id" class="mt-6"/>
			<AsiCheckbox v-model="paymentNewTab" label="new tab"/>

			<OrderPaymentRedirectionDialog v-if="paymentDialog.isLoaded" :open="paymentDialog.isOpen" @cancel="paymentDialog.close()"
			                              :url="paymentUrl" :redirection-timeout-seconds="Number.parseInt(paymentTimeout)"
			                              :new-tab="paymentNewTab"/>
		</AsiContentContainer>
		<v-divider/>
		<AsiContentContainer>
			<AsiCard>
				<div class="d-flex flex-column align-start">
					<div>
						<AsiBtn :icon="icons.edit" x-small>x small</AsiBtn>
						<AsiBtn :icon="icons.edit" x-small/>
					</div>
					<div>
						<AsiBtn :icon="icons.edit" small>small</AsiBtn>
						<AsiBtn :icon="icons.edit" small/>
					</div>
					<div>
						<AsiBtn :icon="icons.edit">regular</AsiBtn>
						<AsiBtn :icon="icons.edit"/>
					</div>
					<div>
						<AsiBtn :icon="icons.edit" large>large</AsiBtn>
						<AsiBtn :icon="icons.edit" large/>
					</div>
					<div>
						<AsiBtn :icon="icons.edit" x-large>x-large</AsiBtn>
						<AsiBtn :icon="icons.edit" x-large/>
					</div>
				</div>
			</AsiCard>
		</AsiContentContainer>
	</section>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component} from "vue-property-decorator";
	import AsiPageTitle from "@/components/common/AsiPageTitle.vue";
	import Icon from "@/plugins/icons";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import AsiCard from "@/components/common/AsiCard.vue";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import AsiTextField from "@/components/common/AsiTextField";
	import OrderHelper from "@/helpers/OrderHelper";
	import AsiCheckbox from "@/components/common/AsiCheckbox";
	import OrderPaymentRedirectionDialog from "@/components/order/shop/OrderPaymentRedirectionDialog.vue";

	@Component({
		components: {
			OrderPaymentRedirectionDialog,
			AsiCheckbox,
			AsiTextField, AsiBtn, AsiCard, AsiContentContainer, AsiPageTitle
		}
	})
	export default class Debug extends Vue {

		private icons = Icon;
		private paymentDialog: DialogHandler = new DialogHandler();
		private paymentUrl: string | null = 'https://asinfotrack.ch';
		private paymentTimeout: number | string = 0;
		private paymentOrderId: string | null = null;
		private paymentNewTab: boolean = false;

		private orderWithUrl(): void {
			if (this.paymentOrderId === null || this.paymentUrl === null) return;
			this.$router.push(OrderHelper.detailRouteWithPaymentUrl(this.paymentOrderId, this.paymentUrl));
		}

	}
</script>

<style lang="scss" scoped>

</style>
