<template>
	<section>
		<AsiCardDetail :title="$t('user.terms.account')" multi-column :columns="2">
			<AsiCardDetailEntry :icon="icons.customer" :label="$t('customer.type')" :value="customerTypeLabel"/>
			<AsiCardDetailEntry :icon="icons.currency" :label="$t('currency.singular')" :value="model.currency"/>
			<AsiCardDetailEntry v-if="model.type === typeBusiness" :icon="icons.phone" :label="$t('customer.phone')" :value="model.phone"/>
		</AsiCardDetail>
		<AsiCardDetail :title="$t('user.singular')" multi-column :columns="2" :no-bottom-margin="model.addresses.length === 0">
			<AsiCardDetailEntry :icon="icons.user" :label="$t('user.firstname')" :value="model.firstname"/>
			<AsiCardDetailEntry :icon="icons.user" :label="$t('user.lastname')" :value="model.lastname"/>
			<AsiCardDetailEntry :icon="icons.username" :label="$t('ui.terms.username')" :value="model.username"/>
			<AsiCardDetailEntry :icon="icons.phone" :label="$t('user.phone')" :value="model.customerPhone"/>
			<AsiCardDetailEntry :icon="icons.language" :label="$t('user.locale')" :value="$t(`locale.${model.locale}`)"/>
		</AsiCardDetail>
		<AsiCardDetail v-if="model.addresses.length > 0" :title="$t('address.plural')" multi-column :columns="2"
		               no-bottom-margin>
			<AsiCardDetailEntry v-for="(address, i) in model.addresses" :key="i"
			                    :icon="icons.address" :label="addressLabel(address, i)">
				<div>
					<div v-for="(line, j) in addressLines(address)" :key="`line-${j}`">
						{{ line }}
					</div>
				</div>
				<div v-if="address.isDefaultShippingAddress || address.isDefaultBillingAddress" class="mt-1">
					<AsiChip v-if="address.isDefaultShippingAddress" label x-small class="mr-3">
						{{ $t('address.terms.shippingAddress') }}
					</AsiChip>
					<AsiChip v-if="address.isDefaultBillingAddress" label x-small>
						{{ $t('address.terms.billingAddress') }}
					</AsiChip>
				</div>
			</AsiCardDetailEntry>
		</AsiCardDetail>
	</section>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import Registration from "@/models/registration/Registration";
	import VueI18n from "vue-i18n";
	import {CustomerType} from "@/helpers/constants";
	import TranslateResult = VueI18n.TranslateResult;
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import Icon from "@/plugins/icons";
	import AddressCreate from "@/models/address/AddressCreate";
	import AddressHelper from "@/helpers/AddressHelper";
	import AsiChip from "@/components/common/AsiChip.vue";

	@Component({
		components: {AsiChip, AsiCardDetailEntry, AsiCardDetail}
	})
	export default class RegistrationSummary extends Vue {

		@Prop({type: Registration, required: true})
		public model!: Registration;

		private icons = Icon;
		private readonly typeBusiness = CustomerType.business;

		private get customerTypeLabel(): string | TranslateResult | null {
			if (this.model.type === null) return null;
			return this.$t(`constants.${this.model.type === CustomerType.business ? 'business' : 'private'}`);
		}

		private addressLabel(address: AddressCreate, index: number): string {
			return address.label ?? `${this.$t('address.singular')} ${index + 1}`;
		}

		private addressLines(address: AddressCreate): string[] {
			return AddressHelper.addressLines(address);
		}

	}
</script>

<style lang="scss" scoped>

</style>
