<template>
	<v-app>
		<TopBar class="white"/>
		<CartDrawer/>

		<v-main class="content-wrapper">
			<div :class="{'root-nav-sticky': rootNavSticky}"
			     :style="{top: rootNavSticky ? undefined : `${topBarHeight}px`}">
				<div class="elevation-1 white" v-if="rootCategories.length > 1 && $route.name === 'Category'">
					<RootCategoryNavigation/>
				</div>
			</div>

			<slot/>
		</v-main>

		<Footer/>
	</v-app>
</template>

<script lang="ts">
	import {Component} from "vue-property-decorator";
	import {mixins} from "vue-class-component";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
	import TopBar from "@/components/layout/TopBar.vue";
	import RootCategoryNavigation from "@/views/shop/layout/partials/RootCategoryNavigation.vue";
	import Footer from "@/views/shop/layout/partials/Footer.vue";
	import CategoryNavigation from "@/components/category/shop/CategoryNavigation.vue";
	import {ICategoryShopListEntry} from "@/models/category/CategoryShopModels";
	import CartDrawer from "@/components/layout/CartDrawer.vue";

	@Component({
		components: {
			CartDrawer,
			CategoryNavigation,
			Footer,
			RootCategoryNavigation,
			TopBar
		}
	})
	export default class LayoutShop extends mixins(ResponsiveChecks) {

		private rootNavSticky: boolean = false;

		public created(): void {
			this.$store.dispatch('category/loadCategories');
		}

		private get topBarHeight(): number {
			return this.$vuetify.application.top;
		}

		private get rootCategories(): ICategoryShopListEntry[] {
			return this.$store.getters['category/rootCategories'];
		}

	}
</script>

<style lang="scss" scoped>
	.content-wrapper {
		background-image: radial-gradient(transparent 30%, rgba(0, 0, 0, 0.05));
	}

	.v-divider {
		border-color: var(--v-primary-base);
		border-style: dashed;
	}

	.root-nav-sticky {
		position: sticky;
		z-index: 1;
	}
</style>
