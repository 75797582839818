<template>
	<LayoutAdmin v-if="isAdminLayout">
		<router-view/>

		<v-snackbar v-if="snackbarMessage" v-model="snackbarVisible" :color="snackbarMessage.color" :timeout="snackbarTimeout" bottom right>
			<span :class="{'white--text': snackbarDark, 'black--text': !snackbarDark}">
				{{ snackbarMessage.message }}
			</span>
			<template v-slot:action>
				<v-btn :dark="snackbarDark" icon @click="snackbarVisible = false">
					<v-icon>{{ icons.close }}</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</LayoutAdmin>
	<LayoutShop v-else>
		<router-view/>

		<v-snackbar v-if="snackbarMessage" v-model="snackbarVisible" :color="snackbarMessage.color" :timeout="snackbarTimeout" bottom right>
			<span :class="{'white--text': snackbarDark, 'black--text': !snackbarDark}">
				{{ snackbarMessage.message }}
			</span>
			<template v-slot:action>
				<v-btn :dark="snackbarDark" icon @click="snackbarVisible = false">
					<v-icon>{{ icons.close }}</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</LayoutShop>
</template>

<script lang="ts">
	import {Component} from "vue-property-decorator";
	import Icon from "@/plugins/icons";
	import {NavigationGuardNext, Route} from "vue-router";
	import Login from "@/views/admin/user/Login.vue";
	import LayoutAdmin from "@/views/admin/layout/LayoutAdmin.vue";
	import LayoutShop from "@/views/shop/layout/LayoutShop.vue";
	import ISnackbarMessage from "@/models/ISnackbarMessage";
	import {UI} from "@/store/modules/ui";
	import RightChecks from "@/mixins/RightChecks.vue";
	import {mixins} from "vue-class-component";

	@Component({
		components: {Login, LayoutShop, LayoutAdmin}
	})
	export default class App extends mixins(RightChecks) {

		private icons = Icon;
		private snackbarVisible: boolean = false;
		private snackbarMessage: ISnackbarMessage | null = null;
		private isAdminLayout: boolean = false;

		private layoutComponent: string = LayoutShop.name;

		public created(): void {
			this.updateLayout(this.$router.currentRoute);
			this.$router.beforeEach(this.beforeRouteChange);
			this.$router.onReady(this.updateLayout);
		}

		public mounted(): void {
			this.$store.subscribe((mutation, state) => {
				if (mutation.type !== 'ui/showSnackbar') {
					return;
				}

				const uiState = state.ui as UI;
				this.snackbarMessage = uiState.snackbarMessage;
				this.snackbarVisible = true;
			});

			if (this.$store.getters['user/isLoggedIn']) {
				this.$store.dispatch('user/loadLoggedInUserData');
			}

			this.$store.dispatch('cart/loadCarts');
			this.$store.dispatch('shoppingList/loadShoppingLists');
		}

		private get snackbarDark(): boolean {
			return this.snackbarMessage?.dark ?? true;
		}

		private get snackbarTimeout(): number | null {
			return this.snackbarMessage?.timeout === undefined ? 5000 : this.snackbarMessage?.timeout;
		}

		private beforeRouteChange(to: Route, from: Route, next: NavigationGuardNext): void {
			this.updateLayout(to);
			next();
		}

		private updateLayout(route: Route | null = null): void {
			this.isAdminLayout = (route ?? this.$router.currentRoute).meta?.adminLayout === true;
		}

	}
</script>

<style lang="scss" scoped>

</style>
