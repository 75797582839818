<template>
	<div>
		<div v-if="topCategories.length > 0">
			<h2 class="text-center my-12">{{ $t('category.terms.topCategories') }}</h2>
			<div class="top-category-wrapper">
				<CategoryFeaturedCard v-for="category in topCategories" :key="category.id" :id="category.id" />
			</div>
		</div>

		<div :class="['d-flex', 'flex-row', 'justify-center', 'mt-16', 'mb-12', {'dropdown-title-wrapper': topCategories.length > 0}]" @click="toggleShowCategory" >
			<v-icon v-if="topCategories.length > 0" :class="['dropdown-icon', 'mr-2', {'active': showAllCategories}]">{{ icons.dropdown }}</v-icon>
			<h2>{{ $t('category.terms.allCategories') }}</h2>
			<v-icon v-if="topCategories.length > 0" :class="['dropdown-icon', 'ml-2', {'active': showAllCategories}]">{{ icons.dropdown }}</v-icon>
		</div>
		<v-expand-transition>
			<div v-show="showAllCategories" class="category-wrapper" :class="{small: small}">
				<CategoryCard v-for="category in categories" :key="category.id"
				              :id="category.id" no-bottom-margin
				              :small="small" :class="{small: small}"/>
			</div>
		</v-expand-transition>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import CategoryCard from "./CategoryCard.vue";
	import {ICategoryShopListEntry} from "@/models/category/CategoryShopModels";
	import CategoryFeaturedCard from "@/components/category/shop/CategoryFeaturedCard.vue";
	import Icon from "@/plugins/icons";

	@Component({
		components: {CategoryFeaturedCard, CategoryCard}
	})
	export default class CategoryNavigation extends Vue {

		@Prop({type: Number, default: 5})
		public columns!: number;

		@Prop({type: Boolean, default: false})
		public small!: boolean;

		@Prop({type: String, default: null})
		public parentCategoryId!: string | null;

		private icons = Icon;

		private showAllCategories: boolean = true;

		public get categories(): ICategoryShopListEntry[] {
			if (this.parentCategoryId !== null) {
				return this.$store.getters['category/categoryChildren'](this.parentCategoryId);
			}

			const roots = this.$store.getters['category/rootCategories'];
			return roots.length === 1
				? this.$store.getters['category/categoryChildren'](roots[0].id)
				: roots;
		}

		public get topCategories(): ICategoryShopListEntry[] {
			return this.categories.filter(c => c.isTopCategory);
		}

		private toggleShowCategory(): void {
			if (this.topCategories.length > 0) {
				this.showAllCategories = !this.showAllCategories;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.top-category-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: center;
		flex-wrap: wrap;
		gap: 1rem;

		margin: 0 auto;
	}
	.category-wrapper {
		display: grid;
		grid-auto-rows: auto;
		grid-gap: 1rem;

		&:not(.small) {
			grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
		}

		&.small {
			grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
		}
	}

	.dropdown-title-wrapper {
		cursor: pointer;

		.dropdown-icon {
			transition: .2s ease-in-out;

			&.active {
				rotate: 180deg;
			}
		}
	}
</style>
