<template>
	<div>
		<AsiBtn v-if="item.itemType === itemType.linkItem" :icon="icons.link" color="secondary" @click="openExternalUrl">{{ $t('item.terms.external') }}</AsiBtn>
		<div v-else>
			<ItemAddToCartButton :quantity="quantityInternal" :item="item" :disabled="!hasPrice"
			                     @click="openDialog"/>

			<ItemAddToCartDialog v-if="hasPrice && withDialog && dialog.isLoaded" :open="dialog.isOpen"
		                     :item="item" :quantity.sync="quantityInternal"
		                     @cancel="dialog.close()" @added="dialog.close(); quantityInternal = 1;"/>
		</div>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop, Watch} from "vue-property-decorator";
	import {IItemShopListEntry} from "@/models/item/ItemShopModels";
	import ItemAddToCartDialog from "@/components/item/shop/ItemAddToCartDialog.vue";
	import DialogHandler from "@/components/common/DialogHandler";
	import ItemAddToCartButton from "@/components/item/shop/ItemAddToCartButton.vue";
	import Gtm, {itemShopListEntryToGtmItem, SelectItemGtmEvent} from "@/plugins/gtm";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import {ItemType} from "@/helpers/constants";
	import Icon from "@/plugins/icons";
	import HyperlinkHelper from "@/helpers/HyperlinkHelper";

	@Component({
		components: {AsiBtn, ItemAddToCartButton, ItemAddToCartDialog}
	})
	export default class ItemAddToCart extends Vue {

		@Prop({type: Object, required: true})
		public item!: IItemShopListEntry;

		@Prop({type: Number, default: 1})
		public quantity!: number;

		@Prop({type: Boolean, default: true})
		public withDialog!: boolean;

		private quantityInternal: number = 1;
		private dialog: DialogHandler = new DialogHandler();

		private icons = Icon;
		private itemType = ItemType;

		private get hasPrice(): boolean {
			return this.item.prices.length > 0;
		}

		@Watch('quantity', {immediate: true})
		private onQuantityChanged(value: number): void {
			this.quantityInternal = value;
		}

		@Watch('quantityInternal')
		private onQuantityInternalChanged(value: number): void {
			if (this.quantity !== value) this.$emit('update:quantity', value);
		}

		private openExternalUrl(): void {
			if (this.item.remoteUrl !== null) {
				HyperlinkHelper.openUrl(this.item.remoteUrl);
			}
		}

		private openDialog(): void {
			Gtm.selectItem({
				items: [
					itemShopListEntryToGtmItem(this.item)
				]
			} as SelectItemGtmEvent);
			this.dialog.open();
		}
	}
</script>

<style lang="scss" scoped>

</style>
