<template>
	<v-badge :value="showBadge" offset-x="20" offset-y="20" color="primary">
		<template v-slot:badge>
			<span v-if="!loading">
				{{numPositions}}
			</span>
			<v-icon v-else x-small style="margin-top: -1px;" class="loading">
				{{icons.loading}}
			</v-icon>
		</template>

		<AsiBtn :icon="icons.cart" @click="toggleCartExpanded"/>
	</v-badge>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component} from "vue-property-decorator";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";

	@Component({
		components: {AsiBtn}
	})
	export default class CartButton extends Vue {

		private icons = Icon;

		private get loading(): boolean {
			return this.$store.state.cart.loading;
		}

		private get showBadge(): boolean {
			return this.numPositions > 0;
		}

		private get numPositions(): number {
			return this.$store.getters['cart/activeCart']?.positions.length ?? 0;
		}

		private toggleCartExpanded(): void {
			this.$store.commit('cart/setExpanded', !this.$store.state.cart.expanded);
		}

	}
</script>

<style lang="scss" scoped>
	@keyframes loading-animation {
		50% {
			transform: scale(1.2) rotate(180deg);
		}
		100% {
			transform: scale(1) rotate(360deg);
		}
	}

	.loading {
		animation: loading-animation linear 1s infinite;
	}
</style>
