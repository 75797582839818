<template>
	<ShopView>
		<h1 class="mb-6 d-flex align-center">
			{{ $t('pageTitles.shop.legalNotice') }}
		</h1>
		<AsiCard unwrapped>
			<v-card-text class="pa-6">
				<div>
					<span class="font-weight-bold">{{ legalNoticeData.maintainerTitle }}</span>
					<ul v-if="this.$isHerzogShop" class="contact-list body-2 pl-0 pt-2 mb-6">
						<li>
							<div class="d-flex flex-row">
								<v-icon size="16" class="mr-3">{{ icons.address }}</v-icon>
								<address class="mb-3" style="margin-top: -2px;">
									<span class="font-weight-medium">Herzog Dichtungen AG</span><br/>
									Steinackerstrasse 31<br/>
									8902 Urdorf
								</address>
							</div>
						</li>
						<li>
							<v-icon size="16" class="mr-3">{{ icons.phone }}</v-icon>
							<a href="tel:+41447342510">
								+41 44 734 25 10
							</a>
						</li>
						<li>
							<v-icon size="16" class="mr-3">{{ icons.email }}</v-icon>
							<a href="mailto:herzogdichtungen@poesia-gruppe.ch">
								herzogdichtungen@poesia-gruppe.ch
							</a>
						</li>
						<li>
							<v-icon size="16" class="mr-3">{{ icons.web }}</v-icon>
							<a href="https://www.poesia.ch" target="_blank">
								www.poesia.ch
							</a>
						</li>
					</ul>
					<ul v-else class="contact-list body-2 pl-0 pt-2 mb-6">
						<li>
							<div class="d-flex flex-row">
								<v-icon size="16" class="mr-3">{{ icons.address }}</v-icon>
								<address class="mb-3" style="margin-top: -2px;">
									<span class="font-weight-medium">mk dichtungs ag</span><br/>
									Grenzweg 3<br/>
									5726 Unterkulm
								</address>
							</div>
						</li>
						<li>
							<v-icon size="16" class="mr-3">{{ icons.phone }}</v-icon>
							<a href="tel:+41627687080">
								+41 62 768 70 80
							</a>
						</li>
						<li>
							<v-icon size="16" class="mr-3">{{ icons.phoneFax }}</v-icon>
							<a href="tel:+41627687081">
								+41 62 768 70 81
							</a>
						</li>
						<li>
							<v-icon size="16" class="mr-3">{{ icons.email }}</v-icon>
							<a href="mailto:mk-ag@poesia-gruppe.ch">
								mk-ag@poesia-gruppe.ch
							</a>
						</li>
						<li>
							<v-icon size="16" class="mr-3">{{ icons.web }}</v-icon>
							<a href="https://www.poesia.ch" target="_blank">
								www.poesia.ch
							</a>
						</li>
					</ul>
					<span class="font-weight-bold">{{ legalNoticeData.exclusionOfLiabilityTitle }}</span>
					<p class="mb-6">{{ legalNoticeData.exclusionOfLiabilityText }}</p>
					<span class="font-weight-bold">{{ legalNoticeData.liabilityForLinksTitle }}</span>
					<p class="mb-6">{{ legalNoticeData.liabilityForLinksText }}</p>
					<span class="font-weight-bold">{{ legalNoticeData.copyrightsTitle }}</span>
					<p class="mb-6">{{ legalNoticeData.copyrightsText }}</p>
					<span class="font-weight-bold">{{ legalNoticeData.dataProtectionTitle }}</span>
					<p class="mb-0">{{ legalNoticeData.dataProtectionText }}</p>
				</div>
			</v-card-text>
		</AsiCard>
	</ShopView>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component} from "vue-property-decorator";
	import AsiLoginForm from "@/components/common/AsiLoginForm.vue";
	import AsiCard from "@/components/common/AsiCard.vue";
	import Icon from "@/plugins/icons";
	import ShopView from "@/components/layout/ShopView.vue";
	import LocaleHelper from "@/helpers/LocaleHelper";
	import {Tenant} from "@/models/ui/Tenant";
	import EnumHelper from "@/helpers/EnumHelper";

	class legalNoticeData {
		maintainerTitle!: string;

		exclusionOfLiabilityTitle!: string;
		exclusionOfLiabilityText!: string;

		liabilityForLinksTitle!: string;
		liabilityForLinksText!: string;

		copyrightsTitle!: string;
		copyrightsText!: string;

		dataProtectionTitle!: string;
		dataProtectionText!: string;
	}

	@Component({
		components: {ShopView, AsiCard, AsiLoginForm}
	})
	export default class LegalNotice extends Vue {

		private icons = Icon;
		private data: Map<string, legalNoticeData> = new Map<string, legalNoticeData>();

		private get lang(): string {
			return this.$i18n.locale === null ? 'de' : LocaleHelper.extractLanguage(this.$i18n.locale);
		}

		public get legalNoticeData(): legalNoticeData | null {
			return this.data.get(this.lang) ?? null;
		}

		public created(): void {
			this.init();
		}

		private init(): void {
			const tenant = EnumHelper.textFromValue(Tenant, this.$isTenant, true);
			this.data.set('en', {
				maintainerTitle: 'Maintainer',

				exclusionOfLiabilityTitle: 'Exclusion of liability',
				exclusionOfLiabilityText: 'We provide no guarantee as to the correctness, accuracy, completeness and timeliness of the information on this website. ' +
					'The content has been carefully prepared and checked by ' + tenant + ', but we accept no liability for material or immaterial damage arising from the use of the information published by us. ' +
					'Please note that all offers are non-binding. ' + tenant + ' may change, supplement or delete this website as well as parts thereof at any time and without prior notice.',

				liabilityForLinksTitle: 'Liability for links',
				liabilityForLinksText: 'We accept no liability for references and links to websites that do not belong to ' + tenant + ' and whose content is therefore not our responsibility. Access and use are at your own risk.',

				copyrightsTitle: 'Copyrights',
				copyrightsText: tenant + ' owns the copyright and all other rights to the content published on this website. Any existing legal claims for third parties are specifically identified as such. ' +
					'The use or copying of any elements is prohibited without the written consent of ' + tenant + '.',

				dataProtectionTitle: 'Data protection',
				dataProtectionText: 'Pursuant to Article 13 of the Swiss Federal Constitution and Federal Data Protection Act (Datenschutzgesetz, DSG), every person is entitled to the protection of their privacy and to protection against misuse of their personal data. ' +
					'We therefore treat our website users’ personal data in the strictest confidence and do not pass this on. In addition, we work together with our hosting providers to protect the databases from unauthorised access, loss, misuse or forgery. ' +
					'\n' +
					'\n' +
					'The following data is also stored in log files when our website is accessed: IP address, date, time, browser request and generally transmitted information about the operating system or browser. ' +
					'Anonymous evaluations are created from these, which we can use to improve our website or our offer.'
			});
			this.data.set('fr', {
				maintainerTitle: 'Opérateur',

				exclusionOfLiabilityTitle: 'Dénégation',
				exclusionOfLiabilityText: 'Il n’existe aucune garantie quant à l’exactitude, la précision, l’exhaustivité et l’actualité des informations de ce site. ' +
					'Le contenu a été soigneusement préparé et vérifié par ' + tenant + ', mais nous déclinons toute responsabilité pour les dommages matériels ou immatériels résultant de l\'utilisation des informations que nous avons publiées. ' +
					'Nous vous prions de noter que toutes les offres sont non contractuelles. ' + tenant + ' peut modifier, modifier ou supprimer ces informations ainsi que des parties du site à tout moment et sans préavis.',

				liabilityForLinksTitle: 'Responsabilité pour les liens',
				liabilityForLinksText: 'Nous n\'assumons aucune responsabilité pour les références et les liens qui mènent à des sites Web n\'appartenant pas à ' + tenant + ' et dont le contenu n\'est donc pas de notre responsabilité. L\'accès et l\'utilisation sont à vos propres risques.',

				copyrightsTitle: 'Droits d\'auteur',
				copyrightsText: tenant + ' est propriétaire des droits d\'auteur et de tous les autres droits liés au contenu publié sur ce site. ' +
					'S\'il existe des réclamations légales de tiers, celles-ci sont spécifiquement identifiées. Sans le consentement écrit de ' + tenant + ', l\'utilisation ou la copie de tout élément est interdite.',

				dataProtectionTitle: 'Politique de confidentialité',
				dataProtectionText: 'Conformément à l\'article 13 de la Constitution fédérale suisse et aux dispositions de la Fédération relatives à la protection des données (loi relative à la protection des données, DSG), toute personne a droit à la protection de sa vie privée et à la protection contre l\'utilisation abusive de ses données personnelles. ' +
					'\n' +
					'\n' +
					'Les données personnelles des utilisateurs de notre site Web sont donc traitées de manière strictement confidentielle et ne seront pas transmises. En outre, nous nous efforçons, en coopération avec nos fournisseurs d’hébergement, de protéger les bases de données contre les accès extérieurs, les pertes, les utilisations abusives ou la contrefaçon. ' +
					'\n' +
					'\n' +
					'Les données suivantes sont également stockées sur notre site Web dans des fichiers journaux: adresse IP, date, heure, demande du navigateur et informations généralement transmises sur le système d\'exploitation, respectivement. Navigateur. ' +
					'Il en résulte des évaluations anonymes, que nous pouvons utiliser pour améliorer notre site Web ou notre offre.'
			});
			this.data.set('de', {
				maintainerTitle: 'Betreiber',

				exclusionOfLiabilityTitle: 'Haftungsausschluss',
				exclusionOfLiabilityText: 'Es besteht keine Gewährleistung bezüglich der inhaltlichen Richtigkeit, Genauigkeit, Vollständigkeit und Aktualität der Informationen auf dieser Webseite. ' +
					'Der Inhalt wurde von der ' + tenant + ' sorgfältig erstellt und geprüft, jedoch übernehmen wir keine Haftung für Schäden materieller oder immaterieller Art, welche aus der Nutzung der von uns veröffentlichten Informationen entstehen. ' +
					'Wir bitten Sie zu beachten, dass alle Angebote unverbindlich sind. Die ' + tenant + ' kann diese, sowie auch Teile der Webseite jederzeit und ohne Vorankündigung verändern, ergänzen oder löschen.',

				liabilityForLinksTitle: 'Haftung für Links',
				liabilityForLinksText: 'Wir übernehmen keine Haftung für Verweise und Links, welche auf Webseiten führen, welche nicht zur ' + tenant + ' gehören und deren Inhalt damit nicht unserer Verantwortung unterliegt. Zugriff und Nutzung erfolgen auf eigene Gefahr.',

				copyrightsTitle: 'Urheberrechte',
				copyrightsText: 'Die ' + tenant + ' besitzt die Urheber- und alle anderen Rechte, der auf dieser Webseite publizierten Inhalte. Bestehen Rechtsansprüche Dritter, werden diese speziell ausgewiesen. Ohne schriftliche Zustimmung von der ' + tenant + ' ist das Verwenden oder Kopieren jeglicher Elemente untersagt.',

				dataProtectionTitle: 'Datenschutz',
				dataProtectionText: 'Jede Person hat laut Artikel 13 der schweizerischen Bundesverfassung und der datenschutzrechtlichen Bestimmungen des Bundes (Datenschutzgesetz, DSG) Anspruch auf Schutz ihrer Privatsphäre sowie auf Schutz vor Missbrauch ihrer persönlichen Daten. ' +
					'Die persönlichen Daten der Nutzer und Nutzerinnen unserer Webseite werden daher streng vertraulich behandelt und nicht weiter gegeben. Zudem bemühen wir uns in Zusammenarbeit mit unseren Hosting-Providern die Datenbanken vor fremden Zugriffen, Verlusten, Missbrauch oder vor Fälschung zu schützen.' +
					'\n' +
					'\n' +
					'Folgende Daten werden bei Zugriffen auch unsere Webseite in Logfiles gespeichert: IP-Adresse, Datum, Uhr-zeit, Browser-Anfrage und allgemein übertragene Informationen zum Betriebssystem resp. Browser. ' +
					'Damit entstehen anonyme Auswertungen, welche von uns zur Verbesserung unserer Webseite bzw. unseres Angebots verwendet werden können.'
			});
		}
	}
</script>

<style lang="scss" scoped>
	.contact-list {
		list-style-type: none;

		a {
			text-decoration: none;
		}
	}

	p {
		white-space: pre-wrap;
	}
</style>
