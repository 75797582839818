<template>
	<AsiListTableLayout :filter-text="filterModel.fulltext" :has-filter="hasFilter" :hide-toolbar="hideToolbar"
	                    @clearFilterText="filterTextChanged(null)" @clearSearch="clearSearch" @update:filterText="filterTextChanged">

		<AsiContentContainer no-bottom-margin>
			<v-data-table
				:footer-props="{itemsPerPageOptions: perPageOptions}"
				:headers="createHeaders()"
				:items="items"
				:loading="loadingInternal"
				:options.sync="tableOptions"
				:server-items-length="total"
				item-key="id"
				multi-sort
				@click:row="openModelDetailPage">

				<template v-slot:item.avatar="{item}">
					<AsiAvatar :size="32" :icon="icons.shoppingList"/>
				</template>
				<template v-slot:item.name="{item}">
					{{ item.name }}
				</template>
				<template v-slot:item.user.customer="{item}">
					<div class="d-flex flex-column">
						<span>
							{{ item.user.customer.name }}
						</span>
						<span v-if="userName(item.user) !== null" class="text--secondary">
							{{ userName(item.user) }}
						</span>
					</div>
				</template>
			</v-data-table>
		</AsiContentContainer>

		<template v-slot:advanced-filters>
			<AsiTextFieldSimple v-model="filterModel.name" :placeholder="$t('shoppingList.name')" clearable/>
		</template>
	</AsiListTableLayout>
</template>

<script lang="ts">
	import {Component, Prop, Watch} from "vue-property-decorator";
	import AsiListTableLayout from "@/components/common/AsiListTableLayout.vue";
	import Icon from "@/plugins/icons";
	import AsiListTable from "@/components/common/AsiListTable.vue";
	import {IShoppingListAdminListEntry} from "@/models/shopping-list/ShoppingListAdminModels";
	import ShoppingListListFilter from "@/models/shopping-list/ShoppingListListFilter";
	import IAsiListTableImplementation from "@/components/common/IAsiListTableImplementation";
	import IPaginatedResponse from "@/models/IPaginatedResponse";
	import AsiListTableHeader from "@/components/common/AsiListTableHeader";
	import AsiListTableOptions from "@/components/common/AsiListTableOptions";
	import AsiContentContainer from "@/components/common/AsiContentContainer.vue";
	import AsiAvatar from "@/components/common/AsiAvatar.vue";
	import UserHelper from "@/models/user/UserHelper";
	import {IUserAdminSimple} from "@/models/user/UserAdminModels";
	import ShoppingListHelper from "@/helpers/ShoppingListHelper";
	@Component({
		components: {AsiAvatar, AsiContentContainer, AsiListTableLayout}
	})
	export default class ShoppingListList extends AsiListTable<IShoppingListAdminListEntry, ShoppingListListFilter> implements IAsiListTableImplementation<IShoppingListAdminListEntry, ShoppingListListFilter>{
		@Prop({type: Boolean, default: false})
		public hideToolbar!: boolean;

		private icons = Icon;
		private initialized: boolean = false;

		public created(): void {
			this.reload(false, false);
			this.$nextTick(() => this.initialized = true);
		}

		public createFilterModel(): ShoppingListListFilter {
			return new ShoppingListListFilter();
		}

		public createHeaders(): AsiListTableHeader[] {
			return [
				new AsiListTableHeader('', 'avatar', false, false, 'center', '4rem'),
				new AsiListTableHeader(this.$t('shoppingList.name'), 'name', true, true, 'start', '30%'),
				new AsiListTableHeader(this.$t('customer.singular'), 'user.customer', true, true, 'start'),
			];
		}

		public fetchData(filterModel: ShoppingListListFilter | null, tableOptions: AsiListTableOptions | null): Promise<IPaginatedResponse<IShoppingListAdminListEntry>> {
			return this.$shoppingListServiceAdmin.shoppingLists(filterModel, tableOptions);
		}

		public filterTextChanged(filterText: string): void {
			if (this.filterModel === null) return;
			this.filterModel.fulltext = filterText;
		}

		public openModelDetailPage(model: IShoppingListAdminListEntry): void {
			this.$router.push(ShoppingListHelper.detailRouteAdmin(model.id));
		}

		public reload(goToFirstPage: boolean = false, debounce: boolean = true): void {
			this.reloadInternal(this, goToFirstPage, debounce);
		}

		protected prepareOptions(options: AsiListTableOptions): AsiListTableOptions {
			if (options.sortBy.length === 0 && this.defaultSortBy.length === 0) {
				options.sortBy = ['name'];
				options.sortDesc = [false];
			}
			return options;
		}

		@Watch('tableOptions', {deep: true})
		private onTableOptionsChanged(): void {
			if (!this.initialized) return;
			this.reload();
		}

		@Watch('filterModel', {deep: true})
		private onFilterModelChanged(): void {
			if (!this.initialized) return;
			this.reload(true);
		}

		private userName(user: IUserAdminSimple): string | null {
			return UserHelper.fullName(user);
		}
	}
</script>

<style lang="scss" scoped>

</style>
