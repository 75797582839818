<template>
	<section>
		<AsiPageTitle :icon="icons.cart" :title="cartLabel" :loading="loading">
			<template v-slot:subtitle>
				<AsiBreadcrumbs :entries="path" hide-icons link-entries/>
			</template>
		</AsiPageTitle>

		<CartTabDetails :cart="cart" />

	</section>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop, Watch} from "vue-property-decorator";
	import AsiPageTitle from "@/components/common/AsiPageTitle.vue";
	import Icon from "@/plugins/icons";
	import Snackbar from "@/helpers/Snackbar";
	import {ICartAdmin} from "@/models/cart/CartAdminModels";
	import VueI18n from "vue-i18n";
	import TranslateResult = VueI18n.TranslateResult;
	import CartHelper from "@/helpers/CartHelper";
	import CartTabDetails from "@/components/cart/admin/CartTabDetails.vue";
	import AsiBreadcrumbs from "@/components/common/AsiBreadcrumbs.vue";
	import PathEntry from "@/models/PathEntry";
	import CustomerHelper from "@/models/customer/CustomerHelper";
	import CustomerTypeChip from "@/components/customer/CustomerTypeChip.vue";

	@Component({
		components: {CustomerTypeChip, AsiBreadcrumbs, CartTabDetails, AsiPageTitle}
	})
	export default class Cart extends Vue {

		@Prop({type: String, required: true})
		public id!: string;

		private icons = Icon;
		private loading: boolean = false;
		private cart: ICartAdmin | null = null;

		private get path(): PathEntry[] {
			return (this.cart === null || this.cart.customer === null) ? [] : [CustomerHelper.pathEntry(this.cart.customer, true)];
		}

		@Watch('id', {immediate: true})
		private onIdChanged(): void {
		    this.loadCart();
		}

		private get cartLabel(): string | TranslateResult | null {
			return this.cart === null ? null : CartHelper.label(this.cart);
		}

		private loadCart(): void {
			this.loading = true;
			this.$cartServiceAdmin.cart(this.id)
				.then(model => this.cart = model)
				.catch(() => Snackbar.loadingError())
				.finally(() => this.loading = false);
		}

	}
</script>

<style lang="scss" scoped>

</style>
