import IFormModel from "@/models/IFormModel";
import {ValidationRule} from "@/declarations/ValidationRule";
import {CustomerType, Salutation} from "@/helpers/constants";
import AddressCreate from "@/models/address/AddressCreate";
import RegistrationRules from "@/models/registration/RegistrationRules";
import UserRules from "@/models/user/UserRules";
import CustomerRules from "@/models/customer/CustomerRules";
import LocaleHelper from "@/helpers/LocaleHelper";

export default class Registration implements IFormModel {

	private static readonly DEFAULT_TIME_ZONE = 'Europe/Zurich';
	private static readonly DEFAULT_LOCALE = 'de-CH';

	public type: CustomerType | null = null;
	public salutation: Salutation = Salutation.mr;
	public username: string | null = null;
	public customerName: string | null = null;
	public customerPhone: string | null = null;
	public currency: string | null = null;
	public firstname: string | null = null;
	public lastname: string | null = null;
	public addresses: AddressCreate[] = [];
	public phone: string | null = null;
	public timeZone: string = 'Europe/Zurich';
	public locale: string = this.currentLocaleOrDefault();

	public reset(): void {
		this.type = null;
		this.salutation = Salutation.mr;
		this.username = null;
		this.customerName = null;
		this.customerPhone = null;
		this.currency = null;
		this.firstname = null;
		this.lastname = null;
		this.addresses = [];
		this.phone = null;
		this.timeZone = Registration.DEFAULT_TIME_ZONE;
		this.locale = this.currentLocaleOrDefault();
	}

	public rules(property: string): ValidationRule[] {
		switch (property) {
			case 'type':
				return RegistrationRules.typeRules;
			case 'salutation':
				return UserRules.salutationRules;
			case 'username':
				return UserRules.usernameRules;
			case 'customerName':
				return CustomerRules.nameRules;
			case 'customerPhone':
				return CustomerRules.phoneRules();
			case 'currency':
				return RegistrationRules.currencyRules;
			case 'firstname':
				return UserRules.firstnameRules;
			case 'lastname':
				return UserRules.lastnameRules;
			case 'phone':
				return UserRules.phoneRules();
			case 'timeZone':
				return UserRules.timeZoneRules;
			case 'locale':
				return UserRules.localeRules;
			default:
				return [];
		}
	}

	private currentLocaleOrDefault() : string {
		return LocaleHelper.localeFromLanguage(LocaleHelper.currentLanguage()) ?? Registration.DEFAULT_LOCALE;
	}
}
