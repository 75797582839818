<template>
	<ItemAttributeFilterSelection v-model="valueInternal" :disabled="disabled" attribute="stockStatus" :data="dataConverted">
		<template v-slot:label="{optionName}">
			{{ optionLabel(optionName) }}
		</template>
	</ItemAttributeFilterSelection>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import ItemAttributeFilter from "@/components/item/shop/attribute-filters/ItemAttributeFilter.vue";
	import AsiSelectSimple from "@/components/common/AsiSelectSimple";
	import VueI18n from "vue-i18n";
	import ItemAttributeFilterSelection
		from "@/components/item/shop/attribute-filters/ItemAttributeFilterSelection.vue";
	import {StockStatus} from "@/helpers/constants";
	import EnumHelper from "@/helpers/EnumHelper";
	import {FilterEntrySelection, FilterEntryStockStatus} from "@/models/item/IItemShopPaginatedResponse";
	import TranslateResult = VueI18n.TranslateResult;

	@Component({
		components: {ItemAttributeFilterSelection, AsiSelectSimple, ItemAttributeFilter}
	})
	export default class ItemAttributeFilterStockStatus extends Vue {

		private static STATUS_OPTIONS = EnumHelper.toSelectItems(StockStatus, true);

		@Prop({type: Array, required: true})
		public value!: StockStatus[];

		@Prop({type: Object, required: true})
		public data!: FilterEntryStockStatus;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		// noinspection JSUnusedLocalSymbols
		private get filterActive(): boolean {
			return this.value.length > 0;
		}

		// noinspection JSUnusedLocalSymbols
		private get valueFormatted(): string | TranslateResult | null {
			if (this.value.length === 0) return null;

			return this.value
				.map(s => ItemAttributeFilterStockStatus.STATUS_OPTIONS.find(o => o.value === s)?.text ?? '?')
				.join(', ');
		}

		private get valueInternal(): string[] {
			return this.value.map(v => v.toString());
		}

		// noinspection JSUnusedLocalSymbols
		private set valueInternal(value: string[]) {
			const converted = value
				.map(v => {
					return (ItemAttributeFilterStockStatus.STATUS_OPTIONS.find(o => o.value.toString() === v)?.value ?? null) as StockStatus | null;
				})
				.filter(v => v !== null) as StockStatus[];
			this.input(converted);
		}

		private get dataConverted(): FilterEntrySelection {
			return {
				options: this.data.options.map(o => {
					return {
						name: o.stockStatus.toString(),
						count: o.count,
					};
				}),
			} as FilterEntrySelection;
		}

		// noinspection JSUnusedLocalSymbols
		private clear(): void {
			this.input([]);
		}

		// noinspection JSMethodCanBeStatic
		private optionLabel(optionName: string): string | TranslateResult {
			const valNumeric = Number.parseInt(optionName);
			return EnumHelper.textFromValue(StockStatus, valNumeric, true) ?? '?';
		}

		@Emit('input')
		public input(value: StockStatus[]): StockStatus[] {
			return value;
		}

	}
</script>

<style lang="scss" scoped>
	.active:before {
		opacity: 0.05;
	}
</style>
