<template>
	<AsiCardDetail :title="titleFinal" :icon="icon" :multi-column="!edit" :columns="columns">
		<CategoryCardAttributesEntry v-for="attribute in attributes" :key="attribute" :attribute="attribute"
		                             :category="category" :update-model="updateModel" :disabled="disabled"
		                             :edit="edit"/>
	</AsiCardDetail>
</template>

<script lang="ts">
	import {Component, Prop} from 'vue-property-decorator';
	import {ICategoryAdminListEntry} from "@/models/category/CategoryAdminModels";
	import CategoryUpdateItemAttributes from "@/models/category/CategoryUpdateItemAttributes";
	import CategoryCardAttributesEntry from "@/components/category/admin/CategoryCardAttributesEntry.vue";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import VueI18n from "vue-i18n";
	import Icon from "@/plugins/icons";
	import ResponsiveChecks from "@/mixins/ResponsiveChecks.vue";
	import {mixins} from "vue-class-component";
	import TranslateResult = VueI18n.TranslateResult;

	@Component({
		components: {AsiCardDetail, CategoryCardAttributesEntry}
	})
	export default class CategoryCardAttributes extends mixins(ResponsiveChecks) {

		@Prop({type: Object, required: true})
		public category!: ICategoryAdminListEntry;

		@Prop({type: Array, required: true})
		public attributes!: string[];

		@Prop({type: Object, default: null})
		public updateModel!: CategoryUpdateItemAttributes | null;

		@Prop({type: Boolean, default: false})
		public edit!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		@Prop({default: null})
		public title!: string | TranslateResult | null;

		@Prop({type: String, default: Icon.attributes})
		public icon!: string;

		private get titleFinal(): string | TranslateResult {
			return this.title ?? this.$t('item.terms.attributes');
		}

		private get columns(): number {
			if (this.sMobile) return 1;
			if (this.sSemiMobile) return 2;
			return 4;
		}

	}
</script>

<style lang="scss" scoped>

</style>
