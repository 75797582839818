<template>
	<AsiCardDetail :icon="icons.paymentType" :title="$t('paymentType.singular')">
		<AsiCardDetailEntry :label="$t('paymentType.singular')" :value="paymentType"
		                    :icon="icons.paymentType"/>
		<AsiCardDetailEntry :label="$t('constants.orderPaid')" :value="isPaid"
		                    :icon="isPaid ? icons.yes : icons.no" :icon-color="isPaid ? 'success' : 'error'"/>
		<AsiCardDetailEntry :icon="icons.address" :label="$t('address.terms.billingAddress')"
		                    :value="addressLines"/>
	</AsiCardDetail>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from 'vue-property-decorator';
	import {OrderPaymentState} from "@/helpers/constants";
	import AsiCardDetail from "@/components/common/AsiCardDetail.vue";
	import AsiCardDetailEntry from "@/components/common/AsiCardDetailEntry.vue";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import Icon from "@/plugins/icons";
	import {IOrderAdmin} from "@/models/order/OrderAdminModels";
	import AddressHelper from "@/helpers/AddressHelper";

	@Component({
		components: {
			AsiCardDetailEntry,
			AsiCardDetail,
		}
	})
	export default class OrderCardPayment extends Vue {

		@Prop({required: true})
		public order!: IOrderAdmin;

		private icons = Icon;

		private get isPaid(): boolean {
			return this.order.paymentState === OrderPaymentState.orderPaymentPaid;
		}

		private get paymentType(): string | null {
			return TranslatedValueHelper.get(this.order.paymentType.name, this.$i18n.locale, true);
		}

		// noinspection JSMethodCanBeStatic
		private get addressLines(): string[] {
			return AddressHelper.addressLines(this.order.billingAddress);
		}

	}
</script>

<style lang="scss" scoped>

</style>
