<template>
	<div>
		<ol class="paragraph-list">
			<li v-for="(paragraph, i) in paragraphs" :key="i">
				<span class="font-weight-bold">
					{{ paragraph.title }}
				</span>

				<template v-if="Array.isArray(paragraph.content)">
					<ol>
						<li v-for="(subParagraph, j) in paragraph.content" :key="`${i}-${j}`">
							{{ subParagraph }}
						</li>
					</ol>
				</template>
				<p v-else>
					{{ paragraph.content }}
				</p>
			</li>
		</ol>

		<p class="ma-0 grey--text">
			<small>(Ausgabe 10/2013)</small>
		</p>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import Icon from "@/plugins/icons";
	import LocaleHelper from "@/helpers/LocaleHelper";
	import AsiLocaleAutocompleteInput from "@/components/common/AsiLocaleAutocompleteInput.vue";

	class TacParagraph {
		title!: string;
		content!: string | string[];
	}

	@Component({
		components: {AsiLocaleAutocompleteInput}
	})
	export default class LegalTermsAndConditions extends Vue {

		@Prop({type: String, default: null})
		public locale!: string;

		private icons = Icon;
		private localeInternal: string | null = null;
		private data: Map<string, TacParagraph[]> = new Map<string, TacParagraph[]>();

		public created(): void {
			this.localeInternal = this.locale ?? this.$i18n.locale;
			this.init();
		}

		private get lang(): string {
			return this.localeInternal === null ? 'de' : LocaleHelper.extractLanguage(this.localeInternal);
		}

		public get paragraphs(): TacParagraph[] {
			return this.data.get(this.lang) ?? [];
		}

		private init(): void {
			if (this.$isHerzogShop) {
				this.data.set('de', [
					{
						title: 'Geltung, Offertstellung und Auftragsannahme',
						content: [
							'Diese Allgemeinen Verkaufsbedingungen gelten für alle – auch zukünftigen – Verträge über Lieferungen und sonstige Leistungen. Einkaufsbedingungen des Käufers verpflichten uns auch dann nicht, wenn wir ihnen nicht nochmals nach Eingang bei uns ausdrücklich widersprechen.',
							'Unsere Offerten sind freibleibend, sofern nichts anderes vermerkt ist. Wir bemühen uns, die angebotenen Preise, Mengen, Qualitäten und Lieferfristen einzuhalten.',
							'Alle Aufträge bedürfen zur Gültigkeit der schriftlichen Bestätigung oder Rechnungsstellung durch uns.',
							'Vereinbarungen, insbesondere mündliche Nebenabreden, Zusagen, Garantien und sonstige Zusicherungen unserer Verkaufsangestellten, werden erst durch unsere schriftliche Bestätigung verbindlich. Die Schriftform wird auch durch die Übermittlung per Telefax oder E-Mail gewahrt.',
						],
					},
					{
						title: 'Datenschutz',
						content: 'Informationen von Käufern speichern und verwenden wir für die Abwicklung der eigenen Geschäfte. Bei der Bearbeitung der Kundendaten beachten wir das geltende Datenschutzrecht.',
					},
					{
						title: 'Preise',
						content: [
							'Die Preise beziehen sich auf die bestätigten oder fakturierten Leistungen. Sie verstehen sich, soweit nichts anderes vereinbart wird, ab unserem Betrieb ausschliesslich Fracht, Porto und Verpackung, jeweils zuzüglich der gesetzlichen Mehrwertsteuer. Für Nachbestellungen sind die Preise nicht anwendbar.',
							'Die Mindestfakturasumme beträgt CHF 30.00 netto Warenwert. Sofern bei einer Bestellung die Summe weniger als CHF 30.00 beträgt, erheben wir zusätzlich zum Endpreis ein Mindermengenzuschlag in der Höhe von maximal CHF 15.00.',
							'Eventuelle Zusatzkosten aufgrund von geforderten Material- und Sicherheitskontrollen oder besonderer Logistik- und Transportprozesse sind nicht enthalten und werden gesondert in Rechnung gestellt.',
							'Wird die Ware verpackt geliefert, so berechnen wir die Verpa,ckung zum Selbstkostenpreis; von uns gelieferte Verpackungen nehmen wir nicht zurück.',
						],
					},
					{
						title: 'Zahlung und Verrechnung',
						content: [
							'Soweit nichts anderes vereinbart wurde, sind unsere Rechnungen innert 30 Tagen zu bezahlen. Der Rechnungsbetrag ist 30 Tage nach Faktura- bzw. Valutadatum fällig. Die Zahlung hat – ohne Skontoabzug – in der Weise zu erfolgen, dass wir am Fälligkeitstag über den Betrag verfügen können. Der Käufer kommt nach Ablauf der dreissigtägigen Zahlungsfrist unmittelbar in Verzug, ohne dass es einer Mahnung bedarf.',
							'Wir behalten uns in jedem Fall vor, Vorauszahlung zu verlangen.',
							'Ein vereinbartes Skonto bezieht sich immer nur auf den Rechnungswert ausschliesslich Fracht und setzt den vollständigen Ausgleich aller fälligen Verbindlichkeiten des Käufers im Zeitpunkt der Skontierung voraus.',
							'Rechnungen für Montagen, Reparaturen, Formen und Werkzeugkostenanteile sind jeweils sofort fällig und netto zahlbar.',
							'Von uns bestrittene oder nicht rechtskräftig festgestellte Gegenforderungen berechtigen den Käufer weder zur Zurückbehaltung noch zur Verrechnung. Das Leistungsverweigerungsrecht im Sinne von Art. 82 OR ist ausgeschlossen.',
							'Nach Ablauf der dreissigtägigen Zahlungsfrist seit Faktura- / Valutadatum sind wir berechtigt, den Ersatz der Mahnkosten zu verlangen und Verzugszinsen von fünf Prozent seit Faktura- bzw. Valutadatum für das Jahr zu berechnen, auch wenn nach Vertrag ein niedrigerer Zinssatz vereinbart ist. Gilt nach Vertrag ein höherer Zinssatz, können wir auch während der Verzugsperiode den höheren Zins verlangen. Die Geltendmachung eines weiteren Verzugsschadens bleibt vorbehalten.',
						],
					},
					{
						title: 'Lieferzeiten',
						content: [
							'Alle Angaben über voraussichtliche Lieferfristen und -termine sind unverbindlich. Sie erfolgen nach bestem Wissen, wie sie bei normaler Zulieferung und unter geordneten Verhältnissen eingehalten werden können. Lieferfristen sind eingehalten, wenn bis zu ihrem Ablauf der Liefergegenstand unseren Betrieb verlassen hat. Die Lieferung erfolgt EXW Unterkulm oder Urdorf (Incoterm 2020).',
							'Unsere Lieferverpflichtung steht unter dem Vorbehalt richtiger und rechtzeitiger Selbstbelieferung, es sei denn, die nicht richtige oder verspätete Selbstbelieferung ist von uns zu vertreten.',
							'Ereignisse höherer Gewalt berechtigen uns, die Lieferungen um die Dauer der Behinderung und einer angemessenen Anlaufzeit hinauszuschieben. Dies gilt auch dann, wenn solche Ereignisse während eines vorliegenden Verzuges eintreten. Der höheren Gewalt stehen gleich währungs-, handelspolitische und sonstige hoheitliche Massnahmen, Streiks, Aussperrungen, von uns nicht verschuldete Betriebsstörungen, Behinderung der Verkehrswege, Verzögerung bei der Einfuhr- / Zollabfertigung, sowie alle sonstigen Umstände, die, ohne von uns verschuldet zu sein, die Lieferungen und Leistungen wesentlich erschweren oder unmöglich machen. Dabei ist es unerheblich, ob die Umstände bei uns, dem Lieferwerk oder einem anderen Vorlieferanten eintreten. Wird infolge der vorgenannten Ereignisse die Durchführung für eine der Vertragsparteien unzumutbar, kann sie durch unverzügliche schriftliche Erklärung von dem Vertrag zurücktreten.',
						],
					},
					{
						title: 'Nutzen und Gefahr, Ausführung der Lieferungen',
						content: [
							'Mit der Übergabe der Ware an einen Spediteur oder Frachtführer - bei Streckengeschäften des Lieferwerkes - gehen Nutzen und Gefahr bei allen Geschäften auf den Käufer über. Pflicht und Kosten der Entladung gehen zu Lasten des Käufers. Für Versicherung sorgen wir nur auf Weisung und Kosten des Käufers.',
							'Wir sind zu Teillieferungen in für den Kunden zumutbarem Umfang berechtigt. Bei Anfertigungsware, Schüttgütern oder Rollenware sind Mehr- oder Minderlieferungen bis zu 10 Prozent der abgeschlossenen Menge zulässig.',
							'Bei Kauf auf Abruf sind wir berechtigt, die gesamte Bestellmenge geschlossen herzustellen bzw. herstellen zu lassen. Abruftermine und -mengen können, soweit keine festen Vereinbarungen getroffen wurden, nur im Rahmen unserer Lieferungs- oder Herstellungsmöglichkeiten eingehalten werden. Wird die Ware nicht vertragsgemäss abgerufen, sind wir berechtigt, sie nach Verstreichen einer angemessenen Nachfrist als geliefert zu berechnen.',
						],
					},
					{
						title: 'Eigentumsvorbehalt',
						content: 'Alle gelieferten Waren bleiben unser Eigentum bis zur Zahlung des vollständigen Kaufpreises. Wir behalten uns vor, die Eintragung im Eigentumvorbehaltsregister beim Betreibungsamt am Sitz resp. Wohnsitz des Käufers zu veranlassen. Bei Zahlungsverzug des Käufers behalten wir uns vor, vom Vertrag zurückzutreten und die gelieferte Ware zurückzufordern (Vorbehalt im Sinne von Art. 214 Abs. 3 OR).',
					},
					{
						title: 'Haftung für Mängel',
						content: [
							'Wir verpflichten uns, bei nachweisbaren Mängeln (Herstellungs- oder Materialfehler) an der gelieferten Ware innerhalb der gesetzlichen oder vertraglich festgelegten Frist nach unserer Wahl Ersatzlieferung oder Nachbesserung zu leisten. Jede weitere Haftung sowie alle weiteren Ansprüche des Käufers für irgendwelche Schäden (direkte oder indirekte, unmittelbare oder mittelbare), insbesondere die gesetzlichen Sachmängelansprüche, sind soweit gesetzlich zulässig, wegbedungen. Eignungs- und Verwendungsrisiken obliegen dem Käufer. Bei unsachgemässer Lagerung oder Behandlung, Überbeanspruchung oder ungeeigneter Verwendung lehnen wir jede Gewährleistung und sonstige Haftung ab. Vorbehalten bleiben die Vorschriften des Bundesgesetzes über die Produktehaftpflicht (PrHG).',
							'Soweit nichts anderes vereinbart, verjähren vertragliche Ansprüche, die dem Käufer gegen uns aus Anlass oder im Zusammenhang mit der Lieferung der Ware entstehen, ein Jahr nach Ablieferung der Ware. Kommt es im Gewährleistungsfall zu einem Umtausch, indem das mängelbehaftete Produkt durch ein identisches Neuprodukt ersetzt wird, so beginnt die Gewährleistungsfrist nicht neu zu laufen.',
							'Bezugnahmen auf SN-, EN- oder andere Normen und ähnliche Regelwerke sowie Angaben zu Qualitäten, Sorten, Massen, Gewichten und Verwendbarkeit der Waren, Angaben in Zeichnungen und Abbildungen sowie Aussagen in Werbemitteln sind keine Zusicherungen oder Garantien, soweit sie nicht ausdrücklich und schriftlich als solche bezeichnet sind.',
							'Für die Untersuchung der Ware und Anzeige von Mängeln gelten die gesetzlichen Vorschriften: Sachmängel der Ware sind unverzüglich, spätestens aber acht Tage seit Ablieferung schriftlich anzuzeigen. Im Falle von verborgenen Mängeln ist die Mängelrüge sofort nach Entdeckung der Mängel schriftlich anzubringen, jedoch spätestens drei Monate nach Erhalt der Ware. Bei nicht rechtzeitiger Mitteilung erlöschen alle Ansprüche auf Gewährleistung.',
							'Solange der Käufer uns nicht Gelegenheit gibt, uns von dem Mangel zu überzeugen, er insbesondere auf Verlangen die beanstandete Ware oder Proben davon nicht zur Verfügung stellt, kann er sich auf Mängel der Ware nicht berufen.',
						],
					},
					{
						title: 'Warenretouren',
						content: 'Wir verpflichten uns, falsch oder mangelhaft gelieferte Ware zurückzunehmen. Rücksendungen von Waren werden nur gegen Voravis und mit unserer Einwilligung an die durch uns vorgegebene Lieferadresse zurückgenommen. Die Ware ist in Originalverpackung, Flüssigkeiten in geschlossenen Gebinden, mit Kopie des Lieferscheins auf Risiko und Kosten des Käufers zu retournieren.',
					},
					{
						title: 'Rechte an geistigem Eigentum',
						content: [
							'An allen Dokumenten wie Offerten, Zeichnungen oder Berechnungen, welche wir für den Käufer anfertigen, behalten wir uns unsere Schutzrechte vor; sie dürfen Dritten nur im Einvernehmen mit uns zugänglich gemacht werden. Zu Offerten gehörige Zeichnungen und andere Unterlagen sind auf Verlangen zurückzugeben.',
							'Sofern wir Gegenstände nach vom Käufer übergebenen Zeichnungen, Modellen, Mustern oder sonstigen Unterlagen geliefert haben, übernimmt dieser die Gewähr dafür, dass Schutzrechte Dritter nicht verletzt werden. Untersagen uns Dritte unter Berufung auf Schutzrechte insbesondere die Herstellung und Lieferung derartiger Gegenstände, sind wir – ohne zur Prüfung der Rechtslage verpflichtet zu sein – berechtigt, insoweit jede weitere Tätigkeit einzustellen und bei Verschulden des Käufers Schadensersatz zu verlangen. Der Käufer verpflichtet sich ausserdem, uns von allen damit in Zusammenhang stehenden Ansprüchen Dritter unverzüglich freizustellen.',
						],
					},
					{
						title: 'Versuchsteile, Formen, Werkzeuge und beigestellte Teile',
						content: [
							'Formen und Werkzeuge, die wir für einen Käufer herstellen oder herstellen lassen, bleiben auch dann unser Eigentum, wenn er die Kosten teilweise oder vollumfänglich übernimmt. Es besteht kein Anspruch auf Aushändigung.',
							'Die Anfertigung von Versuchsteilen einschliesslich der Kosten für Formen und Werkzeuge gehen zu Lasten des Käufers.',
							'Für vom Käufer beigestellte Werkzeuge, Formen und sonstige Fertigungsvorrichtungen beschränkt sich unsere Haftung auf die Sorgfalt wie in eigener Sache. Kosten für Wartung und Pflege trägt der Käufer. Unsere Aufbewahrungspflicht erlischt – unabhängig von Eigentumsrechten des Käufers – spätestens fünf Jahre nach der letzten Fertigung aus der Form oder dem Werkzeug.',
							'Hat der Käufer zur Auftragsdurchführung Teile beizustellen, so sind sie frei Produktionsstätte mit der vereinbarten, andernfalls mit einer angemessenen Mehrmenge für etwaigen Ausschuss, rechtzeitig, unentgeltlich und mängelfrei anzuliefern. Geschieht dies nicht, so gehen hierdurch verursachte Kosten und sonstige Folgen zu seinen Lasten.',
						],
					},
					{
						title: 'Erfüllungsort, Gerichtsstand und anzuwendendes Recht',
						content: [
							'Gerichtsstand und Erfüllungsort für unsere Lieferungen (soweit nicht anders vereinbart) ist unser Sitz. Wir können den Käufer auch an seinem Sitz bzw. Wohnort verklagen.',
							'Für alle Rechtsbeziehungen zwischen uns und dem Käufer gilt Schweizer Recht. Die Anwendbarkeit des UN-Übereinkommens über den internationalen Warenkauf (Wiener Kaufrecht / CISG) ist ausdrücklich ausgeschlossen.',
						],
					},
					{
						title: 'Bindende Fassung',
						content: 'In Zweifelsfällen ist diese deutsche Fassung dieser Allgemeinen Verkaufs- und Lieferbedingungen bindend.',
					},
				] as TacParagraph[]);
				this.data.set('fr', [
					{
						title: 'Champ d\'application, soumission d\'offres et acceptation de la commande',
						content: [
							'Les présentes conditions générales de vente s\'appliquent à tous les contrats -même futurs - relatifs à des livraisons et d\'autres prestations. Les conditions générales d\'achat de l\'acheteur ne nous sont pas applicables, même lorsque nous ne nous y sommes pas opposés une nouvelle fois, de manière expresse, après leur réception.',
							'Sauf mention contraire, nos offres sont sans engagement. Nous nous efforçons de respecter les prix, quantités, qualités et délais de livraison proposés.',
							'Toutes les commandes ne sont valables qu\'à condition d\'avoir été confirmées par écrit ou facturées par nous.',
							'Les conventions, notamment les accords accessoires verbaux, les confirmations, les garanties et autres assurances de nos employés de vente, deviennent uniquement obligatoires après notre confirmation écrite. La forme écrite est également respectée en cas de transmission par fax ou e-mail.',
						],
					},
					{
						title: 'Protection des données',
						content: 'Nous sauvegardons et utilisons les informations relatives aux acheteurs, afin de pouvoir traiter nos affaires. Nous respectons le droit de la protection des données en vigueur, lorsque nous traitons les données de clients.',
					},
					{
						title: 'Prix',
						content: [
							'Les prix se rapportent aux prestations confirmées ou facturées. Ils s\'entendent, sauf convention contraire, à partir de notre usine, hors fret, port et emballage, à chaque fois TVA légale en sus. Les prix ne sont pas applicables aux commandes complémentaires.',
							'La somme de facturation minimale s\'élève à CHF 50.00 de valeur nette de marchandises.',
							'Les éventuels coûts supplémentaires dus à des contrôles de matériel et de sécurité exigés ou de processus logistiques et de transport particuliers ne sont pas inclus et sont facturés séparément.',
							'Si la marchandise est livrée emballée, nous facturons l\'emballage au prix coûtant; nous ne reprenons pas les emballages livrés.',
						],
					},
					{
						title: 'Paiement et compensation',
						content: [
							'Sauf accord contraire, nos factures sont payables dans les 30 jours. Le montant de la facture est exigible 30 jours après la date de facturation, respectivement après la date de valeur. Le paiement doit être effectué – sans escompte – de manière à ce que nous puissions disposer du montant le jour de l\'exigibilité. A l\'expiration du délai de paiement de 30 jours, l\'acheteur tombe immédiatement en demeure, sans qu\'une interpellation ne soit nécessaire.',
							'Dans tous les cas, nous nous réservons le droit d\'exiger un paiement à l\'avance.',
							'Lorsqu\'un escompte a été convenu, celui-ci se rapporte, toujours, uniquement à la valeur facturée de la marchandise vendue, fret non compris, et présuppose que toutes les dettes exigibles de l\'acheteur aient déjà été intégralement réglées, au moment de la déduction de l\'escompte.',
							'Les factures de montage, de réparation, ainsi que celles pour les moules et la participation aux coûts des outils sont toujours immédiatement exigibles et payables net.',
							'Les prétentions de l\'acheteur que nous contestons ou qui n\'ont pas été constatées par une décision entrée en force ne donnent à l\'acheteur ni un droit de rétention, ni un droit de compensation. Le droit de refuser l\'exécution au sens de l\'art. 82 CO est exclu.',
							'Passé le délai de paiement de 30 jours à compter de la date de facturation/de valeur, nous avons le droit d\'exiger le remboursement des frais de mise en demeure et de comptabiliser des intérêts moratoires annuels de 5 pourcents à partir de la date de facturation/de valeur, même si le contrat prévoit un taux d\'intérêt plus bas. Si le contrat prévoit un taux d\'intérêt plus haut, nous pouvons également exiger le taux plus élevé pendant la période de la demeure. Nous nous réservons le droit de faire valoir un autre dommage consécutif au retard.',
						],
					},
					{
						title: 'Heures de livraison',
						content: [
							'Toutes les indications relatives aux délais et échéances de livraison probables sont non contraignantes. Elles sont données au mieux de nos connaissances, en fonction de la manière dont ils pourront être respectés en cas de livraison normale et de circonstances ordinaires. Les délais de livraison sont respectés si l\'objet de la livraison a quitté notre usine jusqu\'à l\'expiration du délai.',
							'Notre obligation de livraison est soumise à la réserve de la livraison correcte et en temps utile de nos propres fournisseurs, à moins que le vice ou le retard de la livraison desdits fournisseurs ne nous soit imputable.',
							'Les événements dus à la force majeure nous donnent le droit de retarder les livraisons d\'une durée équivalente à celle de l\'empêchement, à laquelle vient s\'ajouter un délai de redémarrage approprié. Cette règle s\'applique également si ces événements surviennent pendant une demeure déjà existante. Sont assimilés à la force majeure les mesures de politique monétaire ou commerciale ainsi que toute autre mesure étatique, les grèves, les lockouts, les perturbations du fonctionnement de l\'exploitation survenant sans faute de notre part, l\'obstruction des voies de circulation, les retards dans les procédures d\'importation/de dédouanement, ainsi que toutes les autres circonstances qui, sans faute de notre part, rendent beaucoup plus difficiles ou impossibles les livraisons et prestations. A cet égard, il est sans importance que ces circonstances surviennent chez nous, chez le fournisseur ou un fournisseur précédent. Si l\'exécution ne peut plus être raisonnablement exigée de l\'une des parties au contrat, en raison des événements susmentionnés, celle-ci peut se départir du contrat par une déclaration écrite immédiate.',
						],
					},
					{
						title: 'Profits et risques, exécution des livraisons',
						content: [
							'Pour toutes les affaires, les profits et risques passent à l\'acheteur au moment de la remise de la marchandise à un expéditeur ou à un transporteur - pour les livraisons directes, au moment de la sortie de l\'usine du fournisseur. L\'obligation et les coûts du déchargement sont à la charge de l\'acheteur. En ce qui concerne l\'assurance, nous ne nous occupons de cette question que sur instruction et aux frais de l\'acheteur.',
							'Nous sommes autorisés à effectuer des livraisons partielles dans une mesure appropriée pour le client. Pour les marchandises faites sur mesure, les matériaux en vrac ou la marchandise disponible sous forme de rouleaux, des livraisons en plus ou en moins sont admises jusqu\'à 10% de la quantité convenue.',
							'En cas d\'achats sur appel, nous avons le droit de fabriquer ou de faire fabriquer l\'intégralité de la quantité commandée en une seule fois. Les échéances et quantités appelées ne peuvent être respectées que dans le cadre de nos possibilités de livraison ou de fabrication, dans la mesure où aucun accord fixe n\'a été conclu. Si la marchandise n\'est pas appelée conformément au contrat, nous avons le droit de la facturer comme livrée, après l\'écoulement d\'un délai supplémentaire approprié.',
						],
					},
					{
						title: 'Réserve de propriété',
						content: 'Toute la marchandise livrée reste notre propriété jusqu\'au paiement intégral du prix de vente. Nous nous réservons le droit d\'inscrire une réserve dans le registre des pactes de réserve de propriété de l\'office des poursuites du siège, respectivement du domicile de l\'acheteur. En cas de demeure de l\'acheteur, nous nous réservons le droit de nous départir du contrat et de répéter la marchandise livrée (réserve au sens de l\'art. 214 al. 3 CO).',
					},
					{
						title: 'Responsabilité pour défauts',
						content: [
							'En cas de défaut de la marchandise livrée pouvant être prouvé (défaut de fabrication ou de matériel), nous nous engageons, selon notre choix, à livrer de la marchandise de remplacement ou à réparer le défaut de conformité de la marchandise livrée, dans le délai fixé par la loi ou le contrat. Toute autre responsabilité ainsi que toute autre prétention de l\'acheteur pour des dommages (directs ou indirects, immédiats ou médiats), notamment les droits légaux de garantie pour les défauts, sont exclus dans la mesure où la loi le permet. Les risques liés à l\'aptitude et à l\'utilisation sont supportés par l\'acheteur. En cas de dépôt ou de traitement inapproprié, de surcharge ou d\'utilisation inappropriée, nous excluons toute garantie et toute autre forme de responsabilité. Sont réservées les dispositions de la loi fédérale sur la responsabilité du fait des produits (LRFP).',
							'Sauf convention contraire, les prétentions contractuelles qui naissent en faveur de l\'acheteur et contre nous, à l\'occasion de ou en lien avec la livraison des marchandises se prescrivent par un an après la livraison de la marchandise. Lorsqu\'un cas de garantie survient et que, dans ce cadre-là, on procède à un échange, c\'est-à-dire que la marchandise défectueuse est remplacée par un produit neuf identique, le délai de garantie ne recommence pas à courir.',
							'Les références aux normes SN, EN ou autres et à des règlements semblables, ainsi que les indications relatives aux qualités, sortes, mesures, poids et aux possibilités d\'utilisation des marchandises, les indications contenues dans des dessins et des illustrations ainsi que les affirmations dans des moyens publicitaires ne constituent ni des assurances, ni des garanties, dans la mesure où elles ne sont pas désignées comme telles, de manière expresse et parécrit.',
							'Pour l\'examen de la marchandise et l\'avis des défauts, les dispositions légales s\'appliquent : les défauts matériels de la marchandise doivent être annoncés par écrit et immédiatement, mais au plus tard huit jours après la livraison. En cas de défaut caché, l\'ais doit être fait, par écrit, immédiatement après la découverte des défauts, mais au plus tard trois mois après la réception de la marchandise. Tout droit à la garantie s\'éteint si l\'avis n\'est pas donné à temps.', 'Tant que l\'acheteur ne nous donne pas l\'occasion de constater le défaut, par nous-mêmes, notamment s\'il ne met pas à disposition la marchandise litigieuse ou des échantillons de celle-ci lorsque nous l\'exigeons, il ne peut pas invoquer les défauts de celle-ci.',
						],
					},
					{
						title: 'Retours de marchandise',
						content: 'Nous nous engageons à reprendre la marchandise livrée de manière erronée ou défectueuse. Les retours de marchandise ne sont acceptés que moyennant un préavis et avec notre accord, à l\'adresse de livraison désignée par nous. La marchandise doit nous être retournée dans l\'emballage original, les liquides dans des conteneurs fermés et plombés, avec copie du bon de livraison, aux risques et frais de l\'acheteur.',
					},
					{
						title: 'Droits de propriété intellectuelle',
						content: [
							'Nous nous réservons les droits de propriété intellectuelle sur tous les documents comme les offres, plans, dessins ou calculs que nous créons pour l\'acheteur; ils ne peuvent être rendus accessibles à des tiers qu\'avec notre accord. Les dessins et autres documents intégrés à des offres doivent nous être restitués à notre demande.',
							'Dans la mesure où nous avons livré des objets conçus sur la base de dessins, modèles, échantillons ou autres documents que l\'acheteur nous a transmis, l\'acheteur se porte garant du fait qu\'aucun droit de propriété intellectuelle de tiers ne soit violé. Si des tiers, invoquant leurs droits de propriété intellectuelle, nous interdisent notamment de fabriquer et de livrer de tels objets, nous avons le droit, sans être tenus d\'apprécier la situation juridique, de stopper toute autre activité, dans cette mesure et, si l\'acheteur est fautif, d\'exiger des dommages et intérêts. L\'acheteur s\'engage, en outre, à nous libérer immédiatement de toute prétention de tiers en lien avec de tels droits.',
						],
					},
					{
						title: 'Pièces d\'essai, moules, outils et pièces mises à disposition',
						content: [
							'Les moules et outils que nous fabriquons ou faisons fabriquer pour un acheteur restent notre propriété, même lorsque ce dernier supporte intégralement ou partiellement les coûts. L\'acheteur n\'a pas droit à ce que ceux-ci lui soient remis.',
							'La création de pièces d\'essai, y compris les coûts des moules et outils, sont à la charge de l\'acheteur.',
							'Concernant les outils, moules et autres éléments servant à la fabrication fournis par le client, notre responsabilité se limite au respect de la même diligence que celle dont nous ferions preuve dans nos propres affaires. Les coûts d\'entretien et de soin sont supportés par l\'acheteur. Notre devoir de conservation s\'éteint - indépendamment de tout droit de propriété de l\'acheteur - au plus tard cinq ans après la dernière fabrication à l\'aide du moule ou de l\'outil concerné.',
							'Si l\'acheteur doit mettre à disposition des pièces pour que le contrat puisse être exécuté, elles doivent être livrées franco site de production en temps utile, gratuitement et libres de tout défaut. Leur quantité doit correspondre à la quantité supplémentaire convenue où, s\'il y a risque de mise au rebus de certaines pièces, à une quantité supplémentaire raisonnable. Si la livraison n\'a pas lieu de cette manière, les coûts y relatifs et les autres conséquences sont supportés par l\'acheteur.',
						],
					},
					{
						title: 'Lieu d\'exécution, for et droit applicable',
						content: [
							'Le for et le lieu d\'exécution de nos livraisons se trouvent à notre siège (sauf accord contraire). Nous pouvons également agir contre l\'acheteur à son siège, respectivement à son domicile.',
							'Le droit suisse s\'applique à toutes les relations juridiques entre nous et l\'acheteur. L\'application de la Convention des Nations Unies sur les contrats de vente internationale de marchandises (Convention de Vienne/CVIM) est explicitement exclue.',
						],
					},
					{
						title: 'Version obligatoire',
						content: 'En cas de doute, la version allemande de ces conditions générales de vente et de livraisons déterminant.',
					},
				] as TacParagraph[]);
				this.data.set('en', [
					{
						title: 'Validity, Offer and Order Acceptance',
						content: [
							'The present general terms and conditions apply for all - including future - agreements concerning delivery and other services. Terms and conditions of sale are not applicable to us even if we fail to explicitly decline them again after receipt thereof by us.',
							'Our offers are non-binding unless otherwise indicated. We endeavour to comply with the prices, quantities, quality, and delivery deadlines offered by us.',
							'All orders must be confirmed by us in writing or by invoice in order to be valid.',
							'Agreements, in particular oral or side agreements, assurances, guarantees or other warranties made by members of our sales force are only binding once confirmed by us in writing. Transmission by fax or email shall also be deemed to meet the written requirement.',
						],
					},
					{
						title: 'Data Protection',
						content: 'We record and use information on purchasers in order to process their own transactions. When processing customer information we comply with applicable data protection rules.',
					},
					{
						title: 'Prices',
						content: [
							'Prices refer to confirmed or invoiced performance. Unless otherwise agreed, it is understood ex works, exclusive freight, postage and packaging, plus the statutory value added tax. Prices are not applicable for follow-up orders.',
							'The minimum invoice amount is CHF 50.00 net value of the goods.',
							'Any addition costs which arise due to requested materials and security checks or special logistics and transport costs are not included in prices and shall be invoiced separately.',
							'If the goods are delivered in packaging, we invoice such packaging at cost; we will not accept returns of packaging delivered by us.',
						],
					},
					{
						title: 'Payment and set-off',
						content: [
							'Unless otherwise agreed, our invoices are payable within 30 days. The invoice amount is due within due 30 days of the invoice or value date. Payment - without early payment discount - shall be made in such a way that we can dispose of the funds per the due date. The purchaser shall automatically be in default following expiry of the thirty-day payment deadline, without there being any need to serve notice.',
							'Nevertheless, we reserve the right to demand payment in advance in any event.',
							'An agreed early payment discount always refers only to the value of the invoice exclusive freight and is conditional upon settlement of all outstanding obligations on the part of the purchaser at the time of claiming the discount in question.',
							'Invoices for assembly work, repairs, forms and contributions to the costs of tools are always due immediately and payable net.',
							'Counterclaims that are contested by us or which have not been determined in a legally binding manner, do not entitle the buyer to withhold payments or afford him a right of set-off. The right to refuse performance within the meaning of Art. 82 SCO is excluded.',
							'After expiry of the thirty-day deadline for payment, since the invoice/value date, we are entitled to demand compensation for the costs of sending payment reminders as well as default interest since the invoice or value date for the year, even where the contract stipulates a lower interest rate. If according to the contract a higher interest rate applies, we may also demand said higher interest rate during default period. We reserve the right to assert other damage claims resulting from default.',
						],
					},
					{
						title: 'Delivery Periods',
						content: [
							'Any indications provided re expected delivery dates and time periods are non-binding. Such indications are given to the best of our knowledge as can be met under normal conditions of supply and proper conditions. Delivery deadlines are deemed to have been complied with where the item to be delivered has left our site before the expiry thereof.',
							'Our delivery obligations are entered into subject to the reservation that we are supplied in a correct and timely manner, unless the fact that we have been supplied incorrectly or late is due to an oversight on our part.',
							'In the occurrence of a force majeure event, deliveries shall be delayed for the duration of the hindrance and a reasonable adjustment period. This shall also be the case when such events occur during a pre-existing delay. Considered equivalent to force majeure are currency, trade-policy and other government measures, strikes or lockouts, interruptions to operations that have not been caused by us, obstruction of traffic routes, delays in import/customs clearance, as well as any other circumstances, which, without being caused by us, significantly complicate or render impossible the delivery and performance. In this context, it shall be of no consequence whether the circumstances in question transpired with us, the factory another supplier. Where, as result of the aforementioned occurrences, execution becomes unreasonable for one of the contracting parties, it may withdraw from the contract by way of immediately declaring its intent to do so in writing.',
						],
					},
					{
						title: 'Benefits and Risk, Performance of Deliveries',
						content: [
							'Upon handing over the goods to a shipper or freight forwarder – in the case of drop shipping upon leaving the factory – benefits and risks shall pass to the purchaser for all transactions. All obligations and costs related to unloading shall be borne by the purchaser. We only obtain insurance at the instructions and expense of the purchaser.',
							'We are authorized to make partial deliveries in such quantities as is reasonable for customers. For customized items, bulk items or rolls of material, surplus or short deliveries of up to 10 percent of the agreed quantities are permitted.',
							'In case of purchase on demand, we are entitled to manufacture or have the entire order-quantity manufactured in a single batch. Provided that no fixed agreements have been concluded, on-call deadlines and quantities can only be observed, to the extent they are within the limits of our delivery and production capabilities. Where the goods are not called up as agreed, we are authorised to invoice them as delivered following a reasonable grace period.',
						],
					},
					{
						title: 'Retention of Title',
						content: 'All goods delivered remain our property until payment in full of the purchase price owed. We reserve the right to have our retention of title entered in the registry of retention rights at the purchaser\'s place of residence. In the event of delays in payment by the purchaser, we reserve the right to withdraw from the contract and to demand return of the delivered goods (reservation within the meaning of Art. 214 [3] SCO).',
					},
					{
						title: 'Liability for Defects',
						content: [
							'We undertake, in the event of demonstrated defects (production defects or faulty materials) to the delivered goods within the statutory or contractually agreed period, to either make replacement deliveries or repairs, at our own discretion. To the extent allowed under the law, any further liability and all other claims by the purchaser for any damages (direct or indirect, causal or consequential), in particular for statutory material defect claims, are hereby excluded. The purchaser bares all risks as to the suitability and usage of the goods. We decline to honour any and all warranties in the event of improper storage or handling, excessive or inappropriate use. This notwithstanding the provisions of the Federal Product Liability Act (PrHG).',
							'Unless otherwise agreed, contract claims which the purchaser wishes to assert against us for reasons of or in connection with the delivery shall be time-barred one year after the delivery in question. In the event of a warranty claim, if an exchange takes place in that a defective product is replaced by an identical new product, the warranty period shall not begin anew.',
							'References to SN or EN or other standards and similar regulatory frameworks, as well as indications re quality, types, dimensions, weights and uses for the goods, indications provided in signs and pictures, as well as statements in advertising materials do not constitute assurances or warranties, unless explicitly designated as such in writing.',
							'For the inspection of goods and notifications re defects, the statutory provisions apply: Material defects to the goods are to be immediately notified in writing or at the latest within 8 days of delivery. In the event of disguised defects, the notice of defects is to be provided immediately upon discovery of the defects, albeit at the latest within 3 months of receipt of the goods. In the event of untimely notification, all warranty claims shall be forfeit.',
							'For as long as the purchaser fails to afford us the chance to verify the defects for ourselves, particularly by failing to make the allegedly defective goods or samples available to us on request, the purchaser shall be unable to assert any defects to the goods in question.',
						],
					},
					{
						title: 'Return of Goods',
						content: 'We undertake to take back any goods that have been erroneously delivered or that are defective. Returned goods will only be accepted by us subject to advance notice, with our consent and at such place as indicated by us. The goods are to be returned in their original packaging, liquids in closed and sealed containers, with a copy of the delivery invoice, at the risk and expense of the purchaser.',
					},
					{
						title: 'Intellectual Property Rights',
						content: [
							'We reserve our intellectual property rights on all documents such as offers, blueprints, drawings or calculations, which we have prepared for the purchaser; they may only be made available to third parties subject to our consent. Any drawings and other documents provided together with offers are to be returned to us on request.',
							'Where we have delivered items based on drawings, models, templates and other documents provided by the purchaser, it is the purchaser who is liable that the intellectual property rights of third parties have not been infringed. Where third parties prohibit us, in invocation of their intellectual property rights, from manufacturing and delivering such items in particular, we shall be entitled - without being obliged to verify the legal situation - to cease and desist with any further activities and, in the event the purchaser is to blame, to demand compensation for damages. In addition, the purchaser undertakes to immediately indemnify us for all and any third-party claims in connection herewith.',
						],
					},
					{
						title: 'Test parts, moulds, tools and parts provided',
						content: [
							'Moulds and tools, which we manufacture or have manufactured for a purchaser, shall remain our property even where the purchaser has contributed to the costs in part or in full. The purchaser has no claim to have such items handed over.',
							'The preparation of test parts including any costs for moulds and tools, are to be borne by the purchaser.',
							'For tools, moulds and other production equipment provided by the purchaser, our liability shall be limited to the level of care we use for our own affairs. Costs for maintenance and care shall be borne by the purchaser. Our duty of safekeeping expires - irrespective of the purchaser\'s property rights - at the latest five years following the last production using the mould or tool.',
							'Where the purchaser is required to contribute parts for the execution of the order, they are to be delivered ex works in the agreed excess quantity or, if the amount is not agreed upon, in an appropriate excess quantity for any discarded production, in a timely manner, free of charge and without defects. Failure to comply herewith shall result in any costs and other consequences that arise as a result thereof, being borne by the purchaser.',
						],
					},
					{
						title: 'Place of Performance, Place of Jurisdiction, Applicable Law',
						content: [
							'The place of jurisdiction and place of performance for our deliveries (unless otherwise agreed) is our place of registered office. We reserve the right to take legal action against the purchaser at its place of registered office or residence.',
							'For all legal relations between us and the purchaser, Swiss law shall apply. The United Nations Convention on Contracts for the International Sale of Goods (Vienna Convention/CISG) is hereby excluded.',
						],
					},
					{
						title: 'Obligatory Version',
						content: 'In case of doubt, the German version of these General Conditions of Sale and Supply shall prevail.',
					},
				] as TacParagraph[]);
			} else {
				this.data.set('de', [
					{
						title: 'Vertragsabschluss',
						content: [
							'Alle Vereinbarungen, Abänderungen von Vereinbarungen und rechtserheblichen Erklärungen zwischen mk dichtungs ag und dem Kunden bedürfen zu ihrer Gültigkeit der Schriftform. Auch ein Verzicht auf die Schriftform bedarf der Schriftlichkeit. Mit elektronischer Übermittlung von Mitteilungen in der Form von E-Mail ist die unter dieser Ziffer verlangte Schriftform gewahrt.',
							'Der Vertrag zwischen dem Kunden und mk dichtungs ag kommt mit dem Versand der Auftragsbestätigung durch mk dichtungs ag zustande.',
							'Hat mk dichtungs ag in ihrer Auftragsbestätigung die Bestellung in wesentlichen Punkten oder Nebenpunkten modifiziert und will der Kunde diese Änderungen nicht akzeptieren, so hat er mk dichtungs ag darüber sofort schriftlich zu orientieren. Diese Anzeige muss bei mk dichtungs ag innerhalb von 5 Arbeitstagen eintreffen, nachdem die Auftragsbestätigung beim Kunden eingegangen ist, ansonsten der Vertrag gemäss dem Inhalt der gegenüber der Bestellung abweichenden Auftragsbestätigung als angenommen gilt.',
						],
					},
					{
						title: 'Geltug auch für zukünftige Lieferungen an gleichen Kunden',
						content: 'Diese Allgemeinen Lieferbedingungen gelten auch für alle zukünftigen Lieferungen der mk dichtungs ag an den gleichen Kunden, selbst wenn in einem späteren Vertrag zwischen der mk dichtungs ag und dem Kunden nicht mehr ausdrücklich darauf verwiesen wird.',
					},
					{
						title: 'Leistungsumfang',
						content: [
							'Der Umfang einer Lieferung (Qualität und Quantität) ergibt sich nur und abschliessend aus der Auftragsbestätigung einschliesslich eventueller Beilagen, sofern diese zum integrierenden Bestandteil der Auftragsbestätigung erklärt werden.',
							'Mehr- oder Minderlieferungen bis 10% sind zulässig. mk dichtungs ag fakturiert die effektiv gelieferte Menge.',
						],
					},
					{
						title: 'Liefermodalitäten, Prüfung und Genehmigung der Lieferung',
						content: [
							'Dem Liefertermin kommt nicht die Bedeutung eines Verfalltages zu und es liegt kein Fixgeschäft vor.',
							'Liefertermine können nur auf schriftliche Absprache und im gegenseitigen Einverständnis verschoben werden. Vom Kunden gewünschte Verschiebungen von Lieferterminen innerhalb von 30 Tagen können nur ausnahmsweise gewährt werden. Die für die mk dichtungs ag dadurch entstehenden Kosten hat der Kunde zu tragen.',
							'Die Lieferung erfolgt EXW Unterkulm.',
							'Rechnungen für Montagen, Reparaturen, Formen und Werkzeugkostenanteile sind jeweils sofort fällig und netto zahlbar.',
							'Der Kunde prüft die Lieferung innert 8 Tagen seit der Übernahme der Lieferung. Allfällige Mängel hat der Kunde sofort bei Entdeckung zu rügen. Andernfalls gilt die Lieferung als genehmigt.',
						],
					},
					{
						title: 'Preise und andere Kosten',
						content: [
							'Die bestätigten Preise verstehen sich als Nettopreise exklusive Mehrwertsteuer.',
							'Kosten zur Herstellung von kundenspezifischen Hilfsmitteln (z.B. Werkzeuge, Formen), die zur Herstellung der Lieferung benötigt werden, sind vom Kunden zu tragen und werden zusätzlich in Rechnung gestellt.',
						],
					},
					{
						title: 'Zahlungsbedingungen',
						content: 'Der Kunde hat Rechnungen der mk dichtungs ag seit Rechnungsdatum ohne jeglichen Abzug zu zahlen, sofern keine anderen Vereinbarungen getroffen wurden. Nichteinhalten eines Zahlungstermins berechtigt mk dichtungs ag, alle Lieferungen, die dem Kunden geschuldet sind, bis zur vollständigen Bezahlung aller ausstehenden Rechnungen zurückzubehalten, entbindet den Kunden jedoch nicht von dessen Abnahmeverpflichtung.',
					},
					{
						title: 'Rücksendungen',
						content: 'Es können nur ganze, unverschnittene 25m Rollen oder ganze VE, in einwandfreiem Zustand gegen Gutschrift des Warenwertes zurückgenommen werden.',
					},
					{
						title: 'Eigentum an kundenspezifischen Formen und Werkzeugen',
						content: 'Formen und Werkzeuge bleiben auch bei teilweiser oder ganzer Kostenbeteiligung durch den Kunden in unserem Eigentum und Besitz, und wir sind keinesfalls verpflichtet, diese Formen und Werkzeuge auszuhändigen. Wir verpflichten uns jedoch, diese Formen und Werkzeuge ausschliesslich für den betreffenden Kunden zu benützen, sowie zur sorgfältigen Behandlung und Aufbewahrung. Durch Gebrauch und Abnutzung entstehende Kosten gehen in jedem Fall zu Lasten des Kunden. Diese Formen und Werkzeuge werden während 5 Jahren nach letztmaligem Gebrauch auf unsere Kosten aufbewahrt.',
					},
					{
						title: 'Warenretouren',
						content: 'Wir verpflichten uns, falsch oder mangelhaft gelieferte Ware zurückzunehmen. Rücksendungen von Waren werden nur gegen Voravis und mit unserer Einwilligung an die durch uns vorgegebene Lieferadresse zurückgenommen. Die Ware ist in Originalverpackung, Flüssigkeiten in geschlossenen Gebinden, mit Kopie des Lieferscheins auf Risiko und Kosten des Käufers zu retournieren.',
					},
					{
						title: 'Substitution/Einsatz Dritter',
						content: 'mk dichtungs ag ist ermächtigt, zur Erstellung einer Lieferung ohne Zustimmung des Kunden Dritte bei zuziehen oder die Erstellung Dritten zu übertragen.',
					},
					{
						title: 'Gewährleistung',
						content: [
							'mk dichtungs ag gewährleistet eine mängelfreie Lieferung, d.h. sie sichert zu, dass die Lieferung über die Eigenschaften verfügt und den Qualitätsanforderungen genügt, wie sie in der Auftragsbestätigung und den darin allenfalls als integrierende Bestandteile erwähnten Beilagen ausdrücklich umschrieben sind.',
							'Ob sich die Lieferung für einen bestimmten Verwendungszweck eignet, ist vom Kunden zu prüfen und zu entscheiden, soweit nicht ausdrücklich etwas anderes vereinbart worden ist. Die Verantwortung für die aus der Nutzung der Lieferung erzielten Resultate trägt allein der Kunde.',
							'mk dichtungs ag hat ein Recht auf Nachbesserung oder Nachlieferung nach ihrer Wahl.',
							'Die Gewährleistungspflicht der mk dichtungs ag endet 12 Monate nach Übergabe der Lieferung. Mit dem Enden der Gewährleistungspflicht verjähren die Klagen auf Gewährleistung wegen Mängeln an der Lieferung.',
						],
					},
					{
						title: 'Haftung der mk dichtungs ag',
						content: [
							'Ist eine Verspätung oder Verunmöglichung der Lieferung auf Schwierigkeiten bei der Materialbeschaffung, auf einen Zulieferer der mk dichtungs ag oder auf einen Maschinenausfall, Brand, Ausfall eines Spezialisten usw. bei der mk dichtungs ag, einem Unterlieferanten der mk dichtungs ag oder Dritten gem. Ziff. 8 zurückzuführen, haftet die mk dichtungs ag für dem Kunden daraus entstehenden Schaden, falls ihr vorsätzliches oder grobfahrlässiges Verhalten vorgeworfen werden kann.',
							'Für alle anderen als in Ziff. 10.1 genannten direkten Schäden, die mk dichtungs ag dem Kunden schuldhaft verursacht, haftet mk dichtungs ag dem Kunden bis zum Wert der Lieferung gemäss Auftragsbestätigung. Für darüber hinausgehende, direkte Schäden haftet die mk dichtungs ag, falls ihr ein vorsätzliches oder grobfahrlässiges Verhalten vorgeworfen werden kann.',
							'Soweit gesetzlich zulässig, schliesst mk dichtungs ag jede weitere Haftung aus.',
						],
					},
					{
						title: 'Schutzrechte',
						content: 'Die Herstellung von Lieferungen, die mk dichtungs ag nach Zeichnungen oder Modellen des Kunden herstellt, erfolgt unter der Voraussetzung und Annahme, dass der Kunde die entsprechenden Rechte besitzt. Sollte mk dichtungs ag trotzdem wegen einer Verletzung von Schutzrechten Dritter belangt werden, ist sie vom Kunden vollständig schadlos zu halten.',
					},
					{
						title: 'Salvatorische Klausel',
						content: 'Sollte sich eine Bestimmung dieser Allgemeinen Lieferbedingungen oder einer zwischen der mk dichtungs ag und dem Kunden abgeschlossenen Vereinbarung als ganz oder teilweise unwirksam erweisen, so werden die Vertragsparteien diese Bestimmung durch eine neue, ihrem rechtlichen und wirtschaftlichen Erfolg möglichst nahe kommende Vereinbarung ersetzen.',
					},
					{
						title: 'Gerichtsstand und anwendbares Recht',
						content: [
							'Ausschliesslicher Gerichtsstand ist der Sitz der mk dichtungs ag in Unterkulm.',
							'Anwendbares Recht ist Schweizer Recht unter Ausschluss des Wiener KaufrechtsÜbereinkommens vom 11.April 1980.',
						]
					},
				] as TacParagraph[]);
				this.data.set('fr', [
					{
						title: 'Conclusion du contrat',
						content: [
							'Tous les accords, modifications d\'accord et déclarations juridiquement contraignantes conclus entre mk dichtungs ag et le client nécessitent la forme écrite pour être valables. La renonciation à cette forme écrite doit également revêtir la forme écrite. La transmission électronique de messages sous forme d\'e-mail respecte la forme écrite exigée.',
							'Le contrat naît entre le client et mk dichtungs ag à l\'envoi de la confirmation de la commande par mk dichtungs ag.',
							'Si mk dichtungs ag modifie dans sa confirmation de la commande des points essentiels ou des points accessoires de la commande et le client ne souhaite pas accepter ces modifications, il doit en aviser immédiatement mk dichtungs ag par écrit. Cet avis doit parvenir à mk dichtungs ag dans les cinq ouvrés suivant la réception de la confirmation de la commande par le client, autrement le contrat conformément au contenu de la confirmation de la commande différente de la commande sera réputé accepté.',
						],
					},
					{
						title: 'Validité également pour des futures livraisons au même client',
						content: 'Les présentes Conditions générales de livraison s\'appliquent également à toutes les futures livraisons de mk dichtungs ag au même client, même s\'il n\'y est pas fait explicitement référence dans un contrat conclu ultérieurement entre mk dichtungs ag et ledit client.',
					},
					{
						title: 'Étendue des prestations',
						content: [
							'L\'étendue d\'une livraison (quantité et qualité) résulte uniquement et exclusivement de la confirmation de la commande, y compris des éventuelles annexes, pour autant que celles-ci aient été déclarées comme faisant partie intégrante de la confirmation de la commande.',
							'Les livraisons en plus ou en moins jusqu\'à 10% sont admises. mk dichtungs ag facture la quantité effectivement livrée.',
						],
					},
					{
						title: 'Modalités de la livraison, contrôle et acceptation de la livraison',
						content: [
							'La date de livraison n\'est pas considérée comme une date d\'échéance et ne donne pas lieu à une vente à terme fixe.',
							'Les dates de livraison ne peuvent être reportées que d\'un commun accord écrit. Les reports de dates de livraison dans les trente jours souhaités par le client ne sont acceptables qu\'à titre exceptionnel. Les frais liés au report engagés par mk dichtungs ag doivent être supportés par le client.',
							'La livraison a lieu EXW (départ usine) Unterkulm.',
							'Le client contrôle la livraison dans les 8 jours suivant sa réception. Le client doit signaler les éventuels défauts dès leur découverte, faute de quoi la livraison sera réputée acceptée.',
						],
					},
					{
						title: 'Prix et autres frais',
						content: [
							'Les prix confirmés s\'entendent nets hors taxe sur la valeur ajoutée.',
							'Les frais de fabrication d\'outils spécifiques au client (par exemple outils, moules) qui sont nécessaires à la production de la livraison sont supportés par le client et sont facturés en supplément.',
						],
					},
					{
						title: 'Conditions de paiement',
						content: [
							'Sauf convention contraire, le client doit payer l\'intégralité du montant des factures de mk dichtungs ag dès leur date d\'émission. Le non respect d\'un délai de paiement autorise mk dichtungs ag à retenir toutes les livraisons dues au client jusqu\'au règlement de l\'intégralité des montants des factures dus,mais ne libère pas pour autant le client de son obligation de réceptionner les livraisons.',
						],
					},
					{
						title: 'Retours',
						content: 'Seuls des rouleaux de 25 m non découpés et des unités de conditionnement entières en parfait état peuvent être repris en contrepartie d\'un crédit à hauteur de la valeur des marchandises.',
					},
					{
						title: 'Propriété des moules et outils spécifiques au client',
						content: [
							'Même en cas de participation du client à tout ou partie des frais, nous conservons la possession et la propriété des moules et outils et nous ne sommes en aucun cas tenus de restituer lesdits outils et instruments. Nous nous engageons toutefois à utiliser lesdits moules et formes exclusivement pour le client concerné, de même qu\'à les utiliser et les conserver avec soin. Les frais liés à l\'utilisation et l\'usure sont dans tous les cas à la charge du client. Ces moules et outils sont conservés à nos frais pendant les cinq années suivant leur dernière utilisation.',
						],
					},
					{
						title: 'Substitution/recours à des tiers',
						content: 'mk dichtungs ag a le droit de recourir à des tiers pour produire une livraison sans l\'accord du client ou de confier sa production à des tiers.',
					},
					{
						title: 'Garantie',
						content: [
							'mk dichtungs ag garantit une livraison exemple de défaut. Cela signifie qu\'elle garantit que la livraison présente les caractéristiques et répond aux exigences de qualité telles que décrites expressément dans la confirmation de la commande et les éventuelles annexes réputées partie intégrante.',
							'Sauf convention explicite contraire, c\'est au client de contrôler et de décider si la livraison est apte à l\'usage prévu. Le client est seul responsable des résultats obtenus avec l\'utilisation de la livraison.',
							'mk dichtungs ag a le droit à une amélioration ultérieure ou à une livraison complémentaire, au choix de celle-ci.',
							'L\'obligation de garantie de mk dichtungs ag cesse 12 mois après la remise de la livraison. Les actions en garantie pour les défauts de la livraison se prescrivent à l\'extinction de l\'obligation de garantie.',
						],
					},
					{
						title: 'Responsabilité de mk dichtungs ag',
						content: [
							'Si un retard de la livraison ou l\'impossibilité d\'effectuer la livraison est imputable à des problèmes d\'approvisionnement de matériels, à un fournisseur de mk dichtungs ag ou à une panne de machine, à un incendie, à la défaillance d\'un spécialiste, etc. chez mk dichtungs ag, chez un sous-traitant de mk dichtungs ag ou d\'un tiers conformément au point 8, mk dichtungs ag répond des dommages causés au client si un comportement intentionnel ou une faute peut lui être reproché.',
							'Pour tous les dommages directs autres que ceux énoncés au point 10 que cause mk dichtungs ag par sa faute au client, la responsabilité de mk dichtungs ag est engagée vis-à-vis du client à hauteur de la valeur de la livraison conformément à la confirmation de la commande. mk dichtungs ag répond des dommages directs allant au-delà si un comportement intentionnel ou une faute peut lui être reproché.',
							'Dans la mesure où la loi le permet, mk dichtungs ag décline toute autre responsabilité.',
						],
					},
					{
						title: 'Droits de protection',
						content: 'Les livraisons produites par de mk dichtungs ag d\'après des dessins ou modèles du client sont effectuées à condition et en supposant que le client possède les droits correspondants. Le client doit entièrement dédommager mk dichtungs ag en cas de poursuites engagées contre celle-ci en raison d\'une violation de droits de propriété de tiers.',
					},
					{
						title: 'Clause de sauvegarde',
						content: 'Au cas où une disposition des présentes Conditions générales de livraison ou d\'un accord conclu entre mk dichtungs ag et le client serait partiellement ou totalement nulle, les parties remplaceront cette disposition par une nouvelle disposition qui se rapproche le plus de sa finalité juridique et économique.',
					},
					{
						title: 'For et droit applicable',
						content: [
							'Le for exclusif est le siège de mk dichtungs ag à Unterkulm.',
							'Le droit suisse est applicable, à l\'exclusion de la Convention des Nations Unies sur les contrats de vente internationale de marchandises du 11 avril 1980.',
						],
					},
				] as TacParagraph[]);
				this.data.set('en', [
					{
						title: 'Conclusion of contract',
						content: [
							'All agreements, amendments to agreements and legally relevant declarations between mk dichtungs ag and the Customer must be in writing to be valid. Any waiver of the written form requirement must also be in writing. Electronic transmission of communications in the form of e-mail complies with the written form requirement under this clause.',
							'The contract between the Customer and mk dichtungs ag comes into effect on dispatch of the order confirmation by mk dichtungs ag.',
							'If mk dichtungs ag has modified the order in its order confirmation in essential or minor points and the Customer does not wish to accept these modifications, it must notify mk dichtungs ag of this immediately in writing. This notification must reach mk dichtungs ag within five working days of receipt of the order confirmation by the Customer, otherwise the contract will be deemed to have been accepted in accordance with the content of the order confirmation which differs from the order.',
						],
					},
					{
						title: 'Validity for future deliveries to the same Customer',
						content: 'These General Terms and Conditions of Delivery also apply to all future deliveries by mk dichtungs ag to the same Customer, even if no express reference is made to them in a subsequent contract between mk dichtungs ag and the Customer.',
					},
					{
						title: 'Scope of service',
						content: [
							'The scope of a delivery (quality and quantity) is only and finally defined in the order confirmation including any enclosures, provided that these are declared to be an integral part of the order confirmation.',
							'Excess or short deliveries of up to 10% are permissible. mk dichtungs ag will invoice the quantity actually delivered.',
						],
					},
					{
						title: 'Delivery terms, inspection and approval of the delivery',
						content: [
							'The delivery date shall not be construed as an expiration date and does not constitute a fixed-date transaction.',
							'Delivery dates can only be postponed by written agreement and by mutual consent. Postponements of delivery dates requested by the Customer within 30 days of delivery can only be granted in exceptional cases. The resulting costs incurred by mk dichtungs ag shall be borne by the Customer.',
							'Delivery shall be made EXW Unterkulm (Incoterms 2010).',
							'The Customer shall inspect the delivery within eight days of acceptance of the delivery. The Customer must give notice of any defects immediately upon discovery. Otherwise the delivered goods shall be deemed to have been accepted.',
						],
					},
					{
						title: 'Prices and other costs',
						content: [
							'The confirmed prices are net prices excluding value added tax.',
							'Costs for the production of customer-specific aids (e.g. tools, moulds)required to produce the delivery are to be borne by the Customer and will be invoiced additionally.',
						],
					},
					{
						title: 'Terms of payment',
						content: [
							'Unless otherwise agreed, the Customer must pay mk dichtungs ag invoices after the date of invoice without any deductions. Failure to meet a payment deadline entitles mk dichtungs ag to withhold all deliveries owed to the Customer until all outstanding invoices have been paid in full, but does not release the Customer from its obligation to accept delivery.',
						],
					},
					{
						title: 'Returns',
						content: 'Only whole, uncut 25m rolls or whole selling unit in perfect condition can be taken back in exchange for a credit note for the value of the goods.',
					},
					{
						title: 'Ownership of customer-specific moulds and tools',
						content: [
							'Moulds and tools shall remain our property and possession even if the Customer bears part or all of the costs, and we are under no obligation to return these moulds and tools. However, we undertake to use these moulds and tools exclusively for the Customer in question and to handle and store them with care. Costs arising from use and wear and tear shall in any case be borne by the Customer. These moulds and tools shall be stored at our expense for five years after their last use.',
						],
					},
					{
						title: 'Substitution/use of third parties',
						content: 'mk dichtungs ag is authorised to engage third parties to produce a delivery without the consent of the Customer or to subcontract the production to third parties.',
					},
					{
						title: 'Warranty',
						content: [
							'mk dichtungs ag guarantees delivery free of defects, i.e. it warrants that the delivery has the properties and meets the quality requirements as expressly described in the order confirmation and the enclosures mentioned therein, as integral parts thereof.',
							'Unless expressly agreed otherwise, the Customer shall assess and decide whether the delivery is suitable for a particular purpose. The Customer alone is responsible for the results obtained from the use of the delivery.',
							'mk dichtungs ag has the right to rectify any defects or make a subsequent delivery at its option.',
							'The warranty obligation of mk dichtungs ag ends 12 months after handover of the delivery. Any warranty claims for defects to the delivery become time-barred upon expiry of the seller’s warranty.',
						],
					},
					{
						title: 'Liability of mk dichtungs ag',
						content: [
							'If a delay or impossibility of delivery is attributable to difficulties in the procurement of materials, to a supplier of mk dichtungs ag or to a machine breakdown, fire, absence of a specialist etc. at mk dichtungs ag, a subcontractor of mk dichtungs ag or third parties pursuant to Section 9, mk dichtungs ag is liable for any resulting damage to the Customer if it can be accused of wilful or grossly negligent behaviour.',
							'For all direct damage other than that specified in Section 11.1 which mk dichtungs ag culpably causes to the Customer, mk dichtungs ag is liable to the Customer up to the value of the delivery in accordance with the order confirmation. For direct damage in excess thereof, mk dichtungs ag is liable if it can be accused of deliberate or grossly negligent behaviour.',
							'If permitted by law, mk dichtungs ag shall exclude all further liability.',
						],
					},
					{
						title: 'Industrial property rights',
						content: 'Deliveries manufactured by mk dichtungs ag according to drawings or models provided by the Customer are subject to the prerequisite and assumption that the Customer holds the corresponding rights. Should mk dichtungs ag nevertheless be sued for infringement of third-party industrial property rights, the Customer must indemnify mk dichtungs ag in full',
					},
					{
						title: 'Severability clause',
						content: 'Should any provision of these General Terms and Conditions of Delivery or of an agreement concluded between mk dichtungs ag and the Customer prove to be ineffective in whole or in part, the parties to the contract shall replace it with a new provision that most closely approximates the ineffective provision in terms of legal and economic effects.',
					},
					{
						title: 'Place of jurisdiction and applicable law',
						content: [
							'The exclusive place of jurisdiction is the registered office of mk dichtungs ag in Unterkulm.',
							'Swiss law shall apply to the exclusion of the Vienna Convention on the International Sale of Goods of 11 April 1980 (CISG).',
						],
					},
				] as TacParagraph[]);
			}
		}

	}
</script>

<style lang="scss" scoped>
	@import '../../../node_modules/vuetify/src/styles/styles';

	.paragraph-list {
		list-style: upper-roman;

		> li {
			padding: 0 0 0 $spacer*3;

			> p {
				margin: $spacer*3 0 $spacer*6 0;
			}

			> ol {
				margin: $spacer*3 0 $spacer*6 $spacer*-2;

				> li {
					padding: 0 0 0 $spacer*3;
				}
			}
		}
	}
</style>
