<template>
	<AsiDialogFormLayout v-if="model !== null" :icon="icons.name" :title="$t('user.terms.name')"
	                     :open="open" :loading="loading" :valid="valid" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped scrollable>
		<v-card-text class="pa-6">
			<v-form v-model="valid">
				<v-row>
					<v-col>
						<AsiTextField v-model="model.salutation" :rules="model.rules('salutation')"
						              :label="$t('user.salutation')" :placeholder="$t('user.salutation')"/>
					</v-col>
					<v-col>
						<AsiTextField v-model="model.acronym" :rules="model.rules('acronym')" clearable
						              :label="$t('user.acronym')" :placeholder="$t('user.acronym')"/>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<AsiTextField v-model="model.firstname" :rules="model.rules('firstname')"
						              :label="$t('user.firstname')" :placeholder="$t('user.firstname')"/>
					</v-col>
					<v-col>
						<AsiTextField v-model="model.lastname" :rules="model.rules('lastname')"
						              :label="$t('user.lastname')" :placeholder="$t('user.lastname')"/>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import UserUpdateName from "@/models/user/UserUpdateName";
	import {IUserShop} from "@/models/user/UserShopModels";
	import {IUserAdmin} from "@/models/user/UserAdminModels";
	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
	import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
	import AsiTextField from "@/components/common/AsiTextField";

	@Component({
		components: {AsiTextField, AsiDialogFormLayout, AsiDialogForm}
	})
	export default class UserUpdateDialogName extends AsiDialogForm {

		@Prop({type: Object, required: true})
		public user!: IUserShop | IUserAdmin;

		private model: UserUpdateName | null = null;

		public created(): void {
			this.setUserInternal();
		}

		@Watch('user', {deep: true})
		private onUserChanged(): void {
			this.setUserInternal();
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			this.setUserInternal();
			const form = this.$refs.form as HTMLFormElement;
			this.reset([], form);
			return event;
		}

		private setUserInternal(): void {
			if (this.model === null) {
				this.model = new UserUpdateName(this.user);
				this.valid = true;
			} else {
				this.model.setDetailModel(this.user);
			}
		}

		private requestSave(): void {
			if (this.model === null) return;

			this.loading = true;
			this.$userServiceShop.updateName(this.user.id, this.model)
				.then(this.save)
				.catch(this.handleErrors)
				.finally(() => this.loading = false);
		}

	}
</script>

<style lang="scss" scoped>

</style>
