<template>
	<div class="d-flex flex-row align-center px-6 py-3">
		<AsiDatePickerCombined v-model="dateRange" class="flex-grow-0" :label="$t('ui.terms.dateRange')" simple range />
		<v-btn-toggle class="ml-6" v-model="rangePreset">
			<v-btn small :value="filterPreset.lastWeek">{{ presetName(filterPreset.lastWeek) }}</v-btn>
			<v-btn small :value="filterPreset.lastMonth">{{ presetName(filterPreset.lastMonth) }}</v-btn>
			<v-btn small :value="filterPreset.lastYear">{{ presetName(filterPreset.lastYear) }}</v-btn>
		</v-btn-toggle>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Watch} from "vue-property-decorator";
	import AsiDatePickerCombined from "@/components/common/AsiDatePickerCombined.vue";
	import EnumHelper from "@/helpers/EnumHelper";

	enum FilterPreset {
		lastWeek = 10,
		lastMonth = 50,
		lastYear = 100
	}

	@Component({
		components: {AsiDatePickerCombined}
	})
	export default class DashboardGrowthFilter extends Vue {
		private filterPreset = FilterPreset;

		private rangePreset: FilterPreset | null = null;
		private dateRange: string[] = [];

		public mounted(): void {
			this.rangePreset = FilterPreset.lastYear;
		}

		private calcDateRange(value: FilterPreset | null): string[] {
			let endDate = new Date();
			let startDate = new Date();

			switch (value) {
				case FilterPreset.lastWeek:
					startDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 6);
					break;
				case FilterPreset.lastMonth:
					startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
					break;
				case FilterPreset.lastYear:
					startDate = new Date(endDate.getFullYear() - 1, endDate.getMonth(), endDate.getDate());
					break;
			}

			let result = [];
			result[0] = startDate.toISOString().split('T')[0];
			result[1] = endDate.toISOString().split('T')[0];

			return result;
		}

		private presetName(preset: FilterPreset): string {
			return EnumHelper.textFromValue(FilterPreset, preset, true) ?? '';
		}

		@Watch('rangePreset')
		private onChanged(value: FilterPreset | null): void {
			if (value === null) return;

			this.dateRange = this.calcDateRange(value);
		}

		@Watch('dateRange')
		private onDateRangeChanged(value: string[]): void {
			if (value.length < 2) return;

			let presetRange = this.calcDateRange(this.rangePreset);
			if (value[0] != presetRange[0] && value[1] != presetRange[1]) {
				this.rangePreset = null;
			}

		    this.$emit('update', value);
		}

	}
</script>

<style lang="scss" scoped>

</style>
