<template>
	<div class="d-flex flex-row justify-center align-center grey lighten-3 rounded px-1">

		<AsiBtn :icon="icons.remove" :disabled="disabled" :small="small" :x-small="xSmall"
		        @click.stop="decrease"/>

		<div class="pa-1" style="max-width: 5rem">
			<AsiTextFieldSimple v-if="!small && !xSmall" :disabled="disabled"
			                    :value="inputValue" @change="inputValue = $event" class="text-right"
			                    @focus="$event.target.select()" @keydown="handleKeyDown"/>

			<v-menu v-else v-model="menuOpen" :disabled="disabled" :close-on-content-click="false"
			        max-width="10rem" bottom offset-y left>
				<template v-slot:activator="{on, attrs}">
					<div v-on="on" v-bind="attrs" class="d-flex flex-row align-center mx-1">
						<span class="black--text font-weight-medium">{{ valueInternal }}</span>
						<small v-if="unit !== null" class="ml-1">{{ unitName }}</small>
					</div>
				</template>
				<div class="grey lighten-3 pa-2 d-flex flex-row align-center">
					<AsiTextFieldSimple :value="inputValue" @change="inputValue = $event" class="text-right"
					                    :disabled="disabled" @focus="$event.target.select()" @keydown="handleKeyDown" ref="menuInput"/>
					<small v-if="unit !== null" class="ml-1 grey--text">{{ unitName }}</small>
				</div>
			</v-menu>
		</div>
		<small v-if="!small && !xSmall && unit !== null" class="mx-1">{{ unitName }}</small>

		<AsiBtn :icon="icons.add" :disabled="disabled" :small="small" :x-small="xSmall"
		        @click.stop="increase"/>
	</div>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop, Watch} from "vue-property-decorator";
	import AsiBtn from "@/components/common/AsiBtn.vue";
	import Icon from "@/plugins/icons";
	import AsiTextField from "@/components/common/AsiTextField";
	import AsiTextFieldSimple from "@/components/common/AsiTextFieldSimple";
	import EnumHelper from "@/helpers/EnumHelper";
	import {UnitOfMeasure} from "@/helpers/constants";
	import StringHelper from "@/helpers/StringHelper";

	@Component({
		components: {AsiTextFieldSimple, AsiTextField, AsiBtn}
	})
	export default class ItemQuantitySelection extends Vue {

		@Prop({type: Number, required: true})
		public value!: number;

		@Prop({type: Number, default: 1})
		public step!: number;

		@Prop({type: Number, default: null})
		public unit!: number | null;

		@Prop({type: Boolean, default: false})
		public small!: boolean;

		@Prop({type: Boolean, default: false})
		public xSmall!: boolean;

		@Prop({type: Boolean, default: false})
		public debounce!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		private icons = Icon;
		private valueInternal: number = 1;
		private menuOpen: boolean = false;
		private debounceTimeout: any = null;

		private get unitName(): string | null {
			return EnumHelper.textFromValue(UnitOfMeasure, this.unit, true, this.valueInternal ?? 1);
		}

		private get inputValue(): string | number {
			return this.$n(this.valueInternal);
		}

		private set inputValue(value: number | string) {
			let num: number;
			if (typeof value === 'string') {
				value = value.replace(/[^\d.]/, '').trim();
				if (StringHelper.isEmpty(value)) {
					this.updateValueInternal(1);
					return;
				}
				num = Number.parseFloat(value);
				if (Number.isNaN(num)) {
					this.updateValueInternal(1);
					return;
				}
			} else {
				num = value;
			}

			this.updateValueInternal(num % this.step === 0 ? num : Math.floor(num / this.step) * this.step);
		}

		@Watch('value', {immediate: true})
		private onValueChanged(value: number): void {
			this.valueInternal = value;
			this.$emit('input', this.valueInternal);
		}

		@Watch('menuOpen')
		private onMenuOpenChanged(value: boolean): void {
			if (value) {
				setTimeout(() => {
					const input = this.$refs.menuInput as any;
					if (input === undefined || input.$refs?.input === undefined) return;
					input.$refs.input.select();
				}, 50);
			}
		}

		@Emit('input')
		public input(): number {
			return this.valueInternal;
		}

		private handleKeyDown(event: KeyboardEvent): void {
			switch (event.key) {
				case 'ArrowUp':
				case 'ArrowRight':
				case '+':
					event.preventDefault();
					this.increase();
					break;
				case 'ArrowDown':
				case 'ArrowLeft':
				case '-':
					event.preventDefault();
					this.decrease();
					break;
			}
		}

		private updateValueInternal(value: number, debounce: boolean = false): void {
			this.valueInternal = value;
			clearTimeout(this.debounceTimeout);

			if (this.value === value) return;
			if (this.debounce && debounce) {
				this.debounceTimeout = setTimeout(() => this.input(), 500);
			} else {
				this.input();
			}
		}

		private increase(): void {
			this.updateValueInternal(this.valueInternal + this.step, true);
		}

		private decrease(): void {
			const newValue = this.valueInternal - this.step;
			this.updateValueInternal(newValue < 0 ? 0 : newValue, true);
		}

	}
</script>

<style lang="scss" scoped>

</style>
