<template>
	<v-chip :close="close" :close-icon="icons.close" :color="color" :text-color="textColor" :disabled="disabled" :filter="filter" :label="label"
	        :large="large" :link="link" :outlined="outlined" :pill="pill" :small="small" :x-large="xLarge"
	        :x-small="xSmall" :class="{monospace: monospace}" @click:close="closeClicked" v-on="hasClickListener ? {click: click} : {}">
		<v-icon v-if="loading" :size="iconSize" class="loading-icon">{{ icons.loading }}</v-icon>
		<v-icon v-if="icon && !loading" :color="iconColor" :left="hasContent" :size="iconSize">{{ icon }}</v-icon>
		<slot v-if="!loading"/>
	</v-chip>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Emit, Prop} from "vue-property-decorator";
	import Icon from "@/plugins/icons";

	@Component
	export default class AsiChip extends Vue {

		@Prop({type: String, default: null})
		public icon!: string | null;

		@Prop({type: String, default: null})
		public iconColor!: string | null;

		@Prop({type: String, default: 'grey lighten-3'})
		public color!: null;

		@Prop({type: String, default: ''})
		public textColor!: null;

		@Prop({type: Boolean, default: false})
		public label!: boolean;

		@Prop({type: Boolean, default: false})
		public pill!: boolean;

		@Prop({type: Boolean, default: false})
		public link!: boolean;

		@Prop({type: Boolean, default: false})
		public outlined!: boolean;

		@Prop({type: Boolean, default: false})
		public filter!: boolean;

		@Prop({type: Boolean, default: false})
		public xLarge!: boolean;

		@Prop({type: Boolean, default: false})
		public large!: boolean;

		@Prop({type: Boolean, default: false})
		public small!: boolean;

		@Prop({type: Boolean, default: false})
		public xSmall!: boolean;

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		@Prop({type: Boolean, default: false})
		public disabled!: boolean;

		@Prop({type: Boolean, default: false})
		public close!: boolean;

		@Prop({type: Boolean, default: false})
		public monospace!: boolean;

		private icons = Icon;

		private get iconSize(): number | null {
			if (this.xSmall) return 12;
			if (this.small) return 16;
			if (this.large) return 28;
			if (this.xLarge) return 40;
			return 20;
		}

		private get hasContent(): boolean {
			return (this.$slots.default ?? []).length > 0;
		}

		private get hasClickListener(): boolean {
			return !!this.$listeners.click;
		}

		@Emit('click')
		public click(event: Event): Event {
		    return event;
		}

		@Emit('close')
		public closeClicked(event: Event): Event {
			return event;
		}

	}
</script>

<style lang="scss" scoped>
	@keyframes spin360 {
		0% {
			transform: rotate(0);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	.loading-icon {
		animation: spin360 1s linear infinite;
	}

	.monospace {
		font-family: monospace, monospace;
	}
</style>
