<template>
	<v-stepper-content :step="step" class="pa-0">
		<AsiFadeTransition>
			<v-progress-linear v-show="loading" absolute indeterminate/>
		</AsiFadeTransition>

		<div :class="{'ma-6': !unwrapped}">
			<slot/>
		</div>
	</v-stepper-content>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import AsiFadeTransition from "@/components/common/AsiFadeTransition.vue";

	@Component({
		components: {AsiFadeTransition}
	})
	export default class AsiStepperContent extends Vue {

		@Prop({type: [String, Number], required: true})
		public step!: number | string;

		@Prop({type: Boolean, default: false})
		public loading!: boolean;

		@Prop({type: Boolean, default: false})
		public unwrapped!: boolean;

	}
</script>

<style lang="scss" scoped>

</style>
