<template>
	<AsiDialogFormLayout v-if="model !== null" :icon="icons.category" :title="$t('category.terms.create')"
	                     :open="open" :loading="loading" :valid="valid" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped>

		<v-card-text class="pa-0">
			<v-form v-model="valid">
				<div class="pa-6">
					<v-row>
						<v-col>
							<AsiSelect clearable
							                 :items="categories"
							                 v-model="rootCategoryId" autocomplete="off"
							                 :label="$t('category.rootCategory.singular')" />
						</v-col>
					</v-row>
				</div>
				<v-divider />
				<div class="pa-6">
					<v-row>
						<v-col>
							<TranslatedValueFormPart class="pa-0" :label="$t('item.name')" :model="model.name" required/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<TranslatedValueFormPart class="pa-0" :label="$t('category.description')" :model="model.description" text-area/>
						</v-col>
					</v-row>
				</div>
			</v-form>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import {Component, Emit} from "vue-property-decorator";
	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
	import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
	import Snackbar from "@/helpers/Snackbar";
	import CategoryCreate from "@/models/category/CategoryCreate";
	import TranslatedValueFormPart from "@/components/translated-value/admin/TranslatedValueFormPart.vue";
	import AsiRadioGroup from "@/components/common/AsiRadioGroup";
	import AsiTextField from "@/components/common/AsiTextField";
	import AsiSelectSimple from "@/components/common/AsiSelectSimple";
	import TranslatedValueHelper from "@/models/translated-value/TranslatedValueHelper";
	import i18n from "@/plugins/i18n";
	import AsiSelect from "@/components/common/AsiSelect";

	@Component({
		components: {
			AsiSelect,
			AsiSelectSimple, AsiTextField, AsiRadioGroup, TranslatedValueFormPart, AsiDialogFormLayout}
	})
	export default class CategoryCreateDialog extends AsiDialogForm {

		private model: CategoryCreate = new CategoryCreate();
		private categories: { text: string; value: number | string }[] = [];
		private rootCategoryId: string | null = null;

		public created(): void {
			this.loadCategories();
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			const form = this.$refs.form as HTMLFormElement;
			this.reset([this.model], form);
			return event;
		}

		@Emit('save')
		public save(id: string): string {
			const form = this.$refs.form as HTMLFormElement;
			this.reset([], form);
			this.model = new CategoryCreate();
			return id;
		}

		private loadCategories(): void {
			this.loading = true;
			this.$categoryServiceAdmin.categories(null, null)
				.then(res => {
					this.categories = res.data.map(c => ({ text: TranslatedValueHelper.get(c.name, i18n.locale, true) ?? '?', value: c.id }));
				})
				.finally(() => this.loading = false);
		}

		private requestSave(): void {
			if (!this.valid) return;

			this.loading = true;
			this.$categoryServiceAdmin.create(this.rootCategoryId, this.model)
				.then(res => {
					this.save(res.id);
				})
				.catch(() => Snackbar.createError())
				.finally(() => this.loading = false);
		}
	}
</script>

<style lang="scss" scoped>

</style>
