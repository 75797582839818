<template>
	<transition name="fade">
		<slot/>
	</transition>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component} from "vue-property-decorator";

	@Component
	export default class AsiFadeTransition extends Vue {

	}
</script>

<style lang="scss" scoped>
	.fade-enter-active, .fade-leave-active {
		transition: opacity .5s;
	}

	.fade-enter, .fade-leave-to {
		/* .fade-leave-active below version 2.1.8 */
		opacity: 0;
	}
</style>
