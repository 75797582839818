<template>
	<AsiDialogFormLayout :icon="icons.shipmentType" :title="$t('shipmentType.terms.create')"
	                     :open="open" :loading="loading" :valid="valid" :errors="errors"
	                     @save="requestSave" @cancel="cancel" unwrapped scrollable>
		<v-card-text class="pa-0">
			<v-form v-if="model !== null" v-model="valid">
				<div class="pa-6">
					<v-row>
						<v-col>
							<TranslatedValueFormPart :label="$t('shipmentType.name')" :model="model.name" required/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<AsiRadioGroup v-model="model.deliveryMethod" row class="ma-0">
								<v-radio v-for="dm in deliveryMethods" :key="dm.value" :value="dm.value" :label="dm.text" class="mr-6"/>
							</AsiRadioGroup>
						</v-col>
					</v-row>
				</div>
				<v-divider/>
				<div class="pa-6">
					<v-row class="flex align-center">
						<v-col>
							<AsiTextField v-model="model.postage" :rules="model.rules('postage')"
							              :label="$t('shipmentType.postage')" :placeholder="$t('shipmentType.postage')"
							              class="input-required" :suffix="currencyCode" clearable />
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<AsiTextField v-model="model.externalId" :rules="model.rules('externalId')"
							              :label="$t('paymentType.externalId')" :placeholder="$t('paymentType.externalId')" clearable/>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<AsiCheckbox v-model="model.isBulkyGoodsCharged" :label="$t('shipmentType.isBulkyGoodsCharged')"
							             class="mr-6 mt-0"/>
						</v-col>
					</v-row>
				</div>
			</v-form>
		</v-card-text>
	</AsiDialogFormLayout>
</template>

<script lang="ts">
	import AsiDialogForm from "@/components/common/AsiDialogForm.vue";
	import AsiDialogFormLayout from "@/components/common/AsiDialogFormLayout.vue";
	import {Component, Emit} from "vue-property-decorator";
	import ShipmentTypeCreate from "../../../models/shipment-type/ShipmentTypeCreate";
	import TranslatedValueFormPart from "@/components/translated-value/admin/TranslatedValueFormPart.vue";
	import AsiTextField from "@/components/common/AsiTextField";
	import AsiCheckbox from "@/components/common/AsiCheckbox";
	import AsiRadioGroup from "@/components/common/AsiRadioGroup";
	import {DeliveryMethod} from "@/helpers/constants";
import EnumHelper from "@/helpers/EnumHelper";

	@Component({
		components: {AsiRadioGroup, AsiCheckbox, AsiTextField, TranslatedValueFormPart, AsiDialogFormLayout}
	})
	export default class ShipmentTypeCreateDialog extends AsiDialogForm {

		private model: ShipmentTypeCreate = new ShipmentTypeCreate();

		private get currencyCode(): string {
			//TODO: maybe later replace with configurable currency
			return 'CHF';
		}

		private get deliveryMethods(): {text: string, value: string | number}[] {
			return EnumHelper.toSelectItems(DeliveryMethod, true);
		}

		private requestSave(): void {
			if (!this.valid) return;

			this.loading = true;
			this.$shipmentTypeServiceAdmin.create(this.model)
				.then(this.save)
				.catch(this.handleErrors)
				.finally(() => this.loading = false);
		}

		@Emit('save')
		public save(): void {
			return;
		}

		@Emit('cancel')
		public cancel(event: Event): Event {
			const form = this.$refs.form as HTMLFormElement;
			this.reset([this.model], form);
			return event;
		}

	}
</script>

<style lang="scss" scoped>

</style>
