<template>
	<v-stepper-step :step="step"
	                :editable="editable" :complete="complete"
	                :color="complete ? 'success' : 'primary'"
	                :complete-icon="icons.yes" :edit-icon="complete ? icons.yes : icons.edit"
					class="stepper-step">
		<slot/>
	</v-stepper-step>
</template>

<script lang="ts">
	import Vue from 'vue';
	import {Component, Prop} from "vue-property-decorator";
	import Icon from "@/plugins/icons";

	@Component
	export default class AsiStepperStep extends Vue {

		@Prop({type: [String, Number], required: true})
		public step!: number | string;

		@Prop({type: Boolean, default: false})
		public editable!: boolean;

		@Prop({type: Boolean, default: false})
		public complete!: boolean;

		private icons = Icon;

	}
</script>

<style lang="scss" scoped>
	.stepper-step {
		flex-basis: auto;
	}
</style>
